import React from 'react'
import { Modal } from 'antd'
// import { treeData } from './data/treeData'
import { CardTree } from './CardTree'
import Tree from '~/components/tree-components/Tree'
import TreeNode from '~/components/tree-components/TreeNode'

export default function SeedTreModal(props) {
  const { action, t, monsterDetail } = props

  let renderTreeNodes = null
  if (
    monsterDetail &&
    monsterDetail?.mint_type === 10 &&
    monsterDetail?.seedTrees?.length > 0
  ) {
    renderTreeNodes = monsterDetail?.seedTrees
      ?.slice()
      .reverse()
      .map((par, i) => (
        <TreeNode
          className={`${par?.seedTrees?.length === 1 && 'one__parrent'}`}
          key={i.toString()}
          label={<CardTree data={par} children={0} />}
        >
          {par.childrens
            ?.slice()
            .reverse()
            .map((par1, ii) => (
              <TreeNode
                key={ii.toString() + 'sub'}
                label={<CardTree data={par1} />}
              />
            ))}
        </TreeNode>
      ))
  }

  return (
    <>
      <Modal
        className="treeModal"
        centered
        title={
          <h3 className="flex justify-center text-xl">
            {t('common.btn.seedTree')}
          </h3>
        }
        footer={null}
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        width={'auto'}
      >
        <Tree
          lineWidth={'8px'}
          lineColor={'#1890ff'}
          lineHeight={'20px'}
          nodePadding={'20px'}
          label={<CardTree data={monsterDetail} />}
        >
          {renderTreeNodes}
        </Tree>
      </Modal>
    </>
  )
}
