import {
  CHANGE_LOCALE,
  TOGGLE_MOBILE_NAV,
  UPDATE_BALENCE_OAS,
  UPDATE_INFO_USER
} from '~/common/constants/ThemeConstant'

export function onLocaleChange(locale) {
  return {
    type: CHANGE_LOCALE,
    locale,
  }
}

export function updateBalenceOas(balanceOAS) {
  return {
    type: UPDATE_BALENCE_OAS,
    balanceOAS,
  }
}

export function updateInfoUser(user) {
  return {
    type: UPDATE_INFO_USER,
    user,
  }
}

export function onMobileNavToggle(mobileNav) {
  return {
    type: TOGGLE_MOBILE_NAV,
    mobileNav,
  }
}

export default {
  onLocaleChange,
  updateBalenceOas,
  updateInfoUser,
  onMobileNavToggle
}
