import { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyShop from '~/components/shared-components/BodyShop'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useShop } from '~/views/redux/hooks/useShop'
import ShopItemCardTemp from '../components/ShopItemCardTemp'
import { getNameItem } from '~/helpers/ItemName'
import TransactionCompletedShop from '~/components/Modals/TransactionCompletedShop'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'

export const TrainingItem = () => {
  const { t } = useTranslation()
  const { actions } = useShop()
  const { actions: actionsCommon } = useCommon()
  const [boxs, setBoxs] = useState()
  const [loadingModal, setLoadingModal] = useState(false)
  const [boxActive, setBoxActive] = useState()
  const [txHash, setTxHash] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const [tema, setTema] = useState(1)
  const {
    actions: actionTheme,
    data: { user },
  } = useTheme()

  const { actions: actionsProfile } = useAccountProfile()

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = async () => {
    setIsModalSuccessOpen(false)
  }

  const loadBoxs = async () => {
    // let data = await ContractShop.methods.getListSale().call();
    actions.getDataShop({}, async (res) => {
      let data = await Promise.all(
        res[4]?.data.map((item) => {
          return {
            ...item,
            name: getNameItem(0, item?.id).name,
            img: `${
              process.env.REACT_APP_BASE_URL_IMAGE
            }/public/image/image/items/${getNameItem(0, item?.id).image}.png`,
            price: item?.currentPrice,
            id: item?.id,
            quality: 'SHOP',
          }
        }),
      )
      setBoxs(data)
    })
  }

  const handleBuyShop = async (box, quantity, closeModalCheckout = null) => {
    try {
      setLoadingModal(true)
      setBoxActive(box)
      const typeItem = getNameItem(0, box?.id).id
      let data = {
        address: user?.address_wallet,
        amount: quantity,
        typeItem: typeItem,
      }

      actions.buyItemShop(data, async (res) => {
        setLoadingModal(false)
        closeModalCheckout()
        if (res && Number(res?.statusCode) !== 400) {
          setTxHash(res?.txid)
          await loadBoxs()

          showModalSuccess()
          actionsProfile.getAccountProfile((res) => {
            actionTheme.updateInfoUser({
              ...res?.player_info,
            })
          })
        }
      })
    } catch (error) {
      setLoadingModal(false)
      // eslint-disable-next-line
      console.log(error)
    }
  }

  useEffect(() => {
    actionsCommon.getTema({}, (res) => {
      setTema(res?.tema)
    })
    loadBoxs()
  }, [])
  return (
    <BodyShop>
      <div className="flex flex-col justify-center items-center">
        <div className="">
          <div className="title__shop_item mb-10  ">
            {t('shop.trainingItem').toUpperCase()}
          </div>
        </div>

        <div className="flex flex-wrap gap-5 justify-center max-w-[1396px]">
          {boxs?.map((data, index) => (
            <ShopItemCardTemp
              key={index}
              item={data}
              t={t}
              tema={tema}
              handleConfirm={handleBuyShop}
              loadingModal={loadingModal}
            />
          ))}
        </div>
      </div>
      <TransactionCompletedShop
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        name={`${boxActive?.name}`}
        store={'Shop'}
        txHash={txHash}
      />
    </BodyShop>
  )
}

export default TrainingItem
