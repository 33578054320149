import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/blockchainApi'
import {
  getDataShopSuccess,
  getDataShopFailed,
  GET_DATA_SHOP_START,
} from '../../actions/shop'

/**
 * Get data sale on shop
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListSaleShopApi = (props) => {
  var uri = `/api/shop/list-sale`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListSaleShop(action) {
  try {
    const response = yield call(getListSaleShopApi, action?.payload)
    yield put(getDataShopSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getDataShopFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch Sign Buy Shop
 */
export default function* watchGetListSaleShop() {
  yield takeLatest(GET_DATA_SHOP_START, doGetListSaleShop)
}
