const SubCard = (props) => {
  const { title, children, icon, classCustom } = props
  return (
    <div
      className={`bg-[#000F38] ${
        classCustom ?? 'h-[120px]'
      }  pl-[24px]  py-[10px]`}
      style={{ borderRadius: 10 }}
    >
      <div className="flex items-center mb-2">
        {icon && (
          <img
            style={{ width: 23, height: 25, objectFit: 'contain' }}
            className="mr-2"
            src={icon}
            alt=""
            width={23}
            height={25}
          />
        )}
        <div className="text-[24px]">{title}</div>
      </div>

      <>{children}</>
    </div>
  )
}

export default SubCard
