export const SIGN_BUY_SHOP_START = 'SIGN_BUY_SHOP_START'
export const SIGN_BUY_SHOP_SUCCESS = 'SIGN_BUY_SHOP_SUCCESS'
export const SIGN_BUY_SHOP_FAILED = 'SIGN_BUY_SHOP_FAILED'

export const GET_DATA_SHOP_START = 'GET_DATA_SHOP_START'
export const GET_DATA_SHOP_SUCCESS = 'GET_DATA_SHOP_SUCCESS'
export const GET_DATA_SHOP_FAILED = 'GET_DATA_SHOP_FAILED'

export const BUY_ITEM_SHOP_START = 'BUY_ITEM_SHOP_START'
export const BUY_ITEM_SHOP_SUCCESS = 'BUY_ITEM_SHOP_SUCCESS'
export const BUY_ITEM_SHOP_FAILED = 'BUY_ITEM_SHOP_FAILED'


export const SELL_ITEM_SHOP_START = 'SELL_ITEM_SHOP_START'
export const SELL_ITEM_SHOP_SUCCESS = 'SELL_ITEM_SHOP_SUCCESS'
export const SELL_ITEM_SHOP_FAILED = 'SELL_ITEM_SHOP_FAILED'

//-------------------------Common------------------------//
/**
 * [1]
 * Sign Buy Shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function signBuyShop(payload, onSuccess, onError) {
  return {
    type: SIGN_BUY_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Sign Buy Shop success action
 * @param {*} payload
 * @returns {object}
 */
export function signBuyShopSuccess(payload) {
  return {
    type: SIGN_BUY_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * Sign Buy Shop failed action
 * @returns {object}
 */
export function signBuyShopFailed() {
  return {
    type: SIGN_BUY_SHOP_FAILED,
  }
}

/**
 * [2]
 * Get Data Shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDataShop(payload, onSuccess, onError) {
  return {
    type: GET_DATA_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Get Data Shop success action
 * @param {*} payload
 * @returns {object}
 */
export function getDataShopSuccess(payload) {
  return {
    type: GET_DATA_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * Get Data Shop failed action
 * @returns {object}
 */
export function getDataShopFailed() {
  return {
    type: GET_DATA_SHOP_FAILED,
  }
}

/**
 * [3]
 * Buy item Shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function buyItemShop(payload, onSuccess, onError) {
  return {
    type: BUY_ITEM_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Get Data Shop success action
 * @param {*} payload
 * @returns {object}
 */
export function buyItemShopSuccess(payload) {
  return {
    type: GET_DATA_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * Get Data Shop failed action
 * @returns {object}
 */
export function buyItemShopFailed() {
  return {
    type: BUY_ITEM_SHOP_FAILED,
  }
}

/**
 * [4]
 * Sell item Shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function sellItemShop(payload, onSuccess, onError) {
  return {
    type: SELL_ITEM_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * sell item Shop success action
 * @param {*} payload
 * @returns {object}
 */
export function sellItemShopSuccess(payload) {
  return {
    type: SELL_ITEM_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * Sell item Shop failed action
 * @returns {object}
 */
export function sellItemShopFailed() {
  return {
    type: SELL_ITEM_SHOP_FAILED,
  }
}
export default {
  signBuyShop,
  signBuyShopSuccess,
  signBuyShopFailed,

  getDataShop,
  getDataShopSuccess,
  getDataShopFailed,

  buyItemShop,
  buyItemShopSuccess,
  buyItemShopFailed,

  sellItemShop,
  sellItemShopSuccess, 
  sellItemShopFailed
}
