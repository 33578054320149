import React from 'react'
import { Modal, Row, Col, Button } from 'antd'
export default function modalVerifyFail(props) {
  const { action, t, checkVerify } = props
  return (
    <>
      <Modal
        width={572}
        centered
        title={
          <h3 className="flex justify-center text-[20px] font-normal">
            {t('profile.accountSetting.modal.verifyFail.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[]}
      >
        <div className="flex justify-center gap-5 pt-10  flex-wrap w-full mb-2 ">
          <div>
            <Row>
              <Col span={24}>
                <div className="flex items-center justify-center mt-[-32px] mb-[32px] text-[16px]">
                  <img
                    className="w-[120px] h-[120px] mr-3 "
                    src="../svg/iconFailed.svg"
                    alt=""
                  />
                </div>
                <div className="flex items-center justify-center font__M__plus text-[16px] font-bold">
                  {t('profile.accountSetting.modal.verifyFail.message')}(
                  {checkVerify + '/3'}).
                </div>
              </Col>

              <Col className="flex items-center justify-center w-full my-8 mb-[-44px] ">
                <Button
                  style={{ color: '#FFFFFF' }}
                  className=" w-full h-[44px] border-none text-[16px]"
                  onClick={() => {
                    if (checkVerify === 3) {
                      action?.handleCancelVerifyFail()
                    } else {
                      action?.handleCancel()
                    }
                  }}
                >
                  {t('profile.accountSetting.modal.verifyFail.cancel')}
                </Button>
                <Button
                  className="w-full h-[44px] mt-1 ml-4 rounded-md shadow-md border-none bg-orange-400 text-[16px]"
                  style={{ color: '#fff' }}
                  onClick={() => {
                    if (checkVerify === 3) {
                      action?.handleCancelVerifyFail()
                    } else {
                      action?.handleCancel()
                    }
                  }}
                >
                  {checkVerify === 3
                    ? t('profile.accountSetting.modal.verifyFail.confirm')
                    : t('profile.accountSetting.modal.verifyFail.tryAgain')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}
