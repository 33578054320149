export const getColorParameter = (parameter) => {
  switch (parameter) {
    case 'AGI':
      return '#FEEA60'
    case 'Desert':
      return '#fff'
    case 'DEX':
      return '#60ABFE'
    case 'Earth':
      return '#fff'
    case 'Fire':
      return '#fff'
    case 'Forest':
      return '#fff'
    case 'HP':
      return '#76CC7A'
    case 'INT':
      return '#CC76CC'
    case 'Mountain':
      return '#fff'
    case 'Plains':
      return '#fff'
    case 'SHIELD':
      return '#51c257'
    case 'Slash':
      return '#fff'
    case 'STR':
      return '#FF6262'
    case 'Strike':
      return '#D67D38'
    case 'Thrust':
      return '#fff'
    case 'VIT':
      return '#5B9E5F'
    case 'Volcano':
      return '#fff'
    case 'Wasteland':
      return '#fff'
    case 'Waterfront':
      return '#fff'
    case 'Wind':
      return '#fff'
    default:
      return '#fff'
  }
}

export const getParameter = (parameter) => {
  switch (parameter) {
    case 'health':
      return 'HP'
    case 'strength':
      return 'STR'
    case 'intelligent':
      return 'INT'
    case 'dexterity':
      return 'DEX'
    case 'agility':
      return 'AGI'
    case 'vitality':
      return 'VIT'
    default:
      return 'HP'
  }
}

export const getRankParameter = (parameter) => {
  if (parameter <= 15) {
    return 'lean'
  } else if (parameter <= 30) {
    return 'slightlyThin'
  } else if (parameter <= 70) {
    return 'normal'
  } else if (parameter <= 85) {
    return 'slightlyObese'
  } else if (parameter <= 100) {
    return 'obesity'
  }
}

export const getRanktrainingPolicy = (parameter) => {
  if (parameter <= 15) {
    return 'sparta'
  } else if (parameter <= 30) {
    return 'severe'
  } else if (parameter <= 70) {
    return 'normal'
  } else if (parameter <= 85) {
    return 'sweet'
  } else if (parameter <= 100) {
    return 'doting'
  }
}

export const getRankFatigue = (parameter) => {
  if (parameter <= 50) {
    return 'normal'
  } else if (parameter <= 80) {
    return 'highStress'
  } else if (parameter <= 100) {
    return 'overwork'
  }
}

export const getRankStress = (parameter) => {
  if (parameter <= 50) {
    return 'normal'
  } else if (parameter <= 80) {
    return 'fatigue'
  } else if (parameter <= 100) {
    return 'overStress'
  }
}

export const getTextRankParameter = (parameter) => {
  if (parameter < 150) {
    return 'F'
  } else if (parameter < 300) {
    return 'E'
  } else if (parameter < 450) {
    return 'D'
  } else if (parameter < 600) {
    return 'C'
  } else if (parameter < 750) {
    return 'B'
  } else if (parameter < 900) {
    return 'A '
  } else if (parameter <= 999) {
    return 'S '
  }
}

export function getBgHash(type) {
  switch (type) {
    case 'Genesis Hash':
      return 'linear-gradient(180deg, #860078 0%, #EE87FF 100%)'
    case 'Genesis Box':
      return 'linear-gradient(180deg, #860078 0%, #EE87FF 100%)'
    case 'General Hash':
      return 'linear-gradient(180deg, #8C5400 0%, #FFE27A 100%)'
    case 'General Box':
      return 'linear-gradient(180deg, #8C5400 0%, #FFE27A 100%)'
    case 'Regeneration Hash':
      return 'linear-gradient(180deg, #373737 0%, #C1C1C1 100%)'
    default:
      return 'linear-gradient(180deg, #373737 0%, #C1C1C1 100%)'
  }
}
