import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/blockchainApi'
import {
  getTemaSuccess,
  getTemaFailed,
  GET_TEMA_START,
} from '../../actions/common'

/**
 * get role api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getTemaApi = (props) => {
  var uri = `/api/tema-today`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetTema(action) {
  try {
    const response = yield call(getTemaApi, action?.payload)
    // if (response?.status_code === 200) {
      yield put(getTemaSuccess(response?.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response?.data)
      }
    // } else {
    //   throw response?.data?.message
    // }
  } catch (error) {
    yield put(getTemaFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get role
 */
export default function* watchGetTema() {
  yield takeLatest(GET_TEMA_START, doGetTema)
}
