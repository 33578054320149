import {
  GET_ALL_FARM_START,
  GET_ALL_FARM_SUCCESS,
  GET_ALL_FARM_FAILED,
  GET_ALL_FARM_USER_START,
  GET_ALL_FARM_USER_SUCCESS,
  GET_ALL_FARM_USER_FAILED,
  GET_DETAIL_FARM_START,
  GET_DETAIL_FARM_SUCCESS,
  GET_DETAIL_FARM_FAILED,
  GET_ALL_FARM_SALE_USER_START,
  GET_ALL_FARM_SALE_USER_SUCCESS,
  GET_ALL_FARM_SALE_USER_FAILED,
} from '../actions/farm'

const initialState = {
  isLoading: false,
  allFarms: [],
  total: 0,
  farmDetail: {},
  allFarmsUser: [],
  totalFarmsUser: 0,
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function farmManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FARM_SALE_USER_START:
    case GET_ALL_FARM_USER_START:
    case GET_ALL_FARM_START:
    case GET_DETAIL_FARM_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ALL_FARM_SUCCESS:
      return {
        ...state,
        allFarms: action?.payload?.items,
        total: action?.payload?.count,
        isLoading: false,
      }
    case GET_ALL_FARM_USER_SUCCESS:
    case GET_ALL_FARM_SALE_USER_SUCCESS:
      return {
        ...state,
        allFarmsUser: action?.payload?.items,
        totalFarmsUser: action?.payload?.count,
        isLoading: false,
      }

    case GET_DETAIL_FARM_SUCCESS:
      return {
        ...state,
        farmDetail: action?.payload,
        isLoading: false,
      }

    case GET_ALL_FARM_SALE_USER_FAILED:
    case GET_ALL_FARM_FAILED:
    case GET_ALL_FARM_USER_FAILED:
    case GET_DETAIL_FARM_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
