import { convertOasToUSD, formatNumber } from '~/helpers/common'
import { ReMonsterTwoBorderSm } from '../../Button/ReMonsterTwoBorderSm'
import { useHistory } from 'react-router-dom'
import { ROUTE } from '~/views/routes/config'
import { getBgHash } from '~/helpers/get-color-parameter'

export const BoxCardHash = (props) => {
  const { data, button, tema, img, t } = props
  const history = useHistory()
  return (
    <div
      className={`card__monster w-[250px] h-[350px] ${
        data?.asset !== 'GENERAL BOX' &&
        data?.asset !== 'GENESIS BOX' &&
        'cursor-pointer'
      } `}
      onClick={(e) => {
        if (!e.target.closest('.common__btn')) {
          if (data?.asset !== 'GENERAL BOX' && data?.asset !== 'GENESIS BOX') {
            history.push(
              `${ROUTE.HASH_NFT_DETAIL.SUB_PATH}/${
                data?.tokenId ?? data?.nft_id
              }/${data?.contractAddress}/${undefined}/${data?.owner}/${
                data?.item_type
              }`,
            )
          }
        }
      }}
    >
      <div className="relative flex items-center justify-center h-full">
        <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
          <div
            className="h-full flex flex-col items-center p-[10px] text-[18px]"
            style={{
              background: getBgHash(data?.type_name),
            }}
          >
            {data?.type === 'Regeneration_Hash' ? (
              <div className="flex items-start absolute left-[2%] top-[2%]">
                <img
                  className="mr-2 w-[32px] h-[32px] object-contain "
                  src={`../svg/qualityTag/${data?.quality}.svg`}
                  alt=""
                />
                <div className="title__shop_card text-[14px] ">
                  {data?.name}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-start absolute left-[2%] top-[2%]">
                <div className="title__card">
                  {data?.asset === 'HASH CHIP NFT'
                    ? data?.name
                    : data?.type_name}
                </div>
                <div className="title__card">
                  {data?.type?.replace('GROUP', 'Group')}
                </div>
                {data?.asset !== 'GENERAL BOX' &&
                data?.asset !== 'GENESIS BOX' ? (
                  <div className="py-2">
                    <div
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        borderRadius: 4,
                      }}
                      className={`flex items-center justify-center min-w-[48px] h-[24px] text-[12px] rounded-[4px] px-2 absolute left-[2%] top-[80%]`}
                    >
                      {data?.token_id || data?.tokenId || data?.nft_id
                        ? `#${data?.token_id ?? data?.tokenId ?? data?.nft_id}`
                        : '#0'}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div
              className="h-full flex items-center justify-center"
              style={{ marginTop: '40px' }}
            >
              <img
                alt=""
                className={`card__monster_img object-contain ease-in-out duration-300 ${
                  data?.type_name === 'Hash Chip NFT'
                    ? 'w-[125px] h-[125px]'
                    : 'w-[160px] h-[160px]'
                }`}
                src={img}
              />
            </div>
            {data?.type === 'Regeneration_Hash' ? (
              <>
                <div className="text-[12px] flex w-full justify-between">
                  <div>{t('quality')}</div>
                  <div>{data?.quantity ?? data?.amount}</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
            {data?.price && (
              <>
                <div className="flex items-center justify-center">
                  <img
                    className="w-[16px] h-[16px] object-contain	"
                    src="../imgs/coins/oasis.svg"
                    alt=""
                  />
                  <div className="relative whitespace-nowrap flex ml-2">
                    {formatNumber(data?.price)}
                  </div>
                </div>

                <div className="text-[12px] mb-1">
                  ${formatNumber(convertOasToUSD(data?.price, tema?.tema))}
                </div>
              </>
            )}

            <ReMonsterTwoBorderSm
              title={button?.title}
              functionButton={button?.functionButton}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
