import { CaretUpOutlined } from '@ant-design/icons'
import { Col, Row, Collapse, Slider, InputNumber } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  rankTagData,
  basicParameterData,
  growthTypeData,
  terrainCompatibilityData,
  skillTypeData,
  specialtyElementData,
  personalityData,
  innateTraitsEffectData,
  acquiredTraitsEffectData,
  seedData,
  growthPotentialData,
} from './dataFilter'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'

export default function FilterMonster(props) {
  const {
    rankTagFilter,
    mainSeedFilter,
    subSeedFilter,
    basicParameter,
    growthPotential,
    growthType,
    valueLifeSpan,
    terrainCompatibility,
    skillType,
    specialtyElement,
    personality,
    innateTraitsByEffect,
    acquiredTraitsByEffect,
    setRankTagFilter,
    setMainSeedFilter,
    setSubSeedFilter,
    setBasicParameter,
    setGrowthPotential,
    setGrowthType,
    setValueLifeSpan,
    setTerrainCompatibility,
    setSkillType,
    setSpecialtyElement,
    setPersonality,
    setInnateTraitsByEffect,
    setAcquiredTraitsByEffect,
    classCustom,
  } = props
  const { t } = useTranslation()

  const onChange = (newValue) => {
    setValueLifeSpan(newValue)
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  return (
    <div
      className={`${classCustom ?? 'filter__common'
        } bg-[#0d2758] p-[16px] max-w-[372px] w-[372px]`}
    >
      {isMobile ? (
        <></>
      ) : (
        <>
          {' '}
          <Row className="">
            <Col span={12}>
              <div className="text-[16px]"> {t('filter')}</div>
            </Col>
            <Col span={12} className="flex justify-end">
              <div
                className="text-[16px] text-[#FAAD14] cursor-pointer"
                onClick={() => {
                  setRankTagFilter([])
                  setMainSeedFilter([])
                  setSubSeedFilter([])
                  setBasicParameter({
                    health: [],
                    strength: [],
                    intelligent: [],
                    dexterity: [],
                    agility: [],
                    vitality: [],
                  })
                  setGrowthPotential({
                    hp: [],
                    str: [],
                    int: [],
                    dex: [],
                    agi: [],
                    vit: [],
                  })
                  setGrowthType([])
                  setValueLifeSpan([0, 5000])
                  setTerrainCompatibility([])
                  setSkillType([])
                  setSpecialtyElement([])
                  setPersonality([])
                  setInnateTraitsByEffect([])
                  setAcquiredTraitsByEffect([])
                }}
              >
                {t('clearAll')}
              </div>
            </Col>
          </Row>
          <hr className="my-5" />
        </>
      )}

      <Collapse
        defaultActiveKey={[
          'monsterRank',
          // 'mainSeed',
          // 'subSeed',
          // 'basicParameter',
          // 'growthPotential',
          // 'growthType',
          // 'lifeSpan',
          // 'terrainCompatibility',
          // 'specialtyElement',
          // 'personality',
          // 'innateTraitsByEffect',
          // 'acquiredTraitsByEffect',
        ]}
        expandIconPosition={'end'}
        items={[
          //MonsterRank
          {
            key: 'monsterRank',
            label: t('common.filter.tag.monsterRank'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {rankTagData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center w-[40px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 ${rankTagFilter?.find((rank) => rank === data) &&
                      'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      rankTagFilter?.find((rank) => rank === data)
                        ? setRankTagFilter(
                          rankTagFilter.filter((rank) => rank !== data),
                        )
                        : setRankTagFilter((oldRank) => [...oldRank, data])
                    }}
                  >
                    <img
                      className="w-[30px] h-[30px] object-contain"
                      src={`../svg/rankTag/${data}.svg`}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            ),
          },
          //mainSeed
          {
            key: 'mainSeed',
            label: t('common.filter.tag.mainSeed'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {seedData?.map((data, i) => (
                  <div
                    key={i?.toString()}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${mainSeedFilter?.find(
                      (item) => item === data?.value,
                    ) && 'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      mainSeedFilter?.find(
                        (item) => item === data?.value,
                      )
                        ? setMainSeedFilter(
                          mainSeedFilter.filter(
                            (item) => item !== data?.value,
                          ),
                        )
                        : setMainSeedFilter((oldItem) => [
                          ...oldItem,
                          data?.value,
                        ])
                    }}
                  >
                    <div
                      className={`text-[14px] font__M__plus font-bold`}
                      style={{ fontWeight: 700 }}
                    >
                      {data?.name}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //subSeed
          {
            key: 'subSeed',
            label: t('common.filter.tag.subSeed'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {seedData?.map((data, i) => (
                  <div
                    key={i?.toString()}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${subSeedFilter?.find(
                      (item) => item === data?.value,
                    ) && 'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      subSeedFilter?.find(
                        (item) => item === data?.value,
                      )
                        ? setSubSeedFilter(
                          subSeedFilter.filter(
                            (item) => item !== data?.value,
                          ),
                        )
                        : setSubSeedFilter((oldItem) => [
                          ...oldItem,
                          data?.value,
                        ])
                    }}
                  >
                    <div
                      className={`text-[14px] font__M__plus font-bold`}
                      style={{ fontWeight: 700 }}
                    >
                      {data?.name}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //basicParameter
          {
            key: 'basicParameter',
            label: t('common.filter.tag.basicParameter'),
            children: (
              <div className="flex flex-col gap-[10px]">
                {basicParameterData?.map((data) => (
                  <div className="flex flex-col" key={data?.type}>
                    <div className="text-[14px] mb-3 font__M__plus font-bold">
                      {t(data?.type)}
                    </div>

                    <div className="flex flex-wrap gap-[10px]">
                      {data?.rank?.map((subData) => (
                        <div
                          key={subData}
                          className={`flex items-center justify-center w-[40px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 ${basicParameter[data?.type]?.find(
                            (rank) => rank === subData,
                          ) && 'bg-[#1950b3]'
                            }`}
                          onClick={() => {
                            const findBasicParameter = basicParameter[
                              data?.type
                            ]?.find((rank) => rank === subData)

                            if (findBasicParameter) {
                              const newObjParam = {
                                ...basicParameter,
                              }
                              newObjParam[data?.type] = newObjParam[
                                data?.type
                              ].filter((rank) => rank !== subData)
                              setBasicParameter(newObjParam)
                            } else {
                              const newObjParam = {
                                ...basicParameter,
                              }
                              newObjParam[data?.type] = [
                                ...basicParameter[data?.type],
                                subData,
                              ]
                              setBasicParameter(newObjParam)
                            }
                          }}
                        >
                          <img
                            className="w-[15px] h-[15px] object-contain"
                            src={`../svg/rankTagText/${subData}.svg`}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //growthPotential
          {
            key: 'growthPotential',
            label: t('common.filter.tag.growthPotential'),
            children: (
              <div className="flex flex-col gap-[10px]">
                {growthPotentialData?.map((data) => (
                  <div className="flex flex-col" key={data?.type}>
                    <div className="text-[14px] mb-3 font__M__plus font-bold">
                      {data?.type?.toUpperCase(``)}
                    </div>

                    <div className="flex flex-wrap gap-[10px]">
                      {data?.rank?.map((subData) => (
                        <div
                          key={subData}
                          className={`flex items-center justify-center w-[40px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 ${growthPotential[data?.type]?.find(
                            (rank) => rank === subData,
                          ) && 'bg-[#1950b3]'
                            }`}
                          onClick={() => {
                            const findBasicParameter = growthPotential[
                              data?.type
                            ]?.find((rank) => rank === subData)

                            if (findBasicParameter) {
                              const newObjParam = {
                                ...growthPotential,
                              }
                              newObjParam[data?.type] = newObjParam[
                                data?.type
                              ].filter((rank) => rank !== subData)
                              setGrowthPotential(newObjParam)
                            } else {
                              const newObjParam = {
                                ...growthPotential,
                              }
                              newObjParam[data?.type] = [
                                ...growthPotential[data?.type],
                                subData,
                              ]
                              setGrowthPotential(newObjParam)
                            }
                          }}
                        >
                          <img
                            className="w-[15px] h-[15px] object-contain"
                            src={`../svg/rankTagText/${subData}.svg`}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //growthType
          {
            key: 'growthType',
            label: t('common.filter.tag.growthType'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {growthTypeData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer py-[4px] px-[8px] hover:bg-opacity-80 ${growthType?.find((rank) => rank === data) &&
                      'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      growthType?.find((rank) => rank === data)
                        ? setGrowthType(
                          growthType.filter((rank) => rank !== data),
                        )
                        : setGrowthType((oldRank) => [...oldRank, data])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {data}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //lifeSpan
          {
            key: 'lifeSpan',
            label: t('common.filter.tag.lifeSpan'),
            children: (
              <div>
                <div className="w-full font__M__plus font-medium">
                  <Slider
                    style={{ fontFamily: 'M plus', fontWeight: 'medium' }}
                    onChange={onChange}
                    range
                    step={1}
                    min={0}
                    max={5000}
                    value={valueLifeSpan}
                  />

                  <div className="flex justify-between items-center mb-4">
                    <div className="text-[12px]">0</div>
                    <div className="text-[12px]"> 5000</div>
                  </div>

                  <Row className="flex items center justify-between">
                    <Col span={11}>
                      <InputNumber
                        style={{ fontFamily: 'M plus', fontWeight: 'medium' }}
                        className="h-[44px] w-full"
                        defaultValue={valueLifeSpan[0]}
                        value={valueLifeSpan[0]}
                        min={0}
                        max={valueLifeSpan[1]}
                        onChange={(value) => {
                          setValueLifeSpan([
                            value === null ? 0 : parseFloat(value),
                            valueLifeSpan[1],
                          ])
                        }}
                      />
                    </Col>
                    <Col span={1} className="flex items-center justify-center">
                      -
                    </Col>
                    <Col span={11}>
                      <InputNumber
                        min={valueLifeSpan[0]}
                        max={5000}
                        className="h-[44px] w-full"
                        defaultValue={valueLifeSpan[1]}
                        value={valueLifeSpan[1]}
                        onChange={(value) =>
                          setValueLifeSpan([
                            valueLifeSpan[0],
                            value === null ? 0 : parseFloat(value),
                          ])
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            ),
          },
          //terrainCompatibility
          {
            key: 'terrainCompatibility',
            label: t('common.filter.tag.terrainCompatibility'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {terrainCompatibilityData?.map((data, i) => (
                  <div
                    key={i?.toString()}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${terrainCompatibility?.find(
                      (item) => item.name === data?.name,
                    ) && 'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      terrainCompatibility?.find(
                        (item) => item.name === data?.name,
                      )
                        ? setTerrainCompatibility(
                          terrainCompatibility.filter(
                            (item) => item.name !== data?.name,
                          ),
                        )
                        : setTerrainCompatibility((oldItem) => [
                          ...oldItem,
                          data,
                        ])
                    }}
                  >
                    {data?.value === 0 ? null : (
                      <>
                        <img
                          style={{ width: 24, height: 24 }}
                          className="object-contain mr-1"
                          src={`../imgs/filter/${data?.name}.png`}
                          width={24}
                          height={24}
                          alt=""
                        />
                      </>
                    )}
                    <div
                      className={`${data?.color} font__M__plus font-bold`}
                      style={{ fontWeight: 700 }}
                    >
                      {data?.name}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //skillType
          {
            key: 'skillType',
            label: t('common.filter.tag.skillType'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {skillTypeData?.map((data, i) => (
                  <div
                    key={i?.toString()}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${skillType?.find((item) => item.name === data?.name) &&
                      'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      skillType?.find((item) => item.name === data?.name)
                        ? setSkillType(
                          skillType.filter(
                            (item) => item.name !== data?.name,
                          ),
                        )
                        : setSkillType((oldItem) => [...oldItem, data])
                    }}
                  >
                    <div
                      className={`${data?.color} font__M__plus font-bold`}
                      style={{ fontWeight: 700 }}
                    >
                      {data?.name}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //specialtyElement
          {
            key: 'specialtyElement',
            label: t('common.filter.tag.specialtyElement'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {specialtyElementData?.map((data, i) => (
                  <div
                    key={i?.toString()}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${specialtyElement?.find(
                      (item) => item.value === data?.value,
                    ) && 'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      specialtyElement?.find((item) => item.value === data?.value)
                        ? setSpecialtyElement(
                          specialtyElement.filter(
                            (item) => item.value !== data?.value,
                          ),
                        )
                        : setSpecialtyElement((oldItem) => [...oldItem, data])
                    }}
                  >
                    <img
                      style={{ width: 24, height: 24 }}
                      className="object-contain mr-1"
                      src={`../imgs/filter/${data?.name}.png`}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div
                      className={`${data?.color} font__M__plus font-bold`}
                      style={{ fontWeight: 700 }}
                    >
                      {data?.name}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //personality
          {
            key: 'personality',
            label: t('common.filter.tag.personality'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {personalityData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${personality?.find((rank) => rank === data) &&
                      'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      personality?.find((rank) => rank === data)
                        ? setPersonality(
                          personality.filter((rank) => rank !== data),
                        )
                        : setPersonality((oldRank) => [...oldRank, data])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {data}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //innateTraitsByEffect
          {
            key: 'innateTraitsByEffect',
            label: t('common.filter.tag.innateTraitsByEffect'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {innateTraitsEffectData?.map((data, i) => (
                  <div
                    key={`${i?.toString()}-${data}`}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${innateTraitsByEffect?.find((rank) => rank === data) &&
                      'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      innateTraitsByEffect?.find((rank) => rank === data)
                        ? setInnateTraitsByEffect(
                          innateTraitsByEffect.filter(
                            (rank) => rank !== data,
                          ),
                        )
                        : setInnateTraitsByEffect((oldRank) => [
                          ...oldRank,
                          data,
                        ])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {data}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //acquiredTraitsByEffect
          {
            key: 'acquiredTraitsByEffect',
            label: t('common.filter.tag.acquiredTraitsByEffect'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {acquiredTraitsEffectData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${acquiredTraitsByEffect?.find((rank) => rank === data) &&
                      'bg-[#1950b3]'
                      }`}
                    onClick={() => {
                      acquiredTraitsByEffect?.find((rank) => rank === data)
                        ? setAcquiredTraitsByEffect(
                          acquiredTraitsByEffect.filter(
                            (rank) => rank !== data,
                          ),
                        )
                        : setAcquiredTraitsByEffect((oldRank) => [
                          ...oldRank,
                          data,
                        ])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {data}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
        expandIcon={({ isActive }) => (
          <CaretUpOutlined
            style={{ fontSize: 16 }}
            rotate={isActive ? 0 : 180}
          />
        )}
      />
    </div>
  )
}
