export const GET_ALL_FARM_START = 'GET_ALL_FARM_START'
export const GET_ALL_FARM_SUCCESS = 'GET_ALL_FARM_SUCCESS'
export const GET_ALL_FARM_FAILED = 'GET_ALL_FARM_FAILED'

export const GET_DETAIL_FARM_START = 'GET_DETAIL_FARM_START'
export const GET_DETAIL_FARM_SUCCESS = 'GET_DETAIL_FARM_SUCCESS'
export const GET_DETAIL_FARM_FAILED = 'GET_DETAIL_FARM_FAILED'

export const GET_ALL_FARM_USER_START = 'GET_ALL_FARM_USER_START'
export const GET_ALL_FARM_USER_SUCCESS = 'GET_ALL_FARM_USER_SUCCESS'
export const GET_ALL_FARM_USER_FAILED = 'GET_ALL_FARM_USER_FAILED'

export const GET_ALL_FARM_SALE_USER_START = 'GET_ALL_FARM_SALE_USER_START'
export const GET_ALL_FARM_SALE_USER_SUCCESS = 'GET_ALL_FARM_SALE_USER_SUCCESS'
export const GET_ALL_FARM_SALE_USER_FAILED = 'GET_ALL_FARM_SALE_USER_FAILED'
//-------------------------Farm------------------------//

/**
 * [1]
 * get all farm start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllFarm(payload, onSuccess, onError) {
  return {
    type: GET_ALL_FARM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all farm success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllFarmSuccess(payload) {
  return {
    type: GET_ALL_FARM_SUCCESS,
    payload: payload,
  }
}

/**
 * get all farm failed action
 * @returns {object}
 */
export function getAllFarmFailed() {
  return {
    type: GET_ALL_FARM_FAILED,
  }
}

/**
 * [2]
 * get detail farm start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDetailFarm(payload, onSuccess, onError) {
  return {
    type: GET_DETAIL_FARM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get detail farm success action
 * @param {*} payload
 * @returns {object}
 */
export function getDetailFarmSuccess(payload) {
  return {
    type: GET_DETAIL_FARM_SUCCESS,
    payload: payload,
  }
}

/**
 * get detail farm failed action
 * @returns {object}
 */
export function getDetailFarmFailed() {
  return {
    type: GET_DETAIL_FARM_FAILED,
  }
}

/**
 * [3]
 * get all farm user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllFarmUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_FARM_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all farm user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllFarmUserSuccess(payload) {
  return {
    type: GET_ALL_FARM_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all farm user failed action
 * @returns {object}
 */
export function getAllFarmUserFailed() {
  return {
    type: GET_ALL_FARM_USER_FAILED,
  }
}

/**
 * [4]
 * get all farm sale user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllFarmSaleUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_FARM_SALE_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all farm sale user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllFarmSaleUserSuccess(payload) {
  return {
    type: GET_ALL_FARM_SALE_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all farm sale user failed action
 * @returns {object}
 */
export function getAllFarmSaleUserFailed() {
  return {
    type: GET_ALL_FARM_SALE_USER_FAILED,
  }
}

export default {
  getAllFarm,
  getAllFarmSuccess,
  getAllFarmFailed,

  getDetailFarm,
  getDetailFarmSuccess,
  getDetailFarmFailed,

  getAllFarmUser,
  getAllFarmUserSuccess,
  getAllFarmUserFailed,

  getAllFarmSaleUser,
  getAllFarmSaleUserSuccess,
  getAllFarmSaleUserFailed,
}
