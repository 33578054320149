import {
  GET_ALL_REGENERATION_USER_START,
  GET_ALL_REGENERATION_USER_SUCCESS,
  GET_ALL_REGENERATION_USER_FAILED
} from '../actions/regeneration'

const initialState = {
  isLoading: false,
  allRegenerationUser: [],
  totalRegenerationUser: 0
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function regenerationManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REGENERATION_USER_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ALL_REGENERATION_USER_SUCCESS:
      return {
        ...state,
        allRegenerationUser: action?.payload?.items,
        totalRegenerationUser: action?.payload?.count,
        isLoading: false,
      }

    case GET_ALL_REGENERATION_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
