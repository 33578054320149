import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { convertPrice, formatNumber } from '~/helpers/common'
import { REGEX } from '~/helpers/regex'

export default function CheckoutShopModal(props) {
  const { action, t, box, loadingModal, tema, balance, symbol } = props
  const [form] = Form.useForm()
  const quantityRef = useRef(null)
  const [totalPrice, setTotalPrice] = useState(0)

  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }

  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }
  const [quantity, setQuantity] = useState('1')
  useEffect(() => {
    if (action?.isModalOpen) {
      setTimeout(() => {
        form.setFieldsValue({ quantity: quantity })
      })
    }
  }, [action])
  useEffect(() => {
    setQuantity('1')
    const quantity = 1
    const price = convertPrice(box?.price, tema)
    const total = Number(price) * Number(quantity)
    setTotalPrice(total)
  }, [action?.isModalOpen, box])
  const onChangeQuantity = (e) => {
    setQuantity(e.target.value)
    const quantity = e.target.value
    if (quantity === '') {
      setTotalPrice(0)
    } else if (Number(quantity) === parseInt(quantity, 10)) {
      const price = convertPrice(box?.price, tema)
      const total = Number(price) * Number(quantity)
      setTotalPrice(total)
    }
  }

  const handleBuy = async () => {
    if (loadingModal) return
    form.submit()
  }

  useEffect(() => {
    if (loadingModal === false) {
      form.resetFields(['quantity'])
    }
  }, [loadingModal])

  const handleCancel = () => {
    action?.handleCancel()
    form.resetFields(['quantity'])
  }
  const onFinish = async () => {
    try {
      action?.handleOk(box, quantityRef.current.input.value, () => {
        form.resetFields(['quantity'])
      })
    } catch (error) {}
  }

  const handleCloseModal = () => {
    form.resetFields(['quantity'])
    action?.handleCancel()
  }

  const handleKeyPress = (event) => {
    const charCode = event.charCode
    if (charCode < 48 || charCode > 57) {
      event.preventDefault()
    }
  }
  return (
    <>
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Modal
          key={box.type}
          centered
          title={
            <h3 className="flex justify-center text-xl font-normal">
              {t('modal.checkout_shop.title')}
            </h3>
          }
          open={action?.isModalOpen}
          onCancel={handleCloseModal}
          footer={[
            <div key="buttons" className="flex justify-center">
              <Button
                key="cancel"
                onClick={handleCancel}
                style={cancelButtonStyle}
              >
                {t('modal.checkout_shop.cancel')}
              </Button>
              <Button
                key="ok"
                type="primary"
                onClick={handleBuy}
                style={okButtonStyle}
                loading={loadingModal}
              >
                {t('modal.checkout_shop.ok')}
              </Button>
            </div>,
          ]}
        >
          <div className="font__M__plus text-[16px]">
            <div className="my-5">
              {t('modal.checkout_shop.description', { name: box?.name })}
            </div>
            <div className="my-5">{t('modal.checkout_shop.quantiy')}</div>
            <div className="font__M__plus">
              <Form.Item
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: t('common.schema.required'),
                  },
                  () => ({
                    validator(rule, value) {
                      const notSpace = REGEX.NOT_WHITESPACE_START
                      if (value && !notSpace.test(value)) {
                        return Promise.reject(t('common.schema.mustBeNumeric'))
                      }
                      if (value && value <= 0) {
                        return Promise.reject(
                          t('common.schema.quantityNumeric'),
                        )
                      }

                      const integerPattern = /^-?\d+$/
                      if (value && !integerPattern.test(value)) {
                        return Promise.reject(t('common.schema.mustBeNumeric'))
                      }
                      const numberPattern = /^-?\d+(\.\d+)?$/

                      if (value && !numberPattern.test(value)) {
                        return Promise.reject(t('common.schema.mustBeNumeric'))
                      }
                      const priceBox = convertPrice(box?.price, tema)
                      if (
                        value &&
                        Number(balance) < Number(priceBox) * Number(value)
                      ) {
                        return Promise.reject(
                          t('common.schema.insufficient_balance'),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  type="number"
                  maxLength={7}
                  autoComplete="off"
                  className="bg-white text-black h-[52px] font-bold"
                  // onKeyUp={onChangeQuantity}
                  onChange={onChangeQuantity}
                  onKeyPress={handleKeyPress}
                  placeholder={t('modal.checkout_shop.placeholder')}
                  ref={quantityRef}
                />
              </Form.Item>
            </div>
            <div className="flex justify-between">
              <div>{t('modal.checkout_shop.your_balance')}</div>
              <div>
                {formatNumber(balance)} {symbol ?? 'OAS'}
              </div>
            </div>
            <div className="my-5 flex justify-between font-bold">
              <div>{t('modal.checkout_shop.price')}</div>
              <div>
                {formatNumber(totalPrice)} {symbol ?? 'OAS'}
              </div>
            </div>
          </div>
        </Modal>
      </Form>
    </>
  )
}
