import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import store from './store'
import Routes from './routes'
import { Spin } from 'antd'

function App() {
  return (
      <div className="App">
        <Provider store={store}>
          <Suspense
            fallback={
              <div className="w-full h-[100vh] flex items-center justify-center">
                <Spin spinning={true} size="large">
                  <div className="text-3xl font-bold text-white">
                    {' '}
                    Loading...
                  </div>
                </Spin>
              </div>
            }
          >
            <Router>
              <Routes />
            </Router>
          </Suspense>
        </Provider>
      </div>
  )
}

export default App
