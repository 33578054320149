import { useMemo } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import accountProfileActions from '../actions/accountProfile'

export const useAccountProfile = () => {
  const dispatch = useDispatch()
  const data = useSelector((state) =>
    get(state, 'app.accountProfileManagement'),
  )

  const actions = useMemo(
    () => bindActionCreators(accountProfileActions, dispatch),
    [dispatch],
  )
  return {
    actions,
    data,
  }
}
