export const GET_ACCOUNT_PROFILE_START = 'GET_ACCOUNT_PROFILE_START'
export const GET_ACCOUNT_PROFILE_SUCCESS = 'GET_ACCOUNT_PROFILE_SUCCESS'
export const GET_ACCOUNT_PROFILE_FAILED = 'GET_ACCOUNT_PROFILE_FAILED'

export const UPDATE_ACCOUNT_PROFILE_START =
  'UPDATE_ACCOUNT_PROFILE_START'
export const UPDATE_ACCOUNT_PROFILE_SUCCESS =
  'UPDATE_ACCOUNT_PROFILE_SUCCESS'
export const UPDATE_ACCOUNT_PROFILE_FAILED =
  'UPDATE_ACCOUNT_PROFILE_FAILED'

export const UPDATE_AVATAR_START = 'UPDATE_AVATAR_START'
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS'
export const UPDATE_AVATAR_FAILED = 'UPDATE_AVATAR_FAILED'

export const UPDATE_PHONE_NUMBER_START = 'UPDATE_PHONE_NUMBER_START'
export const UPDATE_PHONE_NUMBER_SUCCESS = 'UPDATE_PHONE_NUMBER_SUCCESS'
export const UPDATE_PHONE_NUMBER_FAILED = 'UPDATE_PHONE_NUMBER_FAILED'

export const GET_PLAYER_INFO_START = 'GET_PLAYER_INFO_START'
export const GET_PLAYER_INFO_SUCCESS = 'GET_PLAYER_INFO_SUCCESS'
export const GET_PLAYER_INFO_FAILED = 'GET_PLAYER_INFO_FAILED'

export const SEND_VERIFY_EMAIL_START = 'SEND_VERIFY_EMAIL_START'
export const SEND_VERIFY_EMAIL_SUCCESS = 'SEND_VERIFY_EMAIL_SUCCESS'
export const SEND_VERIFY_EMAIL_FAILED = 'SEND_VERIFY_EMAIL_FAILED'

export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED'
//-------------------------Common------------------------//
/**
 * [1]
 * Get Account Profile start action
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAccountProfile(onSuccess, onError) {
  return {
    type: GET_ACCOUNT_PROFILE_START,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get Account Profile success action
 * @param {*} payload
 * @returns {object}
 */
export function getAccountProfileSuccess(payload) {
  return {
    type: GET_ACCOUNT_PROFILE_SUCCESS,
    payload: payload,
  }
}

/**
 * get Account Profile failed action
 * @returns {object}
 */
export function getAccountProfileFailed() {
  return {
    type: GET_ACCOUNT_PROFILE_FAILED,
  }
}

/**
 * [2]
 * Update Account Profile start action
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */

export function updateAccountProfile(payload, onSuccess, onError) {
  return {
    type: UPDATE_ACCOUNT_PROFILE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update Account Profile success action
 * @param {*} payload
 * @returns {object}
 */

export function updateAccountProfileSuccess(payload) {
  return {
    type: UPDATE_ACCOUNT_PROFILE_SUCCESS,
    payload: payload,
  }
}

/**
 * update Account Profile failed action
 * @returns {object}
 */
export function updateAccountProfileFailed() {
  return {
    type: UPDATE_ACCOUNT_PROFILE_FAILED,
  }
}

/**
 * [3]
 * Update Avatar Account Profile start action
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateAvatarProfile(payload, onSuccess, onError) {
  return {
    type: UPDATE_AVATAR_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Update Avatar Account Profile success action
 * @param {*} payload
 * @returns {object}
 */
export function updateAvatarSuccess(payload) {
  return {
    type: UPDATE_AVATAR_SUCCESS,
    payload: payload,
  }
}

/**
 * Update Avatar Account Profile failed action
 * @returns {object}
 */

export function updateAvatarFailed() {
  return {
    type: UPDATE_AVATAR_FAILED,
  }
}

/**
 * [4]
 * Update phone number start action
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updatePhoneNumber(payload, onSuccess, onError) {
  return {
    type: UPDATE_PHONE_NUMBER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Update phone number success action
 * @param {*} payload
 * @returns {object}
 */
export function updatePhoneNumberSuccess(payload) {
  return {
    type: UPDATE_PHONE_NUMBER_SUCCESS,
    payload: payload,
  }
}

/**
 * Update phone number failed action
 * @returns {object}
 */

export function updatePhoneNumberFailed() {
  return {
    type: UPDATE_PHONE_NUMBER_FAILED,
  }
}

/**
 * [5]
 * get player info start action
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getPlayerInfo(payload, onSuccess, onError) {
  return {
    type: GET_PLAYER_INFO_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get player info success action
 * @param {*} payload
 * @returns {object}
 */
export function getPlayerInfoSuccess(payload) {
  return {
    type: GET_PLAYER_INFO_SUCCESS,
    payload: payload,
  }
}

/**
 * get player info failed action
 * @returns {object}
 */

export function getPlayerInfoFailed() {
  return {
    type: GET_PLAYER_INFO_FAILED,
  }
}

/**
 * [7]
 * send verify email start action
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function sendVerifyEmail(payload, onSuccess, onError) {
  return {
    type: SEND_VERIFY_EMAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * send verify email success action
 * @param {*} payload
 * @returns {object}
 */
export function sendVerifyEmailSuccess(payload) {
  return {
    type: SEND_VERIFY_EMAIL_SUCCESS,
    payload: payload,
  }
}

/**
 * send verify email failed action
 * @returns {object}
 */

export function sendVerifyEmailFailed() {
  return {
    type: SEND_VERIFY_EMAIL_FAILED,
  }
}

/**
 * [8]
 * verify email start action
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function verifyEmail(payload, onSuccess, onError) {
  return {
    type: VERIFY_EMAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * verify email success action
 * @param {*} payload
 * @returns {object}
 */
export function verifyEmailSuccess(payload) {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    payload: payload,
  }
}

/**
 * verify email failed action
 * @returns {object}
 */

export function verifyEmailFailed() {
  return {
    type: VERIFY_EMAIL_FAILED,
  }
}
export default {
  getAccountProfile,
  getAccountProfileSuccess,
  getAccountProfileFailed,

  updateAccountProfile,
  updateAccountProfileSuccess,
  updateAccountProfileFailed,

  updateAvatarProfile,
  updateAvatarSuccess,
  updateAvatarFailed,

  updatePhoneNumber,
  updatePhoneNumberSuccess,
  updatePhoneNumberFailed,

  getPlayerInfo,
  getPlayerInfoSuccess,
  getPlayerInfoFailed,

  sendVerifyEmail,
  sendVerifyEmailSuccess,
  sendVerifyEmailFailed,

  verifyEmail,
  verifyEmailSuccess,
  verifyEmailFailed,
}
