import { InfoCircleFilled } from '@ant-design/icons'
import { Card, Col, Popover, Progress, Row } from 'antd'
import {
  getColorParameter,
  getTextRankParameter,
} from '~/helpers/get-color-parameter'
import SubCard from '~/components/shared-components/SubCard'
import { getRankOverall, getValutByRank } from '~/helpers/getValutByRank'
import { useEffect, useState } from 'react'
import { RadaMonsterChart2 } from './Charts/RadaMonsterChart2'
import buffData from '~/assets/data/monster_buff_debuff.data.json'
import { calculateIt, handleCalculateOverall } from '~/helpers/monster'
import { converSeed, extractConvertMonsterSeed } from '~/common/constants'
import { title } from 'process'
import '../GeneralMonster/styles.css'
export const Battle1Monster = (props) => {
  const { t, monsterDetail } = props

  // const key = `${monsterDetail?.main_seed?.indexOf('(') === -1
  //   ? monsterDetail?.main_seed.replace('-', '')
  //   : monsterDetail?.main_seed
  //     ?.slice(0, monsterDetail?.main_seed?.indexOf('('))
  //     .replace('-', '')
  //   }_${monsterDetail?.sub_seed?.indexOf('(') === -1
  //     ? monsterDetail?.sub_seed.replace('-', '')
  //     : monsterDetail?.sub_seed
  //       ?.slice(0, monsterDetail?.sub_seed?.indexOf('('))
  //       .replace('-', '')
  //   }`
  const [total, setTotal] = useState(0)
  // const key = `${converSeed(monsterDetail?.main_seed)}_${converSeed(
  //   monsterDetail?.sub_seed,
  // )}`
  const key = `${converSeed(
    extractConvertMonsterSeed(monsterDetail.monster_script_id).mainSeed,
  )}_${converSeed(
    extractConvertMonsterSeed(monsterDetail.monster_script_id).subSeed,
  )}`
  const lastBuffData = buffData[key]
  const [showRadarChart, setShowRadarChart] = useState(true)
  const [showSlideChart, setShowSlideChart] = useState(false)

  const basicParameterData = [
    {
      type: 'HP',
      rank: getTextRankParameter(
        monsterDetail?.alter_basic_p?.health +
          lastBuffData?.basic_parameter?.hp,
      ),
      gp: monsterDetail?.hp_growth_potential,
      total:
        monsterDetail?.alter_basic_p?.health +
        lastBuffData?.basic_parameter?.hp,
    },
    {
      type: 'STR',
      rank: getTextRankParameter(
        monsterDetail?.alter_basic_p?.strength +
          lastBuffData?.basic_parameter?.str,
      ),
      gp: monsterDetail?.str_growth_potential,
      total:
        monsterDetail?.alter_basic_p?.strength +
        lastBuffData?.basic_parameter?.str,
    },
    {
      type: 'INT',
      rank: getTextRankParameter(
        monsterDetail?.alter_basic_p?.intelligent +
          lastBuffData?.basic_parameter?.int,
      ),
      gp: monsterDetail?.int_growth_potential,
      total:
        monsterDetail?.alter_basic_p?.intelligent +
        lastBuffData?.basic_parameter?.int,
    },
    {
      type: 'DEX',
      rank: getTextRankParameter(
        monsterDetail?.alter_basic_p?.dexterity +
          lastBuffData?.basic_parameter?.dex,
      ),
      gp: monsterDetail?.dex_growth_potential,
      total:
        monsterDetail?.alter_basic_p?.dexterity +
        lastBuffData?.basic_parameter?.dex,
    },
    {
      type: 'AGI',
      rank: getTextRankParameter(
        monsterDetail?.alter_basic_p?.agility +
          lastBuffData?.basic_parameter?.agi,
      ),
      gp: monsterDetail?.agi_growth_potential,
      total:
        monsterDetail?.alter_basic_p?.agility +
        lastBuffData?.basic_parameter?.agi,
    },
    {
      type: 'VIT',
      rank: getTextRankParameter(
        monsterDetail?.alter_basic_p?.vitality +
          lastBuffData?.basic_parameter?.vit,
      ),
      gp: monsterDetail?.vit_growth_potential,
      total:
        monsterDetail?.alter_basic_p?.vitality +
        lastBuffData?.basic_parameter?.vit,
    },
  ]

  const ElementResistanceData = [
    {
      type: 'Strike',
      total: lastBuffData?.element_resistance?.strike * 100,
    },
    {
      type: 'Slash',
      total: lastBuffData?.element_resistance?.slash * 100,
    },
    {
      type: 'Thrust',
      total: lastBuffData?.element_resistance?.thrust * 100,
    },
    {
      type: 'Water',
      total: lastBuffData?.element_resistance?.water * 100,
    },
    {
      type: 'Fire',
      total: lastBuffData?.element_resistance?.fire * 100,
    },

    {
      type: 'Wind',
      total: lastBuffData?.element_resistance?.wind * 100,
    },
    {
      type: 'Earth',
      total: lastBuffData?.element_resistance?.earth * 100,
    },
  ]

  const debuffResistanceData = [
    {
      title:
        // 'Inflicts damage equal to 5% of the maximum HP of the targeted monster for each turn, lasting for 3 turns.',
        '10% max HP damage for each turns',
      type: 'Poison',
      total: lastBuffData?.debuff_resistance?.poison_toxic * 100,
    },
    {
      title:
        // "Stamina decreases by 10% after stamina recovery at the start of the Monster's turn. HP and stamina recovery from skills, buff and  are spontaneous recovery reduced by 10% and the effect lasts for 2 turns.",
        '10% stamina damage for each turns HP and ST recovery from skills -10%',
      type: 'Weakened',
      total: lastBuffData?.debuff_resistance?.weakened * 100,
    },
    {
      // title: 'Reduces hit and evasion rate by 15%, lasting for 1 turn.',
      title: 'HIT & AVD -15%',
      type: 'Blind',
      total: lastBuffData?.debuff_resistance?.blind * 100,
    },
    {
      title:
        // 'Reduces SPD by 10% and Mobility by 1 (minimum Mobility is 1), lasting 1 turn.',
        'SPD -15% & MOV -1 & AVD -10%',
      type: 'Slow',
      total: lastBuffData?.debuff_resistance?.slow * 100,
    },
    {
      // title: '1 turn of inactivity.',
      title: 'Disable 1 turn & AVD -10% & Damage Taken +10%',
      type: 'Paralyze',
      total: lastBuffData?.debuff_resistance?.paralyze * 100,
    },
    {
      title:
        // 'Disables any action for one turn, while increasing its stamina recovery by 50%. The effect is released when the monster takes damage.',
        'Disable for 2 turn & AVD -10% & Damage Taken +10% & ST recovery +50%. The effect is released when the monster',
      type: 'Sleep',
      total: lastBuffData?.debuff_resistance?.sleep * 100,
    },
    {
      title:
        // 'Damage Taken +10%, Target Damage +10%, Stamina consumption +10%, lasts for 3 turns.',
        'Dealt Damage +10% & taken damage +10% & consumed ST +10%',
      type: 'Enrage',
      total: lastBuffData?.debuff_resistance?.enrage * 100,
    },
    {
      // title: 'Skill disabled, lasting 2 turns.',
      title: 'Unable to use skill ',
      type: 'Fear',
      total: lastBuffData?.debuff_resistance?.fear * 100,
    },
    {
      title:
        // 'The enemy Monster moves away (flees) to a distant location for 2 turns.',
        'Run away from opponent monster',
      type: 'Confusion',
      total: lastBuffData?.debuff_resistance?.confusion * 100,
    },
    {
      title:
        // 'Randomly uses a skill on the nearest allied monster, lasting for 1 turn.',
        'Attack nearby teammate',
      type: 'Charm',
      total: lastBuffData?.debuff_resistance?.charm * 100,
    },
  ]

  const clearShowSlide = () => {
    setShowRadarChart(false)
    setShowSlideChart(false)
  }
  useEffect(() => {
    setTotal(
      handleCalculateOverall(
        monsterDetail?.alter_basic_p?.health +
          lastBuffData?.basic_parameter?.hp +
          monsterDetail?.alter_basic_p?.strength +
          lastBuffData?.basic_parameter?.str +
          monsterDetail?.alter_basic_p?.intelligent +
          lastBuffData?.basic_parameter?.int +
          monsterDetail?.alter_basic_p?.dexterity +
          lastBuffData?.basic_parameter?.dex +
          monsterDetail?.alter_basic_p?.agility +
          lastBuffData?.basic_parameter?.agi +
          monsterDetail?.alter_basic_p?.vitality +
          lastBuffData?.basic_parameter?.vit,

        monsterDetail?.mov,

        monsterDetail?.spd,

        monsterDetail?.st,

        buffData[key]?.element_resistance?.total +
          buffData[key]?.debuff_resistance?.total,
        calculateIt(
          monsterDetail?.innate_trait_id_list[0]?.innate_trait_id,
          monsterDetail?.innate_trait_id_list[1]?.innate_trait_id,
        ),

        monsterDetail?.monster_acquired_traits[0]?.trait_id === 'None' ||
          monsterDetail?.monster_acquired_traits[0]?.trait_id === undefined
          ? 0
          : monsterDetail?.monster_acquired_traits[0]?.trait_level,
        monsterDetail?.monster_acquired_traits[1]?.trait_id === 'None' ||
          monsterDetail?.monster_acquired_traits[1]?.trait_id === undefined
          ? 0
          : monsterDetail?.monster_acquired_traits[1]?.trait_level,
      ),
    )
  }, [])

  const getImageClassEffect = (data) => {
    switch (data) {
      case 'AGI':
        return 'color-AGI'
      case 'HP':
        return 'color-HP'
      case 'VIT':
        return 'color-VIT'
      case 'INT':
        return 'color-INT'
      case 'STR':
        return 'color-STR'
      case 'DEX':
        return 'color-DEX'
      default:
        return ''
    }
  }
  return (
    <Card className="w-full ">
      <div className="flex items-center mb-4">
        <div className=" text-[24px] mr-2">
          {t('monsterDetail.battle1.basicParameter')}
        </div>
        {/* <Popover
          className="hover:text-[#1890ff] cursor-pointer"
          arrow={false}
          content={<>{monsterDetail?.description}</>}
        >
          <InfoCircleFilled style={{ fontSize: 20 }} />
        </Popover> */}
      </div>

      <div
        className="relative bg-[#000F38] p-[20px] w-full mb-4  min-h-[375px]"
        style={{ borderRadius: 10 }}
      >
        <div className="w-full flex flex-col gap-4">
          {showRadarChart && (
            <>
              <Row className="w-full">
                <Col
                  xl={6}
                  md={6}
                  sm={24}
                  xs={24}
                  className="flex justify-between flex-col gap-4 max-h-[350px]"
                >
                  {basicParameterData?.map((data, i) => (
                    <Row className="w-full h-[44px]" key={data?.type}>
                      <Col xl={24} md={24} sm={24} xs={24}>
                        <div className="grid grid-cols-4 gap-0 h-full">
                          <div className="flex items-center col-span-2">
                            <img
                              className={`object-contain mr-2 ${getImageClassEffect(
                                data?.type,
                              )}`}
                              style={{ width: 24, height: 24 }}
                              src={`../imgs/filter/general/${data?.type}.png`}
                              alt=""
                            />
                            <div className="text-[18px] font__M__plus font-bold">
                              {data?.total?.toFixed()}
                            </div>
                          </div>

                          <div className="flex">
                            <div className="w-[1px] border h-full mr-2"></div>
                            <div className="flex flex-col items-center">
                              <div className="text-[12px] font-medium	">
                                {t('monsterDetail.battle1.rank')}
                              </div>
                              <img
                                style={{
                                  width: 20,
                                  height: 20,
                                  objectFit: 'contain',
                                }}
                                src={`../svg/rankTagText/${data?.rank}.svg`}
                                alt=""
                              />
                            </div>
                          </div>

                          <div className="flex">
                            <div className="w-[1px] border h-full mr-3"></div>
                            <div className="flex flex-col items-center">
                              <div className="text-[12px]">
                                {t('monsterDetail.battle1.gp')}
                              </div>
                              <img
                                style={{
                                  width: 20,
                                  height: 20,
                                  objectFit: 'contain',
                                }}
                                src={`../svg/rankTagText/${data?.gp}.svg`}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col
                  className="relative w-full h-full flex flex-col items-center"
                  xl={18}
                  md={18}
                  sm={24}
                  xs={24}
                >
                  <div className="max-h-[450px] w-full flex items-center">
                    <RadaMonsterChart2
                      monsterDetail={monsterDetail}
                      lastBuffData={lastBuffData}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}

          {showSlideChart &&
            basicParameterData?.map((data) => (
              <div className="flex justify-between flex-col gap-4">
                <Row className="w-full" key={data?.type}>
                  <Col xl={6} md={6} sm={24} xs={24}>
                    <div className="grid grid-cols-4 gap-0 h-full">
                      <div className="flex items-center col-span-2">
                        <img
                          // className="object-contain mr-2"
                          className={`object-contain mr-2 ${getImageClassEffect(
                            data?.type,
                          )}`}
                          style={{ width: 24, height: 24 }}
                          src={`../imgs/filter/general/${data?.type}.png`}
                          alt=""
                        />
                        <div className="text-[18px] font__M__plus font-bold">
                          {data?.total?.toFixed()}
                        </div>
                      </div>

                      <div className="flex">
                        <div className="w-[1px] border h-full mr-2"></div>
                        <div className="flex flex-col items-center">
                          <div className="text-[12px] font-medium	">
                            {t('monsterDetail.battle1.rank')}
                          </div>
                          <img
                            style={{
                              width: 20,
                              height: 20,
                              objectFit: 'contain',
                            }}
                            src={`../svg/rankTagText/${data?.rank}.svg`}
                            alt=""
                          />
                        </div>
                      </div>

                      <div className="flex">
                        <div className="w-[1px] border h-full mr-3"></div>
                        <div className="flex flex-col items-center">
                          <div className="text-[12px]">
                            {t('monsterDetail.battle1.gp')}
                          </div>
                          <img
                            style={{
                              width: 20,
                              height: 20,
                              objectFit: 'contain',
                            }}
                            src={`../svg/rankTagText/${data?.gp}.svg`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="relative w-full flex flex-col items-center"
                    xl={16}
                    md={16}
                    sm={24}
                    xs={24}
                  >
                    <>
                      <Progress
                        size={['100%', 16]}
                        percent={(data?.total / 999) * 100}
                        strokeColor={getColorParameter(data?.type)}
                        showInfo={false}
                        status="active"
                      />
                      <Progress
                        style={{ opacity: 0.5 }}
                        size={['100%', 10]}
                        percent={(getValutByRank(data?.gp) / 7) * 100}
                        strokeColor={getColorParameter(data?.type)}
                        showInfo={false}
                        status="active"
                      />
                    </>
                  </Col>
                </Row>
              </div>
            ))}
        </div>

        <div className="absolute h-full top-0 right-[1%] flex flex-col gap-2 items-center justify-center">
          <div
            onClick={() => {
              clearShowSlide()
              setShowRadarChart(true)
            }}
            className={`${
              showRadarChart && 'bg-[#1890FF]'
            } w-[44px] h-[44px] border border-[#1890FF] rounded-[10px] flex items-center justify-center cursor-pointer	`}
          >
            <img src={`../svg/chart/radar.svg`} alt="" />
          </div>

          <div
            onClick={() => {
              clearShowSlide()
              setShowSlideChart(true)
            }}
            className={`${
              showSlideChart && 'bg-[#1890FF]'
            } w-[44px] h-[44px] border border-[#1890FF] rounded-[10px] flex items-center justify-center cursor-pointer	`}
          >
            <img src={`../svg/chart/slide.svg`} alt="" />
          </div>
        </div>
      </div>

      <div className="grid grid-rows-3 sm:grid-rows-2 grid-flow-col gap-4">
        <div className="row-span-1 sm:row-span-2 sm:col-span-2">
          <div
            className="bg-[#000F38] py-[24px] px-[16px] min-w-[275px] h-[300px] w-full"
            style={{ borderRadius: 10 }}
          >
            <div className="flex flex-col gap-4">
              <div className="text-[24px] ">
                {t('monsterDetail.battle1.overall')}
              </div>
              <div className="flex items-center justify-between">
                <img
                  src={`../svg/rankTagText/${getRankOverall(total)}.svg`}
                  alt=""
                />
                <div className="font__M__plus font-bold">{total}</div>
              </div>

              <div className="flex items-center justify-between">
                <div>SPD</div>
                <div className="font__M__plus font-bold">
                  {monsterDetail?.spd}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div>MOV</div>
                <div className="font__M__plus font-bold">
                  {monsterDetail?.mov}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>ST</div>
                <div className="font__M__plus font-bold">
                  {monsterDetail?.st}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>Battle </div>
                <div className="font__M__plus font-bold">
                  {monsterDetail?.battle_count}/20
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-1 sm:col-span-2">
          <SubCard
            classCustom="h-full"
            title={t('monsterDetail.battle1.elementResistance')}
          >
            <div className="flex flex-wrap gap-6 justify-center">
              {ElementResistanceData?.map((data) => (
                <div
                  key={data?.type}
                  className="group bg-[#000F38] rounded-[10px] w-[70px] h-[70px] border border-[#1890FF] flex flex-col items-center justify-center"
                >
                  <img
                    style={{ width: 24, height: 24, objectFit: 'contain' }}
                    className="mb-1 group-hover:scale-110 transition ease-in-out"
                    src={`../imgs/filter/${data?.type}.png`}
                    alt=""
                  />
                  <div
                    className={`text-[16px] font__M__plus font-bold ${
                      data?.total > 0
                        ? 'text-[#52C41A]'
                        : data?.total < 0
                        ? 'text-[#f70e02]'
                        : ''
                    }`}
                  >
                    {`${data?.total?.toFixed()}%`}
                  </div>
                </div>
              ))}
            </div>
          </SubCard>
        </div>
        <div className="col-span-1 sm:col-span-2">
          <SubCard
            classCustom="h-full"
            title={t('monsterDetail.battle1.debuffResistance')}
          >
            <div className="flex flex-wrap gap-3">
              {debuffResistanceData?.map((data) => (
                <Popover key={data?.type} content={data?.title}>
                  <div
                    className={`group bg-[#000F38] rounded-[10px] w-[65px] h-[65px] flex flex-col items-center justify-center ${
                      data?.total > 0
                        ? 'text-[#52C41A]'
                        : data?.total < 0
                        ? 'text-[#f70e02]'
                        : ''
                    }
                  }`}
                  >
                    <img
                      style={{ width: 40, height: 40, objectFit: 'contain' }}
                      className="mb-1 group-hover:scale-110 transition ease-in-out"
                      src={`../imgs/buff/${data?.type}.png`}
                      alt=""
                    />
                    <div className="text-[16px] font__M__plus font-bold">
                      {`${data?.total?.toFixed()}%`}
                    </div>
                  </div>
                </Popover>
              ))}
            </div>
          </SubCard>
        </div>
      </div>
    </Card>
  )
}

export default Battle1Monster
