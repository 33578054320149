import {
  GET_ALL_GENESIS_USER_START,
  GET_ALL_GENESIS_USER_SUCCESS,
  GET_ALL_GENESIS_USER_FAILED,
  GET_ALL_GENESIS_SALE_USER_START,
  GET_ALL_GENESIS_SALE_USER_SUCCESS,
  GET_ALL_GENESIS_SALE_USER_FAILED,
} from '../actions/genesis'

const initialState = {
  isLoading: false,
  allGenesisUser: [],
  totalGenesisBoxUser: 0,
  totalGenesisHashUser: 0,
  totalGenesisUser: 0
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function genesisManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GENESIS_SALE_USER_START:
    case GET_ALL_GENESIS_USER_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ALL_GENESIS_USER_SUCCESS:
      return {
        ...state,
        allGenesisUser: action?.payload?.items,
        totalGenesisBoxUser: action?.payload?.totalBox,
        totalGenesisHashUser: action?.payload?.totalHash,
        totalGenesisUser: action?.payload?.count,
        isLoading: false,
      }
    case GET_ALL_GENESIS_SALE_USER_SUCCESS:
      return {
        ...state,
        allGenesisUser: action?.payload?.items,
        totalGenesisHashUser: action?.payload?.count,
        isLoading: false,
      }

    case GET_ALL_GENESIS_SALE_USER_FAILED:
    case GET_ALL_GENESIS_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
