import { ReMonsterTwoBorderSm } from '../../../../../components/shared-components/Button/ReMonsterTwoBorderSm'
import { useEffect, useState } from 'react'
import { convertPrice } from '~/helpers/common'
import { getBalanceOAS } from '~/blockchain/provider'
import { useAccount } from '~/common/hooks/useAccount'
import CheckoutShopModal from '~/components/Modals/CheckoutShop'
import SelectWallet from '~/components/Modals/SelectWallet'
import { InfoCircleFilled } from '@ant-design/icons'
import { Col, Row } from 'antd'
import RemainingBoxModal from './DetailBoxCardRemaining'

const ShopBoxCard = (props) => {
  const { box, t, loadingModal, handleConfirm, tema } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenRemaining, setIsModalOpenRemaining] = useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const { user } = useAccount()

  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)
  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpen(true)
  }

  const handleOk = (box, quantity) => {
    handleConfirm(box, quantity, handleCancel)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setIsModalOpenRemaining(false)
  }

  useEffect(() => {
    if (user) {
      loadBalanceOAS()
    }
  }, [user])

  return (
    <>
      <div className="card__monster w-[250px] h-[350px]">
        <div className="relative flex items-center justify-center h-full">
          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
            <div
              className="h-full flex flex-col p-[10px] text-[18px]"
              style={{
                background: 'linear-gradient(180deg, #0D1426 0%, #375877 100%)',
              }}
            >
              <div>
                <Row>
                  <Col span={18}>
                    <div className="title__shop_card">{box?.name}</div>
                    <div className="type__shop_card">{box?.type}</div>
                  </Col>
                  <Col span={6} className="flex justify-end items-start">
                    <div className="hover:text-[#1890ff] cursor-pointer">
                      <InfoCircleFilled
                        style={{ fontSize: 20 }}
                        onClick={() => setIsModalOpenRemaining(true)}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="h-full flex items-center justify-center">
                <img
                  alt=""
                  className="card__monster_img w-[160px] h-[160px] object-contain	ease-in-out duration-300"
                  src={box?.img}
                />
              </div>
              <div className="flex justify-end text-[12px]">
                {box?.remaining?.toLocaleString()}/
                {box?.total?.toLocaleString()}
              </div>
            </div>

            <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
              <div className="flex items-center">
                <img
                  className="w-[16px] h-[16px] object-contain	"
                  src="./imgs/coins/oasis.svg"
                  alt=""
                />
                <div className="relative whitespace-nowrap flex ml-2">
                  {convertPrice(box?.price, tema)}
                </div>
              </div>

              <div className="text-[12px] mb-1">$ {box?.price}</div>

              <ReMonsterTwoBorderSm
                functionButton={() => showModalCheckout()}
              />
            </div>
          </div>
        </div>
        <CheckoutShopModal
          key={box?.name}
          action={{
            isModalOpen: isModalOpen,
            handleOk: handleOk,
            handleCancel: handleCancel,
          }}
          t={t}
          box={box}
          handleConfirm={handleConfirm}
          loadingModal={loadingModal}
          tema={tema}
          balance={balanceOAS}
        />
        <RemainingBoxModal
          action={{
            isModalOpen: isModalOpenRemaining,
            handleCancel: handleCancel,
          }}
          t={t}
          box={box}
          loadingModal={loadingModal}
        />
        <SelectWallet
          action={{
            isModalOpen: isModalConnectOpen,
            handleCancel: handleCancelConnect,
          }}
          t={t}
        />
      </div>
    </>
  )
}

export default ShopBoxCard
