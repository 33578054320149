import { converImageOpenBox } from '~/helpers/Species'

export const BoxCardOpen = (props) => {
  const { data } = props
  return (
    <div className="card__monster w-[250px] h-[350px] cursor-pointer">
      <div className="relative flex items-center justify-center h-full">
        <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
          <div
            className="h-full flex flex-col items-center p-[10px] text-[18px]"
            style={{
              background:
                data?.type_name === 'General Box'
                  ? 'linear-gradient(180deg, #8C5400 0%, #FFE27A 100%), linear-gradient(180deg, #0D1426 0%, #375877 100%)'
                  : 'linear-gradient(180deg, #860078 0%, #BE48C1 53.65%, #EE87FF 100%), linear-gradient(180deg, #0D1426 0%, #375877 100%)',
            }}
          >
            <div className="flex flex-col items-center">
              <div className="title__card">
                {data?.name} {data?.token_id ? `(#${data?.token_id})` : ''}
              </div>
              <div className="title__card">{data?.type}</div>
            </div>

            <div className="h-full flex items-center justify-center">
              <img
                alt=""
                className="card__monster_img w-[160px] h-[160px] object-contain	ease-in-out duration-300"
                // src="../imgs/hash/golem.png"
                src={`${
                  process.env.REACT_APP_BASE_URL_IMAGE
                }/public/image/image/hash/${converImageOpenBox(
                  data?.type,
                )}.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
