import { Popover } from 'antd'
import { useEffect, useState } from 'react'
import { ICON_LEFT_BUTTON_SM, ICON_RIGHT_BUTTON_SM } from '~/assets/svg/button'
import { useAccount } from '~/common/hooks/useAccount'
import { useTranslation } from '~/common/hooks/useTranslation'

export const ReMonsterTwoBorderSm = ({
  title,
  functionButton,
  data,
  quality,
  itemShop,
}) => {
  const { user } = useAccount()
  const { t } = useTranslation()
  const [checkMonsterFree, setCheckMonsterFree] = useState(false)

  useEffect(() => {
    const isFreeMonster = data?.is_free_monster === true
    const isFreeFarm = data?.is_free_farm === true
    const isQualityB = quality === 'B'
    const isBuyFarm = itemShop?.remaining === 0
    if (isFreeMonster || isFreeFarm || isQualityB || isBuyFarm) {
      setCheckMonsterFree(true)
    } else {
      setCheckMonsterFree(false)
    }
  }, [data, quality, itemShop])

  return (
    <>
      {checkMonsterFree ? (
        itemShop?.remaining === 0 ? (
          <div
            className={`flex items-center common__btn relative cursor-pointer ${
              checkMonsterFree ? 'checkFreeBtn' : ''
            }`}
            style={
              checkMonsterFree
                ? {
                    cursor: 'not-allowed',
                    opacity: 0.5,
                  }
                : {}
            }
          >
            <div className="relative flex common__btn_main">
              <div
                className="absolute z-20 left-[-6px] common__btn_left"
                style={{ color: '#69C0FF' }}
              >
                <ICON_LEFT_BUTTON_SM fill="#18ACFF" />
              </div>
              <div
                className="absolute z-20 left-[-1px] common__sub_btn_left"
                style={{ color: '#40A9FF' }}
              >
                <ICON_LEFT_BUTTON_SM />
              </div>
              <div
                className="relative z-10 w-[88px] h-[30px] flex items-center justify-center common__btn_middle"
                style={{
                  clipPath:
                    'polygon(93% 100%, 100% 77%, 100% 22%, 92% 0%, 8% 0%, 0% 24%, 0% 76%, 8% 100%) ',
                  padding: 12,
                }}
              >
                <div className="relative whitespace-nowrap flex text-[14px]">
                  {title ?? 'Buy'}
                </div>
              </div>
              <div
                className="absolute z-20 right-[-1px] common__sub_btn_right"
                style={{ color: '#40A9FF' }}
              >
                <ICON_RIGHT_BUTTON_SM />
              </div>
              <div
                className="absolute z-20 right-[-6px] common__btn_right"
                style={{ color: '#69C0FF' }}
              >
                <ICON_RIGHT_BUTTON_SM />
              </div>
            </div>
          </div>
        ) : (
          <Popover
            content={
              <>
                {quality === 'B'
                  ? t('messageCheckBonus')
                  : t('messageCheckFree')}
              </>
            }
          >
            <div
              className={`flex items-center common__btn relative cursor-pointer ${
                checkMonsterFree ? 'checkFreeBtn' : ''
              }`}
              style={
                checkMonsterFree
                  ? {
                      cursor: 'not-allowed',
                      opacity: 0.5,
                    }
                  : {}
              }
            >
              <div className="relative flex common__btn_main">
                <div
                  className="absolute z-20 left-[-6px] common__btn_left"
                  style={{ color: '#69C0FF' }}
                >
                  <ICON_LEFT_BUTTON_SM fill="#18ACFF" />
                </div>
                <div
                  className="absolute z-20 left-[-1px] common__sub_btn_left"
                  style={{ color: '#40A9FF' }}
                >
                  <ICON_LEFT_BUTTON_SM />
                </div>
                <div
                  className="relative z-10 w-[88px] h-[30px] flex items-center justify-center common__btn_middle"
                  style={{
                    clipPath:
                      'polygon(93% 100%, 100% 77%, 100% 22%, 92% 0%, 8% 0%, 0% 24%, 0% 76%, 8% 100%) ',
                    padding: 12,
                  }}
                >
                  <div className="relative whitespace-nowrap flex text-[14px]">
                    {title ?? 'Buy'}
                  </div>
                </div>
                <div
                  className="absolute z-20 right-[-1px] common__sub_btn_right"
                  style={{ color: '#40A9FF' }}
                >
                  <ICON_RIGHT_BUTTON_SM />
                </div>
                <div
                  className="absolute z-20 right-[-6px] common__btn_right"
                  style={{ color: '#69C0FF' }}
                >
                  <ICON_RIGHT_BUTTON_SM />
                </div>
              </div>
            </div>
          </Popover>
        )
      ) : (
        <div
          onClick={functionButton}
          className="flex items-center common__btn relative cursor-pointer"
        >
          <div className="relative flex common__btn_main">
            <div
              className="absolute z-20 left-[-6px] common__btn_left"
              style={{ color: '#69C0FF' }}
            >
              <ICON_LEFT_BUTTON_SM fill="#18ACFF" />
            </div>
            <div
              className="absolute z-20 left-[-1px] common__sub_btn_left"
              style={{ color: '#40A9FF' }}
            >
              <ICON_LEFT_BUTTON_SM />
            </div>
            <div
              className="relative z-10 w-[88px] h-[30px] flex items-center justify-center common__btn_middle"
              style={{
                clipPath:
                  'polygon(93% 100%, 100% 77%, 100% 22%, 92% 0%, 8% 0%, 0% 24%, 0% 76%, 8% 100%) ',
                padding: 12,
              }}
            >
              <div className="relative whitespace-nowrap flex text-[14px]">
                {title ?? 'Buy'}
              </div>
            </div>
            <div
              className="absolute z-20 right-[-1px] common__sub_btn_right"
              style={{ color: '#40A9FF' }}
            >
              <ICON_RIGHT_BUTTON_SM />
            </div>
            <div
              className="absolute z-20 right-[-6px] common__btn_right"
              style={{ color: '#69C0FF' }}
            >
              <ICON_RIGHT_BUTTON_SM />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
