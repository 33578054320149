import {
  GET_ALL_MONSTER_USER_START,
  GET_ALL_MONSTER_USER_SUCCESS,
  GET_ALL_MONSTER_USER_FAILED,
  GET_ALL_MONSTER_START,
  GET_ALL_MONSTER_SUCCESS,
  GET_ALL_MONSTER_FAILED,
  GET_DETAIL_MONSTER_START,
  GET_DETAIL_MONSTER_SUCCESS,
  GET_DETAIL_MONSTER_FAILED,
  GET_ALL_MONSTER_SALE_USER_START,
  GET_ALL_MONSTER_SALE_USER_SUCCESS,
  GET_ALL_MONSTER_SALE_USER_FAILED,
  GET_ALL_MONSTER_HASH_START,
  GET_ALL_MONSTER_HASH_SUCCESS,
  GET_ALL_MONSTER_HASH_FAILED,
  GET_MONSTER_HASH_DETAIL_START,
  GET_MONSTER_HASH_DETAIL_SUCCESS,
  GET_MONSTER_HASH_DETAIL_FAILED,
} from '../actions/monster'

const initialState = {
  isLoading: false,
  allMonsterUser: [],
  totalMonsterUser: 0,
  allMonsters: [],
  total: 0,
  monsterDetail: {},
  isLoadingDetail: false,
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function monsterManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MONSTER_SALE_USER_START:
    case GET_ALL_MONSTER_USER_START:
    case GET_ALL_MONSTER_START:
    case GET_ALL_MONSTER_HASH_START:
    case GET_MONSTER_HASH_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DETAIL_MONSTER_START:
      return {
        ...state,
        isLoadingDetail: true,
      }
    case GET_ALL_MONSTER_SALE_USER_SUCCESS:
    case GET_ALL_MONSTER_USER_SUCCESS:
      return {
        ...state,
        allMonsterUser: action?.payload?.items,
        totalMonsterUser: action?.payload?.count,
        isLoading: false,
      }

    case GET_ALL_MONSTER_SUCCESS:
      return {
        ...state,
        allMonsters: action?.payload?.items,
        total: action?.payload?.count,
        isLoading: false,
      }
    case GET_ALL_MONSTER_HASH_SUCCESS:
      return {
        ...state,
        allHashes: action?.payload?.items,
        totalHash: action?.payload?.count,
        isLoading: false,
      }

    case GET_DETAIL_MONSTER_SUCCESS:
      return {
        ...state,
        monsterDetail: action?.payload,
        isLoadingDetail: false,
      }
    case GET_MONSTER_HASH_DETAIL_SUCCESS:
      return {
        ...state,
        monsterDetail: action?.payload,
        isLoading: false,
      }
    case GET_ALL_MONSTER_SALE_USER_FAILED:
    case GET_ALL_MONSTER_USER_FAILED:
    case GET_ALL_MONSTER_FAILED:
    case GET_ALL_MONSTER_HASH_FAILED:
    case GET_MONSTER_HASH_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DETAIL_MONSTER_FAILED:
      return {
        ...state,
        isLoadingDetail: false,
      }
    default:
      return state
  }
}
