import {
  GET_TEMA_START,
  GET_TEMA_SUCCESS,
  GET_TEMA_FAILED,
  GET_HISTORIES_START,
  GET_HISTORIES_SUCCESS,
  GET_HISTORIES_FAILED,
  GET_HISTORIES_TOKEN_START,
  GET_HISTORIES_TOKEN_SUCCESS,
  GET_HISTORIES_TOKEN_FAILED,
} from '../actions/common'

const initialState = {
  isLoading: false,
  isLoadingTrade: false,

  allTEMAs: [],
  settings: [],
  allHistories: [],
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function commonManagement(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORIES_START:
    case GET_TEMA_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_HISTORIES_TOKEN_START:
      return {
        ...state,
        isLoadingTrade: true,
      }
    case GET_HISTORIES_TOKEN_SUCCESS:
      return {
        ...state,
        allHistories: action?.payload,
        isLoadingTrade: false,
      }
    case GET_TEMA_SUCCESS:
      return {
        ...state,
        allTEMAs: action?.payload,
        isLoading: false,
      }
    case GET_HISTORIES_SUCCESS:
      return {
        ...state,
        allHistories: action?.payload,
        isLoading: false,
      }
    case GET_HISTORIES_FAILED:
    case GET_TEMA_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case GET_HISTORIES_TOKEN_FAILED:
      return {
        ...state,
        isLoadingTrade: false,
      }
    default:
      return state
  }
}
