import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getDetailFarmSuccess,
  getDetailFarmFailed,
  GET_DETAIL_FARM_START,
} from '../../actions/farm'

/**
 * get detail farm
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getDetailFarmApi = (props) => {
  var uri = `/farms/${props}`
  return api.get(uri)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDetailFarm(action) {
  try {
    const response = yield call(getDetailFarmApi, action?.payload)
    yield put(getDetailFarmSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getDetailFarmFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get detail farm
 */
export default function* watchGetDetailFarm() {
  yield takeLatest(GET_DETAIL_FARM_START, doGetDetailFarm)
}
