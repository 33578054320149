import React from 'react'
import { Button, Modal } from 'antd'
export default function OpenBox(props) {
  const { action, t, data, loadingModal } = props
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }

  return (
    <>
      <Modal
        key={data?.type}
        centered
        title={
          <h3 className="flex justify-center text-xl font-bold">
            {t('modal.open.title', { name: data?.asset })}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            <Button
              key="cancel"
              onClick={action?.handleCancel}
              style={cancelButtonStyle}
            >
              {t('modal.open.cancel')}
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => action?.handleOk(data)}
              style={okButtonStyle}
              loading={loadingModal}
            >
              {t('modal.open.ok')}
            </Button>
          </div>,
        ]}
      >
        <div className="text-center text-[16px]">
          {t('modal.open.description', {
            name: data?.asset,
            group: data?.type,
          })}
        </div>
      </Modal>
    </>
  )
}
