import { useEffect, useState } from 'react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  checkTransactionConfirmation,
  hexToNumberString,
  numberToHex,
} from '~/blockchain/provider'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyShop from '~/components/shared-components/BodyShop'
import ShopBoxCard from '~/views/app/Shop/components/ShopBoxCard'
import { useShop } from '~/views/redux/hooks/useShop'
import configs from '~/configs'
import { ifaceContractShop } from '~/blockchain/contract'
import { useCommon } from '~/views/redux/hooks/useCommon'
import TransactionCompletedShop from '~/components/Modals/TransactionCompletedShop'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { useSDK } from '@metamask/sdk-react'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'

export const GeneralBox = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  const [loadingModal, setLoadingModal] = useState(false)
  const { actions } = useShop()
  const [boxs, setBoxs] = useState()
  const [tema, setTema] = useState(1)
  const { actions: actionsCommon } = useCommon()
  const [txHash, setTxHash] = useState()
  const [boxActive, setBoxActive] = useState()

  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)

  const {
    actions: actionTheme,
    data: { user },
  } = useTheme()

  const { actions: actionsProfile } = useAccountProfile()

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }

  const handleBuyGeneralShop = async (
    box,
    quantity,
    closeModalCheckout = null,
  ) => {
    try {
      setLoadingModal(true)
      setBoxActive(box)
      const data = {
        address: user?.address_wallet,
        typeAsset: 'general_box',
        group: box?.id,
        amount: Number(quantity),
      }
      actions.signBuyShop(data, (res) => {
        buyGeneralBox(
          res.typeAsset,
          res.address,
          res.group,
          res.price,
          Number(quantity),
          res.deadline,
          res.signature,
          closeModalCheckout,
        )
      })
    } catch (error) {
      setLoadingModal(false)
      // eslint-disable-next-line
      console.log(error)
    }
  }
  const buyGeneralBox = async (
    typeAsset,
    address,
    group,
    priceInWei,
    number,
    deadline,
    sig,
    closeModalCheckout = null,
  ) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractShop.encodeFunctionData('buyItem', [
            typeAsset,
            address,
            0,
            group,
            priceInWei,
            number,
            deadline,
            sig,
          ]),
          to: configs.ADDRESS_SHOP,
          value: numberToHex(priceInWei),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            if (closeModalCheckout) {
              closeModalCheckout()
            }
            setTxHash(transaction?.hash)
            showModalSuccess()
            actionsProfile.getAccountProfile((res) => {
              actionTheme.updateInfoUser({
                ...res?.player_info,
              })
            })
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: address,
                data: ifaceContractShop.encodeFunctionData('buyItem', [
                  typeAsset,
                  address,
                  0,
                  group,
                  priceInWei,
                  number,
                  deadline,
                  sig,
                ]),
                to: configs.ADDRESS_SHOP,
                value: numberToHex(priceInWei),
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                if (closeModalCheckout) {
                  closeModalCheckout()
                }
                setTxHash(txHash)
                showModalSuccess()
                actionsProfile.getAccountProfile((res) => {
                  actionTheme.updateInfoUser({
                    ...res?.player_info,
                  })
                })
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
      // eslint-disable-next-line
      console.error(error)
    }
  }

  const loadBoxs = async () => {
    actions.getDataShop({}, (res) => {
      // eslint-disable-next-line
      console.log(res)
      let data = [
        {
          name: 'General Box',
          type: 'Group A',
          id: 'a',
          img: './imgs/box/GoldA.png',
          price: res[1]?.price,
          total: res[1]?.detail?.group_a?.total,
          remaining: res[1]?.detail?.group_a?.remaining,
        },
        {
          name: 'General Box',
          type: 'Group B',
          id: 'b',
          img: './imgs/box/GoldB.png',
          price: res[1]?.price,
          total: res[1]?.detail?.group_b?.total,
          remaining: res[1]?.detail?.group_b?.remaining,
        },
        {
          name: 'General Box',
          type: 'Group C',
          id: 'c',
          img: './imgs/box/GoldC.png',
          price: res[1]?.price,
          total: res[1]?.detail?.group_c?.total,
          remaining: res[1]?.detail?.group_c?.remaining,
        },
        {
          name: 'General Box',
          type: 'Group D',
          id: 'd',
          img: './imgs/box/GoldD.png',
          price: res[1]?.price,
          total: res[1]?.detail?.group_d?.total,
          remaining: res[1]?.detail?.group_d?.remaining,
        },
        {
          name: 'General Box',
          type: 'Group E',
          id: 'e',
          img: './imgs/box/GoldE.png',
          price: res[1]?.price,
          total: res[1]?.detail?.group_e?.total,
          remaining: res[1]?.detail?.group_e?.remaining,
        },
      ]
      setBoxs(data)
    })
  }

  useEffect(() => {
    actionsCommon.getTema({}, (res) => {
      setTema(res?.tema)
    })
    loadBoxs()
  }, [])

  return (
    <BodyShop>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="">
          <div className="title__shop mb-10">
            {t('shop.generalBox').toUpperCase()}
          </div>
        </div>
        <div className="flex flex-wrap gap-5 justify-center max-w-[1396px]">
          {boxs?.map((data, index) => (
            <ShopBoxCard
              tema={tema}
              key={index}
              box={data}
              t={t}
              handleConfirm={handleBuyGeneralShop}
              loadingModal={loadingModal}
            />
          ))}
        </div>
      </div>
      <TransactionCompletedShop
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        name={`${boxActive?.name} (${boxActive?.type})`}
        store={'Shop'}
        txHash={txHash}
      />
    </BodyShop>
  )
}

export default GeneralBox
