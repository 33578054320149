const SpeciesA = [
  {
    name: 'Faireaf',
    image: 'Faireaf_1_Hash',
  },
  {
    name: 'AstarCats',
    image: 'AstarCats_1_Hash',
  },
  {
    name: 'DEKAUSA',
    image: 'DEKAUSA_1_Hash',
  },
  {
    name: 'Sake Barrel PlanetMan',
    image: 'PlanetMan_1_Hash',
  },
]
const SpeciesB = [
  {
    name: 'Golem',
    image: 'Golem_1_Hash',
  },
  {
    name: 'SignWitch',
    image: 'SignWitch_Aries_Hash',
  },
  {
    name: 'Layla',
    image: 'Layla_1_Hash',
  },
  {
    name: 'UPA',
    image: 'UPA_1_Hash',
  },
]

const SpeciesC = [
  {
    name: 'Tofu',
    image: 'Tofu_1_Hash',
  },
  {
    name: 'ODENPETS',
    image: 'ODENPETS_1_Hash',
  },
  {
    name: 'Kids Hero',
    image: 'KidsHero_1_Hash',
  },
  {
    name: 'Kids Villain',
    image: 'KidsVillain_1_Hash',
  },
  {
    name: 'P-1',
    image: 'P1_1_Hash',
  },
]
const SpeciesD = [
  {
    name: 'Unicorn',
    image: 'Unicorn_1_Hash',
  },
  {
    name: 'Hiyori',
    image: 'Hiyori_1_Hash',
  },
  {
    name: 'CCT',
    image: 'CCT_BOT_Hash',
  },
  {
    name: 'Ame-chan',
    image: 'Amechan_1_Hash',
  },
]
const SpeciesE = [
  {
    name: 'Valius',
    image: 'Valius_1_Hash',
  },
  {
    name: 'Yamato',
    image: 'Yamato_1_Hash',
  },
  {
    name: 'Icelime',
    image: 'Icelime_1_Hash',
  },
  {
    name: 'Ossan Paradise',
    image: 'OssanParadise_1_Hash',
  },
]

export function getSpeciesValue(group, species) {
  switch (Number(group)) {
    case 0:
      return SpeciesA[Number(species)]
    case 1:
      return SpeciesB[Number(species)]
    case 2:
      return SpeciesC[Number(species)]
    case 3:
      return SpeciesD[Number(species)]
    case 4:
      return SpeciesE[Number(species)]
    default:
      return undefined
  }
}
export const converImageOpenBox = (name) => {
  switch (name) {
    case 'Faireaf':
      return 'Faireaf_1_Hash'
    case 'AstarCats':
      return 'AstarCats_1_Hash'
    case 'DEKAUSA':
      return 'DEKAUSA_1_Hash'
    case 'Sake Barrel PlanetMan':
      return 'PlanetMan_1_Hash'
    case 'Golem':
      return 'Golem_1_Hash'
    case 'SignWitch':
      return 'SignWitch_Aries_Hash'
    case 'Layla':
      return 'Layla_1_Hash'
    case 'UPA':
      return 'UPA_1_Hash'
    case 'Tofu':
      return 'Tofu_1_Hash'
    case 'ODENPETS':
      return 'ODENPETS_1_Hash'
    case 'Kids Hero':
      return 'KidsHero_1_Hash'
    case 'Kids Villain':
      return 'KidsVillain_1_Hash'
    case 'P-1':
      return 'P1_1_Hash'
    case 'Unicorn':
      return 'Unicorn_1_Hash'
    case 'Hiyori':
      return 'Hiyori_1_Hash'
    case 'CCT':
      return 'CCT_BOT_Hash'
    case 'Ame-chan':
      return 'Amechan_1_Hash'
    case 'Valius':
      return 'Valius_1_Hash'
    case 'Yamato':
      return 'Yamato_1_Hash'
    case 'Icelime':
      return 'Icelime_1_Hash'
    case 'Ossan Paradise':
      return 'OssanParadise_1_Hash'
    default:
      return ''
  }
}
