export const ROW_GUTTER = 16
export const SIDE_NAV_WIDTH = 250
export const SIDE_NAV_COLLAPSED_WIDTH = 80
export const SIDE_NAV_LIGHT = 'SIDE_NAV_LIGHT'
export const SIDE_NAV_DARK = 'SIDE_NAV_DARK'
export const NAV_TYPE_SIDE = 'SIDE'
export const NAV_TYPE_TOP = 'TOP'
export const DIR_LTR = 'ltr'
export const DIR_RTL = 'rtl'

export const CHANGE_LOCALE = 'CHANGE_LOCALE'
export const UPDATE_BALENCE_BIT = 'UPDATE_BALENCE_BIT'
export const UPDATE_BALENCE_OAS = 'UPDATE_BALENCE_OAS'
export const UPDATE_INFO_USER = 'UPDATE_INFO_USER'
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV'
