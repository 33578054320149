import { Card, Col, Row } from 'antd'

export const InfoHash = (props) => {
  const { t, monsterDetail } = props
  return (
    <Card className="w-full">
      <Row>
        <Col span={12} className="text-[16px]">
          <div className="mb-3 font__M__plus font-bold">
            {t('hashNFTDetail.ID')}
          </div>
          {monsterDetail?.main_seed && (
            <div className="mb-3 font__M__plus font-bold">
              {t('hashNFTDetail.name')}
            </div>
          )}
          {monsterDetail?.name && monsterDetail?.asset === 'HASH CHIP NFT' && (
            <div className="mb-3 font__M__plus font-bold">
              {t('hashNFTDetail.name')}
            </div>
          )}

          {/* {monsterDetail?.description && (
            <div className="mb-3 font__M__plus font-bold">
              {t('hashNFTDetail.description')}
            </div>
          )} */}
        </Col>
        <Col span={12} className="text-end">
          <div className="mb-3 font__M__plus font-medium">
            {monsterDetail?.tokenId}
          </div>
          {monsterDetail?.main_seed && (
            <div className="mb-3 font__M__plus font-medium">
              {monsterDetail?.type_name} [{monsterDetail?.main_seed}]
            </div>
          )}
          {monsterDetail?.name && monsterDetail?.asset === 'HASH CHIP NFT' && (
            <div className="mb-3 font__M__plus font-medium">
              {monsterDetail?.name}
            </div>
          )}
          {/* {monsterDetail?.description && (
            <div
              className="mb-3 font__M__plus font-medium"
              dangerouslySetInnerHTML={{ __html: monsterDetail.description }}
            ></div>
          )} */}
        </Col>
      </Row>
      {monsterDetail?.timesRegeneration !== undefined && (
        <Row>
          <Col span={12} className="text-[16px]">
            <div className="mb-3 font__M__plus font-bold">
              {t('hashNFTDetail.regenerationCount')}
            </div>
          </Col>
          <Col span={12} className="text-end">
            <div className="mb-3 font__M__plus font-medium">
              {monsterDetail?.type_name === 'Genesis Hash'
                ? monsterDetail?.timesRegeneration + '/5'
                : monsterDetail?.type_name === 'Hash Chip NFT'
                ? monsterDetail?.timesRegeneration + '/1'
                : monsterDetail?.timesRegeneration + '/3'}
            </div>
          </Col>
        </Row>
      )}
      {monsterDetail?.timesRegeneration === undefined && (
        <Row>
          <Col span={12} className="text-[16px]">
            <div className="mb-3 font__M__plus font-bold">
              {t('hashNFTDetail.regenerationCount')}
            </div>
          </Col>
          <Col span={12} className="text-end">
            <div className="mb-3 font__M__plus font-medium">0/1</div>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12} className="text-[16px]">
          {monsterDetail?.main_seed && (
            <div className="font__M__plus font-bold">
              {t('hashNFTDetail.mainSeed')}
            </div>
          )}
        </Col>
        <Col span={12} className="text-end">
          {monsterDetail?.main_seed && (
            <div className="font__M__plus font-medium">
              {monsterDetail?.main_seed}
            </div>
          )}
        </Col>
      </Row>
    </Card>
  )
}
