import {
  GET_ALL_ITEM_USER_START,
  GET_ALL_ITEM_USER_SUCCESS,
  GET_ALL_ITEM_USER_FAILED,
  GET_ALL_ITEM_START,
  GET_ALL_ITEM_SUCCESS,
  GET_ALL_ITEM_FAILED,
  GET_DETAIL_ITEM_START,
  GET_DETAIL_ITEM_SUCCESS,
  GET_DETAIL_ITEM_FAILED,
  GET_ALL_ITEM_SALE_USER_START,
  GET_ALL_ITEM_SALE_USER_SUCCESS,
  GET_ALL_ITEM_SALE_USER_FAILED,
} from '../actions/item'

const initialState = {
  isLoading: false,
  allItemUser: [],
  totalItemUser: 0,
  allItems: [],
  total: 0,
  itemDetail: {},
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function itemManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ITEM_SALE_USER_START:
    case GET_ALL_ITEM_START:
    case GET_DETAIL_ITEM_START:
    case GET_ALL_ITEM_USER_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ALL_ITEM_SALE_USER_SUCCESS:
    case GET_ALL_ITEM_USER_SUCCESS:
      return {
        ...state,
        allItemUser: action?.payload?.items,
        totalItemUser: action?.payload?.count,
        isLoading: false,
      }
    case GET_ALL_ITEM_SUCCESS:
      return {
        ...state,
        allItems: action?.payload?.items,
        total: action?.payload?.count,
        isLoading: false,
      }

    case GET_DETAIL_ITEM_SUCCESS:
      return {
        ...state,
        itemDetail: action?.payload,
        isLoading: false,
      }

    case GET_ALL_ITEM_SALE_USER_FAILED:
    case GET_ALL_ITEM_FAILED:
    case GET_DETAIL_ITEM_FAILED:
    case GET_ALL_ITEM_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
