import { Col, Row, Collapse, InputNumber, DatePicker } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { eventData, assetTypeData } from './data/tradingHistoriesDataProfile'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'

export const FilterHistory = (props) => {
  const {
    event,
    assetType,
    date,
    setEvent,
    setAssetType,
    setDate,
    classCustom,
  } = props
  const { t } = useTranslation()
  const dateFormatList = ['YYYY/MM/DD']
  const clearAll = () => {
    setEvent([])
    setAssetType([])
    setDate([])
  }
  const onChangeDate = (newValue) => {
    setDate(newValue)
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  return (
    <div
      className={`${
        classCustom ?? 'filter__common'
      } bg-[#0d2758] p-[16px] max-w-[372px] w-[372px]`}
    >
      {isMobile ? (
        <></>
      ) : (
        <>
          {' '}
          <Row className="">
            <Col span={12}>
              <div className="text-[16px]"> {t('filter')}</div>
            </Col>
            <Col span={12} className="flex justify-end">
              <div
                className="text-[16px] text-[#FAAD14] cursor-pointer"
                onClick={() => {
                  clearAll()
                }}
              >
                {t('clearAll')}
              </div>
            </Col>
          </Row>
          <hr className="my-5" />
        </>
      )}

      <Collapse
        defaultActiveKey={['event', 'assetType', 'date']}
        expandIconPosition={'end'}
        items={[
          //event
          {
            key: 'event',
            label: t('common.filter.tag.event'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {eventData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      event?.find((rank) => rank === data) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      event?.find((rank) => rank === data)
                        ? setEvent(event.filter((rank) => rank !== data))
                        : setEvent((oldRank) => [...oldRank, data])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {t(`common.filter.event.${data}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //assetType
          {
            key: 'assetType',
            label: t('common.filter.tag.assetType'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {assetTypeData?.map((data) => (
                  <div
                    key={data.addressContract}
                    className={`flex items-center justify-center min-w-[32px] p-[10px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      assetType?.find(
                        (rank) => rank === data.addressContract,
                      ) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      assetType?.find((rank) => rank === data.addressContract)
                        ? setAssetType(
                            assetType.filter(
                              (rank) => rank !== data.addressContract,
                            ),
                          )
                        : setAssetType((oldRank) => [
                            ...oldRank,
                            data.addressContract,
                          ])
                    }}
                  >
                    <img
                      className="mr-2"
                      src={`../svg/assetType/${data.name}.svg`}
                      alt=""
                    />
                    <div
                      className="text-[14px] font__M__plus font-bold "
                      style={{ marginLeft: '-7px' }}
                    >
                      {t(`common.filter.assetType.${data.name}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          {
            key: 'date',
            label: t('common.filter.tag.date'),
            children: (
              <div>
                <div className="w-full font__M__plus font-medium">
                  <Row className="flex items center justify-between">
                    <Col span={11}>
                      <DatePicker
                        style={{
                          border: 'none',
                        }}
                        format={dateFormatList}
                        className="h-[44px] w-full bg-[#00113F]"
                        onChange={(value, dateString) => {
                          let temp = dateString.split('/')
                          onChangeDate([
                            Date.UTC(temp[0], temp[1] - 1, temp[2]) / 1000,
                            date[1],
                          ])
                        }}
                      />
                    </Col>
                    <Col span={1} className="flex items-center justify-center">
                      -
                    </Col>
                    <Col span={11}>
                      <DatePicker
                        style={{
                          border: 'none',
                        }}
                        className="h-[44px] w-full  bg-[#00113F]"
                        format={dateFormatList}
                        onChange={(value, dateString) => {
                          let temp = dateString.split('/')
                          onChangeDate([
                            date[0],
                            parseInt(
                              Date.UTC(
                                temp[0],
                                temp[1] - 1,
                                temp[2],
                                23,
                                59,
                                59,
                              ) / 1000,
                            ),
                          ])
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}
