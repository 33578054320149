import { Card, Col, Row, Skeleton } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'

import BodyCommon from '~/components/shared-components/BodyCommon'
export const LoadingHashDetail = () => {
  // eslint-disable-next-line
  const { t } = useTranslation()
  return (
    <BodyCommon>
      <div className="relative container__common mx-auto z-10  top-[84px]">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 pt-5 sm:pt-12">
          <div className="flex flex-col mb-3 sm:mb-0">
            <div className="relative card__monster_detail h-[530px] flex items-center justify-center">
              <div className="relative flex items-center justify-center w-[60%] h-[70%] max-w-[500px]">
                <Skeleton.Image
                  className=" flex items-center justify-center relative !w-[100%] !h-[100%]"
                  active={true}
                />
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="flex items-center">
              <div className="title__monster_detail">Loading...</div>
            </div>
            <Card className="mt-3 mb-8">
              <Row>
                <Col span={12} className="flex items-center">
                  <div className="relative z-10 flex items-center">
                    <img
                      className="mr-2"
                      src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                      alt=""
                      width={32}
                      height={32}
                    />
                    <div className="flex items-center">
                      <div className="flex text-[20px] sm:text-[32px] font-[700]">
                        <Skeleton.Button active={true} size={'small'} block />
                      </div>
                      <span className="text-[16px] sm:text-[20px] flex items-center">
                        <div className="px-3">~</div>{' '}
                        <Skeleton.Button active={true} size={'small'} block />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={12} className="text-end">
                  <Skeleton.Button
                    className="mb-1 !w-[15em]"
                    active={true}
                    size={'Large'}
                  />
                </Col>
              </Row>
            </Card>

            <Card className="w-full ">
              <Row className="gap-3"></Row>
            </Card>
          </div>
        </div>

        <div className="mt-5 mb-20"></div>
      </div>
    </BodyCommon>
  )
}

export default LoadingHashDetail
