import {
  FLOWER_HEXAGON_X2,
  FLOWER_HEXAGON_X3,
  FLOWER_HEXAGON_X4,
  HEXAGON_SUB,
  HEXAGON_FULL_X1,
  HEXAGON_FULL_X2,
  HEXAGON_FULL_X3,
  MAIN_SUB,
  MAIN_SUB_SUB_HEXAGON_FULL_X1_RM_RIGHT,
  MAIN_MAIN_DIAMOND_FULL_X1,
  MAIN_SUB_FULL_X3,
  MAIN_SUB_FULL_X2,
  HEXAGON_FULL_X1_Sub,
  HEXAGON_FULL_X1_1,
  HEXAGON_FULL_X1_Sub_1,
  HEXAGON_MAIN,
  FLOWER_HEXAGON_X3_1,
  MAIN_SUB_1,
  MAIN_FLOWER_HEXAGON_X2_Sub,
  HEXAGON_FULL_X3_1,
  MAIN_SUB_FULL_X3_1,
  FLOWER_HEXAGON_X2_1,
  MAIN_FLOWER_HEXAGON_X2_Sub_1,
  HEXAGON_FULL_X1_6,
  HEXAGON_FULL_X1_Sub_6,
  FLOWER_HEXAGON_X3_2,
  MAIN_SUB_2,
  FLOWER_HEXAGON_X4_Sub,
  FLOWER_HEXAGON_X3_X5,
  MAIN_SUB_X5,
  HEXAGON_FULL_X1_7,
  FLOWER_HEXAGON_X2_2,
  MAIN_FLOWER_HEXAGON_X2_Sub_2,
  HEXAGON_FULL_X2_19,
  MAIN_SUB_FULL_X2_19,
  FLOWER_HEXAGON_X2_4,
  MAIN_FLOWER_HEXAGON_X2_Sub_4,
  FLOWER_HEXAGON_X4_4,
  FLOWER_HEXAGON_X4_Sub_4,
  FLOWER_HEXAGON_X3_3,
  MAIN_SUB_3,
  HEXAGON_FULL_X1_O6,
  HEXAGON_FULL_X2_Sub_O6,
  FLOWER_HEXAGON_X2_3,
  MAIN_FLOWER_HEXAGON_X2_Sub_3,
  HEXAGON_FULL_X2_3,
  MAIN_SUB_FULL_X2_3,
  HEXAGON_FULL_X2_18,
  MAIN_SUB_FULL_X2_18,
  HEXAGON_FULL_X5,
  MAIN_SUB_FULL_X5,
  HEXAGON_FULL_X3_49,
  MAIN_SUB_FULL_X3_49,
  HEXAGON_FULL_X1_3,
  HEXAGON_FULL_X1_Sub_3,
  HEXAGON_FULL_X3_49_1,
  MAIN_SUB_FULL_X3_49_1,
  HEXAGON_FULL_X1_2,
  HEXAGON_FULL_X1_Sub_2,
  HEXAGON_FULL_X1_4,
  HEXAGON_FULL_X1_Sub_4,
  MAIN_FLOWER_HEXAGON_X2_Sub_1_Hero,
  MAIN_FLOWER_HEXAGON_X2_Sub_1_1,
} from '~/assets/data/dataImageHexagon'

export const getHexagonMonsterSkill = (monsterName, level) => {
  if (monsterName === 'Faireaf_1') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X3, MAIN_SUB]
      case 2:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 3:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 4:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 5:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 6:
        return [HEXAGON_FULL_X1, HEXAGON_FULL_X1_Sub]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Golem_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X3_1, MAIN_SUB_1]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Tofu_1') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X3, MAIN_SUB]
      case 2:
        return [FLOWER_HEXAGON_X2, MAIN_FLOWER_HEXAGON_X2_Sub]
      case 3:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 4:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_FULL_X3_1, MAIN_SUB_FULL_X3_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Unicorn_1') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Valius_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1, HEXAGON_FULL_X1_Sub]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      case 5:
        return [FLOWER_HEXAGON_X3_2, MAIN_SUB_2]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'AstarCat_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Aries') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X4, FLOWER_HEXAGON_X4_Sub]
      case 5:
        return [HEXAGON_FULL_X3_1, MAIN_SUB_FULL_X3_1]
      case 6:
        return [FLOWER_HEXAGON_X3_X5, MAIN_SUB_X5]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Taurus') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1, HEXAGON_FULL_X1_Sub]
      case 4:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 5:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 6:
        return [HEXAGON_FULL_X3_1, MAIN_SUB_FULL_X3_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_GeminiA') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X4, FLOWER_HEXAGON_X4_Sub]
      case 5:
        return [HEXAGON_FULL_X1, HEXAGON_FULL_X1_Sub]
      case 6:
        return [FLOWER_HEXAGON_X3_1, MAIN_SUB_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_GeminiB') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X4, FLOWER_HEXAGON_X4_Sub]
      case 5:
        return [HEXAGON_FULL_X1, HEXAGON_FULL_X1_Sub]
      case 6:
        return [HEXAGON_FULL_X1_7, MAIN_SUB_SUB_HEXAGON_FULL_X1_RM_RIGHT]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Cancer') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [FLOWER_HEXAGON_X3_X5, MAIN_SUB_X5]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Leo') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [FLOWER_HEXAGON_X2_2, MAIN_FLOWER_HEXAGON_X2_Sub_2]
      case 6:
        return [HEXAGON_FULL_X3_1, MAIN_SUB_FULL_X3_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Virgilia') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 4:
        return [HEXAGON_FULL_X2_19, MAIN_SUB_FULL_X2_19]
      case 5:
        return [HEXAGON_FULL_X3, MAIN_SUB_FULL_X3]
      case 6:
        return [FLOWER_HEXAGON_X2_4, MAIN_FLOWER_HEXAGON_X2_Sub_4]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Libra') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [FLOWER_HEXAGON_X2, MAIN_MAIN_DIAMOND_FULL_X1]
      case 6:
        return [FLOWER_HEXAGON_X2_4, MAIN_FLOWER_HEXAGON_X2_Sub_4]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Scorpius') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X2_4, MAIN_FLOWER_HEXAGON_X2_Sub_4]
      case 5:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 6:
        return [FLOWER_HEXAGON_X4_4, FLOWER_HEXAGON_X4_Sub_4]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Sagittarius') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [FLOWER_HEXAGON_X3_2, MAIN_SUB_2]
      case 4:
        return [FLOWER_HEXAGON_X3_2, MAIN_SUB_2]
      case 5:
        return [FLOWER_HEXAGON_X3_3, MAIN_SUB_3]
      case 6:
        return [HEXAGON_FULL_X1_O6, HEXAGON_FULL_X2_Sub_O6]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Capricorn') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_7, HEXAGON_FULL_X1_7]
      case 5:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 6:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Aquarius') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 4:
        return [HEXAGON_FULL_X2_19, MAIN_SUB_FULL_X2_19]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [FLOWER_HEXAGON_X2_4, MAIN_FLOWER_HEXAGON_X2_Sub_4]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'SignWitch_Pisces') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Layla_1') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [FLOWER_HEXAGON_X2_3, MAIN_FLOWER_HEXAGON_X2_Sub_3]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 6:
        return [HEXAGON_FULL_X2_3, MAIN_SUB_FULL_X2_3]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'ODENPETS_Pochi') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'ODENPETS_Labv') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 3:
        return [FLOWER_HEXAGON_X2_3, MAIN_FLOWER_HEXAGON_X2_Sub_3]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [FLOWER_HEXAGON_X2_4, MAIN_FLOWER_HEXAGON_X2_Sub_4]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'ODENPETS_Laby') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 3:
        return [FLOWER_HEXAGON_X2_3, MAIN_FLOWER_HEXAGON_X2_Sub_3]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [FLOWER_HEXAGON_X2_4, MAIN_FLOWER_HEXAGON_X2_Sub_4]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'ODENPETS_Nyanpen') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [FLOWER_HEXAGON_X2_3, MAIN_FLOWER_HEXAGON_X2_Sub_3]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'ODENPETS_Kumatam') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_FULL_X1, HEXAGON_FULL_X1_Sub]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'ODENPETS_Kumatama') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_FULL_X1, HEXAGON_FULL_X1_Sub]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'ODENPETS_Monta') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [HEXAGON_FULL_X1_3, HEXAGON_FULL_X1_Sub_3]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Hiyori_1') {
    switch (level) {
      case 1:
        return [MAIN_SUB_FULL_X2, MAIN_SUB_FULL_X2]
      case 2:
        return [MAIN_SUB_FULL_X2, MAIN_SUB_FULL_X2]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X2_19, MAIN_SUB_FULL_X2_19]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [FLOWER_HEXAGON_X2_4, MAIN_FLOWER_HEXAGON_X2_Sub_4]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Yamato_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [FLOWER_HEXAGON_X3_2, MAIN_SUB_2]
      case 4:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      case 5:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      case 6:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Dekausa_1') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_1]
      case 2:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_1]
      case 6:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'DEKAUSA_1') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_1]
      case 2:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_1]
      case 6:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'UPA_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [FLOWER_HEXAGON_X4, FLOWER_HEXAGON_X4_Sub]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'CTT_Coni') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X2_18, MAIN_SUB_FULL_X2_18]
      case 2:
        return [HEXAGON_FULL_X5, MAIN_SUB_FULL_X5]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      case 6:
        return [HEXAGON_FULL_X3_49, MAIN_SUB_FULL_X3_49]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'CTT_Mous') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'CTT_Bot') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X3_2, MAIN_SUB_2]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_FULL_X1_3, HEXAGON_FULL_X1_Sub_3]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_FULL_X1_4, HEXAGON_FULL_X1_Sub_4]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'CTT_Conic') {
    switch (level) {
      case 1:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 2:
        return [HEXAGON_FULL_X3_49_1, MAIN_SUB_FULL_X3_49_1]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      case 5:
        return [FLOWER_HEXAGON_X3_1, MAIN_SUB_1]
      case 6:
        return [HEXAGON_FULL_X3_49, MAIN_SUB_FULL_X3_49]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsHero_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_Hero]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [FLOWER_HEXAGON_X2_3, MAIN_FLOWER_HEXAGON_X2_Sub_3]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsHero_2') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_Hero]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsHero_3') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_Hero]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [FLOWER_HEXAGON_X2_3, MAIN_FLOWER_HEXAGON_X2_Sub_3]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsHero_4') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_Hero]
      case 5:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsHero_5') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1_Hero]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [FLOWER_HEXAGON_X3_2, MAIN_SUB_2]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsVillian_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsVillian_2') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsVillian_3') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsVillian_4') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Kidsfight_KidsVillian_5') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Icelime_Eien') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 3:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'Amechan_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [HEXAGON_FULL_X1_2, HEXAGON_FULL_X1_Sub_2]
      case 4:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'PlanetMan_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 3:
        return [FLOWER_HEXAGON_X3_2, MAIN_SUB_2]
      case 4:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 5:
        return [HEXAGON_FULL_X1_4, HEXAGON_FULL_X1_Sub_4]
      case 6:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'P1_XSoldier') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 3:
        return [FLOWER_HEXAGON_X2_1, MAIN_FLOWER_HEXAGON_X2_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'OssanParadise_1') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'OssanParadise_2') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'OssanParadise_3') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 6:
        return [FLOWER_HEXAGON_X3_1, MAIN_SUB_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'OssanParadise_4') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_FULL_X1_6, HEXAGON_FULL_X1_Sub_6]
      case 6:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      default:
        return [[], []]
    }
  }
  if (monsterName === 'OssanParadise_5') {
    switch (level) {
      case 1:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 2:
        return [HEXAGON_FULL_X2, MAIN_SUB_FULL_X2]
      case 3:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 4:
        return [HEXAGON_FULL_X1_1, HEXAGON_FULL_X1_Sub_1]
      case 5:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      case 6:
        return [HEXAGON_MAIN, HEXAGON_SUB]
      default:
        return [[], []]
    }
  }
}
