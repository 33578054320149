import { convertPrice, formatNumber } from '~/helpers/common'
import { ReMonsterTwoBorderSm } from '../../../../../components/shared-components/Button/ReMonsterTwoBorderSm'
import { useEffect, useState } from 'react'
import CheckoutShopModal from '~/components/Modals/CheckoutShop'
import { getBalanceOAS } from '~/blockchain/provider'
import { useAccount } from '~/common/hooks/useAccount'
import SelectWallet from '~/components/Modals/SelectWallet'

const ShopBITCard = (props) => {
  const { t, loadingModal, handleConfirm, item, tema } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const { user } = useAccount()
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }
  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpen(true)
  }

  const handleOk = (item, quantity) => {
    handleConfirm(item, quantity, handleCancel)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (user) {
      loadBalanceOAS()
    }
  }, [user])

  return (
    <div className="card__monster w-[250px] h-[350px]">
      <div className="relative flex items-center justify-center h-full">
        <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
          <div
            className="h-full flex flex-col items-center p-[10px] text-[18px]"
            style={{
              background: 'linear-gradient(180deg, #0D1426 0%, #375877 100%)',
            }}
          >
            <div className="flex items-center">
              <div className="title__shop_card !text-[16px] !font-normal">
                10,000 {item?.name}
              </div>
            </div>

            <div className="h-full flex items-center justify-center">
              <img
                alt=""
                className="card__monster_img w-[135px] h-[160px] object-contain	ease-in-out duration-300"
                src={item?.img}
              />
            </div>
          </div>

          <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
            <div className="flex items-center">
              <img
                className="w-[16px] h-[16px] object-contain	"
                src="./imgs/coins/oasis.svg"
                alt=""
              />
              <div className="relative whitespace-nowrap flex ml-2">
                {formatNumber(convertPrice(item?.price, tema))}
              </div>
            </div>

            <div className="text-[12px] mb-1">${formatNumber(item?.price)}</div>

            <ReMonsterTwoBorderSm functionButton={() => showModalCheckout()} />
          </div>
        </div>
      </div>
      <CheckoutShopModal
        action={{
          isModalOpen: isModalOpen,
          handleOk: handleOk,
          handleCancel: handleCancel,
        }}
        t={t}
        box={item}
        handleConfirm={handleConfirm}
        loadingModal={loadingModal}
        tema={tema}
        balance={balanceOAS}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </div>
  )
}

export default ShopBITCard
