import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/gameApi'
import {
  sendVerifyEmailSuccess,
  sendVerifyEmailFailed,
  SEND_VERIFY_EMAIL_START,
} from '../../actions/accountProfile'

/**
 * send verify email api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const sendVerifyEmailApi = (props) => {
  var uri = `/api/mail/sendverifycode`

  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doSendVerifyEmail(action) {
  try {
    const response = yield call(sendVerifyEmailApi, action?.payload)
    yield put(sendVerifyEmailSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }

  } catch (error) {
    yield put(sendVerifyEmailFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch send verify email
 */
export default function* watchSendVerifyEmail() {
  yield takeLatest(SEND_VERIFY_EMAIL_START, doSendVerifyEmail)
}
