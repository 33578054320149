import { ethers, BigNumber } from 'ethers'
import configs from '~/configs'

const rpcUrl = configs.NETWORK.rpc || 'https://rpc.sandverse.oasys.games/'
const provider = new ethers.providers.JsonRpcProvider(rpcUrl)

const rpcUrlPolygon = configs.POLYGON_NETWORK.rpc || 'https://rpc-amoy.polygon.technology/'
export const providerPolygon = new ethers.providers.JsonRpcProvider(rpcUrlPolygon)

export async function checkTransactionConfirmation(txhash) {
  let checkTransactionLoop = async () => {
    return provider
      .getTransactionReceipt(txhash)
      .then((r) => {
        if (r != null) {
          return 'confirmed'
        } else {
          return checkTransactionLoop()
        }
      })
      .catch(() => {
        return checkTransactionLoop()
      })
  }
  return checkTransactionLoop()
}

export async function checkTransactionConfirmationPolygon(txhash) {
  let checkTransactionLoop = async () => {
    return providerPolygon
      .getTransactionReceipt(txhash)
      .then((r) => {
        if (r != null) {
          return 'confirmed'
        } else {
          return checkTransactionLoop()
        }
      })
      .catch(() => {
        return checkTransactionLoop()
      })
  }
  return checkTransactionLoop()
}

export function hexToNumberString(hex) {
  return BigNumber.from(hex).toString()
}
export function numberToHex(number) {
  return ethers.utils.hexValue(BigNumber.from(String(number)))
}

export function fromWei(number) {
  return ethers.utils.formatEther(String(number))
}

export async function getBalanceOAS(address) {
  return fromWei(String(await provider.getBalance(address)))
}

export function toWei(number) {
  return ethers.utils.parseEther(String(number))
}

export function getAssetType(address) {
  switch (address.toLowerCase()) {
    case configs.ADDRESS_MONSTER.toLowerCase():
      return 'Monster'
    case configs.ADDRESS_FARM.toLowerCase():
      return 'Farm'
    case configs.ADDRESS_GENERAL.toLowerCase():
      return 'General Hash'
    case configs.ADDRESS_GENESIS.toLowerCase():
      return 'Genesis Hash'
    case configs.ADDRESS_REGENERATION_ITEM.toLowerCase():
      return 'Regeneration Hash'
    case configs.ADDRESS_TRAINING_ITEM.toLowerCase():
      return 'Training Item'
    case configs.ADDRESS_FUSION_ITEM.toLowerCase():
      return 'Fusion Item'
    case configs.ADDRESS_ENHANCE_ITEM.toLowerCase():
      return 'Enhance Item'
    default:
      return undefined
  }
}

export function is1155(address) {
  let data = [
    configs.ADDRESS_TRAINING_ITEM.toLowerCase(),
    configs.ADDRESS_ENHANCE_ITEM.toLowerCase(),
    configs.ADDRESS_FUSION_ITEM.toLowerCase(),
    configs.ADDRESS_REGENERATION_ITEM.toLowerCase(),
  ]
  return data.includes(address.toLowerCase())
}

export default provider
