export const GET_ALL_MONSTER_USER_START = 'GET_ALL_MONSTER_USER_START'
export const GET_ALL_MONSTER_USER_SUCCESS = 'GET_ALL_MONSTER_USER_SUCCESS'
export const GET_ALL_MONSTER_USER_FAILED = 'GET_ALL_MONSTER_USER_FAILED'

export const GET_ALL_MONSTER_START = 'GET_ALL_MONSTER_START'
export const GET_ALL_MONSTER_SUCCESS = 'GET_ALL_MONSTER_SUCCESS'
export const GET_ALL_MONSTER_FAILED = 'GET_ALL_MONSTER_FAILED'

export const GET_DETAIL_MONSTER_START = 'GET_DETAIL_MONSTER_START'
export const GET_DETAIL_MONSTER_SUCCESS = 'GET_DETAIL_MONSTER_SUCCESS'
export const GET_DETAIL_MONSTER_FAILED = 'GET_DETAIL_MONSTER_FAILED'

export const GET_ALL_MONSTER_SALE_USER_START = 'GET_ALL_MONSTER_SALE_USER_START'
export const GET_ALL_MONSTER_SALE_USER_SUCCESS = 'GET_ALL_MONSTER_SALE_USER_SUCCESS'
export const GET_ALL_MONSTER_SALE_USER_FAILED = 'GET_ALL_MONSTER_SALE_USER_FAILED'

export const GET_ALL_MONSTER_HASH_START = 'GET_ALL_MONSTER_HASH_START'
export const GET_ALL_MONSTER_HASH_SUCCESS = 'GET_ALL_MONSTER_HASH_SUCCESS'
export const GET_ALL_MONSTER_HASH_FAILED = 'GET_ALL_MONSTER_HASH_FAILED'

export const GET_MONSTER_HASH_DETAIL_START = 'GET_MONSTER_HASH_DETAIL_START'
export const GET_MONSTER_HASH_DETAIL_SUCCESS = 'GET_MONSTER_HASH_DETAIL_SUCCESS'
export const GET_MONSTER_HASH_DETAIL_FAILED = 'GET_MONSTER_HASH_DETAIL_FAILED'
//-------------------------Monster------------------------//

/**
 * [1]
 * get all monster user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllMonsterUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_MONSTER_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all monster user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllMonsterUserSuccess(payload) {
  return {
    type: GET_ALL_MONSTER_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all monster user failed action
 * @returns {object}
 */
export function getAllMonsterUserFailed() {
  return {
    type: GET_ALL_MONSTER_USER_FAILED,
  }
}

/**
 * [2]
 * get all monster start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllMonster(payload, onSuccess, onError) {
  return {
    type: GET_ALL_MONSTER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all monster success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllMonsterSuccess(payload) {
  return {
    type: GET_ALL_MONSTER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all monster failed action
 * @returns {object}
 */
export function getAllMonsterFailed() {
  return {
    type: GET_ALL_MONSTER_FAILED,
  }
}

/**
 * [3]
 * get detail monster start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDetailMonster(payload, onSuccess, onError) {
  return {
    type: GET_DETAIL_MONSTER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get detail monster success action
 * @param {*} payload
 * @returns {object}
 */
export function getDetailMonsterSuccess(payload) {
  return {
    type: GET_DETAIL_MONSTER_SUCCESS,
    payload: payload,
  }
}

/**
 * get detail monster failed action
 * @returns {object}
 */
export function getDetailMonsterFailed() {
  return {
    type: GET_DETAIL_MONSTER_FAILED,
  }
}

/**
 * [4]
 * get all monster sale user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllMonsterSaleUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_MONSTER_SALE_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all monster sale user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllMonsterSaleUserSuccess(payload) {
  return {
    type: GET_ALL_MONSTER_SALE_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all monster sale user failed action
 * @returns {object}
 */
export function getAllMonsterSaleUserFailed() {
  return {
    type: GET_ALL_MONSTER_SALE_USER_FAILED,
  }
}

/**
 * [5]
 * get all monster hash start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllMonsterHash(payload, onSuccess, onError) {
  return {
    type: GET_ALL_MONSTER_HASH_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all monster hash success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllMonsterHashSuccess(payload) {
  return {
    type: GET_ALL_MONSTER_HASH_SUCCESS,
    payload: payload,
  }
}

/**
 * get all monster hash failed action
 * @returns {object}
 */
export function getAllMonsterHashFailed() {
  return {
    type: GET_ALL_MONSTER_HASH_FAILED,
  }
}

/**
 * [6]
 * get monster hash detail start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getMonsterHashDetail(payload, onSuccess, onError) {
  return {
    type: GET_MONSTER_HASH_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get monster hash detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getMonsterHashDetailSuccess(payload) {
  return {
    type: GET_MONSTER_HASH_DETAIL_SUCCESS,
    payload: payload,
  }
}

/**
 * get monster hash detail failed action
 * @returns {object}
 */
export function getMonsterHashDetailFailed() {
  return {
    type: GET_MONSTER_HASH_DETAIL_FAILED,
  }
}

export default {
  //User
  getAllMonsterUser,
  getAllMonsterUserSuccess,
  getAllMonsterUserFailed,

  //MKP
  getAllMonster,
  getAllMonsterSuccess,
  getAllMonsterFailed,

  getDetailMonster,
  getDetailMonsterSuccess,
  getDetailMonsterFailed,

  getAllMonsterSaleUser,
  getAllMonsterSaleUserSuccess,
  getAllMonsterSaleUserFailed,

  getAllMonsterHash,
  getAllMonsterHashSuccess,
  getAllMonsterHashFailed,

  getMonsterHashDetail,
  getMonsterHashDetailSuccess,
  getMonsterHashDetailFailed,
}
