export const getContentInnateTrait = (trait) => {
  switch (trait) {
    case 'Healthy_Body':
      return 'All physical debuff resistances +15%, Fatigue reduction +5% Chance of injury or sickness -10%'
    case 'Iron_Heart':
      return 'All mental debuff resistances +15%, Stress reduction +5% Chance of injury or sickness -10%'
    case 'Agility':
      return 'AVD +15%'
    case 'Insight':
      return 'HIT +15%'
    case 'Hyper_Reactivity':
      return 'AVD and HIT +10%'
    case 'Sniper':
      return 'HIT +10%, CRI +3%'
    case 'Surviver':
      return 'AVD +5%, CRI Resistance +20%, Damage Reduction +5%'
    case 'Armor_piercing':
      return 'CRI +5%, CRI Damage +20%'
    case 'Flame_Aptitude':
      return 'Damage correction for fire element skills +10%, resistance +10%'
    case 'Water_Aptitude':
      return 'Damage correction for water element skills +10%, resistance +10%'
    case 'Wind_Aptitude':
      return 'Damage correction for wind element skills +10%, resistance +10%'
    case 'Earth_Aptitude':
      return 'Damage correction for earth element skills +10%, resistance +10%'
    case 'Slashing_Aptitude':
      return 'Damage correction for slash element skills +10%, resistance +10%'
    case 'Strike_Aptitude':
    case 'Blunt_Aptitude':
      return 'Damage correction for strike element skills +10%, resistance +10%'
    case 'Thrust_Aptitude':
      return 'Damage correction for thrust element skills +10%, resistance +10%'
    case 'Flame_Mastery':
      return 'Damage correction for fire element skills +15%.'
    case 'Water_Mastery':
      return 'Damage correction for water element skills +15%.'
    case 'Wind_Mastery':
      return 'Damage correction for wind element skills +15%.'
    case 'Earth_Mastery':
      return 'Damage correction for earth element skills +15%.'
    case 'Slash_Mastery':
      return 'Damage correction for slash element skills +15%.'
    case 'Strike_Mastery':
    case 'Blunt_Mastery':
      return 'Damage correction for strike element skills +15%.'
    case 'Thrust_Mastery':
    case 'Piercing_Mastery':
      return 'Damage correction for thrust element skills +15%.'
    case 'Converging_Muscles':
      return 'STR skill damage +10%.'
    case 'Take_a_chance':
      return 'Skill damage +5%, Randomness correction +10%'
    case 'Healing_Wave':
      return 'HP and ST recovery effect of skills +10%'
    case 'Support_Master':
      return 'Buffing effect +20%'
    case 'Contrarian':
      return 'Debuff effect +20%, success rate +15%'
    case 'Elemental_Body':
      return 'Resistance to fire, water, wind, and earth elements +10%'
    case 'Flexible_Body':
      return 'Resistance to strike, slash, and thrust elements +12%'
    case 'Strong_Heart':
      return 'Stamina recovery +10%, fatigue reduction +5%'
    case 'Lightning':
      return 'SPD +15%, Stamina consumption -10%'
    case 'Concentration':
      return 'Training huge success rate +5%, failure rate -5%.'
    case 'Longevity':
      return 'Lifespan +300, Resistance to all debuffs +5%'
    case 'Talent_for_Sleep':
      return 'Rest effect +10%, sleep resistance +20%'
    case 'Treasure_Hunter':
      return '10% increase in the probability of finding items and rank increase in exploration'
    case 'Genius':
      return 'Chance to learn a skill and increase its level +10%'
    case 'Adaptability':
      return 'Chance to acquire traits and increase level +10%.'
    case 'Inheritance':
      return 'Parameters +20% when Fusion & Crystallization'
    case 'Coaching':
      return 'Parameters +20% when made coach'
    default:
      return 'None'
  }
}

export const getContentAcquiredTrait = (trait) => {
  switch (trait) {
    case 'HP_Plus':
      return 'HP +20 x trait level'
    case 'STR_Plus':
      return 'STR +20 x trait level'
    case 'INT_Plus':
      return 'INT +20 x trait level'
    case 'DEX_Plus':
      return 'DEX +20 x trait level'
    case 'AGI_Plus':
      return 'AGI +20 x trait level'
    case 'VIT_Plus':
      return 'VIT +20 x trait level'
    case 'HIT_Plus':
      return 'HIT +2% x trait level'
    case 'AVD_Plus':
      return 'AVD +2% x trait level'
    case 'CRI_Plus':
      return 'CRI +1%  x trait level'
    case 'RES_Plus':
      return 'Physical debuff resistance +2% x trait level'
    case 'MND_Plus':
      return 'Mental debuff resistance +2% x trait level'
    case 'ST_Plus':
      return 'Stamina at start of battle +2 x trait level'
    case 'SPD_Plus':
      return 'SPD +2 x trait level'
    case 'Fire_RES_Plus':
      return 'Fire resistance +2% x trait level'
    case 'Fire_Res_Plus':
      return 'Fire resistance +2% x trait level'
    case 'Water_RES_Plus':
      return 'Water resistance +2% x trait level'
    case 'Water_Res_Plus':
      return 'Water resistance +2% x trait level'
    case 'Wind_RES_Plus':
      return 'Wind resistance +2% x trait level'
    case 'Wind_Res_Plus':
      return 'Wind resistance +2% x trait level'
    case 'Earth_RES_Plus':
      return 'Earth resistance +2% x trait level'
    case 'Earth_Res_Plus':
      return 'Earth resistance +2% x trait level'
    case 'Slash_RES_Plus':
      return 'Slash resistance +2% x trait level'
    case 'Slash_Res_Plus':
      return 'Slash resistance +2% x trait level'
    case 'Blunt_RES_Plus':
    case 'Strike_RES_Plus':
      return 'Strike resistance +2% x trait level'
    case 'Blunt_Res_Plus':
    case 'Strike_Res_Plus':
      return 'Strike resistance +2% x trait level'
    case 'Thrust_RES_Plus':
      return 'Thrust resistance +2% x trait level'
    case 'Thrust_Res_Plus':
      return 'Thrust resistance +2% x trait level'
    default:
      return 'None'
  }
}

export const getContentFarmTrait = (trait) => {
  switch (trait) {
    case 'HP_Plus':
      return 'HP +50'
    case 'STR_Plus':
      return 'STR +50'
    case 'INT_Plus':
      return 'INT +50'
    case 'DEX_Plus':
      return 'DEX +50'
    case 'AGI_Plus':
      return 'AGI +50'
    case 'VIT_Plus':
      return 'VIT +50'
    case 'HIT_Plus':
      return 'HIT +5%'
    case 'AVD_Plus':
      return 'AVD +5%'
    case 'CRI_Plus':
      return 'CRI +3%'
    case 'RES_Plus':
      return 'Physical debuff resistance +5%'
    case 'MND_Plus':
      return 'Mental debuff resistance +5%'
    case 'ST_Plus':
      return 'Stamina at start of battle +5'
    case 'SPD_Plus':
      return 'SPD +5'
    case 'Fire_RES_Plus':
      return 'Fire resistance +5%'
    case 'Water_RES_Plus':
      return 'Water resistance +5%'
    case 'Wind_RES_Plus':
      return 'Wind resistance +5%'
    case 'Earth_RES_Plus':
      return 'Earth resistance +5%'
    case 'Slash_RES_Plus':
      return 'Slash resistance +5%'
    case 'Blunt_RES_Plus':
    case 'Strike_RES_Plus':
      return 'Strike resistance +5%'
    case 'Thrust_RES_Plus':
      return 'Thrust resistance +5%'
    default:
      return 'None'
  }
}
