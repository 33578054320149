import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/blockchainApi'
import {
  getHistoriesTokenSuccess,
  getHistoriesTokenFailed,
  GET_HISTORIES_TOKEN_START,
} from '../../actions/common'

/**
 * get histories api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getHistoriesTokenApi = (props) => {
  var uri = `/api/history/token-id`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetHistoriesToken(action) {
  try {
    const response = yield call(getHistoriesTokenApi, action?.payload)
    // if (response?.status_code === 200) {
      yield put(getHistoriesTokenSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
    // } else {
    //   throw response?.data?.message
    // }
  } catch (error) {
    yield put(getHistoriesTokenFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get role
 */
export default function* watchGetHistoriesToken() {
  yield takeLatest(GET_HISTORIES_TOKEN_START, doGetHistoriesToken)
}
