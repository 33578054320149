import {
  SIGN_BUY_SHOP_START,
  SIGN_BUY_SHOP_SUCCESS,
  SIGN_BUY_SHOP_FAILED,
  GET_DATA_SHOP_START,
  GET_DATA_SHOP_SUCCESS,
  GET_DATA_SHOP_FAILED,
  BUY_ITEM_SHOP_SUCCESS,
  BUY_ITEM_SHOP_FAILED,
  BUY_ITEM_SHOP_START,
  SELL_ITEM_SHOP_START,
  SELL_ITEM_SHOP_SUCCESS,
  SELL_ITEM_SHOP_FAILED,
} from '../actions/shop'

const initialState = {
  isLoading: false,
  settings: [],
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function shopManagement(state = initialState, action) {
  switch (action.type) {
    case SIGN_BUY_SHOP_START:
    case GET_DATA_SHOP_START:
    case BUY_ITEM_SHOP_START:
    case SELL_ITEM_SHOP_START:
      return {
        ...state,
        isLoading: true,
      }
    case BUY_ITEM_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case SELL_ITEM_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case SIGN_BUY_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DATA_SHOP_SUCCESS:
      return {
        ...state,
        shopItem: action?.payload,
        isLoading: false,
      }
    case BUY_ITEM_SHOP_FAILED:
    case SIGN_BUY_SHOP_FAILED:
    case GET_DATA_SHOP_FAILED:
    case SELL_ITEM_SHOP_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
