import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/blockchainApi'
import {
  getHistoriesSuccess,
  getHistoriesFailed,
  GET_HISTORIES_START,
} from '../../actions/common'

/**
 * get histories api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getHistoriesApi = (props) => {
  var uri = `/api/history`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetHistories(action) {
  try {
    const response = yield call(getHistoriesApi, action?.payload)
    // if (response?.status_code === 200) {
      yield put(getHistoriesSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
    // } else {
    //   throw response?.data?.message
    // }
  } catch (error) {
    yield put(getHistoriesFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get role
 */
export default function* watchGetHistories() {
  yield takeLatest(GET_HISTORIES_START, doGetHistories)
}
