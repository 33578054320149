import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/gameApi'
import {
  getAccountProfileSuccess,
  getAccountProfileFailed,
  GET_ACCOUNT_PROFILE_START,
} from '../../actions/accountProfile'

/**
 * get Account Profile api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getAccountProfileApi = () => {
  var uri = `/api/user/playerinfo`
  return api.post(uri)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetAccountProfile(action) {
  try {
    const response = yield call(getAccountProfileApi, action?.payload)
    // if (response?.status_code === 200) {
    yield put(getAccountProfileSuccess(response?.data))

    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
    // } else {
    //   throw response?.data?.message
    // }
  } catch (error) {
    yield put(getAccountProfileFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get role
 */
export default function* watchGetAccountProfile() {
  yield takeLatest(GET_ACCOUNT_PROFILE_START, doGetAccountProfile)
}
