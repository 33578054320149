import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/gameApi'
import {
  getPlayerInfoSuccess,
  getPlayerInfoFailed,
  GET_PLAYER_INFO_START,
} from '../../actions/accountProfile'

/**
 * get get player info api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getPlayerInfoApi = () => {
  var uri = `/api/user/playerinfo`
  return api.post(uri)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetPlayerInfo(action) {
  try {
    const response = yield call(getPlayerInfoApi, action?.payload)
    yield put(getPlayerInfoSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getPlayerInfoFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get player info
 */
export default function* watchGetPlayerInfo() {
  yield takeLatest(GET_PLAYER_INFO_START, doGetPlayerInfo)
}
