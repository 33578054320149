import { useEffect, useState } from 'react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  checkTransactionConfirmation,
  hexToNumberString,
  numberToHex,
} from '~/blockchain/provider'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyShop from '~/components/shared-components/BodyShop'
import ShopItemCard from '~/views/app/Shop/components/ShopItemCard'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useShop } from '~/views/redux/hooks/useShop'
import configs from '~/configs'
import { ifaceContractShop } from '~/blockchain/contract'
import TransactionCompletedShop from '~/components/Modals/TransactionCompletedShop'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { useSDK } from '@metamask/sdk-react'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'
import { api } from '~/services/api/blockchainApi'

export const FarmShop = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  const { actions } = useShop()
  const { actions: actionsCommon } = useCommon()
  const [dataFarm, setDataFarm] = useState()
  const [tema, setTema] = useState(1)
  const [loadingModal, setLoadingModal] = useState(false)
  const [txHash, setTxHash] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const [dataFarmResult, setDataFarmResult] = useState([])
  const {
    actions: actionTheme,
    data: { user },
  } = useTheme()

  const { actions: actionsProfile } = useAccountProfile()

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }
  const getResultBuyFarmApi = (props) => {
    var uri = `/api/shop/result-buy-farm`
    return api.get(uri, props)
  }

  const handleBuyFarmShop = async (
    item,
    quantity,
    closeModalCheckout = null,
  ) => {
    try {
      setLoadingModal(true)
      const data = {
        address: user?.address_wallet,
        typeAsset: 'farm',
        group: 'a',
        amount: Number(quantity),
      }
      actions.signBuyShop(data, (res) => {
        buyFarm(
          res.typeAsset,
          res.address,
          1,
          res.price,
          Number(quantity),
          res.deadline,
          res.signature,
          closeModalCheckout,
        )
      })
    } catch (error) {
      setLoadingModal(false)
      // eslint-disable-next-line
      console.log(error)
    }
  }
  const buyFarm = async (
    typeAsset,
    address,
    group,
    priceInWei,
    number,
    deadline,
    sig,
    closeModalCheckout = null,
  ) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractShop.encodeFunctionData('buyItem', [
            typeAsset,
            address,
            0,
            group,
            priceInWei,
            number,
            deadline,
            sig,
          ]),
          to: configs.ADDRESS_SHOP,
          value: numberToHex(priceInWei),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            if (closeModalCheckout) {
              closeModalCheckout()
            }
            setTxHash(transaction?.hash)
            actionsProfile.getAccountProfile((res) => {
              actionTheme.updateInfoUser({
                ...res?.player_info,
              })
            })
            showModalSuccess() // Show success modal only after successful API call
            // Add delay of 5 seconds before calling the API
            setTimeout(() => {
              getResultBuyFarmApi({ address: user.address_wallet })
                .then((dataResultBuyFarm) => {
                  // Handle the API response here
                  console.log(
                    'Result from getResultBuyFarmApi:',
                    dataResultBuyFarm,
                  )
                })
                .catch((error) => {
                  console.error('Error fetching result after buy farm:', error)
                })
                .finally(() => {})
            }, 5000)
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: address,
                data: ifaceContractShop.encodeFunctionData('buyItem', [
                  typeAsset,
                  address,
                  0,
                  group,
                  priceInWei,
                  number,
                  deadline,
                  sig,
                ]),
                to: configs.ADDRESS_SHOP,
                value: numberToHex(priceInWei),
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                if (closeModalCheckout) {
                  closeModalCheckout()
                }
                setTxHash(txHash)
                actionsProfile.getAccountProfile((res) => {
                  actionTheme.updateInfoUser({
                    ...res?.player_info,
                  })
                })
                showModalSuccess() // Show success modal only after successful API call
                // Add delay of 5 seconds before calling the API
                setTimeout(() => {
                  getResultBuyFarmApi({ address: user.address_wallet })
                    .then((dataResultBuyFarm) => {
                      console.log(
                        'Result from getResultBuyFarmApi:',
                        dataResultBuyFarm,
                      )
                      setDataFarmResult(dataResultBuyFarm?.result)
                    })
                    .catch((error) => {
                      console.error(
                        'Error fetching result after buy farm:',
                        error,
                      )
                    })
                    .finally(() => {})
                }, 5000)
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
      // eslint-disable-next-line
      console.error(error)
    }
  }

  const loadFarm = async () => {
    actions.getDataShop({}, (res) => {
      let data = [
        {
          name: 'FARM',
          img: './imgs/common/farm.png',
          price: res[0]?.price,
          total: res[0]?.total,
          remaining: res[0]?.remaining,
        },
      ]
      setDataFarm(data)
    })
  }

  useEffect(() => {
    actionsCommon.getTema({}, (res) => {
      setTema(res?.tema)
    })
    loadFarm()
  }, [])

  return (
    <BodyShop>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="">
          <div className="title__shop mb-10">
            {t('shop.farmShop').toUpperCase()}
          </div>
        </div>
        <div className="flex flex-wrap gap-5">
          {dataFarm?.map((data, index) => (
            <ShopItemCard
              t={t}
              tema={tema}
              key={index}
              item={data}
              handleConfirm={handleBuyFarmShop}
              loadingModal={loadingModal}
            />
          ))}
        </div>
      </div>
      <TransactionCompletedShop
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        name={dataFarm ? `${dataFarm[0]?.name}` : ''}
        store={'Shop'}
        txHash={txHash}
        dataBuyFarm={dataFarmResult}
      />
    </BodyShop>
  )
}

export default FarmShop
