import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/gameApi'
import {
  updateAccountProfileSuccess,
  updateAccountProfileFailed,
  UPDATE_ACCOUNT_PROFILE_START,
} from '../../actions/accountProfile'

/**
 * get Account Profile api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateAccountProfileApi = (props) => {
  var uri = `/api/user/updateplayerinfo`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetUpdateAccountProfile(action) {
  try {
    const response = yield call(updateAccountProfileApi, action?.payload)
    // if (response?.status_code === 200) {
    yield put(updateAccountProfileSuccess(response?.data))

    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
    // } else {
    //   throw response?.data?.message
    // }
  } catch (error) {
    yield put(updateAccountProfileFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch Update Account Profile role
 */
export default function* watchUpdateAccountProfile() {
  yield takeLatest(UPDATE_ACCOUNT_PROFILE_START, doGetUpdateAccountProfile)
}
