import React from 'react'
import { Button, Modal } from 'antd'
import configs from '~/configs'
import { SharedOnTwitter } from '../shared-components/shared-twitter'
export default function TransactionCompletedSellShop(props) {
  const { action, t, name, store, txHash, loadingModal } = props
  let link = `${configs.NETWORK.scan}tx/${txHash}`
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#1890FF',
    width: '160px',
    height: '44px',
    borderRadius: '4px',
  }
  const hashStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '50%',
    color: '#40A9FF',
  }

  return (
    <>
      <Modal
        centered
        title={
          <h3
            className="flex justify-center text-xl"
            style={{ fontWeight: '400' }}
          >
            {t('modal.transaction_completed_sell_shop.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={() => {
          action?.handleCancel()
        }}
        footer={[
          <div key="buttons" className="flex justify-center">
            {/* <Button
              className="flex items-center justify-center bg-[#1890FF] w-[160px] h-[44px] mt-1"
              type="primary"
              key="ok"
              onClick={() => action?.handleOk()}
              style={okButtonStyle}
              loading={loadingModal}
            >
              <img
                className="w-[16px] h-[16px] mr-3"
                src="../svg/twitter.svg"
                alt=""
              />
              <div style={{ color: '#FFFFFF', fontSize: '16px' }}>
                {t('modal.transaction_completed_sell_shop.share_x')}
              </div>
            </Button> */}
            <SharedOnTwitter
              title={`${name}`}
              okButtonStyle={okButtonStyle}
              urlShareTwitter={`${txHash}\n${link}`}
            />
          </div>,
        ]}
      >
        <div className="flex justify-center">
          <img
            className="w-[90px] h-[90px] object-contain"
            src={`${process.env.PUBLIC_URL}/imgs/common/success.svg`}
            alt=""
          />
        </div>

        <div className="my-5 flex justify-center font__M__plus text-[16px]">
          {t('modal.transaction_completed_sell_shop.description', {
            name: name,
          })}
        </div>
        <div className="my-5 flex justify-between font__M__plus text-[16px]">
          <div>
            {t('modal.transaction_completed_sell_shop.transaction_hash')}
          </div>
          <a
            style={hashStyle}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {txHash}
          </a>
        </div>
      </Modal>
    </>
  )
}
