import { convertOasToUSD, formatNumber } from '~/helpers/common'
import { ReMonsterTwoBorderSm } from '../../Button/ReMonsterTwoBorderSm'
import { getSpeciesValue } from '~/helpers/Species'

export const BoxCard = (props) => {
  const { data, button, tema } = props
  return (
    <div className="card__monster w-[250px] h-[350px] cursor-pointer">
      <div className="relative flex items-center justify-center h-full">
        <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
          <div
            className="h-full flex flex-col items-center p-[10px] text-[18px]"
            style={{
              background: 'linear-gradient(180deg, #0D1426 0%, #375877 100%)',
            }}
          >
            <div className="flex flex-col items-center">
              <div className="title__card">{data?.asset}</div>
              <div className="title__card">{data?.type}</div>
            </div>

            <div className="h-full flex items-center justify-center">
              <img
                alt=""
                className="card__monster_img w-[160px] h-[160px] object-contain	ease-in-out duration-300"
                src={
                  data?.asset === 'GENESIS BOX' || data?.asset === 'GENERAL BOX'
                    ? `../imgs/box/${data?.type.split(' ')[1]}.png`
                    : getSpeciesValue(data?.group, data?.species)?.image
                    ? `${
                        process.env.REACT_APP_BASE_URL_IMAGE
                      }/public/image/image/hash/${
                        getSpeciesValue(data?.group, data?.species)?.image
                      }.png`
                    : '../imgs/hash/golem.png'
                }
              />
            </div>
            <div>
              {data?.token_id || data?.tokenId
                ? `#${data?.token_id ?? data?.tokenId}`
                : ''}
            </div>
          </div>

          <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
            {data?.price && (
              <>
                <div className="flex items-center justify-center">
                  <img
                    className="w-[16px] h-[16px] object-contain	"
                    src="../imgs/coins/oasis.svg"
                    alt=""
                  />
                  <div className="relative whitespace-nowrap flex ml-2">
                    {formatNumber(data?.price)}
                  </div>
                </div>

                <div className="text-[12px] mb-1">
                  ${formatNumber(convertOasToUSD(data?.price, tema?.tema))}
                </div>
              </>
            )}

            <ReMonsterTwoBorderSm
              title={button?.title}
              functionButton={button?.functionButton}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
