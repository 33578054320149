import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/blockchainApi'
import {
  sellItemShopSuccess,
  sellItemShopFailed,
  SELL_ITEM_SHOP_START,
} from '../../actions/shop'

/**
 * Sign Buy Shop api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const sellItemShopApi = (props) => {
  var uri = `/api/shop/resold-training-item`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doSellItemShop(action) {
  try {
    const response = yield call(sellItemShopApi, action?.payload)
    yield put(sellItemShopSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(sellItemShopFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch sell item Shop
 */
export default function* watchSellItemShop() {
  yield takeLatest(SELL_ITEM_SHOP_START, doSellItemShop)
}
