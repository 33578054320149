export const getValutByRank = (rank) => {
  switch (rank) {
    case 'S':
      return 7
    case 'A':
      return 6
    case 'B':
      return 5
    case 'C':
      return 4
    case 'D':
      return 3
    case 'E':
      return 2
    case 'F':
      return 1
    default:
      return 1
  }
}

export const getRankbyValue = (rank) => {
  switch (rank) {
    case 7:
      return 'SP'
    case 6:
      return 'S'
    case 5:
      return 'A'
    case 4:
      return 'B'
    case 3:
      return 'C'
    case 2:
      return 'D'
    case 1:
      return 'E'
    case 0:
      return 'F'
    default:
      return 'F'
  }
}

export const getRankOverall = (rank) => {
  if (rank < 1400) {
    return 'F'
  } else if (rank < 2100) {
    return 'E'
  } else if (rank < 2800) {
    return 'D'
  } else if (rank < 4200) {
    return 'C'
  } else if (rank < 4900) {
    return 'B'
  } else if (rank < 5400) {
    return 'A'
  } else {
    return 'S'
  }
}

export const getRankByParameter = (parameter) => {
  if (parameter <= 15) {
    return 'F'
  } else if (parameter <= 30) {
    return 'E'
  } else if (parameter <= 45) {
    return 'D'
  } else if (parameter <= 60) {
    return 'C'
  } else if (parameter <= 75) {
    return 'B'
  } else if (parameter <= 90) {
    return 'A'
  } else if (parameter <= 100) {
    return 'S'
  }
}
export const getStatusByParameter = (status) => {
  if (status < 0) {
    return 'down'
  } else if (status > 0) {
    return 'up'
  }
}

export const getStatusBySpecial = (status) => {
  if (status < 0) {
    return 'down'
  } else if (status > 0) {
    return 'up'
  }
}

export const getBgRank = (rank) => {
  switch (rank) {
    case 'S':
      return 'linear-gradient(180deg, #261C0D 0%, #826E28 100%)'
    case 'A':
      return 'linear-gradient(180deg, #260D25 0%, #9C4C94 100%)'
    case 'B':
      return 'linear-gradient(180deg, #180D26 0%, #5B3777 100%)'
    case 'C':
      return 'linear-gradient(180deg, #091F40 0%, #104088 100%)'
    case 'D':
      return 'linear-gradient(180deg, #0D1426 0%, #176779 100%)'
    case 'E':
      return 'linear-gradient(180deg, #0D2610 0%, #2A6237 100%)'
    case 'F':
      return 'linear-gradient(180deg, #0D1426 0%, #375877 100%)'
    default:
      return 'linear-gradient(180deg, #0D1426 0%, #375877 100%)'
  }
}

export const getParameterByIndex = (parameter) => {
  switch (parameter) {
    case 0:
      return 'HP'
    case 1:
      return 'STR'
    case 2:
      return 'INT'
    case 3:
      return 'DEX'
    case 4:
      return 'AGI'
    case 5:
      return 'VIT'
    default:
      return 'HP'
  }
}
