//0 point 1 self-activation
//0 point 1 stanby
//0 point 1 charge
export const HEXAGON_MAIN = [
  { q: 0, r: 0, s: 0, main: false }, // 0
]
//0 point 1 self-activation sub
//0 point 1 stanby sub
//0 point 1 charge sub
export const HEXAGON_SUB = [
  { q: 0, r: 0, s: 0, main: true }, // 0
]

//3 point 1 straight
export const FLOWER_HEXAGON_X3 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: true }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: true }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: true }, // Top-left corner
  { q: -3, r: 0, s: 0, main: true },
  { q: 0, r: -3, s: 3, main: true },
]
// 3 points 1 straight sub
export const MAIN_SUB = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
]

//1 circle 7 Self-Activation
export const HEXAGON_FULL_X1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 circle 7 Self-Activation sub
export const HEXAGON_FULL_X1_Sub = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

//2 point 1 destination
export const HEXAGON_FULL_X2 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
]
//2 point 1 destination sub
export const MAIN_SUB_FULL_X2 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: 'pointSub' },
  { q: 1, r: 0, s: -1, main: 'pointSub' },
  { q: 0, r: 1, s: -1, main: 'pointSub' },
  { q: -1, r: 1, s: 0, main: 'pointSub' },
  { q: -1, r: 0, s: 1, main: 'pointSub' },
  { q: 0, r: -1, s: 1, main: 'pointSub' },

  // Second ring
  { q: 2, r: -2, s: 0, main: 'pointSub' },
  { q: 2, r: -1, s: -1, main: 'pointSub' },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: 'pointSub' },
  { q: 0, r: 2, s: -2, main: 'pointSub' },
  { q: -1, r: 2, s: -1, main: 'pointSub' },
  { q: -2, r: 2, s: 0, main: 'pointSub' },
  { q: -2, r: 1, s: 1, main: 'pointSub' },
  { q: -2, r: 0, s: 2, main: 'pointSub' },
  { q: -1, r: -1, s: 2, main: 'pointSub' },
  { q: 0, r: -2, s: 2, main: 'pointSub' },
  { q: 1, r: -2, s: 1, main: 'pointSub' },
]

//3 point 1 Designation
export const HEXAGON_FULL_X3 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
  // Third ring
  { q: 3, r: -3, s: 0, main: true },
  { q: 3, r: -2, s: -1, main: true },
  { q: 3, r: -1, s: -2, main: true },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 1, r: 2, s: -3, main: true },
  { q: 0, r: 3, s: -3, main: true },
  { q: -1, r: 3, s: -2, main: true },
  { q: -2, r: 3, s: -1, main: true },
  { q: -3, r: 3, s: 0, main: true },
  { q: -3, r: 2, s: 1, main: true },
  { q: -3, r: 1, s: 2, main: true },
  { q: -3, r: 0, s: 3, main: true },
  { q: -2, r: -1, s: 3, main: true },
  { q: -1, r: -2, s: 3, main: true },
  { q: 0, r: -3, s: 3, main: true },
  { q: 1, r: -3, s: 2, main: true },
  { q: 2, r: -3, s: 1, main: true },
]
//3 point 1 Designation sub
export const MAIN_SUB_FULL_X3 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: 'pointSub' },
  { q: 1, r: 0, s: -1, main: 'pointSub' },
  { q: 0, r: 1, s: -1, main: 'pointSub' },
  { q: -1, r: 1, s: 0, main: 'pointSub' },
  { q: -1, r: 0, s: 1, main: 'pointSub' },
  { q: 0, r: -1, s: 1, main: 'pointSub' },

  // Second ring
  { q: 2, r: -2, s: 0, main: 'pointSub' },
  { q: 2, r: -1, s: -1, main: 'pointSub' },
  { q: 2, r: 0, s: -2, main: 'pointSub' },
  { q: 1, r: 1, s: -2, main: 'pointSub' },
  { q: 0, r: 2, s: -2, main: 'pointSub' },
  { q: -1, r: 2, s: -1, main: 'pointSub' },
  { q: -2, r: 2, s: 0, main: 'pointSub' },
  { q: -2, r: 1, s: 1, main: 'pointSub' },
  { q: -2, r: 0, s: 2, main: 'pointSub' },
  { q: -1, r: -1, s: 2, main: 'pointSub' },
  { q: 0, r: -2, s: 2, main: 'pointSub' },
  { q: 1, r: -2, s: 1, main: 'pointSub' },
  // Third ring
  { q: 3, r: -3, s: 0, main: 'pointSub' },
  { q: 3, r: -2, s: -1, main: 'pointSub' },
  { q: 3, r: -1, s: -2, main: 'pointSub' },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: 'pointSub' },
  { q: 1, r: 2, s: -3, main: 'pointSub' },
  { q: 0, r: 3, s: -3, main: 'pointSub' },
  { q: -1, r: 3, s: -2, main: 'pointSub' },
  { q: -2, r: 3, s: -1, main: 'pointSub' },
  { q: -3, r: 3, s: 0, main: 'pointSub' },
  { q: -3, r: 2, s: 1, main: 'pointSub' },
  { q: -3, r: 1, s: 2, main: 'pointSub' },
  { q: -3, r: 0, s: 3, main: 'pointSub' },
  { q: -2, r: -1, s: 3, main: 'pointSub' },
  { q: -1, r: -2, s: 3, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  { q: 1, r: -3, s: 2, main: 'pointSub' },
  { q: 2, r: -3, s: 1, main: 'pointSub' },
]

//1 point 1 destination
//1 point 1 trap
export const HEXAGON_FULL_X1_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 point 1 destination sub
//1 point 1 trap sub
export const HEXAGON_FULL_X1_Sub_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
]

//3 line 3 Penetrating
export const FLOWER_HEXAGON_X3_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: true }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: true }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: true }, // Top-left corner
  { q: -3, r: 0, s: 0, main: true },
  { q: 0, r: -3, s: 3, main: true },
]
//3 line 3 Penetrating sub
export const MAIN_SUB_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
]

//2 point 1 straight
export const FLOWER_HEXAGON_X2 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
]
// 2 points 1 straight sub
export const MAIN_FLOWER_HEXAGON_X2_Sub = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: false }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]

//2 line 2 straight
export const FLOWER_HEXAGON_X2_6 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
]
// 2 line 2 straight sub
export const MAIN_FLOWER_HEXAGON_X2_Sub_6 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]

//3 circle 7 Designation
// 3 circle 7 standy
export const HEXAGON_FULL_X3_1 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
  // Third ring
  { q: 3, r: -3, s: 0, main: true },
  { q: 3, r: -2, s: -1, main: true },
  { q: 3, r: -1, s: -2, main: true },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 1, r: 2, s: -3, main: true },
  { q: 0, r: 3, s: -3, main: true },
  { q: -1, r: 3, s: -2, main: true },
  { q: -2, r: 3, s: -1, main: true },
  { q: -3, r: 3, s: 0, main: true },
  { q: -3, r: 2, s: 1, main: true },
  { q: -3, r: 1, s: 2, main: true },
  { q: -3, r: 0, s: 3, main: true },
  { q: -2, r: -1, s: 3, main: true },
  { q: -1, r: -2, s: 3, main: true },
  { q: 0, r: -3, s: 3, main: true },
  { q: 1, r: -3, s: 2, main: true },
  { q: 2, r: -3, s: 1, main: true },
]
//3 circle 7 Designation sub
// 3 circle 7 standy sub
export const MAIN_SUB_FULL_X3_1 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: 'pointSub' },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: 'pointSub' },
  { q: -1, r: 1, s: 0, main: 'pointSub' },
  { q: -1, r: 0, s: 1, main: 'pointSub' },
  { q: 0, r: -1, s: 1, main: 'pointSub' },

  // Second ring
  { q: 2, r: -2, s: 0, main: 'pointSub' },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: 'pointSub' },
  { q: -1, r: 2, s: -1, main: 'pointSub' },
  { q: -2, r: 2, s: 0, main: 'pointSub' },
  { q: -2, r: 1, s: 1, main: 'pointSub' },
  { q: -2, r: 0, s: 2, main: 'pointSub' },
  { q: -1, r: -1, s: 2, main: 'pointSub' },
  { q: 0, r: -2, s: 2, main: 'pointSub' },
  { q: 1, r: -2, s: 1, main: 'pointSub' },
  // Third ring
  { q: 3, r: -3, s: 0, main: 'pointSub' },
  { q: 3, r: -2, s: -1, main: 'pointSub' },
  { q: 3, r: -1, s: -2, main: true },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 1, r: 2, s: -3, main: 'pointSub' },
  { q: 0, r: 3, s: -3, main: 'pointSub' },
  { q: -1, r: 3, s: -2, main: 'pointSub' },
  { q: -2, r: 3, s: -1, main: 'pointSub' },
  { q: -3, r: 3, s: 0, main: 'pointSub' },
  { q: -3, r: 2, s: 1, main: 'pointSub' },
  { q: -3, r: 1, s: 2, main: 'pointSub' },
  { q: -3, r: 0, s: 3, main: 'pointSub' },
  { q: -2, r: -1, s: 3, main: 'pointSub' },
  { q: -1, r: -2, s: 3, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  { q: 1, r: -3, s: 2, main: 'pointSub' },
  { q: 2, r: -3, s: 1, main: 'pointSub' },
]

//2 line 1 straight
export const FLOWER_HEXAGON_X2_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
]
// 2 line 1 straight sub
export const MAIN_FLOWER_HEXAGON_X2_Sub_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: false }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]
export const MAIN_FLOWER_HEXAGON_X2_Sub_1_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub'  }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]
// 2 line 1 straight sub
export const MAIN_FLOWER_HEXAGON_X2_Sub_1_Hero = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]

//1 circle 6 Self-Activation
export const HEXAGON_FULL_X1_6 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 circle 6 Self-Activation sub
export const HEXAGON_FULL_X1_Sub_6 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

//3 line 1 straight
export const FLOWER_HEXAGON_X3_2 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: true }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: true }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: true }, // Top-left corner
  { q: -3, r: 0, s: 0, main: true },
  { q: 0, r: -3, s: 3, main: true },
]
// 3 line 1 straight sub
export const MAIN_SUB_2 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 2, r: 0, s: 0, main: false }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
]

//4 line 1 straight
export const FLOWER_HEXAGON_X4 = [
  ...FLOWER_HEXAGON_X3,
  // Additional hexagons or modifications as needed
  { q: 4, r: -4, s: 0, main: true }, // Additional hexagon
  { q: 4, r: 0, s: -4, main: true }, // Additional hexagon
  { q: 0, r: 4, s: -4, main: true }, // Additional hexagon
  { q: -4, r: 4, s: 0, main: true }, // Additional hexagon
  { q: -4, r: 0, s: 4, main: true }, // Additional hexagon
  { q: 0, r: -4, s: 4, main: true }, // Additional hexagon
]
// 4 line 1 straight sub
export const FLOWER_HEXAGON_X4_Sub = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 2, r: 0, s: 0, main: false }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: 'pointSub' }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  // Additional hexagons or modifications as needed
  { q: 4, r: -4, s: 0, main: 'pointSub' }, // Additional hexagon
  { q: 4, r: 0, s: -4, main: true }, // Additional hexagon
  { q: 0, r: 4, s: -4, main: 'pointSub' }, // Additional hexagon
  { q: -4, r: 4, s: 0, main: 'pointSub' }, // Additional hexagon
  { q: -4, r: 0, s: 4, main: 'pointSub' }, // Additional hexagon
  { q: 0, r: -4, s: 4, main: 'pointSub' }, // Additional hexagon
]

//3 x 5 designation
export const FLOWER_HEXAGON_X3_X5 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: true }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: true }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: true }, // Top-left corner
  { q: -3, r: 0, s: 0, main: true },
  { q: 0, r: -3, s: 3, main: true },
]
//3 x 5 designation sub
export const MAIN_SUB_X5 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  { q: 3, r: 1, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 3, r: -1, s: -3, main: true },
  { q: 4, r: -1, s: 3, main: true },
]

//4 line 1 designation
export const FLOWER_HEXAGON_X4_1 = [
  ...FLOWER_HEXAGON_X3,
  // Additional hexagons or modifications as needed
  { q: 4, r: -4, s: 0, main: true }, // Additional hexagon
  { q: 4, r: 0, s: -4, main: true }, // Additional hexagon
  { q: 0, r: 4, s: -4, main: true }, // Additional hexagon
  { q: -4, r: 4, s: 0, main: true }, // Additional hexagon
  { q: -4, r: 0, s: 4, main: true }, // Additional hexagon
  { q: 0, r: -4, s: 4, main: true }, // Additional hexagon
]
// 4 line 1 designation sub
export const FLOWER_HEXAGON_X4_Sub_1 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: false }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  // Additional hexagons or modifications as needed
  { q: 4, r: -4, s: 0, main: 'pointSub' }, // Additional hexagon
  { q: 4, r: 0, s: -4, main: true }, // Additional hexagon
  { q: 0, r: 4, s: -4, main: 'pointSub' }, // Additional hexagon
  { q: -4, r: 4, s: 0, main: 'pointSub' }, // Additional hexagon
  { q: -4, r: 0, s: 4, main: 'pointSub' }, // Additional hexagon
  { q: 0, r: -4, s: 4, main: 'pointSub' }, // Additional hexagon
]

//1 diffusio 7 designation
//1 circle 3(6) self-activation and 1 circle 3(6) self-activation sub
export const HEXAGON_FULL_X1_7 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 diffusio 7 designation sub
export const MAIN_SUB_SUB_HEXAGON_FULL_X1_RM_RIGHT = [
  { q: -2, r: 0, s: 0, main: true }, // 0
  { q: -3, r: 0, s: 0, main: false }, // 0
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

//2 x 5 designation
export const FLOWER_HEXAGON_X2_2 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
]
//2 x 5 designation sub
export const MAIN_FLOWER_HEXAGON_X2_Sub_2 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 2, r: -1, s: -1, main: true }, // //
  { q: 1, r: 1, s: 1, main: true }, // \\

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]

//2 circle 19 self-Activation
export const HEXAGON_FULL_X2_19 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
]
//2 circle 19 self-Activation sub
export const MAIN_SUB_FULL_X2_19 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: true },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
]

//2 circle 4 deignation
export const FLOWER_HEXAGON_X2_4 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
]
//2 circle 4 deignation sub
export const MAIN_FLOWER_HEXAGON_X2_Sub_4 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 2, r: 0, s: 0, main: true }, // --
  { q: 3, r: 0, s: 0, main: true }, // --
  { q: 3, r: -1, s: 0, main: true }, // --
  { q: 2, r: 1, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]

//4 line 4 penetrating
export const FLOWER_HEXAGON_X4_4 = [
  ...FLOWER_HEXAGON_X3,
  // Additional hexagons or modifications as needed
  { q: 4, r: -4, s: 0, main: true }, // Additional hexagon
  { q: 4, r: 0, s: -4, main: true }, // Additional hexagon
  { q: 0, r: 4, s: -4, main: true }, // Additional hexagon
  { q: -4, r: 4, s: 0, main: true }, // Additional hexagon
  { q: -4, r: 0, s: 4, main: true }, // Additional hexagon
  { q: 0, r: -4, s: 4, main: true }, // Additional hexagon
]
//4 line 4 penetrating sub
export const FLOWER_HEXAGON_X4_Sub_4 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  // Additional hexagons or modifications as needed
  { q: 4, r: -4, s: 0, main: 'pointSub' }, // Additional hexagon
  { q: 4, r: 0, s: -4, main: true }, // Additional hexagon
  { q: 0, r: 4, s: -4, main: 'pointSub' }, // Additional hexagon
  { q: -4, r: 4, s: 0, main: 'pointSub' }, // Additional hexagon
  { q: -4, r: 0, s: 4, main: 'pointSub' }, // Additional hexagon
  { q: 0, r: -4, s: 4, main: 'pointSub' }, // Additional hexagon
]

//3 line 3 standby
export const FLOWER_HEXAGON_X3_3 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: true }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: true }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: true }, // Top-left corner
  { q: -3, r: 0, s: 0, main: true },
  { q: 0, r: -3, s: 3, main: true },
]
//3 line 3 standby sub
export const MAIN_SUB_3 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
  // Additional hexagons at the corners
  { q: 3, r: -3, s: 0, main: 'pointSub' }, // Top-right corner
  { q: 3, r: 0, s: -3, main: true }, // Bottom-right corner
  { q: 0, r: 3, s: -3, main: 'pointSub' }, // Bottom-left corner
  { q: -3, r: 3, s: 0, main: 'pointSub' }, // Top-left corner
  { q: -3, r: 0, s: 0, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
]

//1 other 6 standby
export const HEXAGON_FULL_X1_O6 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 other 6 standby sub
export const HEXAGON_FULL_X2_Sub_O6 = [
  { q: 0, r: 0, s: 0, main: true },
  // First ring
  { q: 1, r: 0, s: -1, main: true },
  { q: -1, r: 0, s: 1, main: true },
  // Second ring
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: -2, r: 0, s: 2, main: false },
]

//2 point 1 trap
export const HEXAGON_FULL_X2_2 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
]
//2 point 1 trap sub
export const MAIN_SUB_FULL_X2_2 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: 'pointSub' },
  { q: 1, r: 0, s: -1, main: 'pointSub' },
  { q: 0, r: 1, s: -1, main: 'pointSub' },
  { q: -1, r: 1, s: 0, main: 'pointSub' },
  { q: -1, r: 0, s: 1, main: 'pointSub' },
  { q: 0, r: -1, s: 1, main: 'pointSub' },

  // Second ring
  { q: 2, r: -2, s: 0, main: 'pointSub' },
  { q: 2, r: -1, s: -1, main: 'pointSub' },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: 'pointSub' },
  { q: 0, r: 2, s: -2, main: 'pointSub' },
  { q: -1, r: 2, s: -1, main: 'pointSub' },
  { q: -2, r: 2, s: 0, main: 'pointSub' },
  { q: -2, r: 1, s: 1, main: 'pointSub' },
  { q: -2, r: 0, s: 2, main: 'pointSub' },
  { q: -1, r: -1, s: 2, main: 'pointSub' },
  { q: 0, r: -2, s: 2, main: 'pointSub' },
  { q: 1, r: -2, s: 1, main: 'pointSub' },
]

//2 line 2 penetrating
export const FLOWER_HEXAGON_X2_3 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
]
// 2 line 2 penetrating sub
export const MAIN_FLOWER_HEXAGON_X2_Sub_3 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]

//2 circle 7 destination
export const HEXAGON_FULL_X2_3 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
]
//2 circle 7 destination sub
export const MAIN_SUB_FULL_X2_3 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: 'pointSub' },
  { q: -1, r: 0, s: 1, main: 'pointSub' },
  { q: 0, r: -1, s: 1, main: 'pointSub' },

  // Second ring
  { q: 2, r: -2, s: 0, main: 'pointSub' },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: 'pointSub' },
  { q: -1, r: 2, s: -1, main: 'pointSub' },
  { q: -2, r: 2, s: 0, main: 'pointSub' },
  { q: -2, r: 1, s: 1, main: 'pointSub' },
  { q: -2, r: 0, s: 2, main: 'pointSub' },
  { q: -1, r: -1, s: 2, main: 'pointSub' },
  { q: 0, r: -2, s: 2, main: 'pointSub' },
  { q: 1, r: -2, s: 1, main: 'pointSub' },
]

//2 line 2 penetrating
export const FLOWER_HEXAGON_X2_5 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: true }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: true }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: true }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: true }, // \\
]
//2 line 2 penetratingt sub
export const MAIN_FLOWER_HEXAGON_X2_Sub_5 = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 2, r: -2, s: 0, main: 'pointSub' }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: true }, // --

  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: 0, r: 2, s: -2, main: 'pointSub' }, // \\

  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -2, r: 2, s: 0, main: 'pointSub' }, // //

  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: -2, r: 0, s: 0, main: 'pointSub' }, // --

  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
  { q: 0, r: -2, s: 2, main: 'pointSub' }, // \\
]

//2 circle 18 self-activation
export const HEXAGON_FULL_X2_18 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
]
//2 circle 18 self-activation sub
export const MAIN_SUB_FULL_X2_18 = [
  // Center hexagon
  ...HEXAGON_FULL_X2_18,
]

//5 point 1 Designation
export const HEXAGON_FULL_X5 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },

  // Third ring
  { q: 3, r: -3, s: 0, main: true },
  { q: 3, r: -2, s: -1, main: true },
  { q: 3, r: -1, s: -2, main: true },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 1, r: 2, s: -3, main: true },
  { q: 0, r: 3, s: -3, main: true },
  { q: -1, r: 3, s: -2, main: true },
  { q: -2, r: 3, s: -1, main: true },
  { q: -3, r: 3, s: 0, main: true },
  { q: -3, r: 2, s: 1, main: true },
  { q: -3, r: 1, s: 2, main: true },
  { q: -3, r: 0, s: 3, main: true },
  { q: -2, r: -1, s: 3, main: true },
  { q: -1, r: -2, s: 3, main: true },
  { q: 0, r: -3, s: 3, main: true },
  { q: 1, r: -3, s: 2, main: true },
  { q: 2, r: -3, s: 1, main: true },

  // Fourth ring
  { q: 4, r: -4, s: 0, main: true },
  { q: 4, r: -3, s: -1, main: true },
  { q: 4, r: -2, s: -2, main: true },
  { q: 4, r: -1, s: -3, main: true },
  { q: 4, r: 0, s: -4, main: true },
  { q: 3, r: 1, s: -4, main: true },
  { q: 2, r: 2, s: -4, main: true },
  { q: 1, r: 3, s: -4, main: true },
  { q: 0, r: 4, s: -4, main: true },
  { q: -1, r: 4, s: -3, main: true },
  { q: -2, r: 4, s: -2, main: true },
  { q: -3, r: 4, s: -1, main: true },
  { q: -4, r: 4, s: 0, main: true },
  { q: -4, r: 3, s: 1, main: true },
  { q: -4, r: 2, s: 2, main: true },
  { q: -4, r: 1, s: 3, main: true },
  { q: -4, r: 0, s: 4, main: true },
  { q: -3, r: -1, s: 4, main: true },
  { q: -2, r: -2, s: 4, main: true },
  { q: -1, r: -3, s: 4, main: true },
  { q: 0, r: -4, s: 4, main: true },
  { q: 1, r: -4, s: 3, main: true },
  { q: 2, r: -4, s: 2, main: true },
  { q: 3, r: -4, s: 1, main: true },

  // Fifth ring
  { q: 5, r: -5, s: 0, main: true },
  { q: 5, r: -4, s: -1, main: true },
  { q: 5, r: -3, s: -2, main: true },
  { q: 5, r: -2, s: -3, main: true },
  { q: 5, r: -1, s: -4, main: true },
  { q: 5, r: 0, s: -5, main: true },
  { q: 4, r: 1, s: -5, main: true },
  { q: 3, r: 2, s: -5, main: true },
  { q: 2, r: 3, s: -5, main: true },
  { q: 1, r: 4, s: -5, main: true },
  { q: 0, r: 5, s: -5, main: true },
  { q: -1, r: 5, s: -4, main: true },
  { q: -2, r: 5, s: -3, main: true },
  { q: -3, r: 5, s: -2, main: true },
  { q: -4, r: 5, s: -1, main: true },
  { q: -5, r: 5, s: 0, main: true },
  { q: -5, r: 4, s: 1, main: true },
  { q: -5, r: 3, s: 2, main: true },
  { q: -5, r: 2, s: 3, main: true },
  { q: -5, r: 1, s: 4, main: true },
  { q: -5, r: 0, s: 5, main: true },
  { q: -4, r: -1, s: 5, main: true },
  { q: -3, r: -2, s: 5, main: true },
  { q: -2, r: -3, s: 5, main: true },
  { q: -1, r: -4, s: 5, main: true },
  { q: 0, r: -5, s: 5, main: true },
  { q: 1, r: -5, s: 4, main: true },
  { q: 2, r: -5, s: 3, main: true },
  { q: 3, r: -5, s: 2, main: true },
  { q: 4, r: -5, s: 1, main: true },
]
//5 point 1 Designation sub
export const MAIN_SUB_FULL_X5 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: 'pointSub' },
  { q: 1, r: 0, s: -1, main: 'pointSub' },
  { q: 0, r: 1, s: -1, main: 'pointSub' },
  { q: -1, r: 1, s: 0, main: 'pointSub' },
  { q: -1, r: 0, s: 1, main: 'pointSub' },
  { q: 0, r: -1, s: 1, main: 'pointSub' },

  // Second ring
  { q: 2, r: -2, s: 0, main: 'pointSub' },
  { q: 2, r: -1, s: -1, main: 'pointSub' },
  { q: 2, r: 0, s: -2, main: 'pointSub' },
  { q: 1, r: 1, s: -2, main: 'pointSub' },
  { q: 0, r: 2, s: -2, main: 'pointSub' },
  { q: -1, r: 2, s: -1, main: 'pointSub' },
  { q: -2, r: 2, s: 0, main: 'pointSub' },
  { q: -2, r: 1, s: 1, main: 'pointSub' },
  { q: -2, r: 0, s: 2, main: 'pointSub' },
  { q: -1, r: -1, s: 2, main: 'pointSub' },
  { q: 0, r: -2, s: 2, main: 'pointSub' },
  { q: 1, r: -2, s: 1, main: 'pointSub' },

  // Third ring
  { q: 3, r: -3, s: 0, main: 'pointSub' },
  { q: 3, r: -2, s: -1, main: 'pointSub' },
  { q: 3, r: -1, s: -2, main: 'pointSub' },
  { q: 3, r: 0, s: -3, main: 'pointSub' },
  { q: 2, r: 1, s: -3, main: 'pointSub' },
  { q: 1, r: 2, s: -3, main: 'pointSub' },
  { q: 0, r: 3, s: -3, main: 'pointSub' },
  { q: -1, r: 3, s: -2, main: 'pointSub' },
  { q: -2, r: 3, s: -1, main: 'pointSub' },
  { q: -3, r: 3, s: 0, main: 'pointSub' },
  { q: -3, r: 2, s: 1, main: 'pointSub' },
  { q: -3, r: 1, s: 2, main: 'pointSub' },
  { q: -3, r: 0, s: 3, main: 'pointSub' },
  { q: -2, r: -1, s: 3, main: 'pointSub' },
  { q: -1, r: -2, s: 3, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  { q: 1, r: -3, s: 2, main: 'pointSub' },
  { q: 2, r: -3, s: 1, main: 'pointSub' },

  // Fourth ring
  { q: 4, r: -4, s: 0, main: 'pointSub' },
  { q: 4, r: -3, s: -1, main: 'pointSub' },
  { q: 4, r: -2, s: -2, main: 'pointSub' },
  { q: 4, r: -1, s: -3, main: 'pointSub' },
  { q: 4, r: 0, s: -4, main: false }, //truwf
  { q: 3, r: 1, s: -4, main: 'pointSub' },
  { q: 2, r: 2, s: -4, main: 'pointSub' },
  { q: 1, r: 3, s: -4, main: 'pointSub' },
  { q: 0, r: 4, s: -4, main: 'pointSub' },
  { q: -1, r: 4, s: -3, main: 'pointSub' },
  { q: -2, r: 4, s: -2, main: 'pointSub' },
  { q: -3, r: 4, s: -1, main: 'pointSub' },
  { q: -4, r: 4, s: 0, main: 'pointSub' },
  { q: -4, r: 3, s: 1, main: 'pointSub' },
  { q: -4, r: 2, s: 2, main: 'pointSub' },
  { q: -4, r: 1, s: 3, main: 'pointSub' },
  { q: -4, r: 0, s: 4, main: 'pointSub' },
  { q: -3, r: -1, s: 4, main: 'pointSub' },
  { q: -2, r: -2, s: 4, main: 'pointSub' },
  { q: -1, r: -3, s: 4, main: 'pointSub' },
  { q: 0, r: -4, s: 4, main: 'pointSub' },
  { q: 1, r: -4, s: 3, main: 'pointSub' },
  { q: 2, r: -4, s: 2, main: 'pointSub' },
  { q: 3, r: -4, s: 1, main: 'pointSub' },

  // Fifth ring
  { q: 5, r: -5, s: 0, main: 'pointSub' },
  { q: 5, r: -4, s: -1, main: 'pointSub' },
  { q: 5, r: -3, s: -2, main: 'pointSub' },
  { q: 5, r: -2, s: -3, main: 'pointSub' },
  { q: 5, r: -1, s: -4, main: 'pointSub' },
  { q: 5, r: 0, s: -5, main: true }, //tre
  { q: 4, r: 1, s: -5, main: 'pointSub' },
  { q: 3, r: 2, s: -5, main: 'pointSub' },
  { q: 2, r: 3, s: -5, main: 'pointSub' },
  { q: 1, r: 4, s: -5, main: 'pointSub' },
  { q: 0, r: 5, s: -5, main: 'pointSub' },
  { q: -1, r: 5, s: -4, main: 'pointSub' },
  { q: -2, r: 5, s: -3, main: 'pointSub' },
  { q: -3, r: 5, s: -2, main: 'pointSub' },
  { q: -4, r: 5, s: -1, main: 'pointSub' },
  { q: -5, r: 5, s: 0, main: 'pointSub' },
  { q: -5, r: 4, s: 1, main: 'pointSub' },
  { q: -5, r: 3, s: 2, main: 'pointSub' },
  { q: -5, r: 2, s: 3, main: 'pointSub' },
  { q: -5, r: 1, s: 4, main: 'pointSub' },
  { q: -5, r: 0, s: 5, main: 'pointSub' },
  { q: -4, r: -1, s: 5, main: 'pointSub' },
  { q: -3, r: -2, s: 5, main: 'pointSub' },
  { q: -2, r: -3, s: 5, main: 'pointSub' },
  { q: -1, r: -4, s: 5, main: 'pointSub' },
  { q: 0, r: -5, s: 5, main: 'pointSub' },
  { q: 1, r: -5, s: 4, main: 'pointSub' },
  { q: 2, r: -5, s: 3, main: 'pointSub' },
  { q: 3, r: -5, s: 2, main: 'pointSub' },
  { q: 4, r: -5, s: 1, main: 'pointSub' },
]

//- other 49 self-activation
export const HEXAGON_FULL_X3_49 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },
]
//- other 49 self-activation sub
export const MAIN_SUB_FULL_X3_49 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: true },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },

  // Third ring
  { q: 3, r: -3, s: 0, main: true },
  { q: 3, r: -2, s: -1, main: true },
  { q: 3, r: -1, s: -2, main: true },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 1, r: 2, s: -3, main: true },
  { q: 0, r: 3, s: -3, main: true },
  { q: -1, r: 3, s: -2, main: true },
  { q: -2, r: 3, s: -1, main: true },
  { q: -3, r: 3, s: 0, main: true },
  { q: -3, r: 2, s: 1, main: true },
  { q: -3, r: 1, s: 2, main: true },
  { q: -3, r: 0, s: 3, main: true },
  { q: -2, r: -1, s: 3, main: true },
  { q: -1, r: -2, s: 3, main: true },
  { q: 0, r: -3, s: 3, main: true },
  { q: 1, r: -3, s: 2, main: true },
  { q: 2, r: -3, s: 1, main: true },

  // Fourth ring
  // { q: 4, r: -4, s: 0, main: true },
  { q: 4, r: -3, s: -1, main: true },
  { q: 4, r: -2, s: -2, main: true },
  { q: 4, r: -1, s: -3, main: true },
  { q: 3, r: 1, s: -4, main: true },
  { q: 2, r: 2, s: -4, main: true },
  { q: 1, r: 3, s: -4, main: true },
  { q: -4, r: 3, s: 1, main: true },
  { q: -4, r: 2, s: 2, main: true },
  { q: -4, r: 1, s: 3, main: true },
  { q: -3, r: -1, s: 4, main: true },
  { q: -2, r: -2, s: 4, main: true },
  { q: -1, r: -3, s: 4, main: true },
]

//1 other 3 designation
export const HEXAGON_FULL_X1_3 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 other 3 designation sub
export const HEXAGON_FULL_X1_Sub_3 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -1, r: 0, s: 0, main: 'pointSub' }, // --
  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
]

//- point 1 designation
export const HEXAGON_FULL_X3_49_1 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },
]
//- point 1 designation sub
export const MAIN_SUB_FULL_X3_49_1 = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: 'pointSub' },

  // First ring
  { q: 1, r: -1, s: 0, main: 'pointSub' },
  { q: 1, r: 0, s: -1, main: 'pointSub' },
  { q: 0, r: 1, s: -1, main: 'pointSub' },
  { q: -1, r: 1, s: 0, main: 'pointSub' },
  { q: -1, r: 0, s: 1, main: 'pointSub' },
  { q: 0, r: -1, s: 1, main: 'pointSub' },

  // Second ring
  { q: 2, r: -2, s: 0, main: 'pointSub' },
  { q: 2, r: -1, s: -1, main: 'pointSub' },
  { q: 2, r: 0, s: -2, main: false }, //
  { q: 1, r: 1, s: -2, main: 'pointSub' },
  { q: 0, r: 2, s: -2, main: 'pointSub' },
  { q: -1, r: 2, s: -1, main: 'pointSub' },
  { q: -2, r: 2, s: 0, main: 'pointSub' },
  { q: -2, r: 1, s: 1, main: 'pointSub' },
  { q: -2, r: 0, s: 2, main: 'pointSub' },
  { q: -1, r: -1, s: 2, main: 'pointSub' },
  { q: 0, r: -2, s: 2, main: 'pointSub' },
  { q: 1, r: -2, s: 1, main: 'pointSub' },

  // Third ring
  { q: 3, r: -3, s: 0, main: 'pointSub' },
  { q: 3, r: -2, s: -1, main: 'pointSub' },
  { q: 3, r: -1, s: -2, main: 'pointSub' },
  { q: 3, r: 0, s: -3, main: true }, //
  { q: 2, r: 1, s: -3, main: 'pointSub' },
  { q: 1, r: 2, s: -3, main: 'pointSub' },
  { q: 0, r: 3, s: -3, main: 'pointSub' },
  { q: -1, r: 3, s: -2, main: 'pointSub' },
  { q: -2, r: 3, s: -1, main: 'pointSub' },
  { q: -3, r: 3, s: 0, main: 'pointSub' },
  { q: -3, r: 2, s: 1, main: 'pointSub' },
  { q: -3, r: 1, s: 2, main: 'pointSub' },
  { q: -3, r: 0, s: 3, main: 'pointSub' },
  { q: -2, r: -1, s: 3, main: 'pointSub' },
  { q: -1, r: -2, s: 3, main: 'pointSub' },
  { q: 0, r: -3, s: 3, main: 'pointSub' },
  { q: 1, r: -3, s: 2, main: 'pointSub' },
  { q: 2, r: -3, s: 1, main: 'pointSub' },

  // Fourth ring
  { q: 4, r: -3, s: -1, main: 'pointSub' },
  { q: 4, r: -2, s: -2, main: 'pointSub' },
  { q: 4, r: -1, s: -3, main: 'pointSub' },
  { q: 3, r: 1, s: -4, main: 'pointSub' },
  { q: 2, r: 2, s: -4, main: 'pointSub' },
  { q: 1, r: 3, s: -4, main: 'pointSub' },
  { q: -4, r: 3, s: 1, main: 'pointSub' },
  { q: -4, r: 2, s: 2, main: 'pointSub' },
  { q: -4, r: 1, s: 3, main: 'pointSub' },
  { q: -3, r: -1, s: 4, main: 'pointSub' },
  { q: -2, r: -2, s: 4, main: 'pointSub' },
  { q: -1, r: -3, s: 4, main: 'pointSub' },
]

//1 other 2 Designation
export const HEXAGON_FULL_X1_2 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 other 2 Designation sub
export const HEXAGON_FULL_X1_Sub_2 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: 'pointSub' }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: 'pointSub' }, // \\
  { q: -1, r: 1, s: 0, main: 'pointSub' }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: 'pointSub' }, // \\
]

//1 circle 4 designation
export const HEXAGON_FULL_X1_4 = [
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
//1 circle 4 designation sub
export const HEXAGON_FULL_X1_Sub_4 = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

export const RECTANGULAR_HEXAGON_PATTERN = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: true },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
  { q: 3, r: -3, s: 0, main: true },
  { q: 3, r: -2, s: -1, main: true },
  { q: 3, r: -1, s: -2, main: true },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 1, r: 2, s: -3, main: true },
  { q: 0, r: 3, s: -3, main: true },
  { q: -1, r: 3, s: -2, main: true },
  { q: -2, r: 3, s: -1, main: true },
  { q: -3, r: 3, s: 0, main: true },
  { q: -3, r: 2, s: 1, main: true },
  { q: -3, r: 1, s: 2, main: true },
  { q: -3, r: 0, s: 3, main: true },
  { q: -2, r: -1, s: 3, main: true },
  { q: -1, r: -2, s: 3, main: true },
  { q: 0, r: -3, s: 3, main: true },
  { q: 1, r: -3, s: 2, main: true },
  { q: 2, r: -3, s: 1, main: true },
  // Additional hexagons horizontally aligned
  { q: 4, r: -3, s: -1, main: true },
  { q: -4, r: 3, s: 1, main: true },

  { q: -1, r: -3, s: 4, main: true },
  { q: 1, r: 3, s: -4, main: true },

  { q: 2, r: 2, s: -4, main: true },
  { q: -4, r: 2, s: -4, main: true },

  { q: -2, r: -2, s: -4, main: true },
  { q: 4, r: -2, s: -4, main: true },

  { q: 3, r: 1, s: -1, main: true },
  { q: -4, r: 1, s: -1, main: true },

  { q: -3, r: -1, s: -1, main: true },
  { q: 4, r: -1, s: -1, main: true },
]
export const RECTANGULAR_HEXAGON_PATTERN_ACTIVE = [
  // Center hexagon
  { q: 0, r: 0, s: 0, main: false },

  // First ring
  { q: 1, r: -1, s: 0, main: true },
  { q: 1, r: 0, s: -1, main: true },
  { q: 0, r: 1, s: -1, main: true },
  { q: -1, r: 1, s: 0, main: true },
  { q: -1, r: 0, s: 1, main: true },
  { q: 0, r: -1, s: 1, main: true },

  // Second ring
  { q: 2, r: -2, s: 0, main: true },
  { q: 2, r: -1, s: -1, main: true },
  { q: 2, r: 0, s: -2, main: true },
  { q: 1, r: 1, s: -2, main: true },
  { q: 0, r: 2, s: -2, main: true },
  { q: -1, r: 2, s: -1, main: true },
  { q: -2, r: 2, s: 0, main: true },
  { q: -2, r: 1, s: 1, main: true },
  { q: -2, r: 0, s: 2, main: true },
  { q: -1, r: -1, s: 2, main: true },
  { q: 0, r: -2, s: 2, main: true },
  { q: 1, r: -2, s: 1, main: true },
  { q: 3, r: -3, s: 0, main: true },
  { q: 3, r: -2, s: -1, main: true },
  { q: 3, r: -1, s: -2, main: true },
  { q: 3, r: 0, s: -3, main: true },
  { q: 2, r: 1, s: -3, main: true },
  { q: 1, r: 2, s: -3, main: true },
  { q: 0, r: 3, s: -3, main: true },
  { q: -1, r: 3, s: -2, main: true },
  { q: -2, r: 3, s: -1, main: true },
  { q: -3, r: 3, s: 0, main: true },
  { q: -3, r: 2, s: 1, main: true },
  { q: -3, r: 1, s: 2, main: true },
  { q: -3, r: 0, s: 3, main: true },
  { q: -2, r: -1, s: 3, main: true },
  { q: -1, r: -2, s: 3, main: true },
  { q: 0, r: -3, s: 3, main: true },
  { q: 1, r: -3, s: 2, main: true },
  { q: 2, r: -3, s: 1, main: true },
  // Additional hexagons horizontally aligned
  { q: 4, r: -3, s: -1, main: true },
  { q: -4, r: 3, s: 1, main: true },

  { q: -1, r: -3, s: 4, main: true },
  { q: 1, r: 3, s: -4, main: true },

  { q: 2, r: 2, s: -4, main: true },
  { q: -4, r: 2, s: -4, main: true },

  { q: -2, r: -2, s: -4, main: true },
  { q: 4, r: -2, s: -4, main: true },

  { q: 3, r: 1, s: -1, main: true },
  { q: -4, r: 1, s: -1, main: true },

  { q: -3, r: -1, s: -1, main: true },
  { q: 4, r: -1, s: -1, main: true },
]
export const SUB_MAIN_SUB = [
  { q: -1, r: 0, s: 0, main: true }, // 0
  { q: 0, r: 0, s: 0, main: false }, // 0
  { q: 1, r: 0, s: 0, main: true }, // 0
]

export const MAIN_SUB_MAIN = [
  { q: 0, r: -1, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: true }, // 0
  { q: 2, r: -1, s: 0, main: false }, // 0
]
export const MAIN_MAIN_SUB = [
  ...MAIN_SUB,
  { q: -1, r: -1, s: 0, main: false }, // 0
]
export const MAIN_MAIN_MAIN_SUB = [
  ...MAIN_MAIN_SUB,
  { q: -2, r: -1, s: 0, main: false }, // 0
]
export const MAIN_MAIN_MAIN_MAIN_SUB = [
  ...MAIN_MAIN_MAIN_SUB,
  { q: -3, r: -1, s: 0, main: true }, // 0
]
export const MAIN_SUB_SUB = [
  ...MAIN_SUB,
  { q: 2, r: -1, s: 0, main: true }, // 0
]
export const MAIN_SUB_SUB_SUB = [
  ...MAIN_SUB_SUB,
  { q: 3, r: -1, s: 0, main: true }, // 0
]
export const MAIN_SUB_SUB_SUB_SUB = [
  ...MAIN_SUB_SUB_SUB,
  { q: 4, r: -1, s: 0, main: false }, // 0
]
export const SUB_SUB_HEXAGON_FULL_X1 = [
  { q: -2, r: 0, s: 0, main: false }, // 0
  { q: -3, r: 0, s: 0, main: false }, // 0
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
export const MAIN_DIAMOND_FULL_X1 = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
export const DIAMOND_FULL_X1 = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]
export const MAIN_MAIN_DIAMOND_FULL_X1 = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: false }, // --
  { q: -3, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

export const MAIN_SUB_SUB_DIAMOND_FULL_X1 = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: true }, // --
  { q: -3, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

export const MAIN_MAIN_HEXAGON_FULL_X1 = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: false }, // --
  { q: -3, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

export const MAIN_MAIN_HEXAGON_FULL_X1_RM_RIGHT = [
  { q: -2, r: 0, s: 0, main: false }, // 0
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

export const MAIN_MAIN_MAIN_HEXAGON_FULL_X1_RM_RIGHT = [
  { q: -2, r: 0, s: 0, main: false }, // 0
  { q: -3, r: 0, s: 0, main: false }, // 0
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -1, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: true }, // \\
]

export const FLOWER_HEXAGON_X2_ACTIVE = [
  { q: 0, r: 0, s: 0, main: false }, // 0

  { q: 1, r: -1, s: 0, main: true }, // //
  { q: 2, r: -2, s: 0, main: false }, // //

  { q: 1, r: 0, s: 0, main: true }, // --
  { q: 2, r: 0, s: 0, main: false }, // --

  { q: 0, r: 1, s: -1, main: true }, // \\
  { q: 0, r: 2, s: -2, main: false }, // \\

  { q: -1, r: 1, s: 0, main: true }, // //
  { q: -2, r: 2, s: 0, main: false }, // //

  { q: -1, r: 0, s: 0, main: true }, // --
  { q: -2, r: 0, s: 0, main: false }, // --

  { q: 0, r: -1, s: 1, main: true }, // \\
  { q: 0, r: -2, s: 2, main: false }, // \\
]

export const MAIN_HEXAGON_FULL_X1 = [
  { q: 0, r: 0, s: 0, main: true }, // 0
  { q: -2, r: 0, s: 0, main: false }, // 0
  { q: 1, r: -1, s: 0, main: false }, // //
  { q: 1, r: 0, s: 0, main: false }, // --
  { q: 0, r: 1, s: -1, main: false }, // \\
  { q: -1, r: 1, s: 0, main: false }, // //
  { q: -1, r: 0, s: 0, main: false }, // --
  { q: 0, r: -1, s: 1, main: false }, // \\
]
