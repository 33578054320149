export const dataHash = [
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
  {
    price: 30,
  },
]

export const categoryHashData = [
  {
    name: 'Genesis_Hash',
    color: 'text-[#EA68FF]',
  },
  {
    name: 'General_Hash',
    color: 'text-[#FDD443]',
  },
  {
    name: 'Regeneration_Hash',
    color: 'text-[#FFFFFF]',
  },
  {
    name: 'Hash_Chip_NFT',
    color: 'text-[#FFFFFF]',
  },
]
