export const GET_TEMA_START = 'GET_TEMA_START'
export const GET_TEMA_SUCCESS = 'GET_TEMA_SUCCESS'
export const GET_TEMA_FAILED = 'GET_TEMA_FAILED'

export const GET_HISTORIES_START = 'GET_HISTORIES_START'
export const GET_HISTORIES_SUCCESS = 'GET_HISTORIES_SUCCESS'
export const GET_HISTORIES_FAILED = 'GET_HISTORIES_FAILED'


export const GET_HISTORIES_TOKEN_START = 'GET_HISTORIES_TOKEN_START'
export const GET_HISTORIES_TOKEN_SUCCESS = 'GET_HISTORIES_TOKEN_SUCCESS'
export const GET_HISTORIES_TOKEN_FAILED = 'GET_HISTORIES_TOKEN_FAILED'

//-------------------------Common------------------------//
/**
 * [1]
 * Get Tema start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getTema(payload, onSuccess, onError) {
  return {
    type: GET_TEMA_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get role success action
 * @param {*} payload
 * @returns {object}
 */
export function getTemaSuccess(payload) {
  return {
    type: GET_TEMA_SUCCESS,
    payload: payload,
  }
}

/**
 * get role failed action
 * @returns {object}
 */
export function getTemaFailed() {
  return {
    type: GET_TEMA_FAILED,
  }
}


/**
 * [2]
 * Get Histories start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getHistories(payload, onSuccess, onError) {
  return {
    type: GET_HISTORIES_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get Histories success action
 * @param {*} payload
 * @returns {object}
 */
export function getHistoriesSuccess(payload) {
  return {
    type: GET_HISTORIES_SUCCESS,
    payload: payload,
  }
}

/**
 * get Histories failed action
 * @returns {object}
 */
export function getHistoriesFailed() {
  return {
    type: GET_HISTORIES_FAILED,
  }
}

/**
 * [3]
 * Get Histories token start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getHistoriesToken(payload, onSuccess, onError) {
  return {
    type: GET_HISTORIES_TOKEN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get Histories token success action
 * @param {*} payload
 * @returns {object}
 */
export function getHistoriesTokenSuccess(payload) {
  return {
    type: GET_HISTORIES_TOKEN_SUCCESS,
    payload: payload,
  }
}

/**
 * get Histories token failed action
 * @returns {object}
 */
export function getHistoriesTokenFailed() {
  return {
    type: GET_HISTORIES_TOKEN_FAILED,
  }
}

export default {
  getTema,
  getTemaSuccess,
  getTemaFailed,

  getHistories,
  getHistoriesSuccess,
  getHistoriesFailed,

  getHistoriesToken,
  getHistoriesTokenSuccess,
  getHistoriesTokenFailed,
}
