import React, { useEffect, useState } from 'react'
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
} from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'

import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import appRoutes from '~/views/routes'
import AppLocale from '~/common/langs'
import PageNotFound from '~/views/app/PageNotFound'
import { AppLayout } from '~/layouts'
import { ROUTE as ROUTES_APP } from '~/views/routes/config'
import { USER_INFO } from '~/common/constants'

/**Routing setup*/
export const Routes = (props) => {
  const [locationKeys, setLocationKeys] = useState([])
  const history = useHistory()
  const { location } = props
  const userStorage = localStorage.getItem(USER_INFO) || null

  const {
    data: { locale },
  } = useTheme()

  const currentAppLocale = AppLocale[locale]

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // eslint-disable-next-line
          setLocationKeys(([_, ...keys]) => keys)

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys])

          // Handle back event
        }
      }
    })
  }, [locationKeys])

  return (
    <IntlProvider
      locale={currentAppLocale?.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider
        locale={currentAppLocale.antd}
        autoInsertSpaceInButton={false}
        theme={{
          token: {
            fontFamily: 'Rexliafree',
          },
        }}
      >
        <Switch>
          <Route exact path="/">
            {/* <Redirect to={ROUTES_APP.HOME.PATH} /> */}
            <Redirect to={ROUTES_APP.FARM.PATH} />
          </Route>

          {appRoutes
            ?.filter((route) => route?.public === true)
            ?.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                render={(props) => (
                  <AppLayout location={location}>
                    <route.component {...props} />
                  </AppLayout>
                )}
              />
            ))}

          {userStorage &&
            appRoutes
              ?.filter((route) => route?.public === false)
              ?.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  render={(props) => (
                    <AppLayout location={location}>
                      <route.component {...props} />
                    </AppLayout>
                  )}
                />
              ))}

          <Route path="*" render={(props) => <PageNotFound {...props} />} />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default withRouter(Routes)
