import { USER_INFO } from '~/common/constants'
import {
  CHANGE_LOCALE,
  TOGGLE_MOBILE_NAV,
  UPDATE_BALENCE_OAS,
  UPDATE_INFO_USER,
} from '~/common/constants/ThemeConstant'
import { THEME_CONFIG } from '~/common/theme/config'

const initTheme = {
  ...THEME_CONFIG,
}

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }
    case UPDATE_BALENCE_OAS:
      return {
        ...state,
        balanceOAS: action.balanceOAS,
      }
    case UPDATE_INFO_USER:
      localStorage.setItem(
        USER_INFO,
        JSON.stringify({
          ...action?.user,
          avatar: `${action?.user?.avatar}?time=${new Date()}`,
        }),
      )
      return {
        ...state,
        user: action.user,
      }
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      }
    default:
      return state
  }
}

export default theme
