import { ArrowUpOutlined } from '@ant-design/icons'
import { Button, Dropdown, Row } from 'antd'
import React, { useState } from 'react'
import { ITEM_SORT } from '~/common/constants'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { BoxCard } from '~/components/shared-components/Card/BoxCard'
export const AssetsRewards = () => {
  const stylesComing = {
    marginTop: '50px',
    padding: '300px 0',
    textAlign: 'center',
    fontSize: '64px',
  }

  // const { t } = useTranslation()
  const [sort, setSort] = useState(ITEM_SORT[0]?.label)
  const [typeSort, setTypeSort] = useState('-1')

  const handleChangeSort = (e) => {
    setSort(ITEM_SORT[e.key]?.label)
  }
  const { t } = useTranslation()

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }
  return (
    <BodyProfile>
      {/* <Row align={'middle'} justify="center">
        <h1 style={stylesComing}>Coming Soon!</h1>
      </Row> */}
      <div className="text-[24px] mb-4">{t('profile.menu.rewards')}</div>

      <div className="flex justify-between flex-wrap mb-4">
        <div className="text-[24px]">100,000 Boxs</div>
        <div className="flex items-center text-[16px] gap-2">
          <div> {t('sortBy')}</div>

          <Dropdown
            className="flex items-center cursor-pointer ml-3  sort__value"
            menu={{
              items: ITEM_SORT,
              selectable: true,
              onClick: handleChangeSort,
            }}
            placement="bottomRight"
            trigger={['click']}
          >
            <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
              <div className="title"> {t(`common.filter.sort.${sort}`)}</div>
            </Button>
          </Dropdown>

          <Button
            className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
            onClick={() => {
              handleChangeTypeSort()
            }}
          >
            <ArrowUpOutlined
              style={{ fontSize: 18 }}
              rotate={typeSort === '1' ? 0 : 180}
            />
          </Button>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-10">
        {[
          { price: 1 },
          { price: 1 },
          { price: 1 },
          { price: 1 },
          { price: 1 },
        ]?.map((data, i) => (
          <BoxCard
            key={i?.toString()}
            data={data}
            button={{
              title: 'CLAIM',
              functionButton: () => {
                alert('Đang đép')
              },
            }}
          />
        ))}
      </div>
    </BodyProfile>
  )
}
export default AssetsRewards
