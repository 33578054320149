import React from 'react'
import { Button, Modal } from 'antd'
import configs from '~/configs'
import { SharedOnTwitter } from '../shared-components/shared-twitter'
export default function TransactionCompletedCancelSell(props) {
  const { action, t, name, store, txHash, loadingModal } = props
  let link = `${configs.NETWORK.scan}tx/${txHash}`
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '160px',
    height: '44px',
    borderRadius: '4px',
  }
  const hashStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '50%',
    color: '#40A9FF',
  }

  return (
    <>
      <Modal
        centered
        title={
          <h3 className="flex justify-center text-xl font-bold">
            {t('modal.transaction_completed_cancel_sell.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            {/* <Button
              key="ok"
              type="primary"
              onClick={() => action?.handleOk()}
              style={okButtonStyle}
              loading={loadingModal}
            >
              {t('modal.transaction_completed_cancel_sell.share_x')}
            </Button> */}
            <SharedOnTwitter
              title={`${name}`}
              okButtonStyle={okButtonStyle}
              urlShareTwitter={`${txHash}\n${link}`}
            />
          </div>,
        ]}
      >
        <div className="flex justify-center">
          <img
            className="w-[90px] h-[90px] object-contain"
            src={`${process.env.PUBLIC_URL}/imgs/common/success.svg`}
            alt=""
          />
        </div>

        <div className="my-5 flex justify-center text-[16px]">
          {t('modal.transaction_completed_cancel_sell.description', {
            name: name,
          })}
        </div>
        <div className="my-5 flex justify-between">
          <div>
            {t('modal.transaction_completed_cancel_sell.transaction_hash')}
          </div>
          <a
            style={hashStyle}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {txHash}
          </a>
        </div>
      </Modal>
    </>
  )
}
