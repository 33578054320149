import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getAllMonsterSuccess,
  getAllMonsterFailed,
  GET_ALL_MONSTER_START,
} from '../../actions/monster'

/**
 * get list monster
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListMonsterApi = (props) => {
  var uri = `/monsters`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListMonster(action) {
  try {
    const response = yield call(getListMonsterApi, action?.payload)
    yield put(getAllMonsterSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getAllMonsterFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list item
 */
export default function* watchGetListMonster() {
  yield takeLatest(GET_ALL_MONSTER_START, doGetListMonster)
}
