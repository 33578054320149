import { FARM_TERRAIN } from '~/common/constants'

export const getValueByFarmTerrain = (farmTerrain) => {
  switch (farmTerrain) {
    case FARM_TERRAIN.VOLCANO:
      return 'Volcano'
    case FARM_TERRAIN.MOUNTAIN:
      return 'Mountain'
    case FARM_TERRAIN.WILDLAND:
      return 'WildLand'
    case FARM_TERRAIN.DESERT:
      return 'Desert'
    case FARM_TERRAIN.WATERFRONT:
      return 'Waterfront'
    case FARM_TERRAIN.FOREST:
      return 'Forest'
    case FARM_TERRAIN.PLAIN:
      return 'Plain'
    default:
      return 'Plain'
  }
}

export const getImgByFarmTerrain = (farmTerrain) => {
  switch (farmTerrain) {
    case 0:
      return 'Plain'
    case 1:
      return 'Plain'
    case 2:
      return 'Forest'
    case 3:
      return 'Waterfront'
    case 4:
      return 'Desert'
    case 5:
      return 'Wasteland'
    case 6:
      return 'Mountain'
    case 7:
      return 'Volcano'
    default:
      return ''
  }
}
export const convertImageFamrOfMonster = (image) => {
  switch (image) {
    case 'Ame_chan_Ame_chan_1':
      return 'Volcano'
    case 'Ame_chan_Golem_1':
      return 'Volcano'
    case 'Ame_chan_Hiyori_1':
      return 'Volcano'
    case 'Ame_chan_Icelime_1':
      return 'Volcano'
    case 'Ame_chan_Kids_Villain_1':
      return 'Volcano'
    case 'Ame_chan_Layla_1':
      return 'Volcano'
    case 'Ame_chan_Yamato_1':
      return 'Volcano'
    case 'AstarCats_AstarCats_1':
      return 'Mountain'
    case 'AstarCats_Faireaf_1':
      return 'Mountain'
    case 'AstarCats_Hiyori_1':
      return 'Mountain'
    case 'AstarCats_Kids_Villain_1':
      return 'Mountain'
    case 'AstarCats_Ossan_Paradise_1':
      return 'Mountain'
    case 'AstarCats_SignWitch_1':
      return 'Mountain'
    case 'AstarCats_Tofu_1':
      return 'Mountain'
    case 'CCT_CCT_1':
      return 'Mountain'
    case 'CCT_CCT_2':
      return 'Desert'
    case 'CCT_CCT_3':
      return 'Plain'
    case 'CCT_CCT_4':
      return 'Wasteland'
    case 'DEKAUSA_CCT_1':
      return 'Volcano'
    case 'DEKAUSA_DEKAUSA_1':
      return 'Volcano'
    case 'DEKAUSA_Golem_1':
      return 'Volcano'
    case 'DEKAUSA_ODENPETS_1':
      return 'Volcano'
    case 'DEKAUSA_Planetman_1':
      return 'Volcano'
    case 'DEKAUSA_Tofu_1':
      return 'Volcano'
    case 'DEKAUSA_Yamato_1':
      return 'Volcano'
    case 'Faireaf_Ame_chan_1':
      return 'Forest'
    case 'Faireaf_Faireaf_1':
      return 'Forest'
    case 'Faireaf_Layla_1':
      return 'Forest'
    case 'Faireaf_P1_1':
      return 'Forest'
    case 'Faireaf_SignWitch_1':
      return 'Forest'
    case 'Faireaf_Tofu_1':
      return 'Forest'
    case 'Faireaf_Valius_1':
      return 'Forest'
    case 'Golem_DEKAUSA_1':
      return 'Wasteland'
    case 'Golem_Golem_1':
      return 'Wasteland'
    case 'Golem_Kids_Hero_1':
      return 'Wasteland'
    case 'Golem_Kids_Villain_1':
      return 'Wasteland'
    case 'Golem_Ossan_Paradise_1':
      return 'Wasteland'
    case 'Golem_Planetman_1':
      return 'Wasteland'
    case 'Golem_UPA_1':
      return 'Wasteland'
    case 'Hiyori_Ame_chan_1':
      return 'Plain'
    case 'Hiyori_Faireaf_1':
      return 'Plain'
    case 'Hiyori_Hiyori_1':
      return 'Plain'
    case 'Hiyori_Icelime_1':
      return 'Plain'
    case 'Hiyori_Layla_1':
      return 'Plain'
    case 'Hiyori_Unicorn_1':
      return 'Plain'
    case 'Hiyori_Valius_1':
      return 'Plain'
    case 'Icelime_CCT_1':
      return 'Forest'
    case 'Icelime_DEKAUSA_1':
      return 'Forest'
    case 'Icelime_Icelime_1':
      return 'Forest'
    case 'Icelime_Kids_Hero_1':
      return 'Forest'
    case 'Icelime_Unicorn_1':
      return 'Forest'
    case 'Icelime_UPA_1':
      return 'Forest'
    case 'Icelime_Yamato_1':
      return 'Forest'
    case 'Kids_Hero_Kids_Hero_1':
      return 'Plain'
    case 'Kids_Hero_Kids_Hero_2':
      return 'Plain'
    case 'Kids_Hero_Kids_Hero_3':
      return 'Plain'
    case 'Kids_Hero_Kids_Hero_4':
      return 'Plain'
    case 'Kids_Hero_Kids_Hero_5':
      return 'Plain'
    case 'Kids_Villain_Kids_Villain_1':
      return 'Volcano'
    case 'Kids_Villain_Kids_Villain_2':
      return 'Volcano'
    case 'Kids_Villain_Kids_Villain_3':
      return 'Volcano'
    case 'Kids_Villain_Kids_Villain_4':
      return 'Volcano'
    case 'Kids_Villain_Kids_Villain_5':
      return 'Volcano'
    case 'Layla_Faireaf_1':
      return 'Forest'
    case 'Layla_Hiyori_1':
      return 'Forest'
    case 'Layla_Icelime_1':
      return 'Forest'
    case 'Layla_Layla_1':
      return 'Forest'
    case 'Layla_P1_1':
      return 'Forest'
    case 'Layla_SignWitch_1':
      return 'Forest'
    case 'Layla_Valius_1':
      return 'Forest'
    case 'ODENPETS_Ame_chan_1':
      return 'Plain'
    case 'ODENPETS_DEKAUSA_1':
      return 'Plain'
    case 'ODENPETS_Kids_Hero_1':
      return 'Forest'
    case 'ODENPETS_ODENPETS_1':
      return 'Forest'
    case 'ODENPETS_P1_1':
      return 'Forest'
    case 'Ossan_Paradise_Ossan_Paradise_1':
      return 'Plain'
    case 'Ossan_Paradise_Ossan_Paradise_2':
      return 'Plain'
    case 'Ossan_Paradise_Ossan_Paradise_3':
      return 'Plain'
    case 'Ossan_Paradise_Ossan_Paradise_4':
      return 'Plain'
    case 'Ossan_Paradise_Ossan_Paradise_5':
      return 'Plain'
    case 'P1_Faireaf_1':
      return 'Desert'
    case 'P1_Hiyori_1':
      return 'Desert'
    case 'P1_Icelime_1':
      return 'Desert'
    case 'P1_Kids_Hero_1':
      return 'Desert'
    case 'P1_Layla_1':
      return 'Desert'
    case 'P1_P1_1':
      return 'Desert'
    case 'P1_SignWitch_1':
      return 'Desert'
    case 'Planetman_AstarCats_1':
      return 'Wasteland'
    case 'Planetman_CCT_1':
      return 'Wasteland'
    case 'Planetman_Golem_1':
      return 'Wasteland'
    case 'Planetman_Kids_Hero_1':
      return 'Wasteland'
    case 'Planetman_Ossan_Paradise_1':
      return 'Wasteland'
    case 'Planetman_Planetman_1':
      return 'Wasteland'
    case 'Planetman_Unicorn_1':
      return 'Wasteland'
    case 'SignWitch_Ame_chan_1':
      return 'Wasteland'
    case 'SignWitch_AstarCats_1':
      return 'Forest'
    case 'SignWitch_AstarCats_2':
      return 'Volcano'
    case 'SignWitch_Faireaf_1':
      return 'Waterfront'
    case 'SignWitch_Hiyori_1':
      return 'Waterfront'
    case 'SignWitch_Layla_1':
      return 'Forest'
    case 'SignWitch_P1_1':
      return 'Volcano'
    case 'SignWitch_Planetman_1':
      return 'Mountain'
    case 'SignWitch_SignWitch_1':
      return 'Mountain'
    case 'SignWitch_Tofu_1':
      return 'Forest'
    case 'SignWitch_Unicorn_1':
      return 'Desert'
    case 'SignWitch_UPA_1':
      return 'Plain'
    case 'SignWitch_Valius_1':
      return 'Wasteland'
    case 'Tofu_DEKAUSA_1':
      return 'Desert'
    case 'Tofu_Golem_1':
      return 'Desert'
    case 'Tofu_Icelime_1':
      return 'Desert'
    case 'Tofu_Planetman_1':
      return 'Desert'
    case 'Tofu_SignWitch_1':
      return 'Desert'
    case 'Tofu_Tofu_1':
      return 'Desert'
    case 'Tofu_Yamato_1':
      return 'Desert'
    case 'Unicorn_AstarCats_1':
      return 'Plain'
    case 'Unicorn_CCT_1':
      return 'Plain'
    case 'Unicorn_ODENPETS_1':
      return 'Plain'
    case 'Unicorn_Planetman_1':
      return 'Plain'
    case 'Unicorn_Unicorn_1':
      return 'Plain'
    case 'Unicorn_UPA_1':
      return 'Plain'
    case 'Unicorn_Valius_1':
      return 'Plain'
    case 'UPA_AstarCats_1':
      return 'Waterfront'
    case 'UPA_Golem_1':
      return 'Waterfront'
    case 'UPA_Kids_Villain_1':
      return 'Waterfront'
    case 'UPA_ODENPETS_1':
      return 'Waterfront'
    case 'UPA_Ossan_Paradise_1':
      return 'Waterfront'
    case 'UPA_UPA_1':
      return 'Waterfront'
    case 'UPA_Yamato_1':
      return 'Waterfront'
    case 'Valius_CCT_1':
      return 'Mountain'
    case 'Valius_DEKAUSA_1':
      return 'Mountain'
    case 'Valius_Kids_Villain_1':
      return 'Mountain'
    case 'Valius_ODENPETS_1':
      return 'Mountain'
    case 'Valius_Ossan_Paradise_1':
      return 'Mountain'
    case 'Valius_Unicorn_1':
      return 'Mountain'
    case 'Valius_Valius_1':
      return 'Mountain'
    case 'Yamato_Ame_chan_1':
      return 'Waterfront'
    case 'Yamato_AstarCats_1':
      return 'Waterfront'
    case 'Yamato_ODENPETS_1':
      return 'Waterfront'
    case 'Yamato_P1_1':
      return 'Waterfront'
    case 'Yamato_Tofu_1':
      return 'Waterfront'
    case 'Yamato_UPA_1':
      return 'Waterfront'
    case 'Yamato_Yamato_1':
      return 'Waterfront'
    default:
      return ''
  }
}
