import { Card } from 'antd'

export const ParameterItem = (props) => {
  const { t, itemDetail } = props
  // Reusable render function
  function renderItemDetail(key) {
    if (itemDetail?.[key] && itemDetail?.[key] !== 0) {
      return (
        <div className="flex justify-between">
          <div className="text-[16px]">{t(`itemDetail.${key}`)}</div>
          <div
            className={`text-[16px] ${
              itemDetail[key] > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
            }`}
          >
            {itemDetail[key] > 0 ? '+' : ''}
            {itemDetail[key]}
          </div>
        </div>
      )
    }
    return null
  }

  // Check if any of the fields have valid values
  function shouldShowDescription() {
    const fields = [
      'recovery_stress',
      'recovery_fatigue',
      'training_regimen',
      'training_policy',
      'physical_build',
      'physical',
      'recover_lifespan',
      'degree_of_friendship',
      'condition',
      'body',
      'energy',
      'agi',
      'dex',
      'farm_agi',
      'farm_dex',
      'farm_hp',
      'farm_int',
      'farm_str',
      'farm_vit',
      'fatigue_reduction',
      'hp',
      'int',
      'lifespan',
      'vit',
      'str',
      'rest_enhancement',
      'stress_reduction',
    ]

    return !fields.some(
      (field) => itemDetail?.[field] && itemDetail?.[field] !== 0,
    )
  }
  return (
    <Card className="w-full">
      <div className="grid grid-cols-2 gap-4 ">
        {/* <div className="grid grid-cols-1 bg-[#020f38] py-[16px] px-[24px] min-h-[142px] rounded-[8px] font__M__plus font-bold">
          {itemDetail?.item_type === 'TOURNAMENT_TICKET_UC' && (
            <div>
              <div className="text-[16px]">{t('itemDetail.desticket1')}</div>
              <div className="text-[16px]">{t('itemDetail.desticket2')}</div>
            </div>
          )}

          {itemDetail?.item_type === 'EXPLORATION_TICKET_UC' && (
            <div>
              <div className="text-[16px]">{t('itemDetail.desticket3')}</div>
            </div>
          )}

          {itemDetail?.recovery_stress && itemDetail?.recovery_stress !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.recovery_stress')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.recovery_stress > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.recovery_stress}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.recovery_fatigue &&
          itemDetail?.recovery_fatigue !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.recovery_fatigue')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.recovery_fatigue > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.recovery_fatigue}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.training_regimen &&
          itemDetail?.training_regimen !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.training_regimen')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.training_regimen > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.training_regimen > 0 ? '+' : ''}
                {itemDetail?.training_regimen}
              </div>
            </div>
          ) : (
            ''
          )}
          {itemDetail?.training_policy && itemDetail?.training_policy !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.training_regimen')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.training_policy > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.training_policy > 0 ? '+' : ''}
                {itemDetail?.training_policy}
              </div>
            </div>
          ) : (
            ''
          )}
          {itemDetail?.description && itemDetail?.type === 'Fusion_Item' && (
            <div className="flex justify-between">
              <div className="text-[16px]">{itemDetail?.description}</div>
            </div>
          )}
          {itemDetail?.physical_build && itemDetail?.physical_build !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.physical_build')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.physical_build > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.physical_build > 0 ? '+' : ''}
                {itemDetail?.physical_build}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.physical && itemDetail?.physical !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.physical')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.physical > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.physical > 0 ? '+' : ''}
                {itemDetail?.physical}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.recover_lifespan &&
          itemDetail?.recover_lifespan !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.recover_lifespan')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.recover_lifespan > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.recover_lifespan > 0 ? '+' : ''}
                {itemDetail?.recover_lifespan}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.degree_of_friendship &&
          itemDetail?.degree_of_friendship !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.degree_of_friendship')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.degree_of_friendship > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.degree_of_friendship > 0 ? '+' : ''}
                {itemDetail?.degree_of_friendship}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.condition && itemDetail?.condition !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.condition')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.condition > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.condition > 0 ? '+' : ''}
                {itemDetail?.condition}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.body && itemDetail?.body !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.body')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.body > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.body > 0 ? '+' : ''}
                {itemDetail?.body}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.energy && itemDetail?.energy !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.energy')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.energy > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.energy > 0 ? '+' : ''}
                {itemDetail?.energy}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.agi && itemDetail?.agi !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.agi')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.agi > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.agi > 0 ? '+' : ''}
                {itemDetail?.agi}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.dex && itemDetail?.dex !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.dex')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.dex > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.dex > 0 ? '+' : ''}
                {itemDetail?.dex}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.farm_agi && itemDetail?.farm_agi !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.farm_agi')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.farm_agi > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.farm_agi > 0 ? '+' : ''}
                {itemDetail?.farm_agi}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.farm_dex && itemDetail?.farm_dex !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.farm_dex')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.farm_dex > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.farm_dex > 0 ? '+' : ''}
                {itemDetail?.farm_dex}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.farm_hp && itemDetail?.farm_hp !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.farm_hp')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.farm_hp > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.farm_hp > 0 ? '+' : ''}
                {itemDetail?.farm_hp}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.farm_int && itemDetail?.farm_int !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.farm_int')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.farm_int > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.farm_int > 0 ? '+' : ''}
                {itemDetail?.farm_int}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.farm_str && itemDetail?.farm_str !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.farm_str')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.farm_str > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.farm_str > 0 ? '+' : ''}
                {itemDetail?.farm_str}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.farm_vit && itemDetail?.farm_vit !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.farm_vit')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.farm_vit > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.farm_vit > 0 ? '+' : ''}
                {itemDetail?.farm_vit}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.fatigue_reduction &&
          itemDetail?.fatigue_reduction !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.fatigue_reduction')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.fatigue_reduction > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.fatigue_reduction > 0 ? '+' : ''}
                {itemDetail?.fatigue_reduction}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.hp && itemDetail?.hp !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.hp')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.hp > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.hp > 0 ? '+' : ''}
                {itemDetail?.hp}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.int && itemDetail?.int !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.int')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.int > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.int > 0 ? '+' : ''}
                {itemDetail?.int}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.lifespan && itemDetail?.lifespan !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.lifespan')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.lifespan > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.lifespan > 0 ? '+' : ''}
                {itemDetail?.lifespan}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.vit && itemDetail?.vit !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.vit')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.vit > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.vit > 0 ? '+' : ''}
                {itemDetail?.vit}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.str && itemDetail?.str !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">{t('itemDetail.str')}</div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.str > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.str > 0 ? '+' : ''}
                {itemDetail?.str}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.rest_enhancement &&
          itemDetail?.rest_enhancement !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.rest_enhancement')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.rest_enhancement > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.rest_enhancement > 0 ? '+' : ''}
                {itemDetail?.rest_enhancement}
              </div>
            </div>
          ) : (
            ''
          )}

          {itemDetail?.stress_reduction &&
          itemDetail?.stress_reduction !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {t('itemDetail.stress_reduction')}
              </div>
              <div
                className={`text-[16px]  ${
                  itemDetail?.stress_reduction > 0
                    ? 'text-[#52C41A]'
                    : 'text-[#f70e02]'
                }`}
              >
                {itemDetail?.stress_reduction > 0 ? '+' : ''}
                {itemDetail?.stress_reduction}
              </div>
            </div>
          ) : (
            ''
          )}
        </div> */}
        <div className="grid grid-cols-1 bg-[#020f38] py-[16px] px-[24px] min-h-[142px] rounded-[8px] font__M__plus font-bold">
          {itemDetail?.item_type === 'TOURNAMENT_TICKET_UC' && (
            <div>
              <div className="text-[16px]">{t('itemDetail.desticket1')}</div>
              <div className="text-[16px]">{t('itemDetail.desticket2')}</div>
            </div>
          )}

          {itemDetail?.item_type === 'EXPLORATION_TICKET_UC' && (
            <div>
              <div className="text-[16px]">{t('itemDetail.desticket3')}</div>
            </div>
          )}
          {itemDetail?.description && itemDetail?.type === 'Fusion_Item' && (
            <div className="flex justify-between">
              <div className="text-[16px]">{itemDetail.description}</div>
            </div>
          )}

          {renderItemDetail('recovery_fatigue')}
          {renderItemDetail('recovery_stress')}
          {renderItemDetail('physical')}
          {renderItemDetail('training_policy')}
          {renderItemDetail('degree_of_friendship')}
          {renderItemDetail('training_regimen')}
          {renderItemDetail('physical_build')}
          {renderItemDetail('recover_lifespan')}
          {renderItemDetail('condition')}
          {renderItemDetail('body')}
          {renderItemDetail('energy')}
          {renderItemDetail('agi')}
          {renderItemDetail('dex')}
          {renderItemDetail('farm_agi')}
          {renderItemDetail('farm_dex')}
          {renderItemDetail('farm_hp')}
          {renderItemDetail('farm_int')}
          {renderItemDetail('farm_str')}
          {renderItemDetail('farm_vit')}
          {renderItemDetail('fatigue_reduction')}
          {renderItemDetail('hp')}
          {renderItemDetail('int')}
          {renderItemDetail('lifespan')}
          {renderItemDetail('vit')}
          {renderItemDetail('str')}
          {renderItemDetail('rest_enhancement')}
          {renderItemDetail('stress_reduction')}

          {shouldShowDescription() && itemDetail?.description && (
            <div className="flex justify-between">
              {itemDetail?.item_type === 'TOURNAMENT_TICKET_UC' ||
              itemDetail?.item_type === 'EXPLORATION_TICKET_UC' ||
              itemDetail?.type === 'Fusion_Item' ? (
                <div className="text-[16px]"></div>
              ) : (
                <div className="text-[16px]">
                  {itemDetail?.item_type === 'TOURNAMENT_TICKET_B' ? (
                    <div>
                      <div className="text-[16px]">
                        {t('itemDetail.desticket1')}
                      </div>
                      <div className="text-[16px]">
                        {t('itemDetail.desticket4')}
                      </div>
                    </div>
                  ) : (
                    itemDetail.description
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="bg-[#020f38] py-[16px] px-[24px] h-[142px] rounded-[8px]">
          <div className="flex justify-between items-center font__M__plus font-bold">
            <div className="text-[16px]">{t('itemDetail.supply')}</div>
            <div className="text-[16px] text-[#FAAD14]">
              {itemDetail?.totalAmount}/{itemDetail?.amountLimit}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
