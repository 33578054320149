import React, { useState } from 'react'
import { Modal, Row, Col, Button, Input } from 'antd'

export default function WithdrawFromOasModal(props) {
  const { action, t } = props
  const [inputValue, setInputValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal
        width={572}
        centered
        title={
          <h3 className="flex justify-center text-[20px] font-normal">
            {t('profile.accountSetting.modal.withDrawFromOas.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[]}
      >
        <div className="flex justify-center gap-5 pt-10 flex flex-wrap gap-2 w-full mb-2">
          <div>
            <Row>
              <Col
                span={24}
                className="my-[-40px] mb-[30px] font__M__plus text-[16px] font-bold"
              >
                {t('profile.accountSetting.modal.withDrawFromOas.message')}
              </Col>

              <Col span={24} className="font__M__plus text-[16px] ">
                <div className="flex justify-between font-bold">
                  <div>
                    {t('profile.accountSetting.modal.withDrawFromOas.message1')}
                  </div>
                  <Button
                    className="font-medium w-[48px] h-[32px] font__M__plus leading-17 px-2 text-blue-500"
                    style={{ border: '1px solid' }}
                  >
                    {t('profile.accountSetting.modal.withDrawFromOas.max')}
                  </Button>
                </div>
                <div className="input__black">
                  <Input
                    className="h-[44px] my-4 text-black bg-white font__M__plus text-[16px] "
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={t(
                      'profile.accountSetting.modal.withDrawFromOas.amount',
                    )}
                    suffix={t(
                      'profile.accountSetting.modal.withDrawFromOas.oas',
                    )}
                  />
                </div>
                <div className="text-[14px] font-weight-400">
                  {t('profile.accountSetting.modal.withDrawFromOas.message2')}
                </div>
              </Col>

              <Col className="flex items-center justify-center w-full my-8 mb-[-44px] text-[16px]">
                <Button
                  style={{ color: '#FFFFFF' }}
                  className=" w-full h-[44px] border-none text-[16px]"
                  onClick={() => {
                    action?.handleCancel()
                  }}
                >
                  {t('profile.accountSetting.modal.withDrawFromOas.cancel')}
                </Button>
                <Button
                  className={`w-full h-[44px] mt-1 ml-4 rounded-md shadow-md border-none text-[16px] ${
                    inputValue.length > 0
                      ? 'bg-orange-400'
                      : 'bg-orange-400 bg-opacity-50'
                  }`}
                  style={{ color: '#fff' }}
                >
                  {t('profile.accountSetting.modal.withDrawFromOas.withDraw')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}
