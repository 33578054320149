import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { ethers } from 'ethers'
import configs from '~/configs'

export const providerOAS = await EthereumProvider.init({
  projectId: configs.WALLET_CONNECT_PROJECT_ID,
  chains: [configs.NETWORK.chainId],
  methods: ['personal_sign', 'eth_signTransaction'],
  showQrModal: true,
  qrModalOptions: {
    themeMode: 'dark',
    walletImages: ['https://remonster.world/img/logo_main.png']
  },
  metadata: {
    name: 'Marketplace Re.Monster',
    description: 'Marketplace Re.Monster',
    url: window.location.host,
    icons: ['https://remonster.world/img/logo_main.png'],
    // redirect: Redirect(
    //   native: 'flutterdapp://',
    //   universal: 'https://www.walletconnect.com',
    // ),
  },
  rpcMap: {
    [configs.NETWORK.chainId]: configs.NETWORK.rpc,
  },
})
export const providerPolygon = await EthereumProvider.init({
  projectId: 'a1b4641ec80b6947de498e90bcfb36c2',
  chains: [configs.POLYGON_NETWORK.chainId],
  methods: ['personal_sign', 'eth_signTransaction'],
  showQrModal: true,
  qrModalOptions: {
    themeMode: 'dark',
    walletImages: ['https://remonster.world/img/logo_main.png']
  },
  metadata: {
    name: 'Marketplace Re.Monster',
    description: 'Marketplace Re.Monster',
    url: window.location.host,
    icons: ['https://remonster.world/img/logo_main.png'],
    // redirect: Redirect(
    //   native: 'flutterdapp://',
    //   universal: 'https://www.walletconnect.com',
    // ),
  },
  rpcMap: {
    [configs.POLYGON_NETWORK.chainId]: configs.POLYGON_NETWORK.rpc,
  },
})
export const ethersWeb3Provider = new ethers.providers.Web3Provider(providerOAS);
export const ethersWeb3ProviderPolygon = new ethers.providers.Web3Provider(providerPolygon);


