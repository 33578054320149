import { Card, Col, Row } from 'antd'
import {
  getRankByParameter,
  getStatusByParameter,
  getStatusBySpecial,
} from '~/helpers/getValutByRank'

export const ParameterFarmShop = (props) => {
  const { t, data } = props
  const parametersData = [
    {
      type: 'HP',
      value: data?.farm_basic_enhance_value?.health,
      rankMin: getRankByParameter(data?.farm_basic_enhance_value?.health),
      rankMax: getRankByParameter(data?.farm_max_enhance_value?.health),
      status: getStatusByParameter(data?.farm_basic_enhance_value?.health),
      paramMax: data?.farm_max_enhance_value?.health,
    },
    {
      type: 'STR',
      value: data?.farm_basic_enhance_value?.strength,
      rankMin: getRankByParameter(data?.farm_basic_enhance_value?.strength),
      rankMax: getRankByParameter(data?.farm_max_enhance_value?.strength),
      status: getStatusByParameter(data?.farm_basic_enhance_value?.strength),
      paramMax: data?.farm_max_enhance_value?.strength,
    },
    {
      type: 'INT',
      value: data?.farm_basic_enhance_value?.intelligent,
      rankMin: getRankByParameter(data?.farm_basic_enhance_value?.intelligent),
      rankMax: getRankByParameter(data?.farm_max_enhance_value?.intelligent),
      status: getStatusByParameter(data?.farm_basic_enhance_value?.intelligent),
      paramMax: data?.farm_max_enhance_value?.intelligent,
    },
    {
      type: 'DEX',
      value: data?.farm_basic_enhance_value?.dexterity,
      rankMin: getRankByParameter(data?.farm_basic_enhance_value?.dexterity),
      rankMax: getRankByParameter(data?.farm_max_enhance_value?.dexterity),
      status: getStatusByParameter(data?.farm_basic_enhance_value?.dexterity),
      paramMax: data?.farm_max_enhance_value?.dexterity,
    },
    {
      type: 'AGI',
      value: data?.farm_basic_enhance_value?.agility,
      rankMin: getRankByParameter(data?.farm_basic_enhance_value?.agility),
      rankMax: getRankByParameter(data?.farm_max_enhance_value?.agility),
      status: getStatusByParameter(data?.farm_basic_enhance_value?.agility),
      paramMax: data?.farm_max_enhance_value?.agility,
    },
    {
      type: 'VIT',
      value: data?.farm_basic_enhance_value?.vitality,
      rankMin: getRankByParameter(data?.farm_basic_enhance_value?.vitality),
      rankMax: getRankByParameter(data?.farm_max_enhance_value?.vitality),
      status: getStatusByParameter(data?.farm_basic_enhance_value?.vitality),
      paramMax: data?.farm_max_enhance_value?.vitality,
    },
    {
      type: 'fatigue',
      value: data?.farm_fatigue_enhance_value,
      rankMin: getRankByParameter(data?.farm_fatigue_enhance_value),
      rankMax: getRankByParameter(data?.farm_max_fatigue_enhance_value),
      status: getStatusByParameter(data?.farm_fatigue_enhance_value),
      paramMax: data?.farm_max_fatigue_enhance_value,
    },
    {
      type: 'stress',
      value: data?.farm_stress_enhance_value,
      rankMin: getRankByParameter(data?.farm_stress_enhance_value),
      rankMax: getRankByParameter(data?.farm_max_stress_enhance_value),
      status: getStatusByParameter(data?.farm_stress_enhance_value),
      paramMax: data?.farm_max_stress_enhance_value,
    },
    {
      type: 'rest',
      value: data?.farm_rest_enhance_value,
      rankMin: getRankByParameter(data?.farm_rest_enhance_value),
      rankMax: getRankByParameter(data?.farm_max_rest_enhance_value),
      status: getStatusByParameter(data?.farm_rest_enhance_value),
      paramMax: data?.farm_max_rest_enhance_value,
    },
  ]
  const specialTrainingData = [
    {
      type: 'HP',
      value: data?.special_training_value?.health,
      status: getStatusBySpecial(data?.special_training_value?.health),
    },
    {
      type: 'STR',
      value: data?.special_training_value?.strength,
      status: getStatusBySpecial(data?.special_training_value?.strength),
    },
    {
      type: 'INT',
      value: data?.special_training_value?.intelligent,
      status: getStatusBySpecial(data?.special_training_value?.intelligent),
    },
    {
      type: 'DEX',
      value: data?.special_training_value?.dexterity,
      status: getStatusBySpecial(data?.special_training_value?.dexterity),
    },
    {
      type: 'AGI',
      value: data?.special_training_value?.agility,
      status: getStatusBySpecial(data?.special_training_value?.agility),
    },
    {
      type: 'VIT',
      value: data?.special_training_value?.vitality,
      status: getStatusBySpecial(data?.special_training_value?.vitality),
    },
  ].sort((a, b) => b.value - a.value)
  const getImageClassEffect = (data) => {
    switch (data) {
      case 'body':
        return 'color-body'
      case 'condition':
        return 'color-condition'
      case 'dislike':
        return 'color-dislike'
      case 'energy':
        return 'color-energy'
      case 'fatigue':
        return 'color-fatigue'
      case 'lifespan':
        return 'color-lifespan'
      case 'like':
        return 'color-like'
      case 'stress':
        return 'color-stress'
      case 'friendship':
        return 'color-friendship'
      case 'AGI':
        return 'color-AGI'
      case 'HP':
        return 'color-HP'
      case 'VIT':
        return 'color-VIT'
      case 'INT':
        return 'color-INT'
      case 'STR':
        return 'color-STR'
      case 'DEX':
        return 'color-DEX'
      case 'rest':
        return 'color-rest'
      default:
        return ''
    }
  }
  return (
    <Card className="w-full paramShop ">
      {/* totalParameters */}
      <div className=" flex items-center justify-between text-[16px] w-full mb-2">
        <div>{t('farmDetail.totalParameters')}</div>
        <div className="font__M__plus font-bold">
          {data?.farm_basic_enhance_value?.health +
            data?.farm_basic_enhance_value?.strength +
            data?.farm_basic_enhance_value?.intelligent +
            data?.farm_basic_enhance_value?.dexterity +
            data?.farm_basic_enhance_value?.agility +
            data?.farm_basic_enhance_value?.vitality +
            data?.farm_fatigue_enhance_value +
            data?.farm_stress_enhance_value +
            data?.farm_rest_enhance_value}
          /
          {data?.farm_max_enhance_value?.health +
            data?.farm_max_enhance_value?.strength +
            data?.farm_max_enhance_value?.intelligent +
            data?.farm_max_enhance_value?.dexterity +
            data?.farm_max_enhance_value?.agility +
            data?.farm_max_enhance_value?.vitality +
            data?.farm_max_fatigue_enhance_value +
            data?.farm_max_rest_enhance_value +
            data?.farm_max_stress_enhance_value}
        </div>
      </div>

      {/* parameters */}
      <div className="text-[16px]  mb-2">{t('farmDetail.parameters')}</div>
      <div className="grid grid-cols-3 gap-3">
        {parametersData?.map((data, i) => (
          <div
            key={i?.toString()}
            className="bg-[#000F38] rounded-[5px] w-full h-[64px] border border-[#1890FF]"
          >
            <Row className="h-full">
              <Col span={10}>
                <div className="w-full h-full flex items-center justify-center ">
                  <div className=" relative">
                    {data?.type === 'fatigue' || data?.type === 'stress' ? (
                      <img
                        style={{
                          width: 32,
                          height: 32,
                          marginLeft: '10px',
                        }}
                        src={`../imgs/filter/${data?.type}.png`}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{
                          width: 32,
                          height: data?.type === 'HP' ? 26 : 32,
                          marginLeft: '10px',
                        }}
                        className={`  ${getImageClassEffect(data?.type)}`}
                        src={`../imgs/filter/general/${data?.type}.png`}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col span={13} className="">
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <div className="text-[16px] font__M__plus font-bold">
                    {data?.value}/{data?.paramMax}
                  </div>
                  <div className="flex text-[16px]">
                    <img
                      src={`../svg/rankTagText/${data?.rankMin}.svg`}
                      alt=""
                    />
                    <div className="px-1"> /</div>
                    <img
                      src={`../svg/rankTagText/${data?.rankMax}.svg`}
                      alt=""
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>

      <div className="grid grid-rows-1 gap-3 h-full mb-2">
        <div className="">
          <div className="text-[16px]  mb-2 mt-[10px]">
            {t('farmDetail.specialTraining')}
          </div>
          <div className="grid grid-cols-3 gap-3">
            {specialTrainingData
              ?.filter((data) => data?.value !== 0)
              .map((data, i) => (
                <div
                  key={i?.toString()}
                  className="bg-[#020f38] border border-[#2ca0ffcc] relative w-80% h-[auto] rounded-[5px] "
                  style={{ padding: '0.5rem 0.5rem 0px 0.5rem' }}
                >
                  <div className="">
                    <div
                      key={i?.toString()}
                      className="flex flex-col items-center"
                    >
                      <img
                        // src={`../imgs/filter/${data?.type}.png`}
                        className={` mb-1   ${getImageClassEffect(data?.type)}`}
                        style={{
                          width: 20,
                          height: data?.type === 'HP' ? 16 : 20,
                        }}
                        src={`../imgs/filter/general/${data?.type}.png`}
                        alt=""
                      />
                      <div className="flex items-center">
                        <div className="text-[16px] mr-2 font__M__plus font-bold">
                          {data?.type}
                        </div>
                        <img
                          style={
                            data?.value > 6 ? { width: 24, height: 24 } : {}
                          }
                          src={`../svg/${data?.status}.svg`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div>
        <div className="text-[16px] mb-2">{t('farmDetail.farmTrait')}</div>
        <div className="w-80% bg-[#1f60cb] border border-[#1B38D2] p-[10px] rounded-[5px] h-[60px] flex justify-between items-center">
          <div>
            <div className=" text-[16px] font__M__plus font-bold">
              {data?.farm_trait_id ? t(`${data?.farm_trait_id}`) : ''}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
