import { combineReducers } from 'redux'

//reducers
import authReducer from './auth'
import commonManagement from './common'
import shopManagement from './shop'
import farmManagement from './farm'
import monsterManagement from './monster'
import itemManagement from './item'
import generalManagement from './general'
import genesisManagement from './genesis'
import regenerationManagement from './regeneration'
import accountProfileManagement from './accountProfile'
import hashManagement from './hash'
export default combineReducers({
  authReducer,
  commonManagement,
  shopManagement,
  farmManagement,
  monsterManagement,
  itemManagement,
  generalManagement,
  genesisManagement,
  regenerationManagement,
  accountProfileManagement,
  hashManagement,
})
