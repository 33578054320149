import {  ICON_LEFT_BUTTON_SM_DELETE, ICON_RIGHT_BUTTON_SM_DELETE } from '~/assets/svg/button'
import { useAccount } from '~/common/hooks/useAccount';

export const ReMonsterTwoBorderSmDelete = ({ title, functionButton, data }) => {
  const { user } = useAccount()

  return (
    <div
      onClick={functionButton}
      className="flex items-center common__btn_delete relative cursor-not-allowed"
    >
      <div className="relative flex common__btn_main">
        <div
          className="absolute z-20 left-[-6px] common__btn_left"
          style={{ color: '#69C0FF' }}
        >
          <ICON_LEFT_BUTTON_SM_DELETE fill="#0000" />
        </div>

        <div
          className="absolute z-20 left-[-1px] common__sub_btn_left"
          style={{ color: '#40A9FF' }}
        >
          <ICON_LEFT_BUTTON_SM_DELETE />
        </div>
        <div
          className="relative z-10 w-[102px] h-[30px] flex items-center justify-center common__btn_middle_delete"
          style={{
            clipPath:
              'polygon(93% 100%, 100% 77%, 100% 22%, 92% 0%, 8% 0%, 0% 24%, 0% 76%, 8% 100%) ',
            padding: 12,
          }}
        >
          <div className="relative whitespace-nowrap flex text-[14px]">
            {title ?? 'SOLD OUT'}
          </div>
        </div>
        <div
          className="absolute z-20 right-[-1px] common__sub_btn_right"
          style={{ color: '#40A9FF' }}
        >
          <ICON_RIGHT_BUTTON_SM_DELETE />
        </div>
        <div
          className="absolute z-20 right-[-6px] common__btn_right"
          style={{ color: '#69C0FF' }}
        >
          <ICON_RIGHT_BUTTON_SM_DELETE />
        </div>
      </div>
    </div>
  )
}
