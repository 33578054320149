import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/blockchainApi'
import {
  signBuyShopSuccess,
  signBuyShopFailed,
  SIGN_BUY_SHOP_START,
} from '../../actions/shop'

/**
 * Sign Buy Shop api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const signBuyShopApi = (props) => {
  var uri = `/api/shop/sign-buy-nft`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doSignBuyShop(action) {
  try {
    const response = yield call(signBuyShopApi, action?.payload)
    yield put(signBuyShopSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(signBuyShopFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch Sign Buy Shop
 */
export default function* watchSignBuyShop() {
  yield takeLatest(SIGN_BUY_SHOP_START, doSignBuyShop)
}
