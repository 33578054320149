import { CaretUpOutlined } from '@ant-design/icons'
import { Col, Row, Collapse } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { CategoryData, effectData, qualityDataMyAsset } from './data'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'

export const FilterItemsMyAssets = (props) => {
  const {
    category,
    effect,
    quality,
    setCategory,
    setEffect,
    setQuality,
    classCustom,
  } = props
  const { t } = useTranslation()

  const clearAll = () => {
    setCategory([])
    setEffect([])
    setQuality([])
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const capitalizeData = (data) => {
    const capitalizeSet = new Set(['hp', 'str', 'dex', 'agi', 'vit', 'int'])
    if (capitalizeSet.has(data)) {
      return data.toUpperCase()
    }
    return data
  }
  return (
    <div
      className={`${
        classCustom ?? 'filter__common'
      } bg-[#0d2758] p-[16px] max-w-[372px] w-[372px]`}
    >
      {isMobile ? (
        <></>
      ) : (
        <>
          <Row className="">
            <Col span={12}>
              <div className="text-[16px]"> {t('filter')}</div>
            </Col>
            <Col span={12} className="flex justify-end">
              <div
                className="text-[16px] text-[#FAAD14] cursor-pointer"
                onClick={() => {
                  clearAll()
                }}
              >
                {t('clearAll')}
              </div>
            </Col>
          </Row>

          <hr className="my-5" />
        </>
      )}

      <Collapse
        defaultActiveKey={['category', 'effect', 'quality']}
        expandIconPosition={'end'}
        items={[
          //category
          {
            key: 'category',
            label: t('common.filter.tag.category'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {CategoryData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      category?.find((rank) => rank === data) && 'bg-[#1950b3]'
                    }`}
                    // onClick={() => {
                    //   category?.find((rank) => rank === data)
                    //     ? setCategory(category.filter((rank) => rank !== data))
                    //     : setCategory((oldRank) => [...oldRank, data])
                    // }}
                    style={{
                      cursor:
                        data === 'Training_Item' || data === 'Fusion_Item'
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {t(`common.filter.category.${data}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //effect
          {
            key: 'effect',
            label: t('common.filter.tag.effect'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {effectData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] p-[10px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      effect?.find((rank) => rank === data) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      effect?.find((rank) => rank === data)
                        ? setEffect(effect.filter((rank) => rank !== data))
                        : setEffect((oldRank) => [...oldRank, data])
                    }}
                  >
                    <img
                      className="mr-2"
                      src={`../svg/effect/${capitalizeData(data)}.svg`}
                      alt=""
                    />
                    <div className="text-[14px] font__M__plus font-bold">
                      {t(`common.filter.effect.${data}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //quality
          {
            key: 'quality',
            label: t('common.filter.tag.quality'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {qualityDataMyAsset?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] p-[10px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      quality?.find((rank) => rank === data) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      quality?.find((rank) => rank === data)
                        ? setQuality(quality.filter((rank) => rank !== data))
                        : setQuality((oldRank) => [...oldRank, data])
                    }}
                  >
                    <img
                      className="mr-2 w-[24px] h-[28px]"
                      src={`../svg/qualityTag/${data}.svg`}
                      alt=""
                    />

                    <div className="text-[14px] font__M__plus font-bold">
                      {t(`common.filter.quality.${data}`)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
        expandIcon={({ isActive }) => (
          <CaretUpOutlined
            style={{ fontSize: 16 }}
            rotate={isActive ? 0 : 180}
          />
        )}
      />
    </div>
  )
}
