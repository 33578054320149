import React from 'react'
import { Button, Modal } from 'antd'
export default function BridgeHashChip(props) {
  const { action, tokenid, t, data, loadingModal } = props
  const okButtonStyle = {
    fontWeight: '400',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  return (
    <>
      <Modal
        key={data?.type}
        centered
        title={
          <h3 className="flex justify-center text-xl font-normal ">
            {t('modal.bridge_hashchip.title_deposit')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            <Button
              key="ok"
              type="primary"
              onClick={() => action?.handleOk(data)}
              style={okButtonStyle}
              loading={loadingModal}
            >
              {t('modal.bridge_hashchip.ok')}
            </Button>
          </div>,
        ]}
      >
        <div className="text-center text-[16px]">
          {t('modal.bridge_hashchip.description', {
            name: data?.asset,
            group: data?.type,
          })}
          <p>HashChip ID: {tokenid}</p>
        </div>
      </Modal>
    </>
  )
}
