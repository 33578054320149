import { convertOasToUSD, formatNumber } from '~/helpers/common'
import { ReMonsterTwoBorderSm } from '../../Button/ReMonsterTwoBorderSm'
import { getSpeciesValue } from '~/helpers/Species'

export const BoxCardHashChip = (props) => {
  const { data, button, tema } = props
  return (
    <div className="card__monster w-[250px] h-[350px] cursor-pointer">
      <div className="relative flex items-center justify-center h-full">
        <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
          <div
            className="h-full flex flex-col items-center p-[10px] text-[18px]"
            style={{
              background:
                button?.title === 'Claim'
                  ? 'linear-gradient(180deg, #401b76 25%, #9877ed 100%)'
                  : 'linear-gradient(180deg, #0D1426 0%, #375877 100%)',
            }}
          >
            <div className="flex flex-col items-center">
              <div className="title__card">Hash Chip NFT</div>
              <div className="relative left-[-52px] text-[11px] z-50">
                <div
                  className=" text-[11px] font__M__plus font-bold min-w-[40px] h-[24px] items-center flex justify-center "
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: 4,
                  }}
                >
                  {data ? `#${data}` : ''}
                </div>
              </div>
            </div>

            <div className="h-full flex items-center justify-center">
              <img
                alt=""
                className="card__monster_img w-[160px] h-[160px] object-contain	ease-in-out duration-300"
                src="../imgs/common/hashchip.png"
              />
            </div>
            <div>
              {data?.token_id || data?.tokenId
                ? `#${data?.token_id ?? data?.tokenId}`
                : ''}
            </div>
          </div>

          <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
            {data?.price && (
              <>
                <div className="flex items-center justify-center">
                  <img
                    className="w-[16px] h-[16px] object-contain	"
                    src="../imgs/coins/oasis.svg"
                    alt=""
                  />
                  <div className="relative whitespace-nowrap flex ml-2">
                    {formatNumber(data?.price)}
                  </div>
                </div>

                <div className="text-[12px] mb-1">
                  ${formatNumber(convertOasToUSD(data?.price, tema?.tema))}
                </div>
              </>
            )}

            <ReMonsterTwoBorderSm
              title={button?.title}
              functionButton={button?.functionButton}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
