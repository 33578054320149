export const GET_ALL_ITEM_USER_START = 'GET_ALL_ITEM_USER_START'
export const GET_ALL_ITEM_USER_SUCCESS = 'GET_ALL_ITEM_USER_SUCCESS'
export const GET_ALL_ITEM_USER_FAILED = 'GET_ALL_ITEM_USER_FAILED'

export const GET_ALL_ITEM_START = 'GET_ALL_ITEM_START'
export const GET_ALL_ITEM_SUCCESS = 'GET_ALL_ITEM_SUCCESS'
export const GET_ALL_ITEM_FAILED = 'GET_ALL_ITEM_FAILED'

export const GET_DETAIL_ITEM_START = 'GET_DETAIL_ITEM_START'
export const GET_DETAIL_ITEM_SUCCESS = 'GET_DETAIL_ITEM_SUCCESS'
export const GET_DETAIL_ITEM_FAILED = 'GET_DETAIL_ITEM_FAILED'

export const GET_ALL_ITEM_SALE_USER_START = 'GET_ALL_ITEM_SALE_USER_START'
export const GET_ALL_ITEM_SALE_USER_SUCCESS = 'GET_ALL_ITEM_SALE_USER_SUCCESS'
export const GET_ALL_ITEM_SALE_USER_FAILED = 'GET_ALL_ITEM_SALE_USER_FAILED'

//-------------------------Item------------------------//

/**
 * [1]
 * get all item start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllItem(payload, onSuccess, onError) {
  return {
    type: GET_ALL_ITEM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all item success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllItemSuccess(payload) {
  return {
    type: GET_ALL_ITEM_SUCCESS,
    payload: payload,
  }
}

/**
 * get all item failed action
 * @returns {object}
 */
export function getAllItemFailed() {
  return {
    type: GET_ALL_ITEM_FAILED,
  }
}

/**
 * [2]
 * get detail item start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDetailItem(payload, onSuccess, onError) {
  return {
    type: GET_DETAIL_ITEM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get detail item success action
 * @param {*} payload
 * @returns {object}
 */
export function getDetailItemSuccess(payload) {
  return {
    type: GET_DETAIL_ITEM_SUCCESS,
    payload: payload,
  }
}

/**
 * get detail item failed action
 * @returns {object}
 */
export function getDetailItemFailed() {
  return {
    type: GET_DETAIL_ITEM_FAILED,
  }
}

/**
 * [3]
 * get all item user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllItemUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_ITEM_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all monster item success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllItemUserSuccess(payload) {
  return {
    type: GET_ALL_ITEM_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all item user failed action
 * @returns {object}
 */
export function getAllItemUserFailed() {
  return {
    type: GET_ALL_ITEM_USER_FAILED,
  }
}

/**
 * [4]
 * get all item user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllItemSaleUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_ITEM_SALE_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all item sale success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllItemSaleUserSuccess(payload) {
  return {
    type: GET_ALL_ITEM_SALE_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all item sale user failed action
 * @returns {object}
 */
export function getAllItemSaleUserFailed() {
  return {
    type: GET_ALL_ITEM_SALE_USER_FAILED,
  }
}

export default {
  getAllItemUser,
  getAllItemUserSuccess,
  getAllItemUserFailed,

  getAllItem,
  getAllItemSuccess,
  getAllItemFailed,

  getDetailItem,
  getDetailItemSuccess,
  getDetailItemFailed,

  getAllItemSaleUser,
  getAllItemSaleUserSuccess,
  getAllItemSaleUserFailed,
}
