export const GET_ALL_HASh_USER_START = 'GET_ALL_HASH_USER_START'
export const GET_ALL_HASH_USER_SUCCESS = 'GET_ALL_HASH_USER_SUCCESS'
export const GET_ALL_HASH_USER_FAILED = 'GET_ALL_HASH_USER_FAILED'

//-------------------------Hash------------------------//

/**
 * [1]
 * get all hash user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllHashUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_HASh_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all hash user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllHashUserSuccess(payload) {
  return {
    type: GET_ALL_HASH_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all hash user failed action
 * @returns {object}
 */
export function getAllHashUserFailed() {
  return {
    type: GET_ALL_HASH_USER_FAILED,
  }
}

export default {
  getAllHashUser,
  getAllHashUserSuccess,
  getAllHashUserFailed,
}
