import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'
import { getTextRankParameter } from '~/helpers/get-color-parameter'
import { getParameterByIndex, getValutByRank } from '~/helpers/getValutByRank'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  // customLabelRadial,
)

const plugins = [
  {
    beforeDraw: (chart) => {
      let ctx = chart?.ctx
      ctx.save()

      chart.scales.r._pointLabelItems.forEach((value, index) => {
        let image = new Image()
        image.src = `${process.env.PUBLIC_URL
          }/imgs/filter/${getParameterByIndex(index)}.png`
        // provide the image URL
        ctx.drawImage(
          image,
          index === 4 || index === 5 || index === 3 || index === 0
            ? value?.x - 15
            : value?.x,
          value?.y,
        )
      })
      ctx.restore()
    },
  },
]

const options = {
  scales: {
    r: {
      min: 0,
      max: 999,
      border: {
        dash: (context) => {
          return context.index === 7 ? [] : [6, 6]
        },
      },

      ticks: {
        display: false,
        stepSize: 999 / 7,
      },
      grid: {
        color: [
          '#F4AF3E',
          '#8C8C8C',
          '#52C41A',
          '#4AACD1',
          '#2F54EB',
          '#722ED1',
          '#D064A5',
        ],
      },

      pointLabels: {
        font: {
          family: 'M PLUS 1', // Replace 'YourFontFamily' with the desired font family
          size: 25,
        },
      },
    },
  },

  legend: {
    position: 'right',
    labels: {
      fontColor: '#11c091',
    },
  },

  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return tooltipItem?.dataset?.label === 'Current Parameter'
            ? ` ${tooltipItem?.dataset?.label}: ${tooltipItem?.formattedValue}`
            : ` ${tooltipItem?.dataset?.label}: ${getTextRankParameter(
              tooltipItem?.formattedValue,
            )}`
        },
      },
      titleFont: {
        size: 20,
      },
      bodyFont: {
        size: 20,
      },
    },
    legend: {
      display: true,
      position: 'right',
      labels: {
        size: 30,
        color: '#fff',
        family: "M PLUS 1",
        weight: "bolder",
        font: {
          size: 14,
          family: "M PLUS 1",

      }
      },
    },
  },
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 20,
      right: 0,
      top: -50,
      bottom: 0,
    },
  },
}
export const RadaMonsterChart2 = (props) => {
  const { monsterDetail, lastBuffData } = props
  const data = {
    labels: ['', '', '', '', '', ''],
    datasets: [
      {
        label: 'Current Parameter',
        data: [
          monsterDetail?.alter_basic_p?.health + lastBuffData?.basic_parameter?.hp,
          monsterDetail?.alter_basic_p?.strength + lastBuffData?.basic_parameter?.hp,
          monsterDetail?.alter_basic_p?.intelligent + lastBuffData?.basic_parameter?.hp,
          monsterDetail?.alter_basic_p?.dexterity + lastBuffData?.basic_parameter?.hp,
          monsterDetail?.alter_basic_p?.agility + lastBuffData?.basic_parameter?.hp,
          monsterDetail?.alter_basic_p?.vitality + lastBuffData?.basic_parameter?.hp,
        ],
        borderWidth: 2,
        backgroundColor: 'rgba(242, 72, 34, 0.2)',
        borderColor: 'rgba(242, 72, 34, 1)',
        pointBackgroundColor: 'rgba(242, 72, 34, 0.7)',
        pointBorderColor: 'rgba(242, 72, 34, 1)',
        pointHoverBackgroundColor: 'rgba(242, 72, 34, 0.3)',
        pointHoverBorderColor: 'rgba(242, 72, 34, 0.3)',
      },

      {
        label: 'Growth Potential',
        data: [
          getValutByRank(monsterDetail?.hp_growth_potential) *
            143 ===
            1001
            ? 999
            : getValutByRank(monsterDetail?.hp_growth_potential) *
            143,

          getValutByRank(monsterDetail?.str_growth_potential) *
            143 ===
            1001
            ? 999
            : getValutByRank(monsterDetail?.str_growth_potential) *
            143,

          getValutByRank(monsterDetail?.int_growth_potential) *
            143 ===
            1001
            ? 999
            : getValutByRank(monsterDetail?.int_growth_potential) *
            143,

          getValutByRank(monsterDetail?.dex_growth_potential) *
            143 ===
            1001
            ? 999
            : getValutByRank(monsterDetail?.dex_growth_potential) *
            143,

          getValutByRank(monsterDetail?.agi_growth_potential) *
            143 ===
            1001
            ? 999
            : getValutByRank(monsterDetail?.agi_growth_potential) *
            143,

          getValutByRank(monsterDetail?.vit_growth_potential) *
            143 ===
            1001
            ? 999
            : getValutByRank(monsterDetail?.vit_growth_potential) *
            143,
        ],
        borderWidth: 2,
        backgroundColor: 'rgba(34, 80, 242, 0.2)',
        borderColor: 'rgba(34, 80, 242, 1)',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      },
    ],
  }

  return (
    <>
      <Radar
        style={{ margin: 'auto', width: 440, height: 340 }}
        plugins={plugins}
        data={data}
        options={options}
      />
    </>
  )
}
