import React, { useEffect } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import Header from '~/components/layout-components/Header'
import { useLocation } from 'react-router-dom'
import { scrollToTop } from '~/helpers/common'

export const AppLayout = ({ children }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  useEffect(() => {
    scrollToTop()
  }, [pathname])
  return (
    <React.Fragment key={'AppLayout'}>
      <Header t={t} />
      {children}
    </React.Fragment>
  )
}

export default React.memo(AppLayout)
