import { CopyOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { formatAddress } from '~/helpers/common'
import { fallbackCopyTextToClipboard } from '~/helpers/copy-clipboard-falllback'

export const CopyAddressCommon = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Popover
      content={
        <div className="flex">
          <div className="mr-2">{children}</div>
          <div>
            <CopyOutlined
              className="text-[#40a9ff]"
              onClick={() => {
                fallbackCopyTextToClipboard(children, t)
              }}
            />
          </div>
        </div>
      }
    >
      {formatAddress(children)}
    </Popover>
  )
}
