import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Modal, Form, InputNumber } from 'antd'
import { formatNumber, getFeeMKP } from '~/helpers/common'
export default function SellModal(props) {
  // eslint-disable-next-line
  const { name, action, t, tokenID, loadingModal, tema, balance } = props
  const [price, setPrice] = useState(0)
  const [fee, setFee] = useState(0)
  const [form] = Form.useForm()
  // const priceSell = useRef(null)
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }
  const onChangePrice = (e) => {
    if (!isNaN(e.target.value)) {
      setPrice(e.target.value)
    }
  }

  const handelSell = async () => {
    if (loadingModal) return
    form.submit()
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const feeBlc = await getFeeMKP()
      setFee(feeBlc)
    }
    fetchData()
  }, [])

  const handleCancel = () => {
    action?.handleCancel()
  }

  useEffect(() => {
    if (action?.isModalOpen) {
      setPrice(0)
      form.resetFields()
    }
  }, [action?.isModalOpen])

  function countCharacterOccurrences(str, char) {
    var count = 0

    for (var i = 0; i < str.length; i++) {
      if (str.charAt(i) === char) {
        count++
      }
    }

    return count
  }
  return (
    <>
      <Form
        form={form}
        preserve={false}
        onFinish={(values) => {
          const numberPattern = /^-?\d+(\.\d+)?$/
          if (
            values?.price?.includes('.') &&
            !numberPattern.test(values?.price)
          ) {
            form.setFields([
              {
                name: 'price',
                errors: [t('common.schema.mustBeNumericDecimal')],
              },
            ])
            return
          }

          action?.handleOk(tokenID, price)
        }}
      >
        <Modal
          key={tokenID}
          centered
          title={
            <h3 className="flex justify-center text-xl font-normal">
              {t('modal.sell.title')}
            </h3>
          }
          open={action?.isModalOpen}
          onCancel={handleCancel}
          footer={[
            <div key="buttons" className="flex justify-center">
              <Button
                key="cancel"
                onClick={handleCancel}
                style={cancelButtonStyle}
              >
                {t('modal.sell.cancel')}
              </Button>
              <Button
                key="ok"
                type="primary"
                onClick={handelSell}
                style={okButtonStyle}
                loading={loadingModal}
              >
                {t('modal.sell.ok')}
              </Button>
            </div>,
          ]}
        >
          <div className="pb-5 font__M__plus text-[16px]">
            {t('modal.sell.description', {
              name: `${name}`,
            })}
          </div>
          <div className="pb-5 font__M__plus text-[16px] mb-[-25px]">
            {t('modal.sell.price')}
          </div>
          <div className="py-3">
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: t('common.schema.required'),
                },
                () => ({
                  validator(rule, value) {
                    const numberPattern = /^-?\d+(\.\d+)?$/

                    if (value && value <= 0) {
                      return Promise.reject(t('common.schema.priceNumericSell'))
                    }
                    if (value && !numberPattern.test(value)) {
                      return Promise.reject(
                        t('common.schema.mustBeNumericDecimal'),
                      )
                    }
                    if (value === 0) {
                      return Promise.reject(t('common.schema.priceNumericSell'))
                    }
                    if (
                      value &&
                      (countCharacterOccurrences(value, '.') >= 2 ||
                        value.charAt(0) === '.')
                    ) {
                      return Promise.reject(
                        t('common.schema.mustBeNumericDecimal'),
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input
                suffix={'OAS'}
                maxLength={9}
                autoComplete="off"
                className="input-numberSell"
                onChange={onChangePrice}
                placeholder={t('modal.sell.placeholder')}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
          </div>
          <div className="flex justify-between pb-4 font__M__plus text-[16px]">
            <div>{t('modal.sell.your_balance')}</div>
            <div>{formatNumber(balance)} OAS</div>
          </div>
          <div className="flex justify-between font__M__plus text-[16px] font-bold">
            <div>{t('modal.sell.fee')}</div>
            <div>
              {formatNumber(((Number(price) * Number(fee)) / 100)?.toFixed(3))}{' '}
              OAS
            </div>
          </div>
        </Modal>
      </Form>
    </>
  )
}
