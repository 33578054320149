import { StopOutlined } from '@ant-design/icons'
import { Card, Popover } from 'antd'
import {
  converNamePublicMainSubSeed,
  converSeed,
  extractConvertMonsterSeed,
} from '~/common/constants'
import SubCard from '~/components/shared-components/SubCard'
import './styles.css'
import {
  getRankFatigue,
  getRankParameter,
  getRankStress,
  getRanktrainingPolicy,
} from '~/helpers/get-color-parameter'
import { getPercentpersonality } from '~/helpers/monster'

export const GeneralMonster = (props) => {
  const { t, monsterDetail } = props

  const constStats = [
    {
      type: 'illness',
      value: monsterDetail?.illness,
    },
    {
      type: 'injury',
      value: monsterDetail?.injury,
    },
    {
      type: 'fatigue',
      value: monsterDetail?.fatigue,
    },
    {
      type: 'stress',
      value: monsterDetail?.stress,
    },
  ]

  const infoMonster = [
    {
      type: 'lifespan',
      des: `${(
        monsterDetail?.alter_training_p?.lifespan + monsterDetail?.life_span
      )?.toFixed()}/${monsterDetail?.life_span}`,
    },
    {
      type: 'age',
      des: `${t('week', { total: monsterDetail?.monster_age?.toFixed() })}${
        monsterDetail?.monster_age?.toFixed() > 1 ? t('more') : ''
      }`,
    },
    {
      type: 'growthType',
      des: `${monsterDetail?.growth_type}`,
    },
    {
      type: 'fatigue',
      des: `${Math.floor(
        monsterDetail?.alter_training_p?.fatigue,
      )}/100 ${`[ ${t(
        `Fatigue.${getRankFatigue(
          Math.floor(monsterDetail?.alter_training_p?.fatigue),
        )}`,
      )} ]`}`,
    },
    {
      type: 'stress',
      des: `${Math.floor(monsterDetail?.alter_training_p?.stress)}/100 ${`[ ${t(
        `Stress.${getRankStress(
          Math.floor(monsterDetail?.alter_training_p?.stress),
        )}`,
      )} ]`}`,
    },
    {
      type: 'phisical',
      des: `${(
        Number(monsterDetail?.alter_training_p?.physical) + 50
      )?.toFixed()}/100 ${`[ ${t(
        `rank.${getRankParameter(
          (Number(monsterDetail?.alter_training_p?.physical) + 50)?.toFixed(),
        )}`,
      )} ]`}`,
    },
    {
      type: 'trainingPolicy',
      des: `${(
        Number(
          monsterDetail?.alter_training_p?.training_policy ??
            monsterDetail?.alter_training_p?.trainingPolicy,
        ) + 50
      )?.toFixed()}/100 ${`[ ${t(
        `trainingPolicy.${getRanktrainingPolicy(
          (
            Number(
              monsterDetail?.alter_training_p?.training_policy ??
                monsterDetail?.alter_training_p?.trainingPolicy,
            ) + 50
          )?.toFixed(),
        )}`,
      )} ]`}`,
    },
    {
      type: 'friendship',
      des: `${monsterDetail?.alter_training_p?.friendship}/100`,
    },
    {
      type: 'personality',
      des: `${monsterDetail?.monster_personality_id} `,
    },
  ]
  const mealMonster = [
    {
      type: 'like',
      des: (
        <>
          {monsterDetail?.likes !== 'None' && monsterDetail?.likes !== '0' ? (
            <img
              className="w-[25px] h-[25px]"
              src={`../imgs/meals/${monsterDetail?.likes}_icon.png`}
              alt=""
            />
          ) : (
            <StopOutlined style={{ fontSize: 20, color: 'red' }} />
          )}
        </>
      ),
    },
    {
      type: 'dislike',
      des: (
        <>
          {monsterDetail?.dislikes !== 'None' &&
          monsterDetail?.dislikes !== '0' ? (
            <img
              className="w-[25px] h-[25px]"
              src={`../imgs/meals/${monsterDetail?.dislikes}_icon.png`}
              alt=""
            />
          ) : (
            <StopOutlined style={{ fontSize: 20, color: 'red' }} />
          )}
        </>
      ),
    },
    {
      type: 'energy',
      des: Number(monsterDetail?.alter_training_p?.energy) + 80,
    },
    { type: 'body', des: Number(monsterDetail?.alter_training_p?.body) + 80 },
    {
      type: 'condition',
      des: Number(monsterDetail?.alter_training_p?.condition) + 80,
    },
  ]

  const propertyMonster = getPercentpersonality(
    monsterDetail?.monster_personality_id,
  )
  const getImageClassEffect = (data) => {
    switch (data) {
      case 'body':
        return 'color-body'
      case 'condition':
        return 'color-condition'
      case 'dislike':
        return 'color-dislike'
      case 'energy':
        return 'color-energy'
      case 'fatigue':
        return 'color-fatigue'
      case 'lifespan':
        return 'color-lifespan'
      case 'like':
        return 'color-like'
      case 'stress':
        return 'color-stress'
      case 'friendship':
        return 'color-friendship'
      case 'AGI':
        return 'color-AGI'
      case 'HP':
        return 'color-HP'
      case 'VIT':
        return 'color-VIT'
      case 'INT':
        return 'color-INT'
      case 'STR':
        return 'color-STR'
      case 'DEX':
        return 'color-DEX'
      default:
        return ''
    }
  }

  if (!monsterDetail) return null

  return (
    <Card className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        <SubCard title={t('monsterDetail.info.mainSeed')}>
          {monsterDetail?.monster_script_id && (
            <div className="flex items-center">
              <div className="w-[40px] min-h-[40px] flex items-center justify-center group mr-2">
                <img
                  className="block group-hover:scale-110 transition ease-in-out w-full h-full object-contain border rounded-[10px]"
                  src={`${process.env.PUBLIC_URL}/imgs/seed/${converSeed(
                    extractConvertMonsterSeed(monsterDetail.monster_script_id)
                      .mainSeed,
                  )}.png`}
                  alt=""
                />
              </div>
              <div className="text-[20px] font__M__plus font-bold">
                {converNamePublicMainSubSeed(
                  extractConvertMonsterSeed(monsterDetail.monster_script_id)
                    .mainSeed,
                )}
              </div>
            </div>
          )}
        </SubCard>

        <SubCard title={t('monsterDetail.info.subSeed')}>
          {monsterDetail?.monster_script_id && (
            <div className="flex items-center">
              <div className="w-[40px] min-h-[40px] flex items-center justify-center group mr-2">
                <img
                  className="block group-hover:scale-110 transition ease-in-out w-full h-full object-contain border rounded-[10px]"
                  src={`${process.env.PUBLIC_URL}/imgs/seed/${converSeed(
                    extractConvertMonsterSeed(monsterDetail.monster_script_id)
                      .subSeed,
                  )}.png`}
                  alt=""
                />
              </div>
              <div className="text-[20px] font__M__plus font-bold">
                {/* {monsterDetail?.main_seed === 'P-1(X-soldiers)'
                  ? 'P1'
                  : monsterDetail?.sub_seed?.indexOf('(') === -1
                  ? monsterDetail?.sub_seed
                  : monsterDetail?.sub_seed?.slice(
                      0,
                      monsterDetail?.sub_seed?.indexOf('('),
                    )} */}
                {converNamePublicMainSubSeed(
                  extractConvertMonsterSeed(monsterDetail.monster_script_id)
                    .subSeed,
                )}
              </div>
            </div>
          )}
        </SubCard>

        <SubCard
          title={t('monsterDetail.info.stats')}
          icon={'../svg/monster/stats.svg'}
        >
          <div className="grid grid-cols-6 gap-1">
            {constStats?.map(
              (data) =>
                data?.value > 0 && (
                  <Popover
                    content={
                      <div className="flex">
                        <div className="">{data?.value}</div>
                      </div>
                    }
                  >
                    <img
                      key={data?.type}
                      className="hover:scale-110 transition ease-in-out"
                      src={`../svg/monster/stats/${data?.type}.svg`}
                      width={40}
                      height={40}
                      alt=""
                    />{' '}
                  </Popover>
                ),
            )}
          </div>
        </SubCard>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 md:gap-4">
        <div className="w-full col-span-2">
          {/* infoMonster */}
          <div
            className="bg-[#000F38] px-[24px] py-[10px] w-full mb-4"
            style={{ borderRadius: 10 }}
          >
            {infoMonster?.map((data) => (
              <div
                key={data?.type}
                className="flex justify-between items-center mb-3"
              >
                <div className="flex items-center">
                  <img
                    className={`mr-2 object-contain ${getImageClassEffect(
                      data?.type,
                    )}`}
                    src={`../imgs/monster/${data?.type}.png`}
                    alt=""
                    width={24}
                    height={24}
                  />
                  <div className="font__M__plus font-bold">
                    {t(`monsterDetail.info.${data?.type}`)}
                  </div>
                </div>

                <div className="font__M__plus font-medium">{data?.des}</div>
              </div>
            ))}
          </div>

          {/* propertyMonster */}

          <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
            {propertyMonster?.map((data) => (
              <div
                key={data?.type}
                className="group bg-[#000F38] rounded-[10px] h-[92px] border border-[#1890FF] flex flex-col items-center justify-center"
              >
                <img
                  className={`mb-1 group-hover:scale-110 transition ease-in-out ${getImageClassEffect(
                    data?.type,
                  )}`}
                  style={{ width: 30, height: 30, objectFit: 'contain' }}
                  src={`../imgs/filter/general/${data?.type}.png`}
                  alt=""
                />
                <div className="text-[16px] font__M__plus font-bold">
                  {data?.data}%
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full mt-4 md:mt-0">
          <SubCard
            classCustom="h-auto mb-4 pr-[24px]"
            title={t('monsterDetail.info.meal')}
            icon={'../svg/monster/meal.svg'}
          >
            {mealMonster?.map((data) => (
              <div
                key={data?.type}
                className="flex justify-between items-center mb-3"
              >
                <div className="flex">
                  <img
                    className={`mr-2 object-contain ${getImageClassEffect(
                      data?.type,
                    )}`}
                    src={`../imgs/monster/${data?.type}.png`}
                    alt=""
                    width={24}
                    height={24}
                  />
                  <div className="font__M__plus font-bold">
                    {t(`monsterDetail.info.${data?.type}`)}
                  </div>
                </div>

                <div className="font__M__plus font-medium">{data?.des}</div>
              </div>
            ))}
          </SubCard>

          <SubCard
            classCustom="h-auto"
            title={t('monsterDetail.info.terrain')}
            icon={'../svg/monster/terrain.svg'}
          >
            <div className="flex items-center mb-2">
              <div className="w-[23px] mr-2"></div>
              <div className="text-[18px] font__M__plus font-bold">
                {t('monsterDetail.info.main')}
              </div>
              <div className="text-[18px] font__M__plus font-bold ml-3">
                {t('monsterDetail.info.sub')}
              </div>
            </div>

            <div className="flex items-center mb-3">
              <img
                style={{ width: 23, height: 25, objectFit: 'contain' }}
                className="mr-2"
                src={'../svg/up.svg'}
                alt=""
                width={23}
                height={25}
              />
              <div className="w-[40px] h-[40px] group bg-[#000F38] rounded-[5px] border border-[#1890FF] flex flex-col items-center justify-center">
                <Popover
                  content={
                    <div className="flex">
                      <div className="">
                        {monsterDetail?.main_seeds_specialty_terrain}
                      </div>
                    </div>
                  }
                >
                  <img
                    className="group-hover:scale-110 transition ease-in-out"
                    style={{ width: 30, height: 30, objectFit: 'contain' }}
                    src={`../imgs/filter/${monsterDetail?.main_seeds_specialty_terrain}.png`}
                    alt=""
                  />
                </Popover>
              </div>
              <div className="w-[40px] h-[40px] group bg-[#000F38] rounded-[5px] border border-[#1890FF] flex flex-col items-center justify-center ml-3">
                <Popover
                  content={
                    <div className="flex">
                      <div className="">
                        {monsterDetail?.sub_seeds_specialty_terrain}
                      </div>
                    </div>
                  }
                >
                  <img
                    className="group-hover:scale-110 transition ease-in-out"
                    style={{ width: 30, height: 30, objectFit: 'contain' }}
                    src={`../imgs/filter/${monsterDetail?.sub_seeds_specialty_terrain}.png`}
                    alt=""
                  />
                </Popover>
              </div>
            </div>

            <div className="flex items-center">
              <img
                style={{ width: 23, height: 25, objectFit: 'contain' }}
                className="mr-2"
                src={'../svg/down.svg'}
                alt=""
                width={23}
                height={25}
              />
              <div className="w-[40px] h-[40px] group bg-[#000F38] rounded-[5px] border border-[#1890FF] flex flex-col items-center justify-center">
                <Popover
                  content={
                    <div className="flex">
                      <div className="">
                        {monsterDetail?.main_seeds_poor_terrain}
                      </div>
                    </div>
                  }
                >
                  <img
                    className="group-hover:scale-110 transition ease-in-out"
                    style={{ width: 30, height: 30, objectFit: 'contain' }}
                    src={`../imgs/filter/${monsterDetail?.main_seeds_poor_terrain}.png`}
                    alt=""
                  />
                </Popover>
              </div>
              <div className="w-[40px] h-[40px] group bg-[#000F38] rounded-[5px] border border-[#1890FF] flex flex-col items-center justify-center ml-3">
                <Popover
                  content={
                    <div className="flex">
                      <div className="">
                        {monsterDetail?.sub_seeds_poor_terrain}
                      </div>
                    </div>
                  }
                >
                  <img
                    className="group-hover:scale-110 transition ease-in-out"
                    style={{ width: 30, height: 30, objectFit: 'contain' }}
                    src={`../imgs/filter/${monsterDetail?.sub_seeds_poor_terrain}.png`}
                    alt=""
                  />
                </Popover>
              </div>
            </div>
          </SubCard>
        </div>
      </div>
    </Card>
  )
}

export default GeneralMonster
