import env from './env'
import network from './network.config'
import network_poygon from './network-polygon.config'

const configs = {
  NETWORK: network[env],
  ADDRESS_SHOP: process.env.REACT_APP_ADDRESS_SHOP,
  ADDRESS_GENESIS: process.env.REACT_APP_ADDRESS_GENESIS,
  ADDRESS_GENERAL: process.env.REACT_APP_ADDRESS_GENERAL,
  ADDRESS_MONSTER: process.env.REACT_APP_ADDRESS_MONSTER,
  ADDRESS_FARM: process.env.REACT_APP_ADDRESS_FARM,
  ADDRESS_MARKETPLACE: process.env.REACT_APP_ADDRESS_MARKETPLACE,
  ADDRESS_TRAINING_ITEM: process.env.REACT_APP_ADDRESS_TRAINING_ITEM,
  ADDRESS_REGENERATION_ITEM: process.env.REACT_APP_ADDRESS_REGENERATION_ITEM,
  ADDRESS_FUSION_ITEM: process.env.REACT_APP_ADDRESS_FUSION_ITEM,
  ADDRESS_ENHANCE_ITEM: process.env.REACT_APP_ADDRESS_ENHANCE_ITEM,
  POLYGON_NETWORK: network_poygon[env],
  ADDRESS_DEPOSIT_POLYGON: process.env.REACT_APP_ADDRESS_DEPOSIT_POLYGON,
  ADDRESS_CLAIM_HASHCHIP: process.env.REACT_APP_ADDRESS_CLAIM_HASHCHIP,
  ADDRESS_HASHCHIP_POLYGON: process.env.REACT_APP_ADDRESS_HASHCHIP_POLYGON,
  ADDRESS_HASHCHIP_OAS: process.env.REACT_APP_ADDRESS_HASHCHIP_OAS,
  WALLET_CONNECT_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "5b7ec9b6eeac11ee9d63be0900f4929c",
}

/**
 * Module exports
 */
export default configs;