export const handleCalculateOverall = (
  TP = 1,
  MOV = 1,
  SPD = 1,
  ST = 1,
  RL = 1,
  IT = 1,
  AT1 = 1,
  AT2 = 1,
) => {
  return (
    (TP + MOV * 80 + SPD * 3 + ST * 6) * (1 + RL / 10) +
    IT * 150 +
    (AT1 + AT2) * 50
  )?.toFixed()
}

export const calculateIt = (IT1, IT2) => {
  if (IT1 === 'None' && IT2 === 'None') {
    return 0
  }
  if ((IT1 !== 'None' && IT2 === 'None') || (IT1 === 'None' && IT2 !== 'None')) {
    return 1
  }

  if (IT1 !== 'None' && IT2 !== 'None') {
    return 2
  }
}

export const getPercentpersonality = (type) => {
  switch (type) {
    case "Cool":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 6,
        },
        {
          type: 'DEX',
          data: 4,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Jolly":
      return [
        {
          type: 'HP',
          data: 4,
        },
        {
          type: 'STR',
          data: 6,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Gentle":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 4,
        },
        {
          type: 'DEX',
          data: 6,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Lazy":
      return [
        {
          type: 'HP',
          data: 5,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 5,
        },
      ]
    case "Naive":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 4,
        },
        {
          type: 'DEX',
          data: 3,
        },
        {
          type: 'AGI',
          data: 3,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Shy":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 3,
        },
        {
          type: 'INT',
          data: 3,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 4,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Stubborn":
      return [
        {
          type: 'HP',
          data: 3,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 7,
        },
      ]
    case "Curious":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 2,
        },
        {
          type: 'INT',
          data: 2,
        },
        {
          type: 'DEX',
          data: 2,
        },
        {
          type: 'AGI',
          data: 2,
        },
        {
          type: 'VIT',
          data: 2,
        },
      ]
    case "Devoted":
      return [
        {
          type: 'HP',
          data: 4,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 3,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 3,
        },
      ]
    case "Foodie":
      return [
        {
          type: 'HP',
          data: 4,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 2,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 4,
        },
      ]
    case "Brave":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 3,
        },
        {
          type: 'INT',
          data: 3,
        },
        {
          type: 'DEX',
          data: 2,
        },
        {
          type: 'AGI',
          data: 2,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Emotional":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 5,
        },
        {
          type: 'INT',
          data: 5,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Adventurer":
      return [
        {
          type: 'HP',
          data: 3,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 3,
        },
        {
          type: 'AGI',
          data: 2,
        },
        {
          type: 'VIT',
          data: 2,
        },
      ]
    case "Loyal":
      return [
        {
          type: 'HP',
          data: 2,
        },
        {
          type: 'STR',
          data: 2,
        },
        {
          type: 'INT',
          data: 2,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 4,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Serious":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 2,
        },
        {
          type: 'INT',
          data: 2,
        },
        {
          type: 'DEX',
          data: 1,
        },
        {
          type: 'AGI',
          data: 4,
        },
        {
          type: 'VIT',
          data: 1,
        },
      ]
    case "Dreamer":
      return [
        {
          type: 'HP',
          data: 4,
        },
        {
          type: 'STR',
          data: 4,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 2,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
    case "Innocent":
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 2,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 1,
        },
        {
          type: 'AGI',
          data: 5,
        },
        {
          type: 'VIT',
          data: 2,
        },
      ]
    case "Graceful":
      return [
        {
          type: 'HP',
          data: 2,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 3,
        },
        {
          type: 'DEX',
          data: 2,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 3,
        },
      ]
    case "Panicky":
      return [
        {
          type: 'HP',
          data: 1,
        },
        {
          type: 'STR',
          data: 3,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 4,
        },
        {
          type: 'VIT',
          data: 2,
        },
      ]
    case "Optimist":
      return [
        {
          type: 'HP',
          data: 1,
        },
        {
          type: 'STR',
          data: 2,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 2,
        },
        {
          type: 'AGI',
          data: 3,
        },
        {
          type: 'VIT',
          data: 2,
        },
      ]
    default:
      return [
        {
          type: 'HP',
          data: 0,
        },
        {
          type: 'STR',
          data: 0,
        },
        {
          type: 'INT',
          data: 0,
        },
        {
          type: 'DEX',
          data: 0,
        },
        {
          type: 'AGI',
          data: 0,
        },
        {
          type: 'VIT',
          data: 0,
        },
      ]
  }
}