/* eslint-disable no-param-reassign */
import axios from 'axios'
import { validateStatus } from '~/helpers/api'
import { notification } from 'antd'
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  REACT_APP_BASE_URL_GAME,
  NOTIFICATION_DURATION,
  USER_INFO,
} from '~/common/constants'

const REFRESH_TOKEN_URL = 'admin/auth/refresh-token'
const HEADERS_MULTIPLE_PART = {
  'Content-Type': 'multipart/form-data; boundary=something',
}

const instance = axios.create({
  baseURL: REACT_APP_BASE_URL_GAME,
  headers: {
    contentType: 'application/json',
    accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    if (config.url !== REFRESH_TOKEN_URL && localStorage.getItem(AUTH_TOKEN)) {
      const token = localStorage.getItem(AUTH_TOKEN)
      config.headers['Authorization'] = `Bearer ${token}`
      // config.headers['x-auth-token'] = `Bearer ${token}`
    }
    return config
  },
  function (error) {
    // Các trường hợp lỗi 5xx, 4xx, network xử lý ở đây
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
instance.interceptors.response.use(
  async function (response) {
    // const t = useTranslation()
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response
    const responseTransorm = { ...response.data }

    if (response.data.code === 401) {
      notification.error({
        message: response?.data?.message || 'Unauthorized',
        duration: NOTIFICATION_DURATION,
      })
      startLogout()
    }
    
    if (response.status === 200 || response.status === 201) {
      return responseTransorm
    } else {
      notification.error({
        message: response?.data?.message?.message || 'something went wrong',
      })
      return responseTransorm
    }
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const response = error.response
    if (response.status === 401) {
      localStorage.removeItem(AUTH_TOKEN)
      localStorage.removeItem(USER_INFO)
      window.open('/auth/login', '_self')
    }
    if (
      response.status === 403 &&
      response.config &&
      !response.config._isRefreshBefore &&
      response.config.url !== REFRESH_TOKEN_URL &&
      localStorage.getItem(REFRESH_TOKEN)
    ) {
      return refreshAccessToken()
        .then((refresh) => {
          if (refresh.statusCode === 200) {
            notification.destroy()
            //add to Authorization
            axios.defaults.headers.common['Authorization'] =
              refresh.data.accessToken.token

            // save to localStorage
            localStorage.setItem(AUTH_TOKEN, refresh.data.accessToken.token)
            localStorage.setItem(REFRESH_TOKEN, refresh.data.refreshToken.token)
            response.config._isRefreshBefore = true

            return instance(response.config)
          } else {
            startLogout()
          }
        })
        .catch(() => {
          startLogout()
        })
    } else if (response.status === 401) {
      notification.error({
        message: response?.data?.message || 'Unauthorized',
        duration: NOTIFICATION_DURATION,
      })
      startLogout()
    } else {
      notification.error({
        message: response?.data?.message || 'Unknow',
      })
      return Promise.reject(error)
    }
  },
)

const startLogout = () => {

}

const api = {
  instance,
  post: (endpoint, params) => {
    return instance
      .post(endpoint, params, {
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
      .catch(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
  },

  postMultiplePart: (endpoint, params) => {
    return instance
      .post(endpoint, params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
      .catch(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
  },

  get: (endpoint, params = {}) => {
    return instance
      .get(endpoint, {
        params: params,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
      .catch(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
  },

  put: (endpoint, params) => {
    return instance
      .put(endpoint, params, {
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
      .catch(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
  },

  patch: (endpoint, params) => {
    return instance
      .patch(endpoint, params, {
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
      .catch(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
  },

  delete: (endpoint, params) => {
    return instance
      .delete(endpoint, {
        data: params,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
      .catch(
        (response) => {
          return response
        },
        (err) => {
          return err.response || err
        },
      )
  },
}

/**
 *
 * @returns {Promise}
 */
export const refreshAccessToken = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN)
  return instance.get(REFRESH_TOKEN_URL, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
      'x-auth-token': `Bearer ${refreshToken}`,
    },
  })
}

export { api }
