import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Dropdown, Pagination, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import { MONSTER_SORT_SALE_USER, SCREEN_SIZE } from '~/common/constants'
import { useQueryState } from '~/common/hooks/useQueryState'
import CancelSell from '~/components/Modals/CancelSell'
import { MonstersCard } from '~/components/shared-components/Card/MonstersCard'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useMonster } from '~/views/redux/hooks/useMonster'
import configs from '~/configs'
import {
  checkTransactionConfirmation,
  hexToNumberString,
} from '~/blockchain/provider'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { NodataMonster } from '~/components/shared-components/NoData/NodataMonster'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'
import FilterMonster from '~/views/app/Home/components/FilterMonster'
import useDebounce from '~/common/hooks/useDebounce'
// const FilterMonster = React.lazy(() =>
//   import('~/views/app/Home/components/FilterMonster'),
// )

export const MonsterSale = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  // monster
  const [rankTagFilter, setRankTagFilter] = useState([])
  const [mainSeedFilter, setMainSeedFilter] = useState([])
  const [subSeedFilter, setSubSeedFilter] = useState([])
  const [basicParameter, setBasicParameter] = useState({
    health: [],
    strength: [],
    intelligent: [],
    dexterity: [],
    agility: [],
    vitality: [],
  })
  const [growthPotential, setGrowthPotential] = useState({
    hp: [],
    str: [],
    int: [],
    dex: [],
    agi: [],
    vit: [],
  })
  const [growthType, setGrowthType] = useState([])
  const [valueLifeSpan, setValueLifeSpan] = useState([0, 5000])
  const [terrainCompatibility, setTerrainCompatibility] = useState([])
  const [skillType, setSkillType] = useState([])
  const [specialtyElement, setSpecialtyElement] = useState([])
  const [personality, setPersonality] = useState([])
  const [innateTraitsByEffect, setInnateTraitsByEffect] = useState([])
  const [acquiredTraitsByEffect, setAcquiredTraitsByEffect] = useState([])

  const [sort, setSort] = useState(MONSTER_SORT_SALE_USER[0])
  const [typeSort, setTypeSort] = useState('-1')
  const {
    data: { user },
  } = useTheme()
  const [txHash, setTxHash] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [nftActive, setNftActive] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const [openFilterMonster, setOpenFilterMonster] = useState(false)
  const {
    actions,
    data: { allMonsterUser, totalMonsterUser, isLoading },
  } = useMonster()
  const {
    actions: actionsTema,
    data: { allTEMAs },
  } = useCommon()
  const { page, pageSize, setPage, setPageSize } = useQueryState()

  const showModalCancelSell = () => {
    setIsModalOpen(true)
  }
  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (_, size) => {
    setPageSize(size)
  }
  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccess()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccess()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setLoadingModal(false)
  }

  const handleChangeSort = (e) => {
    setSort(MONSTER_SORT_SALE_USER[e.key])
  }

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }
  const debouncedPage = useDebounce(page, 1000)
  const debouncedPageSize = useDebounce(pageSize, 1000)
  const debouncedSort = useDebounce(sort, 1000)
  const debouncedTypeSort = useDebounce(typeSort, 1000)
  const debouncedRankTagFilter = useDebounce(rankTagFilter, 1000)
  const debouncedMainSeedFilter = useDebounce(mainSeedFilter, 1000)
  const debouncedSubSeedFilter = useDebounce(subSeedFilter, 1000)
  const debouncedBasicParameter = useDebounce(basicParameter, 1000)
  const debouncedGrowthPotential = useDebounce(growthPotential, 1000)
  const debouncedGrowthType = useDebounce(growthType, 1000)
  const debouncedValueLifeSpan = useDebounce(valueLifeSpan, 1000)
  const debouncedTerrainCompatibility = useDebounce(terrainCompatibility, 1000)
  const debouncedPersonality = useDebounce(personality, 1000)
  const debouncedInnateTraitsByEffect = useDebounce(innateTraitsByEffect, 1000)
  const debouncedAcquiredTraitsByEffect = useDebounce(
    acquiredTraitsByEffect,
    1000,
  )
  const debouncedSkillType = useDebounce(skillType, 1000)
  const debouncedSpecialtyElement = useDebounce(specialtyElement, 1000)
  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      wallet_address: user.address_wallet,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      monster_rank: rankTagFilter?.map((data) => Number(data)),
      main_seed: mainSeedFilter,
      sub_seed: subSeedFilter,
      alter_basic_p: basicParameter,
      growth_potential: growthPotential,
      growth_type: growthType,
      life_span: {
        from: valueLifeSpan[0],
        to: valueLifeSpan[1],
      },
      terrain_compatibility: terrainCompatibility?.map((data) => data?.name),
      monster_personality_id: personality,
      innate_trait_id_list: innateTraitsByEffect,
      monster_acquired_traits: acquiredTraitsByEffect,
      skill_type: skillType?.map((data) => data?.name),
      element_type: specialtyElement?.map((data) => data?.name),
    }
    actions.getAllMonsterSaleUser(params)
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    rankTagFilter,
    mainSeedFilter,
    subSeedFilter,
    basicParameter,
    growthPotential,
    growthType,
    valueLifeSpan,
    terrainCompatibility,
    personality,
    innateTraitsByEffect,
    acquiredTraitsByEffect,
    skillType,
    specialtyElement,
  ])

  useEffect(() => {
    if (user) {
      actionsTema.getTema()
    }
  }, [txHash])

  useEffect(
    () => {
      const abortController = new AbortController()
      if (user) {
        refreshData()
      }
      return () => {
        abortController.abort()
      }
    },

    [
      txHash,
      debouncedPage,
      debouncedPageSize,
      debouncedSort,
      debouncedTypeSort,
      debouncedRankTagFilter,
      debouncedMainSeedFilter,
      debouncedSubSeedFilter,
      debouncedBasicParameter,
      debouncedGrowthPotential,
      debouncedGrowthType,
      debouncedValueLifeSpan,
      debouncedTerrainCompatibility,
      debouncedPersonality,
      debouncedInnateTraitsByEffect,
      debouncedAcquiredTraitsByEffect,
      debouncedSkillType,
      debouncedSpecialtyElement,
    ], // Only call effect if debounced search term changes
  )

  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    sort,
    typeSort,
    rankTagFilter,
    mainSeedFilter,
    subSeedFilter,
    basicParameter,
    growthPotential,
    growthType,
    valueLifeSpan,
    terrainCompatibility,
    personality,
    innateTraitsByEffect,
    acquiredTraitsByEffect,
    skillType,
    specialtyElement,
  ])
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const showDrawer = () => {
    setOpenFilterMonster(true)
  }

  const onClose = () => {
    setOpenFilterMonster(false)
  }
  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh] filter_profile'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterMonster
                className="filter_profile"
                rankTagFilter={rankTagFilter}
                mainSeedFilter={mainSeedFilter}
                subSeedFilter={subSeedFilter}
                basicParameter={basicParameter}
                growthPotential={growthPotential}
                growthType={growthType}
                valueLifeSpan={valueLifeSpan}
                terrainCompatibility={terrainCompatibility}
                skillType={skillType}
                specialtyElement={specialtyElement}
                personality={personality}
                innateTraitsByEffect={innateTraitsByEffect}
                acquiredTraitsByEffect={acquiredTraitsByEffect}
                setRankTagFilter={setRankTagFilter}
                setMainSeedFilter={setMainSeedFilter}
                setSubSeedFilter={setSubSeedFilter}
                setBasicParameter={setBasicParameter}
                setGrowthPotential={setGrowthPotential}
                setGrowthType={setGrowthType}
                setValueLifeSpan={setValueLifeSpan}
                setTerrainCompatibility={setTerrainCompatibility}
                setSkillType={setSkillType}
                setSpecialtyElement={setSpecialtyElement}
                setPersonality={setPersonality}
                setInnateTraitsByEffect={setInnateTraitsByEffect}
                setAcquiredTraitsByEffect={setAcquiredTraitsByEffect}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0  relative md:sticky top-0 pt-[1.5rem]  sm:top-[104px] pb-12">
            <div className="text-[24px] mb-4 uppercase">
              {t('profile.menu.monsters')}
            </div>
            <div className="flex justify-between flex-wrap mb-4">
              <div className="text-[24px]">
                {t('countMonster', {
                  total: totalMonsterUser,
                })}
                {totalMonsterUser > 1 ? t('more') : ''}
              </div>
              {isMobile ? (
                <div className="flex items-center text-[16px] gap-2">
                  <Dropdown
                    className="flex items-center cursor-pointer sort__value"
                    menu={{
                      items: MONSTER_SORT_SALE_USER,
                      selectable: true,
                      onClick: handleChangeSort,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                      <div className="title">
                        {' '}
                        {t(`common.filter.sort.${sort?.label}`)}
                      </div>
                    </Button>
                  </Dropdown>

                  <Button
                    className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                    onClick={() => {
                      handleChangeTypeSort()
                    }}
                  >
                    <ArrowUpOutlined
                      style={{ fontSize: 18 }}
                      rotate={typeSort === '1' ? 0 : 180}
                    />
                  </Button>
                  <div>
                    <Button
                      type="primary"
                      onClick={showDrawer}
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                    >
                      <FilterOutlined />
                      {t('filter')}
                    </Button>
                    <Drawer
                      title={t('filter')}
                      width={330}
                      placement="left"
                      onClose={onClose}
                      open={openFilterMonster}
                      extra={
                        <div
                          className="text-[16px] text-[#FAAD14] cursor-pointer"
                          onClick={() => {
                            setRankTagFilter([])
                            setMainSeedFilter([])
                            setSubSeedFilter([])
                            setBasicParameter({
                              HP: [],
                              STR: [],
                              INT: [],
                              DEX: [],
                              AGI: [],
                              VIT: [],
                            })
                            setGrowthPotential({
                              HP: [],
                              STR: [],
                              INT: [],
                              DEX: [],
                              AGI: [],
                              VIT: [],
                            })
                            setGrowthType([])
                            setValueLifeSpan([0, 0])
                            setTerrainCompatibility([])
                            setSkillType([])
                            setSpecialtyElement([])
                            setPersonality([])
                            setInnateTraitsByEffect([])
                            setAcquiredTraitsByEffect([])
                          }}
                        >
                          {t('clearAll')}
                        </div>
                      }
                    >
                      <div className="flex w-full h-full">
                        <FilterMonster
                          className="filter_profile"
                          rankTagFilter={rankTagFilter}
                          mainSeedFilter={mainSeedFilter}
                          subSeedFilter={subSeedFilter}
                          basicParameter={basicParameter}
                          growthPotential={growthPotential}
                          growthType={growthType}
                          valueLifeSpan={valueLifeSpan}
                          terrainCompatibility={terrainCompatibility}
                          skillType={skillType}
                          specialtyElement={specialtyElement}
                          personality={personality}
                          innateTraitsByEffect={innateTraitsByEffect}
                          acquiredTraitsByEffect={acquiredTraitsByEffect}
                          setRankTagFilter={setRankTagFilter}
                          setMainSeedFilter={setMainSeedFilter}
                          setSubSeedFilter={setSubSeedFilter}
                          setBasicParameter={setBasicParameter}
                          setGrowthPotential={setGrowthPotential}
                          setGrowthType={setGrowthType}
                          setValueLifeSpan={setValueLifeSpan}
                          setTerrainCompatibility={setTerrainCompatibility}
                          setSkillType={setSkillType}
                          setSpecialtyElement={setSpecialtyElement}
                          setPersonality={setPersonality}
                          setInnateTraitsByEffect={setInnateTraitsByEffect}
                          setAcquiredTraitsByEffect={setAcquiredTraitsByEffect}
                          classCustom="filter__profile"
                        />
                      </div>
                    </Drawer>
                  </div>
                </div>
              ) : (
                <div className="flex items-center text-[16px] gap-2">
                  <div> {t('sortBy')}</div>

                  <Dropdown
                    className="flex items-center cursor-pointer ml-3  sort__value"
                    menu={{
                      items: MONSTER_SORT_SALE_USER,
                      selectable: true,
                      onClick: handleChangeSort,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                      <div className="title">
                        {' '}
                        {t(`common.filter.sort.${sort?.label}`)}
                      </div>
                    </Button>
                  </Dropdown>

                  <Button
                    className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                    onClick={() => {
                      handleChangeTypeSort()
                    }}
                  >
                    <ArrowUpOutlined
                      style={{ fontSize: 18 }}
                      rotate={typeSort === '1' ? 0 : 180}
                    />
                  </Button>
                </div>
              )}
            </div>

            <Spin spinning={isLoading}>
              <div className="flex flex-wrap justify-center gap-4">
                {allMonsterUser?.map((data, i) => (
                  <MonstersCard
                    key={i?.toString()}
                    data={data}
                    button={{
                      title: 'Cancel',
                      functionButton: () => {
                        setNftActive(data)
                        showModalCancelSell()
                      },
                    }}
                    tema={allTEMAs}
                  />
                ))}
              </div>
            </Spin>
            {!isLoading && totalMonsterUser === 0 && <NodataMonster />}

            {totalMonsterUser > 0 && (
              <div className="pagination__common">
                <Pagination
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                  className="mt-5"
                  size="small"
                  locale={{ jump_to: 'Go to', page: '' }}
                  total={totalMonsterUser}
                  defaultCurrent={page}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                  showTitle
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <CancelSell
        action={{
          isModalOpen: isModalOpen,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={`Monster(#${nftActive?.monster_nft_id})`}
        loadingModal={isLoadingModal}
        // name={'Monster'}
        // tema={tema}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        name={`Monster(#${nftActive?.monster_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
    </BodyProfile>
  )
}
export default MonsterSale
