import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Pagination, Row, Spin } from 'antd'
import configs from '~/configs'
import { ParameterFarmShop } from '~/views/app/Farm/FarnDetail/components/ParameterFarmShop'
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons'
import { getImgByFarmTerrain } from '~/helpers/getValueByFarmTerrain'
import { SharedOnTwitter } from '../shared-components/shared-twitter'
export default function TransactionCompletedShop(props) {
  const { action, t, name, txHash, loadingModal, apiLoading, dataBuyFarm } =
    props
  const [selectedCard, setSelectedCard] = useState(null)
  const [dataFarmResult, setDataFarmResult] = useState({})
  // const [dataFarmResultParameter, setDataFarmResultParameter] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 6
  const handleChangePage = (page) => {
    setCurrentPage(page)
  }
  useEffect(() => {
    if (dataBuyFarm?.length < 1) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [dataBuyFarm])
  let link = `${configs.NETWORK.scan}tx/${txHash}`
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#1890FF',
    width: '160px',
    height: '44px',
    borderRadius: '4px',
  }
  const okButtonStyleBack = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#1890FF',
    width: '90px',
    height: '44px',
    borderRadius: '4px',
  }
  const hashStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '50%',
    color: '#40A9FF',
    marginLeft: '100px',
  }
  // const dataBuyFarm = [
  //   {
  //     _id: '6538ac6f7821658b178f1bea',
  //     crt_by: '0x48C067bBA30256384c2e3194d282b70bf86C9226',
  //     crt_dt: '2023-10-25T05:49:35.752Z',
  //     ipaddress: '10.0.1.7',
  //     player_id: '64e4760a39e38ce033827776',
  //     farm_nft_id: 4,
  //     farm_name: 'Forest',
  //     is_farm_favorite: true,
  //     is_farm_setted: false,
  //     farm_trait_id: 'HP_Plus',
  //     contain_monster_id: '',
  //     farm_terrain: 2,
  //     farm_basic_enhance_value: {
  //       health: 37,
  //       strength: 31,
  //       intelligent: 29,
  //       dexterity: 23,
  //       agility: 23,
  //       vitality: 27,
  //     },
  //     farm_max_enhance_value: {
  //       health: 93,
  //       strength: 90,
  //       intelligent: 95,
  //       dexterity: 87,
  //       agility: 77,
  //       vitality: 86,
  //     },
  //     special_training_value: {
  //       health: 12,
  //       strength: 6,
  //       intelligent: 0,
  //       dexterity: 0,
  //       agility: 0,
  //       vitality: -6,
  //     },
  //     farm_stress_enhance_value: 28,
  //     farm_fatigue_enhance_value: 26,
  //     farm_rest_enhance_value: 12,
  //     farm_max_stress_enhance_value: 62,
  //     farm_max_fatigue_enhance_value: 64,
  //     farm_max_rest_enhance_value: 84,
  //     farm_placement_position: -1,
  //     is_free_farm: false,
  //   },
  //   {
  //     _id: '6538ac717821658b178f1c10',
  //     crt_by: '0x48C067bBA30256384c2e3194d282b70bf86C9226',
  //     crt_dt: '2023-10-25T05:49:37.009Z',
  //     ipaddress: '10.0.1.7',
  //     player_id: '64e4760a39e38ce033827776',
  //     farm_nft_id: 3,
  //     farm_name: 'Forest',
  //     is_farm_favorite: true,
  //     is_farm_setted: false,
  //     farm_trait_id: 'None',
  //     contain_monster_id: '',
  //     farm_terrain: 2,
  //     farm_basic_enhance_value: {
  //       health: 32,
  //       strength: 24,
  //       intelligent: 38,
  //       dexterity: 23,
  //       agility: 26,
  //       vitality: 20,
  //     },
  //     farm_max_enhance_value: {
  //       health: 94,
  //       strength: 90,
  //       intelligent: 86,
  //       dexterity: 99,
  //       agility: 89,
  //       vitality: 81,
  //     },
  //     special_training_value: {
  //       health: 12,
  //       strength: 6,
  //       intelligent: 0,
  //       dexterity: 0,
  //       agility: 0,
  //       vitality: -6,
  //     },
  //     farm_stress_enhance_value: 14,
  //     farm_fatigue_enhance_value: 23,
  //     farm_rest_enhance_value: 24,
  //     farm_max_stress_enhance_value: 91,
  //     farm_max_fatigue_enhance_value: 92,
  //     farm_max_rest_enhance_value: 71,
  //     farm_placement_position: -1,
  //     is_free_farm: false,
  //   },
  //   {
  //     _id: '6538ac717821658b178f1c10',
  //     crt_by: '0x48C067bBA30256384c2e3194d282b70bf86C9226',
  //     crt_dt: '2023-10-25T05:49:37.009Z',
  //     ipaddress: '10.0.1.7',
  //     player_id: '64e4760a39e38ce033827776',
  //     farm_nft_id: 3,
  //     farm_name: 'Forest',
  //     is_farm_favorite: true,
  //     is_farm_setted: false,
  //     farm_trait_id: 'None',
  //     contain_monster_id: '',
  //     farm_terrain: 2,
  //     farm_basic_enhance_value: {
  //       health: 32,
  //       strength: 24,
  //       intelligent: 38,
  //       dexterity: 23,
  //       agility: 26,
  //       vitality: 20,
  //     },
  //     farm_max_enhance_value: {
  //       health: 94,
  //       strength: 90,
  //       intelligent: 86,
  //       dexterity: 99,
  //       agility: 89,
  //       vitality: 81,
  //     },
  //     special_training_value: {
  //       health: 12,
  //       strength: 6,
  //       intelligent: 0,
  //       dexterity: 0,
  //       agility: 0,
  //       vitality: -6,
  //     },
  //     farm_stress_enhance_value: 14,
  //     farm_fatigue_enhance_value: 23,
  //     farm_rest_enhance_value: 24,
  //     farm_max_stress_enhance_value: 91,
  //     farm_max_fatigue_enhance_value: 92,
  //     farm_max_rest_enhance_value: 71,
  //     farm_placement_position: -1,
  //     is_free_farm: false,
  //   },
  //   {
  //     _id: '6538ac717821658b178f1c10',
  //     crt_by: '0x48C067bBA30256384c2e3194d282b70bf86C9226',
  //     crt_dt: '2023-10-25T05:49:37.009Z',
  //     ipaddress: '10.0.1.7',
  //     player_id: '64e4760a39e38ce033827776',
  //     farm_nft_id: 3,
  //     farm_name: 'Forest',
  //     is_farm_favorite: true,
  //     is_farm_setted: false,
  //     farm_trait_id: 'None',
  //     contain_monster_id: '',
  //     farm_terrain: 2,
  //     farm_basic_enhance_value: {
  //       health: 32,
  //       strength: 24,
  //       intelligent: 38,
  //       dexterity: 23,
  //       agility: 26,
  //       vitality: 20,
  //     },
  //     farm_max_enhance_value: {
  //       health: 94,
  //       strength: 90,
  //       intelligent: 86,
  //       dexterity: 99,
  //       agility: 89,
  //       vitality: 81,
  //     },
  //     special_training_value: {
  //       health: 12,
  //       strength: 6,
  //       intelligent: 0,
  //       dexterity: 0,
  //       agility: 0,
  //       vitality: -6,
  //     },
  //     farm_stress_enhance_value: 14,
  //     farm_fatigue_enhance_value: 23,
  //     farm_rest_enhance_value: 24,
  //     farm_max_stress_enhance_value: 91,
  //     farm_max_fatigue_enhance_value: 92,
  //     farm_max_rest_enhance_value: 71,
  //     farm_placement_position: -1,
  //     is_free_farm: false,
  //   },
  //   {
  //     _id: '6538ac717821658b178f1c10',
  //     crt_by: '0x48C067bBA30256384c2e3194d282b70bf86C9226',
  //     crt_dt: '2023-10-25T05:49:37.009Z',
  //     ipaddress: '10.0.1.7',
  //     player_id: '64e4760a39e38ce033827776',
  //     farm_nft_id: 3,
  //     farm_name: 'Forest',
  //     is_farm_favorite: true,
  //     is_farm_setted: false,
  //     farm_trait_id: 'None',
  //     contain_monster_id: '',
  //     farm_terrain: 2,
  //     farm_basic_enhance_value: {
  //       health: 32,
  //       strength: 24,
  //       intelligent: 38,
  //       dexterity: 23,
  //       agility: 26,
  //       vitality: 20,
  //     },
  //     farm_max_enhance_value: {
  //       health: 94,
  //       strength: 90,
  //       intelligent: 86,
  //       dexterity: 99,
  //       agility: 89,
  //       vitality: 81,
  //     },
  //     special_training_value: {
  //       health: 12,
  //       strength: 6,
  //       intelligent: 0,
  //       dexterity: 0,
  //       agility: 0,
  //       vitality: -6,
  //     },
  //     farm_stress_enhance_value: 14,
  //     farm_fatigue_enhance_value: 23,
  //     farm_rest_enhance_value: 24,
  //     farm_max_stress_enhance_value: 91,
  //     farm_max_fatigue_enhance_value: 92,
  //     farm_max_rest_enhance_value: 71,
  //     farm_placement_position: -1,
  //     is_free_farm: false,
  //   },
  // ]
  const handleChageRight = () => {
    if (selectedCard === null || selectedCard === dataBuyFarm?.length) {
      return
    }
    const nextCardIndex = selectedCard === null ? 0 : selectedCard
    setSelectedCard(nextCardIndex + 1)
    setDataFarmResult(dataBuyFarm[nextCardIndex])
  }

  const handleChageLeft = () => {
    if (selectedCard === null || selectedCard === 1) {
      return
    }
    const prevCardIndex = selectedCard - 2
    setSelectedCard(prevCardIndex + 1)
    setDataFarmResult(dataBuyFarm[prevCardIndex])
  }

  const currentDataFarmShopResult = dataBuyFarm?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  )
  const handleCancelWrapper = () => {
    setSelectedCard(null)
    setCurrentPage(1)
    action?.handleCancel()
  }
  return (
    <>
      {name === 'FARM' ? (
        <>
          <Modal
            centered
            title={
              <h3
                className="flex justify-center text-xl"
                style={{ fontWeight: '400' }}
              >
                {t('modal.transaction_completed_shop.title')}
              </h3>
            }
            width={800}
            open={action?.isModalOpen}
            // onCancel={action?.handleCancel}
            onCancel={handleCancelWrapper}
            footer={null}
          >
            <Row>
              <Col span={7}>
                <div className="flex justify-center ">
                  <img
                    className="w-[80px] h-[80px] object-contain"
                    src={`${process.env.PUBLIC_URL}/imgs/common/success.svg`}
                    alt=""
                  />
                </div>
              </Col>
              <Col span={17}>
                <div className="my-5 flex  font__M__plus text-[14px]">
                  {t('modal.transaction_completed_shop.description', {
                    name: name,
                  })}
                </div>
                <div className="my-5 flex font__M__plus text-[14px]">
                  <div>
                    {t('modal.transaction_completed_shop.transaction_hash')}
                  </div>
                  <a
                    style={hashStyle}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {txHash}
                  </a>
                </div>
              </Col>
            </Row>
            {selectedCard === null ? (
              <>
                <Spin spinning={isLoading} className="mt-[-7px]">
                  <div>
                    <div className="flex-container flex items-center justify-center flex-wrap">
                      {currentDataFarmShopResult.map((data, index) => (
                        <div
                          className="card__monster_farm w-[200px] h-[200px] cursor-pointer m-[10px]"
                          key={index}
                          onClick={() => {
                            const selectedCardIndex =
                              (currentPage - 1) * pageSize + index + 1
                            setSelectedCard(selectedCardIndex)
                            setDataFarmResult(data)
                          }}
                        >
                          <div className="relative flex items-center justify-center h-full">
                            <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
                              <div
                                className={`h-full flex flex-col p-[10px] text-[18px] bg-cover bg-no-repeat`}
                                style={{
                                  backgroundImage: `url(../imgs/farm/bgFarm/${getImgByFarmTerrain(
                                    data?.farm_terrain,
                                  )}.png)`,
                                }}
                              >
                                <div className="flex items-center">
                                  <div className="title__card">
                                    {data?.farm_name ?? 'Farm'}
                                  </div>
                                </div>
                                <div className="absolute left-[5%] top-[16%]">
                                  <div
                                    className="font__M__plus font-bold text-[12px] min-w-[48px] h-[24px] flex items-center justify-center"
                                    style={{
                                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                      borderRadius: 4,
                                    }}
                                  >
                                    {data ? `#${data?.farm_nft_id}` : ''}
                                  </div>
                                </div>
                                <div className="h-full flex items-center justify-center">
                                  <div className="relative">
                                    <img
                                      width={160}
                                      height={160}
                                      alt=""
                                      loading="lazy"
                                      className="relative card__monster_img w-[110px] h-[110px] object-contain ease-in-out duration-300 z-50"
                                      src={`../imgs/farm/${getImgByFarmTerrain(
                                        data?.farm_terrain,
                                      )}.png`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {dataBuyFarm?.length > pageSize && (
                    <div className="flex justify-end pt-[10px] pagination__common">
                      <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={dataBuyFarm?.length}
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </Spin>
                <div key="buttons" className="flex justify-center mt-[10px]">
                  {/* <Button
                    className="flex items-center justify-center bg-[#1890FF] w-[160px] h-[44px] mt-1"
                    type="primary"
                    key="ok"
                    onClick={() => action?.handleOk()}
                    style={okButtonStyle}
                    loading={loadingModal}
                  >
                    <img
                      className="w-[16px] h-[16px] mr-3"
                      src="../svg/twitter.svg"
                      alt=""
                    />
                    <div style={{ color: '#FFFFFF', fontSize: '16px' }}>
                      {t('modal.transaction_completed_shop.share_x')}
                    </div>
                  </Button> */}
                  <SharedOnTwitter
                    title={`${name}`}
                    okButtonStyle={okButtonStyle}
                    urlShareTwitter={`${txHash}\n${link}`}
                  />
                </div>
                <style jsx>{`
                  .flex-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                  }
                `}</style>
              </>
            ) : (
              <>
                <Row>
                  <Col span={10}>
                    <div className="flex justify-center">
                      <div className="card__monster w-[250px] h-[350px] cursor-pointer ">
                        <div className="relative flex items-center justify-center h-full">
                          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
                            <div
                              className={`h-full flex flex-col p-[10px] text-[18px] bg-cover	bg-no-repeat`}
                              style={{
                                backgroundImage: `url(../imgs/farm/bgFarm/${getImgByFarmTerrain(
                                  dataFarmResult?.farm_terrain,
                                )}.png)`,
                              }}
                            >
                              <div className="flex items-center ">
                                {dataFarmResult?.farm_name ?? 'Farm'}
                              </div>
                              <div className="absolute left-[5%] top-[16%]">
                                <div
                                  className="font__M__plus font-bold text-[12px] min-w-[48px] h-[24px] flex items-center justify-center "
                                  style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    borderRadius: 4,
                                  }}
                                >
                                  {dataFarmResult
                                    ? `#${dataFarmResult?.farm_nft_id}`
                                    : ''}
                                </div>
                              </div>
                              <div className="h-full flex items-center justify-center">
                                <div className="relative">
                                  <img
                                    width={160}
                                    height={160}
                                    alt=""
                                    loading="lazy"
                                    className="relative card__monster_img w-[160px] h-[160px] object-contain ease-in-out duration-300 z-50"
                                    src={`../imgs/farm/${getImgByFarmTerrain(
                                      dataFarmResult?.farm_terrain,
                                    )}.png`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div key="buttons" className="flex justify-center mt-[5px]">
                      {/* <Button
                        className="flex items-center justify-center bg-[#1890FF] w-[160px] h-[44px] mt-1"
                        type="primary"
                        key="ok"
                        onClick={() => action?.handleOk()}
                        style={okButtonStyle}
                        loading={loadingModal}
                      >
                        <img
                          className="w-[16px] h-[16px] mr-3"
                          src="../svg/twitter.svg"
                          alt=""
                        />
                        <div style={{ color: '#FFFFFF', fontSize: '16px' }}>
                          {t('modal.transaction_completed_shop.share_x')}
                        </div>
                      </Button> */}
                      <SharedOnTwitter
                        title={`${name}`}
                        okButtonStyle={okButtonStyle}
                        urlShareTwitter={`${txHash}\n${link}`}
                      />
                    </div>

                    <div className="flex justify-center ">
                      <CaretLeftFilled
                        style={{
                          fontSize: '45px',
                          color: selectedCard === 1 ? 'black' : '',
                        }}
                        onClick={handleChageLeft}
                      />
                      <div className="flex justify-center items-center mr-[20px] ml-[20px] text-[16px]">
                        {selectedCard}/{dataBuyFarm?.length}
                      </div>
                      <CaretRightFilled
                        style={{
                          fontSize: '45px',
                          color:
                            selectedCard === dataBuyFarm?.length ? 'black' : '',
                        }}
                        onClick={handleChageRight}
                      />
                    </div>
                    <div key="buttons" className="flex justify-center">
                      <Button
                        className="flex items-center justify-center bg-[#1890FF] w-[160px] h-[44px] mt-1"
                        type="primary"
                        key="ok"
                        onClick={() => setSelectedCard(null)}
                        style={okButtonStyleBack}
                        loading={loadingModal}
                      >
                        <div style={{ color: '#FFFFFF', fontSize: '16px' }}>
                          {t('modal.transaction_completed_shop.back')}
                        </div>
                      </Button>
                    </div>
                  </Col>

                  <Col span={14}>
                    <ParameterFarmShop t={t} data={dataFarmResult} />
                  </Col>
                </Row>
              </>
            )}
          </Modal>
        </>
      ) : (
        <>
          <Modal
            centered
            title={
              <h3
                className="flex justify-center text-xl"
                style={{ fontWeight: '400' }}
              >
                {t('modal.transaction_completed_shop.title')}
              </h3>
            }
            open={action?.isModalOpen}
            onCancel={action?.handleCancel}
            footer={[
              <div key="buttons" className="flex justify-center">
                {/* <Button
                  className="flex items-center justify-center bg-[#1890FF] w-[160px] h-[44px] mt-1"
                  type="primary"
                  key="ok"
                  onClick={() => action?.handleOk()}
                  style={okButtonStyle}
                  loading={loadingModal}
                >
                  <img
                    className="w-[16px] h-[16px] mr-3"
                    src="../svg/twitter.svg"
                    alt=""
                  />
                  <div style={{ color: '#FFFFFF', fontSize: '16px' }}>
                    {t('modal.transaction_completed_shop.share_x')}
                  </div>
                </Button> */}
                <SharedOnTwitter
                  title={`${name}`}
                  okButtonStyle={okButtonStyle}
                  urlShareTwitter={`${txHash}\n${link}`}
                />
              </div>,
            ]}
          >
            <div className="flex justify-center">
              <img
                className="w-[90px] h-[90px] object-contain"
                src={`${process.env.PUBLIC_URL}/imgs/common/success.svg`}
                alt=""
              />
            </div>

            <div className="my-5 flex justify-center font__M__plus text-[16px]">
              {t('modal.transaction_completed_shop.description', {
                name: name,
              })}
            </div>
            <div className="my-5 flex justify-between font__M__plus text-[16px]">
              <div>
                {t('modal.transaction_completed_shop.transaction_hash')}
              </div>
              <a
                style={hashStyle}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {txHash}
              </a>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}
