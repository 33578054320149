import React from 'react'
import { Modal } from 'antd'
import { useAuth } from '~/views/redux/hooks/useAuth'
import { utils } from 'ethers'
import { providerOAS } from '~/blockchain/oas'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useSDK } from '@metamask/sdk-react'

export default function SelectWallet(props) {
  const { sdk } = useSDK()
  const message = 'Login with Re.Monster'
  const { action, t } = props
  const { actions } = useAuth()
  const { actions: actionTheme } = useTheme()

  const handleConnectMetamask = async () => {
    action.handleCancel()
    sdk?.terminate()
    await sdk
      ?.connect()
      .then(async (accounts) => {
        let signature = await sdk.getProvider().request({
          method: 'personal_sign',
          params: [accounts[0], message],
        })

        let dataUser = {
          address_wallet: accounts[0],
          signature: signature,
          message: message,
          wallet: 'MW',
          is_market: true,
        }
        localStorage.setItem('wallet', 'MW')
        actions.login(dataUser, (res) => {
          actionTheme.updateInfoUser(res)
          // if(res?.player_language){
          //   actionTheme.onLocaleChange(res?.player_language)
          // }
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error)
      })
  }

  const handleConnectOAS = async () => {
    try {
      action.handleCancel()
      providerOAS
        .connect()
        .then(async () => {
          let address = providerOAS.accounts[0]
          const signature = await providerOAS.request({
            method: 'personal_sign',
            params: [utils.hexlify(utils.toUtf8Bytes(message)), address],
          })
          let dataUser = {
            address_wallet: address,
            signature: signature,
            message: message,
            wallet: 'OAS',
            is_market: true,
          }
          localStorage.setItem('wallet', 'OAS')
          actions.login(dataUser, (res) => {
            actionTheme.updateInfoUser(res)
            if (res?.player_language) {
              actionTheme.onLocaleChange(res?.player_language)
            }
          })
        })
        .catch((error) => {})
    } catch (error) {}
  }

  return (
    <>
      <Modal
        width={700}
        centered
        title={
          <h3 className="flex justify-center text-[20px] font-bold">
            {t('modal.connect.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[]}
      >
        <div className="flex justify-center gap-5 pt-10 flex flex-wrap gap-2 w-full mb-2">
          <div
            className="st-wallet rounded-[10px]"
            onClick={handleConnectMetamask}
          >
            <div className="w-[60px] h-[60px] bg-white flex justify-center items-center rounded-[8px]">
              <img
                className="w-[50px] h-[50px] object-contain"
                src="../imgs/logo/metamask.png"
                alt=""
              />
            </div>
            {t('modal.connect.metamask')}
          </div>
          <div
            className="st-wallet-1 rounded-[10px]"
            onClick={handleConnectOAS}
          >
            <div className="w-[60px] h-[60px] flex justify-center items-center rounded-[8px]">
              <img
                className="w-[100%] h-[100%] object-contain rounded-[8px]"
                src="../imgs/logo/oas-wallet.png"
                alt=""
              />
            </div>
            <div>
              <div>{t('modal.connect.oas')}</div>
              <div className="flex items-center gap-1">
                <img
                  className="h-[16px] object-contain"
                  src="../imgs/logo/wallet-connect.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
