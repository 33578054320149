export const getbgQuality = (quality) => {
  switch (quality) {
    case 'R':
      return 'linear-gradient(180deg, #230D26 0%, #663777 100%)'
    case 'UC':
      return 'linear-gradient(180deg, #261C0D 0%, #826E28 100%)'
    case 'C':
      return 'linear-gradient(180deg, #0D1426 0%, #375877 100%)'
    default:
      return 'linear-gradient(180deg, #0D1426 0%, #375877 100%)'
  }
}
