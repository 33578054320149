import { Card, Col, Row, Skeleton } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'

import BodyCommon from '~/components/shared-components/BodyCommon'
export const LoadingFarmDetail = () => {
  // eslint-disable-next-line
  const { t } = useTranslation()
  return (
    <BodyCommon>
      <div className="relative container__common mx-auto z-10  top-[84px]">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 pt-5 sm:pt-12">
          <div className="flex flex-col mb-3 sm:mb-0">
            <div className="relative card__monster_detail h-[530px] flex items-center justify-center">
              <div className="relative flex items-center justify-center w-[60%] h-[70%] max-w-[500px]">
                <Skeleton.Image
                  className=" flex items-center justify-center relative !w-[100%] !h-[100%]"
                  active={true}
                />
              </div>
            </div>

            <Card className="mt-3">
              <Row>
                <Col span={12} className="text-[16px]">
                  <div className="mb-3 font__M__plus font-bold">
                    {t('farmDetail.ownerAddress')}
                  </div>
                  <div className="mb-3 font__M__plus font-bold">
                    {t('farmDetail.contractAddress')}
                  </div>
                  <div className="font__M__plus font-bold">
                    {t('farmDetail.tokenID')}
                  </div>
                </Col>
                <Col span={12} className="text-end">
                  <div className="text-[#40a9ff] mb-3 font__M__plus font-medium">
                    <Skeleton.Button active={true} size={'small'} block />
                  </div>
                  <div className="mb-3 font__M__plus font-medium">
                    <Skeleton.Button active={true} size={'small'} block />
                  </div>
                  <div className="font__M__plus font-medium">
                    <Skeleton.Button active={true} size={'small'} block />
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="col-span-2">
            <div className="flex items-center">
              <div className="title__monster_detail">Loading...</div>
            </div>
            <Card className="mt-3 mb-8">
              <Row>
                <Col span={12} className="flex items-center">
                  <div className="relative z-10 flex items-center">
                    <img
                      className="mr-2"
                      src={'../imgs/coins/oasis.svg'}
                      alt=""
                      width={32}
                      height={32}
                    />
                    <div className="flex items-center">
                      <div className="flex text-[20px] sm:text-[32px] font-[700]">
                        <Skeleton.Button active={true} size={'small'} block />
                      </div>
                      <span className="text-[16px] sm:text-[20px] flex items-center">
                        <div className="px-3">~</div>{' '}
                        <Skeleton.Button active={true} size={'small'} block />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={12} className="text-end">
                  <Skeleton.Button
                    className="mb-1 !w-[15em]"
                    active={true}
                    size={'Large'}
                  />
                </Col>
              </Row>
            </Card>

            <Card className="w-full ">
              <Row className="gap-3">
                <Col span={11}>
                  <div className=" flex items-center justify-between text-[20px] w-full mb-3">
                    <div>{t('farmDetail.totalParameters')}</div>
                    <div className="font__M__plus font-bold">
                      {0}
                      /810
                    </div>
                  </div>
                </Col>
                <Col span={11}></Col>

                <Col span={11}>
                  <div className="text-[20px]  mb-3">
                    {t('farmDetail.parameters')}
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((data) => (
                      <div
                        key={data?.toString()}
                        className="bg-[#000F38] rounded-[5px] w-full h-[64px] border border-[#1890FF]"
                      >
                        <Row className="h-full">
                          <Col
                            span={24}
                            className="flex items-center justify-center"
                          >
                            <div className="w-[80%]">
                              <div className="flex flex-col h-full w-full items-center justify-center">
                                <Skeleton.Button
                                  className="mb-1"
                                  active={true}
                                  size={'small'}
                                  block
                                />
                                <Skeleton.Button
                                  active={true}
                                  size={'small'}
                                  block
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col span={11}>
                  <div className="grid grid-rows-2 gap-4 h-full">
                    <div className="">
                      <div className="text-[20px]  mb-3">
                        {t('farmDetail.specialTraining')}
                      </div>

                      <div className="bg-[#020f38] border border-[#2ca0ffcc] relative w-80% h-[auto] rounded-[5px] p-4">
                        <div className="grid grid-cols-4 gap-4">
                          {[1, 2, 3, 4]?.map((data) => (
                            <div
                              key={data?.toString()}
                              className="flex flex-col items-center"
                            >
                              <div className="flex items-center">
                                <Skeleton.Button
                                  className="mb-1"
                                  active={true}
                                  size={'large'}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-[20px]  mb-3">
                        {t('farmDetail.farmTrait')}
                      </div>

                      <Skeleton.Button
                        className="mb-1"
                        active={true}
                        size={'large'}
                        block
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </div>

        <div className="mt-5 mb-20"></div>
      </div>
    </BodyCommon>
  )
}

export default LoadingFarmDetail
