import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/gameApi'
import {
  verifyEmailSuccess,
  verifyEmailFailed,
  VERIFY_EMAIL_START,
} from '../../actions/accountProfile'

/**
 * send verify email api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const verifyEmailApi = (props) => {
  var uri = `/api/mail/verify`

  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doVerifyEmail(action) {
  try {
    const response = yield call(verifyEmailApi, action?.payload)
    yield put(verifyEmailSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }

  } catch (error) {
    yield put(verifyEmailFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch send verify email
 */
export default function* watchVerifyEmail() {
  yield takeLatest(VERIFY_EMAIL_START, doVerifyEmail)
}
