import {
  GET_ALL_GENERAL_USER_START,
  GET_ALL_GENERAL_USER_SUCCESS,
  GET_ALL_GENERAL_USER_FAILED,
  GET_ALL_GENERAL_SALE_USER_START,
  GET_ALL_GENERAL_SALE_USER_SUCCESS,
  GET_ALL_GENERAL_SALE_USER_FAILED,
} from '../actions/general'

const initialState = {
  isLoading: false,
  allGeneralUser: [],
  totalGeneralBoxUser: 0,
  totalGeneralHashUser: 0,
  totalGeneralUser: 0
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function generalManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GENERAL_SALE_USER_START:
    case GET_ALL_GENERAL_USER_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ALL_GENERAL_USER_SUCCESS:
      return {
        ...state,
        allGeneralUser: action?.payload?.items,
        totalGeneralBoxUser: action?.payload?.totalBox,
        totalGeneralHashUser: action?.payload?.totalHash,
        totalGeneralUser: action?.payload?.count,
        isLoading: false,
      }

    case GET_ALL_GENERAL_SALE_USER_SUCCESS:
      return {
        ...state,
        allGeneralUser: action?.payload?.items,
        totalGeneralHashUser: action?.payload?.count,
        isLoading: false,
      }

    case GET_ALL_GENERAL_SALE_USER_FAILED:
    case GET_ALL_GENERAL_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
