export const GET_ALL_GENESIS_USER_START = 'GET_ALL_GENESIS_USER_START'
export const GET_ALL_GENESIS_USER_SUCCESS = 'GET_ALL_GENESIS_USER_SUCCESS'
export const GET_ALL_GENESIS_USER_FAILED = 'GET_ALL_GENESIS_USER_FAILED'

export const GET_ALL_GENESIS_SALE_USER_START = 'GET_ALL_GENESIS_SALE_USER_START'
export const GET_ALL_GENESIS_SALE_USER_SUCCESS = 'GET_ALL_GENESIS_SALE_USER_SUCCESS'
export const GET_ALL_GENESIS_SALE_USER_FAILED = 'GET_ALL_GENESIS_SALE_USER_FAILED'
//-------------------------Genesis------------------------//

/**
 * [1]
 * get all genesis user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllGenesisUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_GENESIS_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all genesis user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllGenesisUserSuccess(payload) {
  return {
    type: GET_ALL_GENESIS_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all genesis user failed action
 * @returns {object}
 */
export function getAllGenesisUserFailed() {
  return {
    type: GET_ALL_GENESIS_USER_FAILED,
  }
}

/**
 * [2]
 * get all genesis sale user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllGenesisSaleUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_GENESIS_SALE_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all genesis sale user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllGenesisSaleUserSuccess(payload) {
  return {
    type: GET_ALL_GENESIS_SALE_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all genesis sale user failed action
 * @returns {object}
 */
export function getAllGenesisSaleUserFailed() {
  return {
    type: GET_ALL_GENESIS_SALE_USER_FAILED,
  }
}


export default {
  getAllGenesisUser,
  getAllGenesisUserSuccess,
  getAllGenesisUserFailed,

  getAllGenesisSaleUser,
  getAllGenesisSaleUserSuccess,
  getAllGenesisSaleUserFailed
}
