import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getAllMonsterHashSuccess,
  getAllMonsterHashFailed,
  GET_ALL_MONSTER_HASH_START,
} from '../../actions/monster'

/**
 * get list monster hash
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListMonsterHashApi = (props) => {
  var uri = `/monsters/hash`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListMonsterHash(action) {
  try {
    const response = yield call(getListMonsterHashApi, action?.payload)
    yield put(getAllMonsterHashSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getAllMonsterHashFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list monster hash
 */
export default function* watchGetListMonsterHash() {
  yield takeLatest(GET_ALL_MONSTER_HASH_START, doGetListMonsterHash)
}
