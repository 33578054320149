import { convertPrice, formatNumber } from '~/helpers/common'
import { ReMonsterTwoBorderSm } from '../../../../../components/shared-components/Button/ReMonsterTwoBorderSm'
import { useState } from 'react'
import { useAccount } from '~/common/hooks/useAccount'
import SelectWallet from '~/components/Modals/SelectWallet'
import { getbgQuality } from '~/helpers/quality'
import CheckoutShopItem from '~/components/Modals/CheckoutShopItem'
import { ReMonsterTwoBorderSmDelete } from '~/components/shared-components/Button/ReMonsterTwoBorderSmDelete'
import { Col, Popover, Row } from 'antd'
import { getListValueItemShopItem } from '~/helpers/ItemName'

const ShopItemCardTemp = (props) => {
  const { t, loadingModal, handleConfirm, item, tema } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user } = useAccount()
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpen(true)
  }

  const handleOk = (item, quantity) => {
    handleConfirm(item, quantity, handleCancel)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className="card__monster w-[260px] h-[360px]">
      <div className="relative flex items-center justify-center h-full">
        <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
          <div
            className="h-full flex flex-col p-[10px] text-[18px]"
            style={{
              background: getbgQuality(item?.quality),
            }}
          >
            <div className="flex items-center">
              <img
                className="mr-2 w-[32px] h-[32px] object-contain"
                src={`../svg/qualityTag/SHOP.svg`}
                alt=""
              />

              <div className="title__shop_card text-[14px] ">{item?.name}</div>
            </div>
            <Popover
              overlayStyle={{
                width: '250px',
                marginBottom: '-120px !important',
              }}
              overlayClassName="custom-popover"
              trigger="hover"
              content={
                <div className="mb-5">
                  {getListValueItemShopItem(item?.name).map((item, index) => (
                    <Row key={index} className="mb-2">
                      <Col span={20} className="font__M__plus">
                        {/* {item.value} */}
                        {t(`itemDetail.${item.value}`)}
                      </Col>
                      <Col
                        span={4}
                        className={`font__M__plus text-right ${
                          item.number > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                        }`}
                      >
                        {item.number}
                      </Col>
                    </Row>
                  ))}
                </div>
              }
            >
              <div className="h-full flex items-center justify-center">
                <img
                  alt=""
                  className="card__monster_img w-[160px] h-[160px] object-contain	ease-in-out duration-300"
                  src={item?.img}
                />
              </div>
            </Popover>
            <div className="text-[12px] flex w-full justify-between">
              <div>{t('available')}</div>
              <div>
                {item?.limitPerDay - item?.quantitySold + item?.quantityReSold}
              </div>
            </div>
          </div>

          <div className="h-[93px] bg-[#0D2758] flex flex-col items-center justify-center p-[10px]">
            <div className="mb-1 flex items-center">
              <img
                className="w-[16px] h-[16px] object-contain	"
                src="./imgs/coins/bit.svg"
                alt=""
              />
              <div className="relative whitespace-nowrap flex ml-2">
                {formatNumber(item?.price)}
              </div>
            </div>
            {item?.limitPerDay - item?.quantitySold + item?.quantityReSold >
            0 ? (
              <ReMonsterTwoBorderSm
                functionButton={() => showModalCheckout()}
              />
            ) : (
              <ReMonsterTwoBorderSmDelete />
            )}
          </div>
        </div>
      </div>
      <CheckoutShopItem
        action={{
          isModalOpen: isModalOpen,
          handleOk: handleOk,
          handleCancel: handleCancel,
        }}
        t={t}
        box={item}
        loadingModal={loadingModal}
        tema={1}
        avallable={
          item?.limitPerDay - item?.quantitySold + item?.quantityReSold
        }
        balance={user?.bit_balance}
        symbol={'BIT'}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </div>
  )
}

export default ShopItemCardTemp
