import {
  parameterData,
  rankTagData,
  rankData,
} from '~/views/app/Home/components/dataFilter'

export const boostParameterData = [
  {
    type: 'health',
    rank: rankData,
  },
  {
    type: 'strength',
    rank: rankData,
  },
  {
    type: 'intelligent',
    rank: rankData,
  },
  {
    type: 'dexterity',
    rank: rankData,
  },
  {
    type: 'agility',
    rank: rankData,
  },
  {
    type: 'vitality',
    rank: rankData,
  },
  {
    type: 'fatigue',
    rank: rankData,
  },
  {
    type: 'stress',
    rank: rankData,
  },
  {
    type: 'rest',
    rank: rankData,
  },
]

export const specialTrainningData = [
  {
    type: 'main_parameter',
    rank: parameterData,
  },
  {
    type: 'sub_parameter',
    rank: parameterData,
  },
  {
    type: 'decrement_parameter',
    rank: parameterData,
  },
]

export const FarmTraitData = [
  'None',
  'HP_Plus',
  'STR_Plus',
  'INT_Plus',
  'DEX_Plus',
  'AGI_Plus',
  'VIT_Plus',
  'HIT_Plus',
  'AVD_Plus',
  'CRI_Plus',
  'RES_Plus',
  'MND_Plus',
  'ST_Plus',
  'SPD_Plus',
  'Fire_RES_Plus',
  'Water_RES_Plus',
  'Wind_RES_Plus',
  'Earth_RES_Plus',
  'Slash_RES_Plus',
  'Blunt_RES_Plus',
  'Thrust_RES_Plus',
]
