import { Select } from 'antd'
import React, { useContext } from 'react'
import { filterOption } from './shared'
import { configContext } from './config'

export const AreaSelect = ({ optionProps, ...selectProps }) => {
  const { areas } = useContext(configContext)

  return (
      <Select
        style={{ width: 120, color: '#FFF' }}
        showSearch
        bordered={false}
        optionLabelProp="label"
        filterOption={filterOption}
        {...selectProps}
      >
        {areas.map((item, i) => {
          const key = `${item.name} ${item.phoneCode}`
          const fixedProps = {
            key,
            value: `${item.phoneCode}`,
            label: (
              <div className="flex items-center justify-center">
                <img
                  alt=""
                  className="w-[24px] h-[24px]"
                  src={`${
                    process.env.PUBLIC_URL
                  }/imgs/flags/24x24/${item?.short?.toLowerCase()}.png`}
                />
                <span className="ml-2 text-white">
                  ({`+${item?.phoneCode}`})
                </span>
              </div>
            ),
          }
          return (
            <Select.Option key={i?.toString()} {...optionProps} {...fixedProps}>
              <div className="flex items-center">
                <img
                  alt=""
                  className="w-[24px] h-[24px]"
                  src={`${
                    process.env.PUBLIC_URL
                  }/imgs/flags/24x24/${item?.short?.toLowerCase()}.png`}
                />
                <span className="ml-2 text-white">
                  ({`+${item?.phoneCode}`})
                </span>
              </div>
            </Select.Option>
          )
        })}
      </Select>
  )
}

export default AreaSelect
