import { Button, Form, Input } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { TwitterShareButton } from 'react-share'
import { useTranslation } from '~/common/hooks/useTranslation'

// const CLIENT_ID = '546c25a59c58ad7'
// const IMGUR_API_URL = 'https://api.imgur.com/3/image'

export const SharedOnTwitter = (props) => {
  const { urlShareTwitter, title, okButtonStyle } = props
  const { t } = useTranslation()
  // const [urlImage, setUrlImage] = useState(null)
  // const [form] = Form.useForm()

  // const handleSubmit = async (values) => {
  //   try {
  //     const response = await fetch(values.file)
  //     const blob = await response.blob()
  //     const file = new File([blob], values.file.split('/').pop(), {
  //       type: blob.type,
  //     })

  //     const formData = new FormData()
  //     formData.append('image', file)

  //     const res = await axios.post(IMGUR_API_URL, formData, {
  //       headers: {
  //         Authorization: `Client-ID ${CLIENT_ID}`,
  //       },
  //     })
  //     setUrlImage(`https://i.imgur.com/${res.data.data.id}`)
  //   } catch (error) {
  //     console.error('Error fetching image:', error)
  //   }
  // }

  // useEffect(() => {
  //   form.setFieldsValue({ file: urlShareTwitter })
  // }, [form, urlShareTwitter])
  // const okButtonStyle = {
  //   fontWeight: 'bold',
  //   padding: '10px',
  //   color: '#fff',
  //   backgroundColor: '#FAA52C',
  //   height: '48px',
  //   borderRadius: '4px',
  // }
  return (
    <>
      {/* <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
        requiredMark={false}
      >
        <Form.Item name="file" labelAlign="left">
          <Input />
        </Form.Item>

        <Button htmlType="submit">{t('modal.open_success.submit')}</Button>
      </Form> */}
      {urlShareTwitter && (
        <TwitterShareButton
          url={urlShareTwitter}
          title={title}
          hashtags={['ReMonster']}
          // className="Demo__some-network__share-button"
        >
          <Button type="primary" style={okButtonStyle}>
            {t('modal.open_success.share_x')}
          </Button>
        </TwitterShareButton>
      )}
    </>
  )
}
