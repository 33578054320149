import { ICON_LEFT_BUTTON, ICON_RIGHT_BUTTON } from '~/assets/svg/button'
import { ICON_COIN } from '~/assets/svg/coin'

export const ReMonsterTwoBorder = ({ icon, price }) => {
  return (
    <div className="flex items-center common__btn relative justify-center">
      <div className="relative flex common__btn_main">
        <div
          className="absolute z-20 left-[-6px] common__btn_left"
          style={{ color: '#69C0FF' }}
        >
          <ICON_LEFT_BUTTON fill="#18ACFF" />
        </div>

        <div
          className="absolute z-20 left-[-1px] common__sub_btn_left"
          style={{ color: '#40A9FF' }}
        >
          <ICON_LEFT_BUTTON />
        </div>
        <div
          className="relative z-10 min-w-[150px] !px-3 py-[12px] flex items-center justify-center common__btn_middle"
          style={{
            height: 'auto',
            clipPath:
              'polygon(93% 100%, 100% 77%, 100% 22%, 92% 0%, 8% 0%, 0% 24%, 0% 76%, 8% 100%) ',
          }}
        >
          {icon ? (
            <img
              src={icon}
              alt=""
              style={{ width: 20, height: 20, marginLeft: '6px' }}
            />
          ) : (
            <ICON_COIN style={{ width: 20, height: 20 }} />
          )}
          <div className="relative whitespace-nowrap flex ml-2 mr-[6px]">
            {price ?? '000.000 +'}
          </div>
        </div>
        <div
          className="absolute z-20 right-[-1px] common__sub_btn_right"
          style={{ color: '#40A9FF' }}
        >
          <ICON_RIGHT_BUTTON />
        </div>
        <div
          className="absolute z-20 right-[-6px] common__btn_right"
          style={{ color: '#69C0FF' }}
        >
          <ICON_RIGHT_BUTTON />
        </div>
      </div>
    </div>
  )
}
