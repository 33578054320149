export const ButtonPolygon = ({ text, onClick, block, active, shadownBtn, borderColor, bgColor, textCss, customSize, clipPathOut, clipPathIn }) => {
  return (
    <div
      className={`flex items-center common__btn_poloygon relative cursor-pointer ${block && 'w-full'}`}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <div className={`relative flex common__btn_main ${shadownBtn ?? ""}  ${block && 'w-full'}`}>
        <div
          className={`${clipPathOut ?? 'polygon__outside'} relative z-10 p-[12px] ${customSize ?? 'w-[112px] h-[43px]'}  flex items-center justify-center ${borderColor ?? 'bg-[#fff]'}
          `}
        >
          <div
            className={`${clipPathIn ?? 'polygon__inside'} flex items-center ${active && 'active__polygon'
              } ${bgColor ?? ''}`}
          >
            <div className={`relative whitespace-nowrap text-center w-full text-[16px] ${textCss}`}>
              {text ?? 'General'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
