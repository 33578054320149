import { combineReducers } from 'redux'
import App from '~/views/redux/reducers/index'
import Theme from '~/common/theme/redux/reducers'

const rootReducers = combineReducers({
  theme: Theme,
  app: App,
})

export default rootReducers
