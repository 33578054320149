import { useTranslation } from '~/common/hooks/useTranslation'
import * as animationData from '~/assets/animation/noDataMonster.json'
import Lottie from 'react-lottie'

export const NodataMonster = () => {
  const { t } = useTranslation()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-[400px] h-[400px] object-contain	">
        <Lottie options={defaultOptions} />
      </div>

      <div className="text-2xl font-bold mt-5">{t('noDataFound')}</div>
    </div>
  )
}
