import { Card, Col, Popover, Row } from 'antd'
import SkillDetailModal from '../../modals/SkillDetailModal'
import { useEffect, useState } from 'react'
import skillData from '~/assets/data/monster_skill.data.json'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import {
  getContentAcquiredTrait,
  getContentFarmTrait,
  getContentInnateTrait,
} from '~/helpers/get-content-trait'
import { converImageSkill, getColorRankSkill } from '~/common/constants'
import { ButtonPolygon } from '~/components/shared-components/Button/ButtonPolygon'
import dataSkillID from '~/assets/data/Skill_ID.json'

export const Battle2Monster = (props) => {
  const { t, monsterDetail } = props
  const {
    data: { locale },
  } = useTheme()
  const [isModalOpen, setIsModalOpen] = useState({
    isOpen: false,
    skillInfo: null,
    idSkill: null,
    skillIdMonster: null,
  })

  const showModal = (data, id, skill_id) => {
    setIsModalOpen({
      isOpen: true,
      skillInfo: data,
      idSkill: id,
      skillIdMonster: skill_id,
    })
  }

  const handleCancelModal = () => {
    setIsModalOpen({
      isOpen: false,
      skillInfo: null,
      idSkill: null,
      skillIdMonster: null,
    })
  }

  const [innate, setInnate] = useState([])
  const [acquired, setAcquired] = useState([])
  useEffect(() => {
    if (monsterDetail && monsterDetail.innate_trait_id_list) {
      const filteredListInnate = monsterDetail.innate_trait_id_list.filter(
        (data) => data?.innate_trait_id !== 'None',
      )

      setInnate(filteredListInnate)
    }

    if (monsterDetail && monsterDetail.monster_acquired_traits) {
      const filteredListAcquired = monsterDetail.monster_acquired_traits.filter(
        (data) => data?.trait_id !== 'None',
      )

      setAcquired(filteredListAcquired)
    }
  }, [monsterDetail])

  const getRank = (skillId, skillLevel, id) => {
    const skill = skillData[skillId?.slice(0, -8)]?.learning
    if (!skill) {
      return null
    }

    if (skillLevel === 0) {
      skillLevel = 1
    }
    return skill['Skill' + id]['lv.' + skillLevel]
  }

  const getSkillIDMonster = dataSkillID
    .filter(
      (item) => item.monsterScriptableId === monsterDetail?.monster_script_id,
    )
    .map((item) => item.monsterSkillId)

  return (
    <Card className="w-full">
      <div className="flex flex-col gap-4">
        <div className="">
          <div className="text-[24px]">{t('monsterDetail.battle2.skills')}</div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
            {monsterDetail?.skill_detail?.map((data, i) => {
              const skillId = getSkillIDMonster[i]
              return (
                <div
                  onClick={() => {
                    showModal(data, i + 1, skillId)
                  }}
                  key={i?.toString()}
                  className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-[70px] cursor-pointer"
                >
                  <Row gutter={10}>
                    <Col
                      span={6}
                      className="flex items-center relative "
                      style={{ flex: 0 }}
                    >
                      <div className="w-12 relative ">
                        <ButtonPolygon
                          clipPathOut="polygon__outside_imgSkill"
                          clipPathIn="polygon__inside_imgSkill"
                          borderColor={'bg-[#00C9FA]'}
                          textCss="flex items-center justify-center"
                          customSize={'w-[50px] h-[50px]'}
                          text={
                            <>
                              <div
                                className="absolute z-10 polygon__inside_rankSkill"
                                style={getColorRankSkill(
                                  getRank(
                                    converImageSkill(skillId),
                                    data?.skill_level,
                                    i + 1,
                                  ),
                                )}
                              >
                                {getRank(
                                  converImageSkill(skillId),
                                  data?.skill_level,
                                  i + 1,
                                )}
                              </div>
                              <img
                                className="w-12 h-12 relative"
                                src={`${
                                  process.env.REACT_APP_BASE_URL_IMAGE_SKILL
                                }/${converImageSkill(skillId)}.png`}
                                alt=""
                              />
                            </>
                          }
                        />
                      </div>
                    </Col>

                    <Col span={18} className="">
                      <div className="flex flex-col w-full h-full">
                        <div
                          className="text-[16px] truncate"
                          style={{
                            fontFamily: 'Rexliafree',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {skillData[converImageSkill(skillId)?.slice(0, -8)]
                            ?.skill_list[i]?.Name && locale === 'en'
                            ? skillData[
                                converImageSkill(skillId)?.slice(0, -8)
                              ]?.skill_list[i]?.Name.split('\n')[1]
                            : skillData[
                                converImageSkill(skillId)?.slice(0, -8)
                              ]?.skill_list[i]?.Name.split('\n')[0]}
                        </div>

                        <div style={{ fontFamily: 'Rexliafree' }}>
                          {`Lv. ${data?.skill_level}/3`}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })}
          </div>
        </div>

        <div className="">
          <div className="text-[24px]">{t('monsterDetail.battle2.traits')}</div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
            <div className="grid grid-cols-1 gap-3 innnate">
              <div className="text-[16px] font__M__plus font-bold ">
                {t('monsterDetail.battle2.innnate')}
              </div>

              {innate?.map(
                (data, i) =>
                  data?.innate_trait_id !== 'None' && (
                    <Popover
                      key={i?.toString()}
                      content={t(
                        `traits.${getContentInnateTrait(
                          data?.innate_trait_id,
                        )}`,
                      )}
                    >
                      <div
                        className={`bg-[#1d800e] border border-[#00BE09] p-[10px] rounded-[5px] h-[70px] ${
                          innate.length === 1 ? 'mb-[82px]' : ''
                        }  flex justify-between items-center`}
                      >
                        <div>
                          <div className="text-[16px] font__M__plus font-bold">
                            {t(`${data?.innate_trait_id}`)}
                          </div>
                        </div>
                      </div>
                    </Popover>
                  ),
              )}
              {innate.length === 0 && (
                <div className="bg-[#1d800e] border border-[#00BE09] p-[10px] rounded-[5px] h-[70px] mb-[82px]  flex justify-between items-center">
                  {t(`traits.None`)}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 gap-3">
              <div className="text-[16px] font__M__plus font-bold ">
                {t('monsterDetail.battle2.acquired')}
              </div>

              {acquired?.map(
                (data, i) =>
                  data?.trait_id !== 'None' && (
                    <Popover
                      key={i?.toString()}
                      content={t(
                        `traits.${getContentAcquiredTrait(data?.trait_id)}`,
                      )}
                    >
                      <div
                        key={i?.toString()}
                        className={`bg-[#ce5734] border border-[#E33700] p-[10px] rounded-[5px] h-[70px] ${
                          acquired.length === 1 ? 'mb-[82px]' : ''
                        } flex justify-between items-center`}
                      >
                        <div className="text-[16px] font__M__plus font-bold">
                          {t(`${data?.trait_id}`)}
                        </div>
                        <div className="text-[16px] font__M__plus font-bold">{`${data?.trait_level}/5`}</div>
                      </div>
                    </Popover>
                  ),
              )}
              {acquired?.length === 0 && (
                <div className=" bg-[#ce5734] border border-[#E33700] p-[10px] rounded-[5px] h-[70px] mb-[82px] flex justify-between items-center">
                  {t(`traits.None`)}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 gap-3">
              <div className="text-[16px] font__M__plus font-bold ">
                {t('monsterDetail.battle2.farm')}
              </div>
              {monsterDetail?.player_farm_list !== null ? (
                <Popover
                  content={t(
                    `traits.${getContentFarmTrait(
                      monsterDetail?.player_farm_list?.farm_trait_id,
                    )}`,
                  )}
                >
                  <div className="bg-[#1f60cb] border border-[#1B38D2] p-[10px] rounded-[5px] h-[70px] mb-[82px] flex justify-between items-center">
                    <div>
                      <div className="text-[16px] font__M__plus font-bold">
                        {t(`${monsterDetail?.player_farm_list?.farm_trait_id}`)}
                      </div>
                    </div>
                  </div>
                </Popover>
              ) : (
                <div className="bg-[#1f60cb] border border-[#1B38D2] p-[10px] rounded-[5px] h-[70px]  mb-[82px] flex justify-between items-center">
                  {t(`traits.None`)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <SkillDetailModal
        skillInfo={isModalOpen?.skillInfo}
        idSkill={isModalOpen?.idSkill}
        action={{
          isModalOpen: isModalOpen.isOpen,
          handleCancel: handleCancelModal,
        }}
        skillIdMonster={isModalOpen?.skillIdMonster}
        t={t}
      />
    </Card>
  )
}

export default Battle2Monster
