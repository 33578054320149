import React from 'react'
import { Form, Input } from 'antd'
import AreaSelect from './area-select'
import { useTranslation } from '~/common/hooks/useTranslation'
import { REGEX } from '~/helpers/regex'

export const CountryPhoneInput = ({
  defaultValue,
  onChange,
  selectProps = {},
  inputGroupProps,
  inline,
  className,
  playerInfo,
  disabled,
  form,
  checkReadonlyPhone,
  phoneValue,
  ...inputProps
}) => {
  const { value, onChangePhone } = inputProps
  const { t } = useTranslation()
  const commonProps = { disabled }
  // Custom number validation rule using a regular expression
  const validateNumber = (_, value) => {
    const numberPattern = REGEX.PHONE_NUMBER

    if (value && value?.indexOf('-') !== -1) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }
    if (value && value?.indexOf(' ') !== -1) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }
    if (value && !numberPattern.test(value)) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }

    if (value && value?.length > 10) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }

    return Promise.resolve()
  }

  const rules = {
    phone: [
      // {
      //   required: true,
      //   message: t('validate.required', {
      //     0: t('profile.accountSetting.personalInformation.phone'),
      //   }),
      // },
      { validator: validateNumber },
    ],
  }

  return (
    <div className="w-full relative">
      <Form.Item
        rules={rules?.phone}
        label={
          <span className="font__M__plus font-bold">
            {t('profile.accountSetting.personalInformation.phone')}
          </span>
        }
        name="player_phone"
        labelAlign="left"
      >
        <div className={`${checkReadonlyPhone ? 'ReadOnly' : ''}`}>
          <Input
            onChange={onChangePhone}
            {...inputProps}
            className={`${
              checkReadonlyPhone ? 'bg-[#283E79]' : ''
            } antd-country-phone-input pl-[120px]${
              className ? ` ${className}` : ''
            }`}
            value={phoneValue}
            readOnly={checkReadonlyPhone}
            suffix={
              <>
                {playerInfo?.phone_verified && (
                  <img
                    src={`${process.env.PUBLIC_URL}/imgs/icons/verify_success.png`}
                    alt=""
                  />
                )}
              </>
            }
          />
        </div>
      </Form.Item>
      <Form.Item
        name="country_code"
        className="absolute z-[100] h-[44px] top-[40px]"
        rules={rules?.code}
      >
        <AreaSelect
          onChange={onChangePhone}
          {...commonProps}
          {...selectProps}
        />
      </Form.Item>
    </div>
  )
}
