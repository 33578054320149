import React from 'react'
import { Button, Modal } from 'antd'
import { convertPrice, formatNumber } from '~/helpers/common'
export default function CheckoutModal(props) {
  const { action, t, box, loadingModal, balance, name } = props
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }

  return (
    <>
      <Modal
        key={box.type}
        centered
        title={
          <h3 className="flex justify-center text-xl font-bold">
            {t('modal.checkout.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            <Button
              key="cancel"
              onClick={action?.handleCancel}
              style={cancelButtonStyle}
            >
              {t('modal.checkout.cancel')}
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => action?.handleOk()}
              style={okButtonStyle}
              loading={loadingModal}
            >
              {t('modal.checkout.ok')}
            </Button>
          </div>,
        ]}
      >
        <div className="font__M__plus text-[16px]">
          <div>
            {t('modal.checkout.description', {
              name: box?.name ?? box?.monster_name ?? box?.farm_name ?? name,
            })}
            {/* {box?.type ? `(${box?.type})` : ''} */}
          </div>
          <div className="my-5 flex justify-between p-2 bg-white bg-opacity-10">
            <div>{t('modal.checkout.price')}</div>
            <div className="font-bold">{formatNumber(box?.price)} OAS</div>
          </div>
          <div className="flex justify-between pb-3">
            <div>{t('modal.checkout.your_balance')}</div>
            <div>{formatNumber(balance)} OAS</div>
          </div>
          <div className="flex justify-between font-bold">
            <div>{t('modal.checkout.total_price')}</div>
            <div>{formatNumber(box?.price)} OAS</div>
          </div>
        </div>
      </Modal>
    </>
  )
}
