import React, { useRef, useState } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { convertPrice, formatNumber } from '~/helpers/common'
export default function BridgeOAS(props) {
  const {
    action,
    t,
    title,
    textConfirm,
    textCancel,
    description,
    loadingModal,
    balance,
  } = props
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }
  const [form] = Form.useForm()
  const amount = useRef(null)
  const [amountOAS, setAmountOAS] = useState()

  const onChangeAmount = () => {
    const amountTemp = amount.current.input.value
    setAmountOAS(amountTemp)
  }
  const handelSell = async () => {
    if (loadingModal) return
    form.submit()
  }

  const handelMax = async () => {
    amount.current.input.value = balance
  }
  return (
    <>
      <Form form={form} preserve={false} onFinish={() => action?.handleOk()}>
        <Modal
          // key={box.type}
          centered
          title={
            <h3 className="flex justify-center text-xl font-bold">{title}</h3>
          }
          open={action?.isModalOpen}
          onCancel={action?.handleCancel}
          footer={[
            <div key="buttons" className="flex justify-center">
              <Button
                key="cancel"
                onClick={action?.handleCancel}
                style={cancelButtonStyle}
              >
                {textCancel}
              </Button>
              <Button
                key="ok"
                type="primary"
                onClick={handelSell}
                style={okButtonStyle}
                loading={loadingModal}
              >
                {textConfirm}
              </Button>
            </div>,
          ]}
        >
          <div className="font__M__plus">
            <div>{description}</div>
            <div className="flex justify-between mt-5 mb-2">
              <div>
                {t('modal.deposit.your_balance')}: {formatNumber(balance)} OAS
              </div>
              <Button
                onClick={handelMax}
                className="flex justify-center items-center  w-[48px] h-[32px] text-[14px]"
              >
                {t('modal.deposit.max')}
              </Button>
            </div>
            <div>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: t('common.schema.required'),
                  },
                  () => ({
                    validator(rule, value) {
                      const numberPattern = /^-?\d+(\.\d+)?$/
                      if (value && !numberPattern.test(value)) {
                        return Promise.reject(t('common.schema.mustBeNumeric'))
                      }
                      if (value && Number(balance) < Number(value)) {
                        return Promise.reject(
                          t('common.schema.insufficient_balance'),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  autoComplete="off"
                  className="bg-white text-black h-[52px]"
                  onKeyUp={onChangeAmount}
                  placeholder={t('modal.deposit.placeholder')}
                  ref={amount}
                />
              </Form.Item>
            </div>
            <div className="pt-2 flex justify-between">
              <div>{t('modal.deposit.note')}</div>
            </div>
          </div>
        </Modal>
      </Form>
    </>
  )
}
