import { convertImageMonsterSeed } from '~/common/constants'

export const CardTree = (props) => {
  const { data } = props
  return (
    <div
      className={`flex items-center justify-center ${
        data?.seedTrees?.length === 1 && 'no__parr'
      }`}
    >
      <div className="releative w-[4rem] h-[4rem] md:w-[152px] md:h-[152px] border-4 border-[#1890ff] rounded-[8px] rotate-180">
        <img
          className="w-full h-full object-contain rounded-[3px]"
          alt=""
          src={`${
            process.env.PUBLIC_URL
          }/imgs/monsterSeed/${convertImageMonsterSeed(
            data?.monster_script_id,
          )}.png`}
          // src={`${
          //   process.env.REACT_APP_BASE_URL_IMAGE
          // }/public/image/monsters/${convertImageMonsterSeed(
          //   data?.monster_script_id,
          // )}.png`}
        />
        <div className="absolute top-0">
          <img
            className="w-[1.5rem] h-[1.5rem] sm:w-[40px] sm:h-[40px]"
            style={{ objectFit: 'contain' }}
            src={`../svg/rankTag/${data?.monster_rank}.svg`}
            alt=""
          />
        </div>

        <div className="group absolute bottom-1 sm:bottom-2	w-full flex items-center justify-center">
          <div
            className="polygon__outside relative z-10 w-[80%] h-[14px] sm:h-[30px] flex items-center justify-center bg-[#dafefe]"
            style={{
              clipPath:
                'polygon(93% 100%, 100% 77%, 100% 22%, 92% 0%, 8% 0%, 0% 24%, 0% 76%, 8% 100%) ',
            }}
          >
            <div
              className={`polygon__inside flex items-center bg-[#8bb2cf] group-hover:opacity-90	`}
            >
              <div className="relative whitespace-nowrap text-center w-full flex items-center justify-center">
                <div className="ml-1" style={{ fontFamily: 'Rexliafree' }}>
                  {data?.monster_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
