export const ICON_COIN = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g filter="url(#filter0_i_141_12090)">
        <path
          d="M11 19.4668C15.5046 19.4668 19.1562 15.8151 19.1562 11.3105C19.1562 6.80597 15.5046 3.1543 11 3.1543C6.49543 3.1543 2.84375 6.80597 2.84375 11.3105C2.84375 15.8151 6.49543 19.4668 11 19.4668Z"
          fill="url(#paint0_linear_141_12090)"
        />
      </g>
      <g filter="url(#filter1_iiii_141_12090)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9999 21.7872C16.7862 21.7872 21.4767 17.0967 21.4767 11.3105C21.4767 5.52424 16.7862 0.83374 10.9999 0.83374C5.21369 0.83374 0.523193 5.52499 0.523193 11.3112C0.523193 17.0967 5.21369 21.7872 10.9999 21.7872ZM10.9999 19.4667C15.5044 19.4667 19.1562 15.815 19.1562 11.3105C19.1562 6.80599 15.5044 3.15499 10.9999 3.15499C6.49544 3.15499 2.84369 6.80599 2.84369 11.3105C2.84369 15.815 6.49544 19.4675 10.9999 19.4675V19.4667Z"
          fill="url(#paint1_linear_141_12090)"
        />
      </g>
      <g filter="url(#filter2_f_141_12090)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.43467 7.48621C6.37069 7.52689 6.31804 7.58308 6.28159 7.64956C6.24514 7.71603 6.22607 7.79064 6.22617 7.86646V8.07646C6.22619 8.16784 6.25959 8.25607 6.32008 8.32457C6.38057 8.39307 6.46399 8.43712 6.55467 8.44846V14.1462H6.47367C6.35747 14.1463 6.24459 14.1849 6.15271 14.256C6.06083 14.3272 5.99513 14.4267 5.96592 14.5392L5.73342 15.4287C5.71897 15.4842 5.71744 15.5422 5.72892 15.5983C5.7404 15.6545 5.76459 15.7072 5.79964 15.7526C5.8347 15.7979 5.87967 15.8346 5.93112 15.8599C5.98256 15.8851 6.03911 15.8982 6.09642 15.8982H15.4339C15.4903 15.8983 15.5461 15.8856 15.5969 15.8612C15.6478 15.8367 15.6925 15.8011 15.7276 15.757C15.7628 15.7129 15.7876 15.6614 15.8001 15.6064C15.8126 15.5513 15.8126 15.4942 15.7999 15.4392L15.5959 14.5535C15.5693 14.4376 15.5041 14.3342 15.411 14.2603C15.318 14.1863 15.2025 14.1461 15.0837 14.1462H15.0034V8.44621C15.0915 8.43229 15.1718 8.38742 15.2298 8.31965C15.2878 8.25189 15.3198 8.16566 15.3199 8.07646V7.86646C15.3201 7.79071 15.3011 7.71615 15.2648 7.64968C15.2285 7.58321 15.176 7.52698 15.1122 7.48621L11.0749 4.91746C10.9847 4.86007 10.88 4.82959 10.773 4.82959C10.6661 4.82959 10.5614 4.86007 10.4712 4.91746L6.43467 7.48696V7.48621ZM13.7967 14.1462V8.45146H12.5892V14.1462H13.7967ZM11.3824 14.1462V8.45146H10.1749V14.1462H11.3824ZM8.96817 14.1462V8.45146H7.76142V14.1462H8.96817Z"
          fill="#DC7B3C"
        />
      </g>
      <g filter="url(#filter3_ii_141_12090)">
        <path
          d="M6.46094 7.65503C6.46089 7.57941 6.4799 7.505 6.51621 7.43867C6.55252 7.37235 6.60496 7.31624 6.66869 7.27553L10.7059 4.70603C10.7962 4.64864 10.9009 4.61816 11.0078 4.61816C11.1147 4.61816 11.2195 4.64864 11.3097 4.70603L15.3462 7.27553C15.4101 7.31615 15.4626 7.37222 15.4991 7.43855C15.5355 7.50489 15.5547 7.57934 15.5547 7.65503V7.86503C15.5547 7.96449 15.5152 8.05987 15.4449 8.13019C15.3745 8.20052 15.2791 8.24003 15.1797 8.24003H6.83594C6.73648 8.24003 6.6411 8.20052 6.57077 8.13019C6.50045 8.05987 6.46094 7.96449 6.46094 7.86503V7.65503Z"
          fill="#FFC546"
        />
      </g>
      <path
        d="M6.46094 7.65503C6.46089 7.57941 6.4799 7.505 6.51621 7.43867C6.55252 7.37235 6.60496 7.31624 6.66869 7.27553L10.7059 4.70603C10.7962 4.64864 10.9009 4.61816 11.0078 4.61816C11.1147 4.61816 11.2195 4.64864 11.3097 4.70603L15.3462 7.27553C15.4101 7.31615 15.4626 7.37222 15.4991 7.43855C15.5355 7.50489 15.5547 7.57934 15.5547 7.65503V7.86503C15.5547 7.96449 15.5152 8.05987 15.4449 8.13019C15.3745 8.20052 15.2791 8.24003 15.1797 8.24003H6.83594C6.73648 8.24003 6.6411 8.20052 6.57077 8.13019C6.50045 8.05987 6.46094 7.96449 6.46094 7.86503V7.65503Z"
        fill="url(#paint2_linear_141_12090)"
      />
      <path
        d="M6.7887 8.08765C6.7887 8.03792 6.80845 7.99023 6.84361 7.95506C6.87878 7.9199 6.92647 7.90015 6.9762 7.90015H7.8087C7.85842 7.90015 7.90612 7.9199 7.94128 7.95506C7.97644 7.99023 7.9962 8.03792 7.9962 8.08765V14.0996H6.7887V8.08765Z"
        fill="url(#paint3_linear_141_12090)"
      />
      <path
        d="M6.7887 8.08765C6.7887 8.03792 6.80845 7.99023 6.84361 7.95506C6.87878 7.9199 6.92647 7.90015 6.9762 7.90015H7.8087C7.85842 7.90015 7.90612 7.9199 7.94128 7.95506C7.97644 7.99023 7.9962 8.03792 7.9962 8.08765V14.0996H6.7887V8.08765Z"
        fill="url(#paint4_linear_141_12090)"
      />
      <path
        d="M9.203 7.90015H10.4098V14.0996H9.203V7.90015Z"
        fill="url(#paint5_linear_141_12090)"
      />
      <path
        d="M9.203 7.90015H10.4098V14.0996H9.203V7.90015Z"
        fill="url(#paint6_linear_141_12090)"
      />
      <path
        d="M11.6172 7.90015H12.8239V14.0996H11.6172V7.90015Z"
        fill="url(#paint7_linear_141_12090)"
      />
      <path
        d="M11.6172 7.90015H12.8239V14.0996H11.6172V7.90015Z"
        fill="url(#paint8_linear_141_12090)"
      />
      <path
        d="M14.0306 7.90015H15.2374V14.0996H14.0306V7.90015Z"
        fill="url(#paint9_linear_141_12090)"
      />
      <path
        d="M14.0306 7.90015H15.2374V14.0996H14.0306V7.90015Z"
        fill="url(#paint10_linear_141_12090)"
      />
      <g filter="url(#filter4_ii_141_12090)">
        <path
          d="M6.19991 14.3278C6.22929 14.2154 6.29516 14.1158 6.38719 14.0449C6.47921 13.9739 6.59219 13.9354 6.70841 13.9355H15.3184C15.4372 13.9356 15.5524 13.9759 15.6453 14.0498C15.7382 14.1238 15.8033 14.2271 15.8299 14.3428L16.0347 15.2278C16.0474 15.2828 16.0476 15.3401 16.0351 15.3952C16.0227 15.4503 15.9979 15.5019 15.9627 15.5461C15.9275 15.5903 15.8828 15.626 15.8319 15.6505C15.781 15.675 15.7252 15.6877 15.6687 15.6875H6.33116C6.27385 15.6876 6.2173 15.6744 6.16586 15.6492C6.11441 15.624 6.06944 15.5873 6.03439 15.5419C5.99933 15.4966 5.97514 15.4438 5.96366 15.3877C5.95218 15.3315 5.95372 15.2735 5.96816 15.218L6.19991 14.3278Z"
          fill="#FFC248"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_141_12090"
          x="2.65625"
          y="3.1543"
          width="16.5"
          height="16.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.1875" dy="0.1875" />
          <feGaussianBlur stdDeviation="0.375" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.831373 0 0 0 0 0.45098 0 0 0 0 0.196078 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_141_12090"
          />
        </filter>
        <filter
          id="filter1_iiii_141_12090"
          x="0.298193"
          y="0.60874"
          width="21.4035"
          height="21.4036"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.225" dy="0.225" />
          <feGaussianBlur stdDeviation="0.225" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.886275 0 0 0 0 0.662745 0 0 0 0 0.235294 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_141_12090"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.225" dy="-0.225" />
          <feGaussianBlur stdDeviation="0.225" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.956863 0 0 0 0 0.607843 0 0 0 0 0.294118 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_141_12090"
            result="effect2_innerShadow_141_12090"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.225" />
          <feGaussianBlur stdDeviation="0.225" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.956863 0 0 0 0 0.607843 0 0 0 0 0.294118 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_141_12090"
            result="effect3_innerShadow_141_12090"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.225" dy="0.225" />
          <feGaussianBlur stdDeviation="0.225" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.337255 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_141_12090"
            result="effect4_innerShadow_141_12090"
          />
        </filter>
        <filter
          id="filter2_f_141_12090"
          x="5.27131"
          y="4.37959"
          width="10.9881"
          height="11.9686"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.225"
            result="effect1_foregroundBlur_141_12090"
          />
        </filter>
        <filter
          id="filter3_ii_141_12090"
          x="6.46094"
          y="4.43066"
          width="9.20625"
          height="3.99683"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.1875" />
          <feGaussianBlur stdDeviation="0.1875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.980392 0 0 0 0 0.647059 0 0 0 0 0.172549 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_141_12090"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.1125" dy="0.1875" />
          <feGaussianBlur stdDeviation="0.1875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.980392 0 0 0 0 0.647059 0 0 0 0 0.172549 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_141_12090"
            result="effect2_innerShadow_141_12090"
          />
        </filter>
        <filter
          id="filter4_ii_141_12090"
          x="5.76855"
          y="13.748"
          width="10.4633"
          height="2.12695"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.1875" dy="-0.1875" />
          <feGaussianBlur stdDeviation="0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.992157 0 0 0 0 0.658824 0 0 0 0 0.0705882 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_141_12090"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.1875" dy="0.1875" />
          <feGaussianBlur stdDeviation="0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.8 0 0 0 0 0.290196 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_141_12090"
            result="effect2_innerShadow_141_12090"
          />
        </filter>
        <linearGradient
          id="paint0_linear_141_12090"
          x1="7.67225"
          y1="15.389"
          x2="16.4848"
          y2="4.2793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F19D48" />
          <stop offset="1" stopColor="#E89A44" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_141_12090"
          x1="10.9999"
          y1="0.83374"
          x2="10.9999"
          y2="21.7872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#FFC24B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_141_12090"
          x1="13.4847"
          y1="5.96678"
          x2="13.1794"
          y2="6.42953"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.459" stopColor="#FFCA3F" />
          <stop offset="1" stopColor="#FFCA3F" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_141_12090"
          x1="6.8982"
          y1="10.2941"
          x2="7.4847"
          y2="10.2941"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.167" stopColor="#FFB33B" />
          <stop offset="1" stopColor="#FFC047" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_141_12090"
          x1="7.9962"
          y1="11.5144"
          x2="7.7067"
          y2="11.5144"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.067" stopColor="#FFC34C" />
          <stop offset="1" stopColor="#FFC34C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_141_12090"
          x1="9.3125"
          y1="10.2941"
          x2="9.89825"
          y2="10.2941"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.167" stopColor="#FFB33B" />
          <stop offset="1" stopColor="#FFC047" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_141_12090"
          x1="10.4098"
          y1="11.5144"
          x2="10.121"
          y2="11.5144"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.067" stopColor="#FFC34C" />
          <stop offset="1" stopColor="#FFC34C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_141_12090"
          x1="11.7267"
          y1="10.2941"
          x2="12.3124"
          y2="10.2941"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.167" stopColor="#FFB33B" />
          <stop offset="1" stopColor="#FFC047" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_141_12090"
          x1="12.8239"
          y1="11.5144"
          x2="12.5352"
          y2="11.5144"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.067" stopColor="#FFC34C" />
          <stop offset="1" stopColor="#FFC34C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_141_12090"
          x1="14.1409"
          y1="10.2941"
          x2="14.7266"
          y2="10.2941"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.167" stopColor="#FFB33B" />
          <stop offset="1" stopColor="#FFC047" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_141_12090"
          x1="15.2381"
          y1="11.5144"
          x2="14.9494"
          y2="11.5144"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.067" stopColor="#FFC34C" />
          <stop offset="1" stopColor="#FFC34C" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
