import configs from '~/configs'
export const tradingHistoriesDataProfile = [
  {
    key: '1',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '2',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '3',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '4',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '5',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '6',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '7',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '8',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '9',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '10',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
  {
    key: '11',
    event: 'For Sale',
    assetType: 'Farm',
    tokenID: '1',
    time: '2023-09-11T07:50:15.000000Z',
    from: '0xkjf..jas74ns1',
    to: '0xkjf..jas74ns1',
    price: 20,
    txHash: '0xb27c40cf52f0821...',
    created_at: '2023-09-11T07:50:15.000000Z',
  },
]

export const eventData = ['OrderCreated', 'OrderSuccessful']

export const assetTypeData = [
  {
    name: 'monter',
    addressContract: configs.ADDRESS_MONSTER
  },
  {
    name: 'farm',
    addressContract: configs.ADDRESS_FARM
  },
  {
    name: 'genersisHash',
    addressContract: configs.ADDRESS_GENESIS
  },
  {
    name: 'generalHash',
    addressContract: configs.ADDRESS_GENERAL
  },
  {
    name: 'regenerationHash',
    addressContract: configs.ADDRESS_REGENERATION_ITEM
  },
  {
    name: 'fushionItem',
    addressContract: configs.ADDRESS_FUSION_ITEM
  },
  {
    name: 'trainingItem',
    addressContract: configs.ADDRESS_TRAINING_ITEM
  },
  {
    name: 'enhanceItem',
    addressContract: configs.ADDRESS_ENHANCE_ITEM
  }
]
