import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row } from 'antd'
import { convertPrice, formatNumber } from '~/helpers/common'
import { REGEX } from '~/helpers/regex'
import { ContractTrainingItem } from '~/blockchain/contract'
import { getListValueItemShopItem } from '~/helpers/ItemName'
export default function CheckoutShopItem(props) {
  const { action, t, box, loadingModal, tema, balance, symbol, avallable } =
    props
  const [form] = Form.useForm()
  const quanlity = useRef(null)
  const [totalPrice, setTotalPrice] = useState(0)
  const [detailItem, setDetailItem] = useState(null)
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }

  const _calulate = (price, maxSupply, currentSupply) => {
    if (currentSupply <= maxSupply * 0.2) {
      return price
    } else if (currentSupply > maxSupply * 0.8) {
      return price * 2
    } else {
      const result =
        price * (1 + (currentSupply - 0.2 * maxSupply) / (0.6 * maxSupply))
      return result
    }
  }

  const calulateTotal = (price, maxSupply, currentSupply, amount) => {
    let total = 0;
    for (let i = 0; i < amount; i++) {
      total +=  Math.floor(_calulate(price, maxSupply, currentSupply + i));
    }
    return total;
  };

  const onChangeQuantity = async (e) => {
    const quantity = e?.target.value
    if (quantity === '') {
      setTotalPrice(0)
    } else if (
      Number(quantity) === parseInt(quantity, 10) &&
      quantity <= avallable
    ) {
      const detailItem = await ContractTrainingItem.itemDetail(
        Number(box.id) - 3,
      )
      const currentSupply = Number(detailItem.totalAmount)
      const maxSupply = Number(detailItem.amountLimit)
      const totalPrice = calulateTotal(
        Number(box?.initPrice),
        maxSupply,
        currentSupply,
        Number(quantity),
      )
      setTotalPrice(Math.floor(totalPrice))
    }
  }

  useEffect(() => {
    if (action?.isModalOpen) {
      const fetchData = async () => {
        const detailItem = await ContractTrainingItem.itemDetail(
          Number(box.id) - 3,
        )
        setDetailItem(detailItem)

        const currentSupply = Number(detailItem.totalAmount)
        const maxSupply = Number(detailItem.amountLimit)
        const totalPrice = calulateTotal(
          Number(box?.initPrice),
          maxSupply,
          currentSupply,
          1,
        )
        setTotalPrice(totalPrice)
      }
      fetchData()
    }
  }, [action?.isModalOpen, box])

  const handleBuy = async () => {
    if (loadingModal) return
    form.submit()
  }

  useEffect(() => {
    if (loadingModal === false) {
      form.resetFields(['quantity'])
    }
  }, [loadingModal])

  const handleCancel = () => {
    action?.handleCancel()
    form.resetFields(['quantity'])
  }
  const onFinish = async () => {
    try {
      action?.handleOk(box, quanlity.current.input.value, () => {
        form.resetFields(['quantity'])
      })
    } catch (error) {}
  }
  const handleCloseModal = () => {
    form.resetFields(['quantity'])
    action?.handleCancel()
  }

  return (
    <>
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        initialValues={{ quantity: 1 }}
      >
        <Modal
          key={box.type}
          centered
          title={
            <h3 className="flex justify-center text-xl font-normal">
              {t('modal.checkout_shop.title')}
            </h3>
          }
          open={action?.isModalOpen}
          onCancel={handleCloseModal}
          footer={[
            <div key="buttons" className="flex justify-center">
              <Button
                key="cancel"
                onClick={handleCancel}
                style={cancelButtonStyle}
              >
                {t('modal.checkout_shop.cancel')}
              </Button>
              <Button
                key="ok"
                type="primary"
                onClick={handleBuy}
                style={okButtonStyle}
                loading={loadingModal}
              >
                {t('modal.checkout_shop.ok')}
              </Button>
            </div>,
          ]}
        >
          <div className="font__M__plus text-[16px]">
            <div className="my-5">
              {t('modal.checkout_shop.description', { name: box?.name })}
            </div>
            <div
              className="mb-5"
              style={{
                padding: '10px 20px',
                borderRadius: '8px',
                background: '#000F38',
              }}
            >
              {getListValueItemShopItem(box?.name).map((item, index) => (
                <Row key={index} className="mb-2">
                  <Col span={20} className="font__M__plus">
                    {t(`itemDetail.${item.value}`)}
                  </Col>
                  <Col
                    span={4}
                    className={`font__M__plus text-right ${
                      item.number > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
                    }`}
                  >
                    {item.number}
                  </Col>
                </Row>
              ))}
            </div>
            <div className="flex justify-between mb-5">
              <div className="">{t('modal.checkout_shop.quantiy')}</div>
              <div>
                {t('modal.sell_mkp.itemAvaiable', {
                  quanlityTotal: avallable,
                })}
              </div>
            </div>

            <div className="font__M__plus">
              <Form.Item
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: t('common.schema.required'),
                  },
                  () => ({
                    validator(rule, value) {
                      const notSpace = REGEX.NOT_WHITESPACE_START
                      if (value && !notSpace.test(value)) {
                        return Promise.reject(t('common.schema.mustBeNumeric'))
                      }
                      if (value && value <= 0) {
                        return Promise.reject(
                          t('common.schema.quantityNumeric'),
                        )
                      }

                      const integerPattern = /^-?\d+$/
                      if (value && !integerPattern.test(value)) {
                        return Promise.reject(t('common.schema.mustBeNumeric'))
                      }
                      const numberPattern = /^-?\d+(\.\d+)?$/

                      if (value && !numberPattern.test(value)) {
                        return Promise.reject(t('common.schema.mustBeNumeric'))
                      }
                      if (value && value > avallable) {
                        return Promise.reject(t('common.schema.totalQuantity'))
                      }
                      if (value && Number(balance) < Number(totalPrice)) {
                        return Promise.reject(
                          t('common.schema.insufficient_balance'),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  type="number"
                  maxLength={7}
                  autoComplete="off"
                  className="bg-white text-black h-[52px] font-bold"
                  onChange={onChangeQuantity}
                  placeholder={t('modal.checkout_shop.placeholder')}
                  ref={quanlity}
                  defaultValue={1}
                />
              </Form.Item>
            </div>
            <div className="flex justify-between">
              <div>{t('modal.checkout_shop.your_balance')}</div>
              <div>
                {formatNumber(balance)} {symbol ?? 'OAS'}
              </div>
            </div>
            <div className="my-5 flex justify-between font-bold">
              <div>{t('modal.checkout_shop.price')}</div>
              <div>
                {formatNumber(totalPrice)} {symbol ?? 'OAS'}
              </div>
            </div>
          </div>
        </Modal>
      </Form>
    </>
  )
}
