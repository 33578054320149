export const GET_ALL_REGENERATION_USER_START = 'GET_ALL_REGENERATION_USER_START'
export const GET_ALL_REGENERATION_USER_SUCCESS = 'GET_ALL_REGENERATION_USER_SUCCESS'
export const GET_ALL_REGENERATION_USER_FAILED = 'GET_ALL_REGENERATION_USER_FAILED'
//-------------------------Regeneration------------------------//

/**
 * [1]
 * get all Regeneration user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllRegenerationUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_REGENERATION_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all Regeneration user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllRegenerationUserSuccess(payload) {
  return {
    type: GET_ALL_REGENERATION_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all Regeneration user failed action
 * @returns {object}
 */
export function getAllRegenerationUserFailed() {
  return {
    type: GET_ALL_REGENERATION_USER_FAILED,
  }
}


export default {
  getAllRegenerationUser,
  getAllRegenerationUserSuccess,
  getAllRegenerationUserFailed
}
