import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getAllFarmSaleUserSuccess,
  getAllFarmSaleUserFailed,
  GET_ALL_FARM_SALE_USER_START,
} from '../../actions/farm'

/**
 * get list farm sale user
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListFarmSaleUserApi = (props) => {
  var uri = `/farms`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListFarmSaleUser(action) {
  try {
    const response = yield call(getListFarmSaleUserApi, action?.payload)
    yield put(getAllFarmSaleUserSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getAllFarmSaleUserFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list farm user
 */
export default function* watchGetListFarmSaleUser() {
  yield takeLatest(GET_ALL_FARM_SALE_USER_START, doGetListFarmSaleUser)
}
