import {
  GET_ALL_HASh_USER_START,
  GET_ALL_HASH_USER_SUCCESS,
  GET_ALL_HASH_USER_FAILED,
} from '../actions/hash'

const initialState = {
  isLoading: false,
  allHashUser: [],
  totalHashUser: 0,
  allHash: [],
  total: 0,
  itemDetail: {},
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function hashManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HASh_USER_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ALL_HASH_USER_SUCCESS:
      return {
        ...state,
        allHashUser: action?.payload?.items,
        totalHashUser: action?.payload?.totalHash,
        totalBoxUser: action?.payload?.totalBox,
        totalCountUser: action?.payload?.count,
        isLoading: false,
      }
    case GET_ALL_HASH_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
