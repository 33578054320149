import { Menu } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { getItemMenu } from '~/helpers/common'
import { ROUTE } from '~/views/routes/config'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { CaretUpOutlined } from '@ant-design/icons'
import { useAuth } from '~/views/redux/hooks/useAuth'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useSDK } from '@metamask/sdk-react'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'

export const SideBar = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { actions } = useAuth()
  const { sdk } = useSDK()
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const handleMenuItemClick = () => {
    setIsSubMenuOpen(true)
  }
  const { actions: actionTheme } = useTheme()
  const menuprofile = [
    getItemMenu(
      <div className="flex">
        <div className="mr-3" onClick={handleMenuItemClick}>
          {t(`profile.menu.myCreatedSales`)}
        </div>
        <CaretUpOutlined
          style={{ fontSize: 16 }}
          rotate={180}
          onClick={handleMenuItemClick}
        />
      </div>,
      'myCreatedSales',
      null,
      [
        getItemMenu(
          <div
            style={{ cursor: 'not-allowed' }}
            // to={ROUTE?.PROFILE_MONSTER_SALES.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_MONSTER_SALES.TITLE}`)}
              </span>
            </div>
          </div>,
          ROUTE.PROFILE_MONSTER_SALES.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.PROFILE_FARMS_SALES.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_FARMS_SALES.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_FARMS_SALES.PATH,
          null,
        ),
        getItemMenu(
          <Link
            // style={{ cursor: 'not-allowed' }}
            to={ROUTE?.PROFILE_ITEMS_SALES.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_ITEMS_SALES.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_ITEMS_SALES.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.PROFILE_HASH_SALES.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_HASH_SALES.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_HASH_SALES.PATH,
          null,
        ),
      ],
    ),

    getItemMenu(
      <div className="flex">
        <div className="mr-3" onClick={handleMenuItemClick}>
          {t(`profile.menu.myAssets`)}
        </div>
        <CaretUpOutlined
          style={{ fontSize: 16 }}
          rotate={180}
          onClick={handleMenuItemClick}
        />
      </div>,
      'myAssets',
      null,
      [
        getItemMenu(
          <div
            style={{ cursor: 'not-allowed' }}
            // to={ROUTE?.PROFILE_MONSTERS.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_MONSTERS.TITLE}`)}
              </span>
            </div>
          </div>,
          ROUTE.PROFILE_MONSTERS.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.PROFILE_FARMS.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_FARMS.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_FARMS.PATH,
          null,
        ),
        getItemMenu(
          <Link
            // style={{ cursor: 'not-allowed' }}
            to={ROUTE?.PROFILE_ITEMS.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_ITEMS.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_ITEMS.PATH,
          null,
        ),
        getItemMenu(
          <Link
            to={ROUTE?.PROFILE_HASH.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_HASH.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_HASH.PATH,
          null,
        ),
        getItemMenu(
          <div
            style={{ cursor: 'not-allowed' }}
            // to={ROUTE?.PROFILE_REWARDS.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_REWARDS.TITLE}`)}
              </span>
            </div>
          </div>,
          ROUTE.PROFILE_REWARDS.PATH,
          null,
        ),
      ],
    ),

    getItemMenu(
      <Link
        to={ROUTE?.PROFILE_BRIDGE.PATH}
        className={`navbar__left_element h-full w-full`}
      >
        <div className={` relative h-full w-full`}>
          <span className="w-full relative">
            {t(`profile.menu.${ROUTE.PROFILE_BRIDGE.TITLE}`)}
          </span>
        </div>
      </Link>,
      ROUTE.PROFILE_BRIDGE.PATH,
      null,
    ),

    getItemMenu(
      <Link
        to={ROUTE?.PROFILE_TRADE_HISTORY.PATH}
        className={`navbar__left_element h-full w-full`}
      >
        <div className={` relative h-full w-full`}>
          <span className="w-full relative">
            {t(`profile.menu.${ROUTE.PROFILE_TRADE_HISTORY.TITLE}`)}
          </span>
        </div>
      </Link>,
      ROUTE.PROFILE_TRADE_HISTORY.PATH,
      null,
    ),

    getItemMenu(
      <Link
        to={ROUTE?.PROFILE_ACCOUNT_SETTINGS.PATH}
        className={`navbar__left_element h-full w-full`}
      >
        <div className={` relative h-full w-full`}>
          <span className="w-full relative">
            {t(`profile.menu.${ROUTE.PROFILE_ACCOUNT_SETTINGS.TITLE}`)}
          </span>
        </div>
      </Link>,
      ROUTE.PROFILE_ACCOUNT_SETTINGS.PATH,
      null,
    ),
  ]
  const menuprofileMobile = [
    getItemMenu(
      <div className="flex">
        <div className="mr-3" onClick={handleMenuItemClick}>
          {t(`profile.menu.myProfile`)}
        </div>
        <CaretUpOutlined
          style={{ fontSize: 16 }}
          rotate={180}
          onClick={handleMenuItemClick}
        />
      </div>,
      'myProfile',
      null,
      [
        getItemMenu(
          <div className="flex">
            <div className="mr-3" onClick={handleMenuItemClick}>
              {t(`profile.menu.myCreatedSales`)}
            </div>
            <CaretUpOutlined
              style={{ fontSize: 16 }}
              rotate={180}
              onClick={handleMenuItemClick}
            />
          </div>,
          'myCreatedSales',
          null,
          [
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_MONSTER_SALES.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_MONSTER_SALES.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_MONSTER_SALES.PATH,
              null,
            ),
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_FARMS_SALES.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_FARMS_SALES.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_FARMS_SALES.PATH,
              null,
            ),
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_ITEMS_SALES.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_ITEMS_SALES.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_ITEMS_SALES.PATH,
              null,
            ),
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_HASH_SALES.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_HASH_SALES.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_HASH_SALES.PATH,
              null,
            ),
          ],
        ),

        getItemMenu(
          <div className="flex">
            <div className="mr-3" onClick={handleMenuItemClick}>
              {t(`profile.menu.myAssets`)}
            </div>
            <CaretUpOutlined
              style={{ fontSize: 16 }}
              rotate={180}
              onClick={handleMenuItemClick}
            />
          </div>,
          'myAssets',
          null,
          [
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_MONSTERS.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_MONSTERS.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_MONSTERS.PATH,
              null,
            ),
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_FARMS.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_FARMS.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_FARMS.PATH,
              null,
            ),
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_ITEMS.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_ITEMS.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_ITEMS.PATH,
              null,
            ),
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_HASH.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_HASH.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_HASH.PATH,
              null,
            ),
            getItemMenu(
              <Link
                to={ROUTE?.PROFILE_REWARDS.PATH}
                className={`navbar__left_element h-full w-full`}
              >
                <div className={` relative h-full w-full`}>
                  <span className="w-full relative">
                    {t(`profile.menu.${ROUTE.PROFILE_REWARDS.TITLE}`)}
                  </span>
                </div>
              </Link>,
              ROUTE.PROFILE_REWARDS.PATH,
              null,
            ),
          ],
        ),

        getItemMenu(
          <Link
            to={ROUTE?.PROFILE_BRIDGE.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_BRIDGE.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_BRIDGE.PATH,
          null,
        ),

        getItemMenu(
          <Link
            to={ROUTE?.PROFILE_TRADE_HISTORY.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_TRADE_HISTORY.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_TRADE_HISTORY.PATH,
          null,
        ),

        getItemMenu(
          <Link
            to={ROUTE?.PROFILE_ACCOUNT_SETTINGS.PATH}
            className={`navbar__left_element h-full w-full`}
          >
            <div className={` relative h-full w-full`}>
              <span className="w-full relative">
                {t(`profile.menu.${ROUTE.PROFILE_ACCOUNT_SETTINGS.TITLE}`)}
              </span>
            </div>
          </Link>,
          ROUTE.PROFILE_ACCOUNT_SETTINGS.PATH,
          null,
        ),
      ],
    ),
  ]
  const defaultOpenKeys = () => {
    if (
      location.pathname === ROUTE.PROFILE_MONSTER_SALES.PATH ||
      location.pathname === ROUTE.PROFILE_FARMS_SALES.PATH ||
      location.pathname === ROUTE.PROFILE_ITEMS_SALES.PATH ||
      location.pathname === ROUTE.PROFILE_HASH_SALES.PATH
    ) {
      return ['myProfile', 'myCreatedSales']
    } else if (
      location.pathname === ROUTE.PROFILE_MONSTERS.PATH ||
      location.pathname === ROUTE.PROFILE_FARMS.PATH ||
      location.pathname === ROUTE.PROFILE_ITEMS.PATH ||
      location.pathname === ROUTE.PROFILE_HASH.PATH ||
      location.pathname === ROUTE.PROFILE_REWARDS.PATH
    ) {
      return ['myProfile', 'myAssets']
    } else if (location.pathname === ROUTE.PROFILE_BRIDGE.PATH) {
      return ['myProfile', ROUTE.PROFILE_BRIDGE.PATH]
    } else if (location.pathname === ROUTE.PROFILE_TRADE_HISTORY.PATH) {
      return ['myProfile', ROUTE.PROFILE_TRADE_HISTORY.PATH]
    } else if (location.pathname === ROUTE.PROFILE_ACCOUNT_SETTINGS.PATH) {
      return ['myProfile', ROUTE.PROFILE_ACCOUNT_SETTINGS.PATH]
    }
    return []
  }
  return (
    <div
      className="relative md:sticky top-0 md:top-[108px] sidebar__profile flex items-center justify-between"
      style={{
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        ...(isMobile
          ? {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }
          : {}),
      }}
    >
      <Menu
        className="w-full "
        style={isMobile ? { marginLeft: '-25px', marginTop: '-15px' } : ''}
        triggerSubMenuAction={isSubMenuOpen ? 'click' : 'hover'}
        mode={isMobile ? 'inline' : 'horizontal'}
        items={isMobile ? menuprofileMobile : menuprofile}
        onClick={handleMenuItemClick}
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={isMobile ? defaultOpenKeys : ''}
      />
      <div
        className={`relative h-full w-[90px] text-[#FF4D4F] cursor-pointer`}
        onClick={() => {
          actionTheme.updateInfoUser(null)
          if (localStorage.getItem('wallet') === 'MW') {
            sdk?.terminate()
          }
          actions.logout({}, () => {
            // history.push('/')
            history.push('/farm')
          })
        }}
      >
        <span className="w-full relative text-[16px] ml-[3px] ">
          {t(`profile.menu.logOut`)}
        </span>
      </div>
    </div>
  )
}

export default SideBar
