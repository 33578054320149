import React, { useState } from 'react'
import { Button, Dropdown, Modal, Menu } from 'antd'

export default function ReceiveHashChip(props) {
  const { action, t, data, loadingModal } = props
  const [group, setSelectedOption] = useState('1') // Default value

  const handleSelectChange = ({ key }) => {
    setSelectedOption(key)
  }

  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }

  const OPTIONS = [
    {
      label: 'Group A',
      key: '0',
    },
    {
      label: 'Group B',
      key: '1',
    },
    {
      label: 'Group C',
      key: '2',
    },
    {
      label: 'Group D',
      key: '3',
    },
    {
      label: 'Group E',
      key: '4',
    },
  ]

  const menu = (
    <Menu onClick={handleSelectChange}>
      {OPTIONS.map((option) => (
        <Menu.Item key={option.key}>{option.label}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <>
      <Modal
        key={data?.type}
        centered
        title={
          <h3 className="flex justify-center text-xl font-bold">
            {t('modal.bridge_hashchip.title_receive')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            <Button
              key="ok"
              type="primary"
              onClick={() => action?.handleOk(data, group)}
              style={okButtonStyle}
              loading={loadingModal}
            >
              {t('modal.bridge_hashchip.claim')}
            </Button>
          </div>,
        ]}
      >
        <div className="text-left">
          <p>{t('modal.bridge_hashchip.description1')}</p>
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button className="flex bg-[#0d2758] px-[10px] py-[9px] h-[44px] w-[200px] ml-[17px]">
              {OPTIONS.find((option) => option.key === group)?.label ||
                'Select Group'}
            </Button>
          </Dropdown>
          <p>{t('modal.bridge_hashchip.description2')}</p>
          <p>{t('modal.bridge_hashchip.description3')}</p>
          <p className="ml-[17px]">
            {t('modal.bridge_hashchip.description3.1')}
          </p>
          <p>{t('modal.bridge_hashchip.description4')}</p>
          <p className="ml-[17px]">
            {t('modal.bridge_hashchip.description4.1')}
          </p>
          <p>{t('modal.bridge_hashchip.description5')}</p>
        </div>
      </Modal>
    </>
  )
}
