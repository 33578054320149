import React from 'react'
import { Button, Col, Form, Row, Select } from 'antd'
import lang from '~/assets/data/language.data.json'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'

export default function ChangeLanguage(props) {
  const { t } = props
  const [form] = Form.useForm()
  const {
    data: { locale },
    actions: actionTheme,
  } = useTheme()

  const {
    actions,
    data: { playerInfo },
  } = useAccountProfile()

  const handleSubmit = (values) => {
    actionTheme?.onLocaleChange(values?.lang)

    const params = {
      player_name: playerInfo.user_name,
      player_email: playerInfo.email,
      player_phone: playerInfo?.player_phone,
      player_language: values?.lang,
      player_twitter: playerInfo?.player_twitter,
    }
    actions.updateAccountProfile(params, (res) => {
      actionTheme.updateInfoUser(res?.player_info)
    })
  }

  return (
    <Form
      id="ChangeLanguage"
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      translate="yes"
      autoComplete="off"
      size="middle"
      validateTrigger={['onBlur', 'onChange']}
      requiredMark={false}
      initialValues={{ lang: locale }}
    >
      <Row className="mb-3 md:mb-0" gutter={10}>
        <Col xl={16} md={16} sm={24} xs={24}>
          <Form.Item
            label={
              <span className="font__M__plus font-bold">{t('language')}</span>
            }
            name="lang"
            labelAlign="left"
          >
            <Select
              className="h-[44px] font-bold "
              placeholder={t(
                'profile.accountSetting.personalInformation.email',
              )}
              suffixIcon={<CaretDownOutlined />}
              options={lang.map((item) => ({
                label: (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <img
                        alt=""
                        className="w-[24px] h-[24px] mr-2"
                        src={`${process.env.PUBLIC_URL}/imgs/flags/24x24/${item?.icon}.png`}
                      />
                      <span>{item?.langName}</span>
                    </div>

                    {locale === item.langId ? (
                      <CheckOutlined className="text-[#52c41b]" />
                    ) : null}
                  </div>
                ),
                value: item?.langId,
              }))}
            />
          </Form.Item>
        </Col>
        <Col
          xl={8}
          md={8}
          sm={24}
          xs={24}
          className="flex items-center justify-center w-full"
          style={{ minWidth: '100px' }}
        >
          <Button
            htmlType="submit"
            className="bg-[#1890FF] w-full h-[42px] mt-2"
            type="primary"
          >
            <span className="ml-[-6px]">{t('common.btn.change')}</span>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
