import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getMonsterHashDetailSuccess,
  getMonsterHashDetailFailed,
  GET_MONSTER_HASH_DETAIL_START,
} from '../../actions/monster'

/**
 * get detail monster hash
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getMonsterHashDetailApi = (props) => {
  var uri = `/monsters/hash/${props?.tokenId}/${props?.contract}`
  return api.get(uri,props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetMonsterHashDetail(action) {
  try {
    const response = yield call(getMonsterHashDetailApi, action?.payload)
    yield put(getMonsterHashDetailSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getMonsterHashDetailFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get detail monster hash
 */
export default function* watchGetMonsterHashDetail() {
  yield takeLatest(GET_MONSTER_HASH_DETAIL_START, doGetMonsterHashDetail)
}
