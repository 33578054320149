export const ButtonHexagon = ({ text, onClick, block, active,shadownBtn, borderColor, bgColor, textCss, customSize, clipPathOut, clipPathIn }) => {
    return (
      <div
        className={`flex items-center common__btn_hexagon relative cursor-pointer ${block && 'w-full'}`}
        onClick={() => {
            if(onClick){
              onClick()
            }
        }}
      >
        <div className={`relative flex common__btn_main ${shadownBtn ?? ""} w-[46px] justify-center  ${block && 'w-full'}`}>
          <div
            className={`${clipPathOut ?? 'hexagon__outside'} relative z-10 p-[12px] ${customSize ?? 'w-[40px] h-[46px]'}  flex items-center justify-center ${borderColor ?? 'bg-[#fff]'}
            `}
          >
            <div
              className={`${clipPathIn ?? 'hexagon__inside'} flex items-center ${active && 'active__hexagon'
                } ${bgColor ?? ''}`}
            >
              <div className={`relative whitespace-nowrap text-center w-full ${textCss}`}>
                {text ?? 'General'}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  