import React from 'react'
import { Button, Modal, Spin } from 'antd'
import configs from '~/configs'
import { getBgHash } from '~/helpers/get-color-parameter'
import { getbgQuality } from '~/helpers/quality'
import { getNameItemV1 } from '~/helpers/ItemName'
import {
  converImageGeneralBox,
  converImageGenesisBox,
} from '~/common/constants'
import { getImgByFarmTerrain } from '~/helpers/getValueByFarmTerrain'
import { SharedOnTwitter } from '~/components/shared-components/shared-twitter'

export default function ModalResultBridge(props) {
  const { action, t, txHash, dataResultBridge, isLoadingDataFarmResult } = props

  const link = `${configs.NETWORK.scan}tx/${txHash}`

  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#1890FF',
    width: '160px',
    height: '44px',
    borderRadius: '4px',
  }

  const hashStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '50%',
    color: '#40A9FF',
    marginLeft: '100px',
  }

  const renderItem = (item, index) => {
    let type_id
    switch (item?.type) {
      case 'Traning_Item':
        type_id = 0
        break
      case 'Enhance_Item':
        type_id = 1
        break
      case 'Fusion_Item':
        type_id = 2
        break
      case 'Regeneration_Item':
        type_id = 3
        break
      default:
        type_id = 0
        break
    }

    item.type_id = type_id

    return (
      <div key={index} className="flex items-center justify-center">
        <div className="card__monster_farm w-[180px] h-[180px]">
          <div className="relative flex items-center justify-center h-full">
            <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
              <div
                className="h-full flex flex-col p-[10px] text-[18px]"
                style={{ background: getbgQuality(item?.quality) }}
              >
                <div className="flex items-center">
                  <img
                    className="mr-2 w-[32px] h-[32px] object-contain"
                    src={`../svg/qualityTag/${item?.quality}.svg`}
                    alt=""
                  />
                  <div className="text-[14px]">{item?.name}</div>
                </div>
                <div className="h-full flex items-center justify-center">
                  <img
                    width={160}
                    height={160}
                    alt=""
                    loading="lazy"
                    className="relative card__monster_img w-[110px] h-[110px] object-contain ease-in-out duration-300 z-50"
                    src={
                      `${
                        process.env.REACT_APP_BASE_URL_IMAGE
                      }/public/image/image/items/${
                        getNameItemV1(item?.type_id, item?.nft_id)?.image
                      }.png` ?? '../imgs/hash/golem.png'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderHash = (item, index) => (
    <div key={index} className="flex items-center justify-center">
      <div className="card__monster_farm w-[180px] h-[180px]">
        <div className="relative flex items-center justify-center h-full">
          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
            <div
              className="h-full flex flex-col p-[10px] text-[18px]"
              style={{
                background: 'linear-gradient(180deg, #0D1426 0%, #375877 100%)',
              }}
            >
              <div className="flex items-start flex-col">
                <div className="text-[14px]">Hash Chip NFT (Redeemed)</div>
                <div className="relative left-0 text-[11px] z-50">
                  <div
                    className="text-[11px] font__M__plus font-bold min-w-[33px] h-[24px] items-center flex justify-center"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      borderRadius: 4,
                    }}
                  >
                    {item ? `#${item?.tokenId}` : ''}
                  </div>
                </div>
              </div>
              <div className="h-full flex items-center justify-center mt-[-20px]">
                <img
                  width={160}
                  height={160}
                  alt=""
                  loading="lazy"
                  className="relative card__monster_img w-[110px] h-[110px] object-contain ease-in-out duration-300 z-50"
                  src="../imgs/common/result.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const renderBox = (item, index) => {
    let image
    switch (item?.asset) {
      case 'GENESIS BOX':
        image = `../imgs/box/${converImageGenesisBox(
          item?.type.split(' ')[1],
        )}.png`
        break
      default:
        image = `../imgs/box/${converImageGeneralBox(
          item?.type.split(' ')[1],
        )}.png`
        break
    }

    return (
      <div key={index} className="flex items-center justify-center">
        <div className="card__monster_farm w-[180px] h-[180px]">
          <div className="relative flex items-center justify-center h-full">
            <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
              <div
                className="h-full flex flex-col p-[10px] text-[18px]"
                style={{ background: getBgHash(item?.type_name) }}
              >
                <div className="flex flex-col items-start absolute left-[2%] top-[2%]">
                  <div className="text-[14px]">{item?.type_name}</div>
                  <div className="text-[14px]">
                    {item?.type?.replace('GROUP', 'Group')}
                  </div>
                </div>
                <div
                  className="h-full flex items-center justify-center"
                  style={{ marginTop: '40px' }}
                >
                  <img
                    width={160}
                    height={160}
                    alt=""
                    loading="lazy"
                    className="relative card__monster_img w-[110px] h-[110px] object-contain ease-in-out duration-300 z-50"
                    src={image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderFarm = (item, index) => (
    <div key={index} className="flex items-center justify-center">
      <div className="card__monster_farm w-[180px] h-[180px]">
        <div className="relative flex items-center justify-center h-full">
          <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
            <div
              className="h-full flex flex-col p-[10px] text-[18px]"
              style={{
                backgroundImage: `url(../imgs/farm/bgFarm/${getImgByFarmTerrain(
                  item?.farm_terrain,
                )}.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="flex items-start flex-col">
                <div className="text-[14px]">{item?.farm_name ?? 'Farm'}</div>
                <div className="relative left-0 text-[11px] z-50">
                  <div
                    className="text-[11px] font__M__plus font-bold min-w-[33px] h-[24px] items-center flex justify-center"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      borderRadius: 4,
                    }}
                  >
                    {item ? `#${item?.farm_nft_id}` : ''}
                  </div>
                </div>
              </div>
              <div className="h-full flex items-center justify-center">
                <img
                  width={160}
                  height={160}
                  alt=""
                  loading="lazy"
                  className="relative card__monster_img w-[110px] h-[110px] object-contain ease-in-out duration-300 z-50"
                  src={`../imgs/farm/${getImgByFarmTerrain(
                    item?.farm_terrain,
                  )}.png`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const gatherAllItems = () => {
    const boxes = dataResultBridge.listGenesisBox
      ?.map((item) => `${item.type_name} ${item.type}`)
      .join(', ')
    const farms = dataResultBridge.farms
      ?.map((item) => `${item.farm_name ?? 'Farm'} #${item.farm_nft_id}`)
      .join(', ')
    const items = dataResultBridge.items
      ?.map((item) => `${item.name}`)
      .join(', ')
    const haschips = dataResultBridge.haschips
      ?.map((item) => `Hash Chip NFT #${item.tokenId}`)
      .join(', ')

    return [boxes, farms, items, haschips].filter(Boolean).join('\n ')
  }

  const allItems = gatherAllItems()
  return (
    <Modal
      centered
      title={
        <h3
          className="flex justify-center text-xl"
          style={{ fontWeight: '400' }}
        >
          {t('profile.menu.youReceived')}
        </h3>
      }
      width={900}
      open={action?.isModalOpen}
      onCancel={action?.handleCancel}
      footer={[
        <div key="buttons" className="flex justify-center">
          {/* <Button
            className="flex items-center justify-center bg-[#1890FF] w-[160px] h-[44px] mt-1"
            type="primary"
            key="ok"
            style={okButtonStyle}
            // loading={loadingModal}
          >
            <img
              className="w-[16px] h-[16px] mr-3"
              src="../svg/twitter.svg"
              alt=""
            />
            <div style={{ color: '#FFFFFF', fontSize: '16px' }}>
              {t('modal.transaction_completed_shop.share_x')}
            </div>
          </Button> */}
          <SharedOnTwitter
            title={`You Received`}
            okButtonStyle={okButtonStyle}
            urlShareTwitter={`${link}\n${allItems}`}
          />
        </div>,
      ]}
    >
      <Spin spinning={isLoadingDataFarmResult}>
        <div className="flex flex-wrap justify-center items-center gap-5">
          {dataResultBridge.listGenesisBox?.map(renderBox)}
          {dataResultBridge.farms?.map(renderFarm)}
          {dataResultBridge.items?.map(renderItem)}
          {dataResultBridge.haschips?.map(renderHash)}
        </div>
      </Spin>
    </Modal>
  )
}
