export const GET_ALL_GENERAL_USER_START = 'GET_ALL_GENERAL_USER_START'
export const GET_ALL_GENERAL_USER_SUCCESS = 'GET_ALL_GENERAL_USER_SUCCESS'
export const GET_ALL_GENERAL_USER_FAILED = 'GET_ALL_GENERAL_USER_FAILED'

export const GET_ALL_GENERAL_SALE_USER_START = 'GET_ALL_GENERAL_SALE_USER_START'
export const GET_ALL_GENERAL_SALE_USER_SUCCESS = 'GET_ALL_GENERAL_SALE_USER_SUCCESS'
export const GET_ALL_GENERAL_SALE_USER_FAILED = 'GET_ALL_GENERAL_SALE_USER_FAILED'
//-------------------------General------------------------//

/**
 * [1]
 * get all general user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllGeneralUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_GENERAL_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all general user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllGeneralUserSuccess(payload) {
  return {
    type: GET_ALL_GENERAL_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all general user failed action
 * @returns {object}
 */
export function getAllGeneralUserFailed() {
  return {
    type: GET_ALL_GENERAL_USER_FAILED,
  }
}

/**
 * [2]
 * get all general sale user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getAllGeneralSaleUser(payload, onSuccess, onError) {
  return {
    type: GET_ALL_GENERAL_SALE_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get all general sale user success action
 * @param {*} payload
 * @returns {object}
 */
export function getAllGeneralSaleUserSuccess(payload) {
  return {
    type: GET_ALL_GENERAL_SALE_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get all general sale user failed action
 * @returns {object}
 */
export function getAllGeneralSaleUserFailed() {
  return {
    type: GET_ALL_GENERAL_SALE_USER_FAILED,
  }
}

export default {
  getAllGeneralUser,
  getAllGeneralUserSuccess,
  getAllGeneralUserFailed,

  getAllGeneralSaleUser,
  getAllGeneralSaleUserSuccess,
  getAllGeneralSaleUserFailed
}
