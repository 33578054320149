import { CaretUpOutlined } from '@ant-design/icons'
import { Col, Row, Collapse } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { terrainCompatibilityData } from '../../Home/components/dataFilter'
import { boostParameterData, specialTrainningData, FarmTraitData } from './data'
import { getColorParameter, getParameter } from '~/helpers/get-color-parameter'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'

export const FilterFarm = (props) => {
  const location = useLocation()
  const {
    terrainCompatibility,
    setTerrainCompatibility,
    boostParameter,
    setBoostParameter,
    boostPotensial,
    setBoostPotensial,
    specialTrainning,
    setSpecialTrainning,
    farmTrait,
    setFarmTrait,
    classCustom,
  } = props

  const { t } = useTranslation()

  const clearAll = () => {
    setTerrainCompatibility([])
    setBoostParameter({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setBoostPotensial({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setSpecialTrainning({
      main_parameter: [],
      sub_parameter: [],
      decrement_parameter: [],
    })
    setFarmTrait([])
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  return (
    <div
      className={`${
        classCustom ?? 'filter__common'
      } bg-[#0d2758] p-[16px] max-w-[372px] w-[372px]`}
    >
      {isMobile ? (
        <></>
      ) : (
        <>
          {' '}
          <Row className="">
            <Col span={12}>
              <div className="text-[16px]"> {t('filter')}</div>
            </Col>
            <Col span={12} className="flex justify-end">
              <div
                className="text-[16px] text-[#FAAD14] cursor-pointer"
                onClick={() => {
                  clearAll()
                }}
              >
                {t('clearAll')}
              </div>
            </Col>
          </Row>
          <hr className="my-5" />
        </>
      )}

      <Collapse
        defaultActiveKey={[
          'terrainCompatibility',
          // 'boostParameter',
          // 'boostPotensial',
          // 'specialTrainning',
          // 'farmTrait',
        ]}
        expandIconPosition={'end'}
        items={[
          //terrainCompatibility
          {
            key: 'terrainCompatibility',
            label: t('common.filter.tag.terrainCompatibility'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {terrainCompatibilityData?.map((data, i) => (
                  <div
                    key={i?.toString()}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      terrainCompatibility?.find(
                        (item) => item.name === data?.name,
                      ) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      terrainCompatibility?.find(
                        (item) => item.name === data?.name,
                      )
                        ? setTerrainCompatibility(
                            terrainCompatibility.filter(
                              (item) => item.name !== data?.name,
                            ),
                          )
                        : setTerrainCompatibility((oldItem) => [
                            ...oldItem,
                            data,
                          ])
                    }}
                  >
                    {data?.value !== 0 && (
                      <img
                        style={{ width: 24, height: 24 }}
                        className="object-contain mr-1"
                        src={`../imgs/filter/${data?.name}.png`}
                        width={24}
                        height={24}
                        alt=""
                      />
                    )}

                    <div className={`${data?.color} font__M__plus font-bold`}>
                      {data?.name}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //boostParameter
          {
            key: 'boostParameter',
            label: t('common.filter.tag.boostParameter'),
            children: (
              <div className="flex flex-col gap-[10px]">
                {boostParameterData?.map((data) => (
                  <div className="flex flex-col" key={data?.type}>
                    <div className="text-[14px] mb-3 font__M__plus font-bold">
                      {t(data?.type)}
                    </div>

                    <div className="flex flex-wrap gap-[10px]">
                      {data?.rank?.map((subData) => (
                        <div
                          key={subData}
                          className={`flex items-center justify-center w-[40px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 ${
                            boostParameter[data?.type]?.find(
                              (rank) => rank === subData,
                            ) && 'bg-[#1950b3]'
                          }`}
                          onClick={() => {
                            const findBasicParameter = boostParameter[
                              data?.type
                            ]?.find((rank) => rank === subData)

                            if (findBasicParameter) {
                              const newObjParam = {
                                ...boostParameter,
                              }
                              newObjParam[data?.type] = newObjParam[
                                data?.type
                              ].filter((rank) => rank !== subData)
                              setBoostParameter(newObjParam)
                            } else {
                              const newObjParam = {
                                ...boostParameter,
                              }
                              newObjParam[data?.type] = [
                                ...boostParameter[data?.type],
                                subData,
                              ]
                              setBoostParameter(newObjParam)
                            }
                          }}
                        >
                          <img
                            className="w-[15px] h-[15px] object-contain"
                            src={`../svg/rankTagText/${subData}.svg`}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //boostPotensial
          {
            key: 'boostPotensial',
            label: t('common.filter.tag.boostPotensial'),
            children: (
              <div className="flex flex-col gap-[10px]">
                {boostParameterData?.map((data) => (
                  <div className="flex flex-col" key={data?.type}>
                    <div className="text-[14px] mb-3 font__M__plus font-bold">
                      {t(data?.type)}
                    </div>

                    <div className="flex flex-wrap gap-[10px]">
                      {data?.rank?.map((subData) => (
                        <div
                          key={subData}
                          className={`flex items-center justify-center w-[40px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 ${
                            boostPotensial[data?.type]?.find(
                              (rank) => rank === subData,
                            ) && 'bg-[#1950b3]'
                          }`}
                          onClick={() => {
                            const findBasicParameter = boostPotensial[
                              data?.type
                            ]?.find((rank) => rank === subData)

                            if (findBasicParameter) {
                              const newObjParam = {
                                ...boostPotensial,
                              }
                              newObjParam[data?.type] = newObjParam[
                                data?.type
                              ].filter((rank) => rank !== subData)
                              setBoostPotensial(newObjParam)
                            } else {
                              const newObjParam = {
                                ...boostPotensial,
                              }
                              newObjParam[data?.type] = [
                                ...boostPotensial[data?.type],
                                subData,
                              ]
                              setBoostPotensial(newObjParam)
                            }
                          }}
                        >
                          <img
                            className="w-[15px] h-[15px] object-contain"
                            src={`../svg/rankTagText/${subData}.svg`}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //specialTrainning
          {
            key: 'specialTrainning',
            label: t('common.filter.tag.specialTrainning'),
            children: (
              <div className="flex flex-col gap-[10px]">
                {specialTrainningData?.map((data) => (
                  <div className="flex flex-col" key={data?.type}>
                    <div className="text-[14px] mb-3 font__M__plus font-bold">
                      {t(data?.type)}
                    </div>

                    <div className="flex flex-wrap gap-[10px]">
                      {data?.rank?.map((subData) => (
                        <div
                          key={subData}
                          className={`flex items-center justify-center h-[40px] rounded-[8px] bg-[#00113F] p-[10px] cursor-pointer hover:bg-opacity-80 ${
                            specialTrainning[data?.type]?.find(
                              (rank) => rank === subData,
                            ) && 'bg-[#1950b3]'
                          }`}
                          onClick={() => {
                            const findBasicParameter = specialTrainning[
                              data?.type
                            ]?.find((rank) => rank === subData)

                            if (findBasicParameter) {
                              const newObjParam = {
                                ...specialTrainning,
                              }
                              newObjParam[data?.type] = newObjParam[
                                data?.type
                              ].filter((rank) => rank !== subData)
                              setSpecialTrainning(newObjParam)
                            } else {
                              const newObjParam = {
                                ...specialTrainning,
                              }
                              newObjParam[data?.type] = [
                                ...specialTrainning[data?.type],
                                subData,
                              ]
                              setSpecialTrainning(newObjParam)
                            }
                          }}
                        >
                          <img
                            className="w-[16px] h-[16px] object-contain mr-2"
                            src={`../imgs/filter/${getParameter(subData)}.png`}
                            alt=""
                          />
                          <div
                            className="text-[14px] font__M__plus font-bold"
                            style={{
                              color: getColorParameter(getParameter(subData)),
                            }}
                          >
                            {getParameter(subData)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
          //farmTrait
          {
            key: 'farmTrait',
            label: t('common.filter.tag.farmTrait'),
            children: (
              <div className="flex flex-wrap gap-[10px]">
                {FarmTraitData?.map((data) => (
                  <div
                    key={data}
                    className={`flex items-center justify-center min-w-[32px] h-[40px] rounded-[8px] bg-[#00113F] cursor-pointer hover:bg-opacity-80 py-[4px] px-[8px] ${
                      farmTrait?.find((rank) => rank === data) && 'bg-[#1950b3]'
                    }`}
                    onClick={() => {
                      farmTrait?.find((rank) => rank === data)
                        ? setFarmTrait(
                            farmTrait.filter((rank) => rank !== data),
                          )
                        : setFarmTrait((oldRank) => [...oldRank, data])
                    }}
                  >
                    <div className="text-[14px] font__M__plus font-bold">
                      {t(data)}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
        expandIcon={({ isActive }) => (
          <CaretUpOutlined
            style={{ fontSize: 16 }}
            rotate={isActive ? 0 : 180}
          />
        )}
      />
    </div>
  )
}
