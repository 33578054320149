import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { BoxCardHashChip } from '~/components/shared-components/Card/BoxCardHashChip'
import BridgeHashChip from '~/components/Modals/BridgeHashChip'
import ReceiveHashChip from '~/components/Modals/ReceiveHashChip'
import { useSDK } from '@metamask/sdk-react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import { api } from '~/services/api/blockchainApi'
import configs from '~/configs'
import {
  checkTransactionConfirmation,
  checkTransactionConfirmationPolygon,
  hexToNumberString,
} from '~/blockchain/provider'
import {
  ContractHashChipPolygon,
  ifaceContractClaimHashChip,
  ifaceContractDeposit,
  ifaceContractHashChipPolygon,
} from '~/blockchain/contract'
import { useAccount } from '~/common/hooks/useAccount'
import { NoData } from '~/components/shared-components/NoData'
import { Spin } from 'antd'
import ModalResultBridge from './modalResultBridge'

export const ProfileBridge = () => {
  const { t } = useTranslation()

  const [isModalDeposit, setIsModalDeposit] = useState(false)
  const [isModalClaim, setIsModalClaim] = useState(false)
  const [isModalClaimResult, setIsModalClaimResult] = useState(false)
  const { sdk } = useSDK()
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAccount()
  const [tokenDeposit, setTokenDeposit] = useState()
  const [listHashChipPolygon, setListHashChipPolygon] = useState([])
  const [listResultClaim, setListResultClaim] = useState([])
  const [listHashChipClaim, setListHashChipClaim] = useState([])
  const [isReload, setReload] = useState(false)
  const [isLoadingDataFarmResult, setLoadingDataFarmResult] = useState(false)

  // Ref to store interval ID
  const intervalRef = useRef(null)

  const showModalDeposit = (id) => {
    setIsModalDeposit(true)
    setTokenDeposit(id)
  }

  const showModalClaim = (id) => {
    setIsModalClaim(true)
    setTokenDeposit(id)
  }

  const handleCancel = () => {
    setIsModalDeposit(false)
    setIsModalClaim(false)
  }
  const handleCancelResult = () => {
    setIsModalClaimResult(false)
  }

  const getListHashChipApi = (props) => {
    var uri = `/api/hash-chip/balance`
    return api.get(uri, props)
  }

  const getListHashChipClaimApi = (props) => {
    var uri = `/api/bridge/get-token-bridge`
    return api.get(uri, props)
  }
  const getResultClaimApi = (props) => {
    var uri = `/api/bridge/result-bridge`
    return api.get(uri, props)
  }

  const signDataClaimApi = (props) => {
    var uri = `/api/bridge/sign`
    return api.post(uri, props)
  }

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const accounts = await sdk?.getProvider().request({
        method: 'eth_requestAccounts',
      })
      if (accounts && accounts.length > 0) {
        const data = await getListHashChipApi({ address: accounts[0] })
        const dataClaim = await getListHashChipClaimApi({
          address: accounts[0],
        })
        // const dataClaimResult = await getResultClaimApi({
        //   address: accounts[0],
        // })
        // setListResultClaim(dataClaimResult.result)
        setListHashChipPolygon(data.listToken)
        setListHashChipClaim(dataClaim.data)
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const timer = setTimeout(() => {
      fetchData()
    }, 6000)

    return () => clearTimeout(timer)
  }, [sdk, isLoadingModal, isReload])

  useEffect(() => {
    fetchData()
  }, [])

  const handleDeposit = async (data) => {
    setLoadingModal(true)

    try {
      await sdk.connect()
      await changeNetworkInMetamask(
        sdk.getProvider(),
        configs.POLYGON_NETWORK.chainId,
        setLoadingModal,
      )
      await sdk.connect()
      let networkId = await sdk
        .getProvider()
        .request({ method: 'eth_chainId', params: [] })
      networkId = hexToNumberString(networkId)

      if (parseInt(networkId) !== parseInt(configs.POLYGON_NETWORK.chainId))
        return
      let allowance = await ContractHashChipPolygon.getApproved(tokenDeposit)

      if (
        allowance.toLowerCase() ===
        configs.ADDRESS_DEPOSIT_POLYGON.toLowerCase()
      ) {
        deposit()
      } else {
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractHashChipPolygon.encodeFunctionData(
                  'approve',
                  [configs.ADDRESS_DEPOSIT_POLYGON, tokenDeposit],
                ),
                to: configs.ADDRESS_HASHCHIP_POLYGON,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmationPolygon(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log('Approve:', r, txHash)
                deposit()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
    }
  }

  const deposit = async () => {
    try {
      sdk
        .getProvider()
        .request({
          method: 'eth_sendTransaction',
          params: [
            {
              from: user.address_wallet,
              data: ifaceContractDeposit.encodeFunctionData('bridgeNFT', [
                tokenDeposit,
                configs.NETWORK.chainId,
              ]),
              to: configs.ADDRESS_DEPOSIT_POLYGON,
            },
          ],
        })
        .then((txHash) => {
          checkTransactionConfirmationPolygon(txHash).then(async (r) => {
            if (r === 'confirmed') {
              setReload(!isReload)
              setLoadingModal(false)
              handleCancel()
            }
          })
        })
        .catch((error) => {
          setLoadingModal(false)
          // eslint-disable-next-line
          console.error(error)
        })
    } catch (error) {
      setLoadingModal(false)
    }
  }

  const handleClaim = async (data, group) => {
    try {
      setLoadingModal(true)
      await sdk.connect()
      await changeNetworkInMetamask(
        sdk.getProvider(),
        configs.NETWORK.chainId,
        setLoadingModal,
      )
      await sdk.connect()
      let networkId = await sdk
        .getProvider()
        .request({ method: 'eth_chainId', params: [] })
      networkId = hexToNumberString(networkId)
      console.log(networkId)
      if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
      const dataSign = await signDataClaimApi({
        address: user.address_wallet,
        tokenId: tokenDeposit,
      })
      sdk
        .getProvider()
        .request({
          method: 'eth_sendTransaction',
          params: [
            {
              from: user.address_wallet,
              data: ifaceContractClaimHashChip.encodeFunctionData('claimNFT', [
                dataSign.data.address,
                dataSign.data.tokenId,
                group,
                2,
                dataSign.data.deadline,
                dataSign.data.signature,
              ]),
              to: configs.ADDRESS_CLAIM_HASHCHIP,
            },
          ],
        })
        .then((txHash) => {
          console.log(txHash)
          checkTransactionConfirmation(txHash).then(async (r) => {
            if (r === 'confirmed') {
              setReload(!isReload)
              setIsLoading(true)
              // setTimeout(async () => {
              //   try {
              //     const dataClaimResult = await getResultClaimApi({
              //       // address: user.address_wallet,
              //       txHash: txHash,
              //     })
              //     setIsModalClaimResult(true)
              //     setLoadingModal(false)
              //     setListResultClaim(dataClaimResult.result)
              //   } catch (error) {
              //     console.error('Error fetching claim result:', error)
              //   } finally {
              //     setIsLoading(false)
              //   }
              // }, 6000)
              intervalRef.current = setInterval(async () => {
                try {
                  const dataClaimResult = await getResultClaimApi({
                    // address: user.address_wallet,
                    txHash: txHash,
                  })
                  setIsModalClaimResult(true)
                  setLoadingModal(false)
                  setListResultClaim(dataClaimResult.result)
                  setLoadingDataFarmResult(true)

                  if (dataClaimResult.result?.farms?.length === 3) {
                    clearInterval(intervalRef.current)
                    setLoadingDataFarmResult(false)
                  }
                } catch (error) {
                  console.error('Error fetching claim result:', error)
                } finally {
                  setIsLoading(false)
                }
              }, 6000)

              handleCancel()
            }
          })
        })
        .catch((error) => {
          setLoadingModal(false)
          console.error(error)
        })
    } catch (error) {
      setLoadingModal(false)
    }
  }

  return (
    <BodyProfile>
      <div className="text-[24px] mb-4">{t('profile.menu.bridge')}</div>
      <Spin spinning={isLoading}>
        <div className="flex flex-wrap justify-center gap-10">
          {listHashChipPolygon?.map((data, i) => (
            <BoxCardHashChip
              key={i?.toString()}
              data={data}
              button={{
                title: t('modal.bridge_hashchip.buttonTitle'),
                functionButton: () => {
                  showModalDeposit(listHashChipPolygon[i])
                },
              }}
            />
          ))}
          {listHashChipClaim?.map((data, i) => (
            <BoxCardHashChip
              key={i?.toString()}
              data={data}
              button={{
                title: t('modal.bridge_hashchip.claim'),
                functionButton: () => {
                  showModalClaim(listHashChipClaim[i])
                },
              }}
            />
          ))}
        </div>
      </Spin>

      {listHashChipPolygon?.length === 0 &&
        listHashChipClaim?.length === 0 &&
        !isLoading && <NoData />}
      <BridgeHashChip
        action={{
          isModalOpen: isModalDeposit,
          handleOk: handleDeposit,
          handleCancel: handleCancel,
        }}
        tokenid={tokenDeposit}
        t={t}
        loadingModal={isLoadingModal}
      />
      <ReceiveHashChip
        action={{
          isModalOpen: isModalClaim,
          handleOk: handleClaim,
          handleCancel: handleCancel,
        }}
        t={t}
        loadingModal={isLoadingModal}
      />
      <ModalResultBridge
        action={{
          isModalOpen: isModalClaimResult,
          // handleOk: handleClaim,
          handleCancel: handleCancelResult,
        }}
        t={t}
        dataResultBridge={listResultClaim}
        isLoadingDataFarmResult={isLoadingDataFarmResult}
      />
    </BodyProfile>
  )
}

export default ProfileBridge
