import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Dropdown, Pagination, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  checkTransactionConfirmation,
  hexToNumberString,
} from '~/blockchain/provider'
import { FARM_SORT_SALE_USER, SCREEN_SIZE } from '~/common/constants'
import { useQueryState } from '~/common/hooks/useQueryState'
import { FarmsCard } from '~/components/shared-components/Card/FarmsCard'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useFarm } from '~/views/redux/hooks/useFarm'
import configs from '~/configs'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { NodataMonster } from '~/components/shared-components/NoData/NodataMonster'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { FilterFarm } from '~/views/app/Farm/components/FilterFarm'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'

export const FarmSale = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  // farm
  const [terrainCompatibility, setTerrainCompatibility] = useState([])
  const [boostParameter, setBoostParameter] = useState({
    health: [],
    strength: [],
    intelligent: [],
    dexterity: [],
    agility: [],
    vitality: [],
    fatigue: [],
    stress: [],
    rest: [],
  })
  const [boostPotensial, setBoostPotensial] = useState({
    health: [],
    strength: [],
    intelligent: [],
    dexterity: [],
    agility: [],
    vitality: [],
    fatigue: [],
    stress: [],
    rest: [],
  })

  const [specialTrainning, setSpecialTrainning] = useState({
    main_parameter: [],
    sub_parameter: [],
    decrement_parameter: [],
  })
  const [farmTrait, setFarmTrait] = useState([])

  const [sort, setSort] = useState(FARM_SORT_SALE_USER[0])
  const [typeSort, setTypeSort] = useState('-1')
  const {
    data: { user },
  } = useTheme()
  const [txHash, setTxHash] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [nftActive, setNftActive] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const {
    actions,
    data: { allFarmsUser, totalFarmsUser, isLoading },
  } = useFarm()
  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const {
    actions: actionsTema,
    data: { allTEMAs },
  } = useCommon()

  const handleChangeSort = (e) => {
    setSort(FARM_SORT_SALE_USER[e.key])
  }
  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }
  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      wallet_address: user.address_wallet,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      farm_terrain: terrainCompatibility?.map((data) => data?.value),
      boost_parameter: boostParameter,
      boost_potensial: boostPotensial,
      main_parameter: specialTrainning['main_parameter'],
      sub_parameter: specialTrainning['sub_parameter'],
      decrement_parameter: specialTrainning['decrement_parameter'],
      farm_trait_id: farmTrait,
    }
    actions.getAllFarmSaleUser(params)
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
  ])

  useEffect(() => {
    if (user) {
      refreshData()
      actionsTema.getTema()
    }
  }, [
    txHash,
    page,
    pageSize,
    sort,
    typeSort,
    terrainCompatibility,
    boostParameter,
    boostPotensial,
    specialTrainning,
    farmTrait,
  ])

  const showModalCancelSell = () => {
    setIsModalOpen(true)
  }

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setLoadingModal(false)
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccess()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccess()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterFarm, setOpenFilterFarm] = useState(false)
  const showDrawer = () => {
    setOpenFilterFarm(true)
  }

  const onClose = () => {
    setOpenFilterFarm(false)
  }
  const clearAll = () => {
    setTerrainCompatibility([])
    setBoostParameter({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setBoostPotensial({
      health: [],
      strength: [],
      intelligent: [],
      dexterity: [],
      agility: [],
      vitality: [],
      fatigue: [],
      stress: [],
      rest: [],
    })
    setSpecialTrainning({
      main_parameter: [],
      sub_parameter: [],
      decrement_parameter: [],
    })
    setFarmTrait([])
  }
  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh] filter_profile'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterFarm
                terrainCompatibility={terrainCompatibility}
                boostParameter={boostParameter}
                boostPotensial={boostPotensial}
                specialTrainning={specialTrainning}
                farmTrait={farmTrait}
                setTerrainCompatibility={setTerrainCompatibility}
                setBoostParameter={setBoostParameter}
                setBoostPotensial={setBoostPotensial}
                setSpecialTrainning={setSpecialTrainning}
                setFarmTrait={setFarmTrait}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0  relative md:sticky pt-[1.5rem] top-0 sm:top-[104px] pb-12">
            <div className="text-[24px] mb-4 uppercase">
              {t('profile.menu.farms')}
            </div>
            <div className="flex justify-between flex-wrap mb-4">
              <div className="text-[24px]">
                {t('countFarm', {
                  total: totalFarmsUser,
                })}
                {totalFarmsUser > 1 ? t('more') : ''}
              </div>
              {isMobile ? (
                <>
                  <div className="flex items-center text-[16px] gap-2">
                    <Dropdown
                      className="flex items-center cursor-pointer sort__value"
                      menu={{
                        items: FARM_SORT_SALE_USER,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {t(`common.filter.sort.${sort?.label}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>
                    <div>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                      >
                        <FilterOutlined />
                        {t('filter')}
                      </Button>
                      <Drawer
                        title={t('filter')}
                        width={330}
                        placement="left"
                        onClose={onClose}
                        open={openFilterFarm}
                        extra={
                          <div
                            className="text-[16px] text-[#FAAD14] cursor-pointer"
                            onClick={() => {
                              clearAll()
                            }}
                          >
                            {t('clearAll')}
                          </div>
                        }
                      >
                        <div className="flex w-full h-full">
                          <FilterFarm
                            terrainCompatibility={terrainCompatibility}
                            setTerrainCompatibility={setTerrainCompatibility}
                            boostParameter={boostParameter}
                            setBoostParameter={setBoostParameter}
                            boostPotensial={boostPotensial}
                            setBoostPotensial={setBoostPotensial}
                            specialTrainning={specialTrainning}
                            setSpecialTrainning={setSpecialTrainning}
                            farmTrait={farmTrait}
                            setFarmTrait={setFarmTrait}
                            classCustom="filter__profile"
                          />
                        </div>
                      </Drawer>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center text-[16px] gap-2">
                    <div> {t('sortBy')}</div>

                    <Dropdown
                      className="flex items-center cursor-pointer ml-3  sort__value"
                      menu={{
                        items: FARM_SORT_SALE_USER,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {t(`common.filter.sort.${sort?.label}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>
                  </div>
                </>
              )}
            </div>

            <Spin spinning={isLoading}>
              <div className="flex flex-wrap justify-center gap-4">
                {allFarmsUser?.map((data, i) => (
                  <FarmsCard
                    key={i?.toString()}
                    data={data}
                    button={{
                      title: 'Cancel',
                      functionButton: () => {
                        setNftActive(data)
                        showModalCancelSell()
                      },
                    }}
                    tema={allTEMAs}
                  />
                ))}
              </div>
            </Spin>
            {!isLoading && totalFarmsUser === 0 && <NodataMonster />}

            {totalFarmsUser > 0 && (
              <div className="pagination__common">
                <Pagination
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                  className="mt-5"
                  size="small"
                  locale={{ jump_to: 'Go to', page: '' }}
                  total={totalFarmsUser}
                  defaultCurrent={page}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                  showTitle
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <CancelSell
        action={{
          isModalOpen: isModalOpen,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
        loadingModal={isLoadingModal}
        // name={'Monster'}
        // tema={tema}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        name={`${nftActive?.farm_name} (#${nftActive?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
    </BodyProfile>
  )
}
export default FarmSale
