import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Modal, Form, InputNumber } from 'antd'
import { formatNumber, getFeeMKP } from '~/helpers/common'
export default function SellToMKPModal(props) {
  // eslint-disable-next-line
  const { name, action, t, tokenID, loadingModal, tema, item, quanlityTotal } =
    props
  const [price, setPrice] = useState()
  const [quantity, setQuantity] = useState()
  const [totalPrice, setTotalPrice] = useState(0)
  const [fee, setFee] = useState(0)
  const [form] = Form.useForm()
  const priceSell = useRef(null)
  const quantitySell = useRef(null)
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }

  const onChangePrice = (e) => {
    setPrice(e.target.value)
    if (
      e.target.value &&
      quantity &&
      quantity <= quanlityTotal &&
      e.target.value >= 0
    ) {
      let total = Number(e.target.value) * quantity
      setTotalPrice(total)
    }
    if (e.target.value === null) {
      setTotalPrice(0)
    }
  }

  const onChangeQuantity = (e) => {
    setQuantity(e.target.value)
    if (
      price &&
      e.target.value &&
      e.target.value <= quanlityTotal &&
      e.target.value >= 0 &&
      price >= 0
    ) {
      let total = price * e.target.value
      setTotalPrice(total)
    }
    if (e.target.value === null) {
      setTotalPrice(0)
    }
  }

  const handelSell = async () => {
    if (loadingModal) return
    form.submit()
  }

  const handleCancel = () => {
    action?.handleCancel()
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const feeBlc = await getFeeMKP()
      setFee(feeBlc)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (action?.isModalOpen) {
      form.resetFields()
      setTotalPrice(0)
      setPrice(0)
      setQuantity(0)
    }
  }, [action?.isModalOpen])
  return (
    <>
      <Form
        form={form}
        preserve={false}
        onFinish={() => action?.handleOk(tokenID, price, quantity)}
      >
        <Modal
          key={tokenID}
          centered
          title={
            <h3 className="flex justify-center text-xl font-bold">
              {t('modal.sell_mkp.title')}
            </h3>
          }
          open={action?.isModalOpen}
          onCancel={handleCancel}
          footer={[
            <div key="buttons" className="flex justify-center">
              <Button
                key="cancel"
                onClick={handleCancel}
                style={cancelButtonStyle}
              >
                {t('modal.sell_mkp.cancel')}
              </Button>
              <Button
                key="ok"
                type="primary"
                onClick={handelSell}
                style={okButtonStyle}
                loading={loadingModal}
              >
                {t('modal.sell_mkp.ok')}
              </Button>
            </div>,
          ]}
        >
          <div className="pb-5 text-[16px]">
            {t('modal.sell_mkp.description', {
              name: `${name}`,
            })}
          </div>
          <div className="text-[16px]">{t('modal.sell_mkp.price')}</div>
          <div className="py-2">
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: t('common.schema.required'),
                },
                () => ({
                  validator(rule, value) {
                    const numberPattern = /^-?\d+(\.\d+)?$/
                    if (value && value <= 0) {
                      return Promise.reject(t('common.schema.priceNumericSell'))
                    }

                    if (value && !numberPattern.test(value)) {
                      return Promise.reject(t('common.schema.mustBeNumeric'))
                    }
                    if (value === 0) {
                      return Promise.reject(t('common.schema.priceNumericSell'))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input
                maxLength={9}
                autoComplete="off"
                className="input-numberSell"
                onChange={onChangePrice}
                placeholder={t('modal.sell_mkp.placeholder_price')}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                suffix={'OAS'}
              />
            </Form.Item>
          </div>
          <div className="flex justify-between">
            <div className="text-[16px]">{t('modal.sell_mkp.quantity')}</div>
            <div>
              {t('modal.sell_mkp.max', {
                quanlityTotal: quanlityTotal,
              })}
            </div>
          </div>
          <div className="py-2">
            <Form.Item
              name="quantity"
              rules={[
                {
                  required: true,
                  message: t('common.schema.required'),
                },
                () => ({
                  validator(rule, value) {
                    if (value && value > quanlityTotal) {
                      return Promise.reject(t('common.schema.totalQuantity'))
                    }
                    if (value === 0) {
                      return Promise.reject(t('common.schema.quantityNumeric'))
                    }
                    if (value && value <= 0) {
                      return Promise.reject(t('common.schema.quantityNumeric'))
                    }
                    const integerPattern = /^-?\d+$/
                    if (value && !integerPattern.test(value)) {
                      return Promise.reject(t('common.schema.mustBeNumeric'))
                    }
                    const numberPattern = /^-?\d+(\.\d+)?$/
                    if (value && !numberPattern.test(value)) {
                      return Promise.reject(t('common.schema.mustBeNumeric'))
                    } else if (value > item?.quantity) {
                      return Promise.reject(t('common.schema.insufficient'))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input
                maxLength={7}
                autoComplete="off"
                className=" input-numberSell"
                onChange={onChangeQuantity}
                placeholder={t('modal.sell_mkp.placeholder_quantity')}
                // ref={quantitySell}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
          </div>
          <div className="flex justify-between pb-4 text-[16px]">
            <div>{t('modal.sell_mkp.total_price')}</div>
            <div>{formatNumber(totalPrice)} OAS</div>
          </div>
          <div className="flex justify-between pb-4 text-[16px]">
            <div>{t('modal.sell_mkp.fee')}</div>
            <div>{fee}%</div>
          </div>
          <div className="flex justify-between font-bold text-[16px]">
            <div>{t('modal.sell_mkp.receive')}</div>
            <div>{formatNumber(totalPrice * (1 - fee / 100))} OAS</div>
          </div>
        </Modal>
      </Form>
    </>
  )
}
