import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/gameApi'
import {
  updateAvatarSuccess,
  updateAvatarFailed,
  UPDATE_AVATAR_START,
} from '../../actions/accountProfile'

/**
 * get Account Profile api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateAvatarAccountApi = (props) => {
  var uri = `/api/user/updateavatar`

  return api.postMultiplePart(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateAvatarAccount(action) {
  try {
    const response = yield call(updateAvatarAccountApi, action?.payload)
    // if (response?.status_code === 200) {
    yield put(updateAvatarSuccess(response?.data))

    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
    // } else {
    //   throw response?.data?.message
    // }
  } catch (error) {
    yield put(updateAvatarFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch Update Avatar role
 */
export default function* watchUpdateAvatarAccount() {
  yield takeLatest(UPDATE_AVATAR_START, doUpdateAvatarAccount)
}
