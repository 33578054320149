import { Drawer, Image } from 'antd'
import NavMenuData from './Header/NavMenuData'
import { ROUTE } from '~/views/routes/config'
import { Link } from 'react-router-dom'
import { CopyAddressCommon } from '../shared-components/CopyAddressCommon'
import { formatLongText, formatNumber } from '~/helpers/common'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { ReMonsterTwoBorder } from '../shared-components/Button/ReMonsterTwoBorder'
import SideBar from '../shared-components/BodyProfile/Component/SideBar'
import BodyProfile from '../shared-components/BodyProfile'

export const NavMobile = ({ mobileNav, onMobileNavToggle }) => {
  const onClose = () => {
    onMobileNavToggle(false)
  }
  const {
    data: { balanceOAS, user },
  } = useTheme()
  return (
    <>
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        open={mobileNav}
        styles={{ body: { padding: 5 } }}
      >
        <div className="flex flex-col items-start gap-3">
          <div className="w-full ">
            <Link
              // to={ROUTE?.HOME?.PATH}
              to={ROUTE?.FARM?.PATH}
              className={`navbar__left_element flex h-full w-full justify-center`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/imgs/common/reMonsterLogo.png`}
                alt=""
              />
            </Link>
          </div>
          {user && (
            <>
              <Link
                // to={ROUTE?.PROFILE_MONSTER_SALES.PATH}
                to={ROUTE?.PROFILE_FARMS_SALES.PATH}
                className={`h-full w-full`}
              >
                <div
                  className="flex items-center"
                  style={{ cursor: 'pointer' }}
                >
                  <div className="flex items-center">
                    <Image
                      className="rounded-full"
                      width={48}
                      height={48}
                      src={`${user?.avatar}`}
                      fallback={`${process.env.PUBLIC_URL}/imgs/common/avatar-default.png`}
                      loading="lazy"
                      preview={false}
                    />
                  </div>
                  <div className="ml-2">
                    <div>{formatLongText(user?.user_name)}</div>
                    <div className="flex gap-1">
                      {/* {formatAddress(user.address_wallet)} */}
                      <CopyAddressCommon>
                        {user.address_wallet}
                      </CopyAddressCommon>
                      {localStorage.getItem('wallet') === 'MW' ? (
                        <img
                          className="w-[25px] h-[25px] object-contain"
                          src={`${process.env.PUBLIC_URL}/imgs/logo/metamask.png`}
                          alt=""
                        />
                      ) : (
                        <img
                          className="w-[20px] h-[20px] object-contain"
                          src={`${process.env.PUBLIC_URL}/imgs/logo/oas-wallet.png`}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Link>
              <div className="flex justify-between	w-full">
                <div className="ml-[7px]">
                  <ReMonsterTwoBorder
                    price={formatNumber(user?.bit_balance)}
                    icon={`${process.env.PUBLIC_URL}/svg/coins/bit.svg`}
                  />
                </div>
                <div className="mr-[7px]">
                  <ReMonsterTwoBorder
                    price={formatNumber(balanceOAS)}
                    icon={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                  />
                </div>
              </div>
            </>
          )}
          <NavMenuData />
          {user && (
            <div className="ml-[26px] w-[386px]">
              <SideBar />
            </div>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default NavMobile
