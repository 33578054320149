import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getAllGenesisUserSuccess,
  getAllGenesisUserFailed,
  GET_ALL_GENESIS_USER_START,
} from '../../actions/genesis'

/**
 * get list genesis user
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListGenesisUserApi = (props) => {
  var uri = `/users/genesis`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListGenesisUser(action) {
  try {
    const response = yield call(getListGenesisUserApi, action?.payload)
    yield put(getAllGenesisUserSuccess(response?.data))

    // Call callback action if provided
    if (action.onSuccess) {

      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getAllGenesisUserFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list genesis user
 */
export default function* watchGetListGenesisUser() {
  yield takeLatest(GET_ALL_GENESIS_USER_START, doGetListGenesisUser)
}
