import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '~/services/api/gameApi'

import { loginFailed, loginSuccess, LOGIN_START } from '../../actions/auth'
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  USER_INFO,
} from '~/common/constants/ApiConstant'
import { NOTIFICATION_DURATION } from '~/common/constants'
import { notification } from 'antd'

/**
 * Login
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const loginApi = (params) => {
  const uri = `/api/user/walletlogin`
  return api.post(uri, params)
}

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doLogin(action) {
  try {
    const response = yield call(loginApi, action?.payload)
    if (response?.success === true) {
      const { data } = response
      if (!data) {
        notification.error({
          message: 'LOGIN FAILED',
          duration: NOTIFICATION_DURATION,
        })
        return
      }
      // Save token to local storage
      localStorage.setItem(AUTH_TOKEN, data.token)
      // Save refresh token to local storage
      localStorage.setItem(REFRESH_TOKEN, 'REFRESH_TOKEN')
      // Save user infomation to local storage
      localStorage.setItem(USER_INFO, JSON.stringify({ ...data.user }))

      yield put(loginSuccess({ ...data.user }))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess({ ...data.user })
      }
    } else {
      notification.error({
        message: 'LOGIN FAILED',
        duration: NOTIFICATION_DURATION,
      })
      throw response?.data?.message
    }
  } catch (error) {
    yield put(loginFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * Watch login
 */
export default function* watchLogin() {
  yield takeLatest(LOGIN_START, doLogin)
}
