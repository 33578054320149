import { Col, Row } from 'antd'
import SideBar from './Component/SideBar'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '~/common/constants'

export const BodyProfile = ({ children }) => {
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  return (
    <div className="relative h-screen ">
      <div className="block__ground h-full">
        <Row className="profile__body container__common mx-auto p-[10px] !pt-6 relative top-[84px] ">
          <Col xl={24} md={24} sm={24} xs={24} className="relative">
            {!isMobile && <SideBar />}
          </Col>

          <Col xl={24} md={24} sm={24} xs={24}>
            <div className={`${isMobile ? 'mt-[-22px]' : ''} pt-[1.5rem]`}>
              {children}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BodyProfile
